import "./productTile.html";
import { BaseComponent } from "../../Core/BaseComponent";
import * as angular from "angular";
import { Binding } from "../../Utils/AngularBindings";
import "./productTile.less";
import "./productTile.service";
import "./productTile.controller";

export class ProductTileComponent extends BaseComponent {
  constructor() {
    super(
      "Rhinestone.ProductTileController",
      "/templates/tiles/productTile/productTile.html"
    );

    this.bindings = {
      tile: Binding.oneWay
    };
  }
}

angular
  .module("PortalApp")
  .component("rhProductTile", new ProductTileComponent());
