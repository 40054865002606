import * as angular from "angular";

export class UrlEncode {
  public static Factory(): any {
    const filterFac = () => {
      return (url: string) => {
        return encodeURI(url);
      };
    };
    return filterFac;
  }
}

angular.module("PortalApp").filter("urlEncode", UrlEncode.Factory());
