import * as angular from "angular";
import { IPortalConfig } from "../../Core/portal.provider";
import { SearchSubscriptionService } from "../searchSubscription.service";
import { SearchSubscription } from "../searchSubscription";

export class ConfirmSearchSubscriptionViewController {
  private subscriptionId: string;
  private ticket: string;

  public email: string;

  public subscription: SearchSubscription;
  public emailAccountLink: string;
  public verifying = true;
  public errorMessage: any;

  public static $inject = [
    "$routeParams",
    "$location",
    "portal",
    "searchSubscriptionService"
  ];

  constructor(
    $routeParams: any,
    private $location: angular.ILocationService,
    private portal: IPortalConfig,
    private subscriptionService: SearchSubscriptionService
  ) {
    this.subscriptionId = $routeParams.id;
    this.email = $routeParams.email;
    this.ticket = $routeParams.ticket;
  }

  public async $onInit() {
    try {
      this.subscription = await this.subscriptionService.getSubscription(
        this.email,
        this.subscriptionId,
        this.ticket
      );
      this.emailAccountLink = `${this.$location.protocol()}://${this.$location.host()}/Abonnementer/Afmeld?email=${
        this.subscription.emailAddress
      }&ticket=${this.ticket}`;

      if (this.subscription.confirmed) {
        this.verifying = false;
      } else {
        await this.subscriptionService.confirmSubscription(
          this.email,
          this.subscriptionId,
          this.ticket
        );
        this.verifying = false;
      }
    } catch (error) {
      this.errorMessage = error;
    }
  }
}

angular
  .module("PortalApp")
  .controller(
    "Rhinestone.ConfirmSearchSubscriptionViewController",
    ConfirmSearchSubscriptionViewController
  );
