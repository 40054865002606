import "./assetsMembers.html";
import { BaseComponent } from "../Core/BaseComponent";
import * as angular from "angular";
import { Binding } from "../Utils/AngularBindings";
import "./assetsMembers.controller";
export class AssetCollectionMembersComponent extends BaseComponent {
  constructor() {
    super(
      "Rhinestone.AssetCollectionMembersController",
      "/templates/assets/assetsMembers"
    );
    this.bindings = {
      assetCollection: Binding.oneWay
    };
  }
}

angular
  .module("PortalApp")
  .component("rhAssetMembers", new AssetCollectionMembersComponent());
