import * as angular from "angular";
import { openAsPdf } from "@rhinestone/portal-web-react";

export interface IDocumentPrintService {
  convertToBlobAndOpenAsPdf(data: any, nameOfFile?: string): void;
}

export class DocumentPrintService implements IDocumentPrintService {
  public static $inject: string[] = [];

  constructor() {}

  public convertToBlobAndOpenAsPdf(data: any, nameOfFile?: string): void {
    openAsPdf(data, nameOfFile);
  }
}

angular
  .module("rhDocuments")
  .service("documentPrintService", DocumentPrintService);
