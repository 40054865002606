import "./error.html";
import { BaseComponent } from "../Core/BaseComponent";
import * as angular from "angular";
import { Binding } from "../Utils/AngularBindings";
import "./error.less";
import "./error.Controller";
export class ErrorComponent extends BaseComponent {
  constructor() {
    super("Rhinestone.ErrorController", "/templates/controls/error");

    this.bindings = {
      message: Binding.oneWay
    };
  }
}

angular.module("PortalApp").component("rhError", new ErrorComponent());
