export function buildTrimmedTitle(
  documentTitle: string,
  sliceTitle: string,
  sectionMaxLength: number,
  titleMaxLength: number
): string {
  let splitter = " "; // splitter between title and section

  const trimmedSliceTitle = sliceTitle.substring(
    0,
    Math.max(
      sectionMaxLength,
      titleMaxLength - documentTitle.length - splitter.length
    )
  );

  if (
    documentTitle.length >
    titleMaxLength - trimmedSliceTitle.length - splitter.length
  ) {
    splitter = "... "; // add ... between title and section - if title is trimmed
  }

  const trimmedTitle = documentTitle.substring(
    0,
    titleMaxLength - trimmedSliceTitle.length - splitter.length
  );
  return `${trimmedTitle}${splitter}${trimmedSliceTitle}`;
}
