import "./searchResultMobile.html";
import * as angular from "angular";
import { BaseComponent } from "./../../Core/BaseComponent";
import { Binding } from "../../Utils/AngularBindings";
import "./searchResultMobile.less";
import "./searchResultMobile.controller";
export class SearchResultMobileComponent extends BaseComponent {
  constructor() {
    super(
      "Rhinestone.SearchResultMobileController",
      "/templates/Search/SearchResultMobile/searchResultMobile.html"
    );
    this.bindings = {
      result: Binding.oneWay,
      isLoading: Binding.oneWay,
      hasError: Binding.oneWay,
      resultView: Binding.oneWay,
      isAuthenticated: Binding.oneWay,
      isMobile: Binding.oneWay,
      loadMore: Binding.outputEvent
    };
  }
}

angular
  .module("PortalApp")
  .component("rhSearchResultMobile", new SearchResultMobileComponent());
