import "./assetsList.html";
import { BaseComponent } from "../Core/BaseComponent";
import * as angular from "angular";
import { Binding } from "../Utils/AngularBindings";
import "./assetsList.Controller";
import "./assetsList.less";
import "./assetsListSearchBox.component";

export class AssetListComponent extends BaseComponent {
  constructor() {
    super("Rhinestone.AssetsListController", "/templates/Assets/assetsList");
    this.bindings = {
      archived: Binding.oneWay
    };
  }
}

angular.module("PortalApp").component("rhAssetList", new AssetListComponent());
