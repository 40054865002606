import { INotificationService } from "./notification.service";
import * as angular from "angular";

export interface ILocalizedNotificationService {
  success(translationId: string, interpolateParams?: any): Promise<void>;
  warning(translationId: string, interpolateParams?: any): Promise<void>;
  error(translationId: string, interpolateParams?: any): Promise<void>;
  busy(
    translationId: string,
    interpolateParams?: any
  ): angular.IPromise<JQuery>;
  hide(notification: angular.IPromise<JQuery>): void;
}

export class LocalizedNotificationService
  implements ILocalizedNotificationService {
  public static $inject = ["$translate", "notificationService"];

  constructor(
    private $translate: angular.translate.ITranslateService,
    private notificationService: INotificationService
  ) {}

  public async success(
    translationId: string,
    interpolateParams?: any
  ): Promise<void> {
    const translation = await this.$translate(translationId, interpolateParams);
    this.notificationService.success(translation);
  }

  public async warning(
    translationId: string,
    interpolateParams?: any
  ): Promise<void> {
    const translation = await this.$translate(translationId, interpolateParams);
    this.notificationService.warning(translation);
  }

  public async error(
    translationId: string,
    interpolateParams?: any
  ): Promise<void> {
    const translation = await this.$translate(translationId, interpolateParams);
    this.notificationService.error(translation);
  }

  public busy(
    translationId: string,
    interpolateParams?: any
  ): angular.IPromise<JQuery> {
    return this.$translate(translationId, interpolateParams).then(message => {
      return this.notificationService.busy(message);
    });
  }

  public hide(notification: angular.IPromise<JQuery>): void {
    return this.notificationService.hide(notification);
  }
}

angular
  .module("PortalApp")
  .service("localizedNotificationService", LocalizedNotificationService);
