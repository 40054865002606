import "./searchBox.html";
import { BaseComponent } from "../../Core/BaseComponent";
import * as angular from "angular";
import { Binding } from "../../Utils/AngularBindings";
import "./searchBox.less";
import "./searchBox.controller";

export class SearchBoxComponent extends BaseComponent {
  constructor() {
    super(
      "Rhinestone.SearchBoxController",
      "/templates/Search/SearchBox/searchBox.html"
    );
    this.bindings = {
      onSubmit: Binding.outputEvent,
      onLinked: Binding.outputEvent,
      isLoading: Binding.oneWay,
      searchText: Binding.twoWay,
      autoFocus: Binding.oneWay
    };
  }
}

angular.module("PortalApp").component("rhSearchBox", new SearchBoxComponent());
