import "./subscriptionsList.html";
import { BaseComponent } from "../Core/BaseComponent";
import * as angular from "angular";
import "./subscriptionsList.controller";
import { Binding } from "../Utils/AngularBindings";

export class SubscriptionsListComponent extends BaseComponent {
  constructor() {
    super(
      "Rhinestone.SubscriptionsListController",
      "/templates/Subscriptions/subscriptionsList"
    );

    this.bindings = {
      subscriptions: Binding.oneWay,
      onSubscriptionRestore: Binding.outputEvent,
      onSubscriptionDelete: Binding.outputEvent,
      onSubscriptionUpdate: Binding.outputEvent
    };
  }
}

angular
  .module("PortalApp")
  .component("rhSubscriptionsList", new SubscriptionsListComponent());
