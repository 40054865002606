import { AssetsService } from "./assets.service";
import * as angular from "angular";
import { NewAssetCollectionResult } from "./NewCollectionFromQuoteDialogController";
import * as _ from "underscore";
import { AssetCollectionViewModel } from "@rhinestone/portal-web-api";
import "./ExistingCollectionFromQuoteDialog.html";

export class ExistingCollectionFromQuoteDialogController {
  public static $inject = ["assetsService", "$uibModalInstance"];

  public assetCollections: AssetCollectionViewModel[];
  public selectedCollectionId: string = "";

  constructor(
    private assetsService: AssetsService,
    private $uibModalInstance: angular.ui.bootstrap.IModalServiceInstance
  ) {
    this.loadCollections();
  }

  public loadCollections(): any {
    return this.assetsService.getCollections().then(data => {
      this.assetCollections = data;
    });
  }

  public canAdd(): boolean {
    return this.selectedCollectionId !== "";
  }

  public add(): void {
    const selected = this.getSelectedCollection();
    this.$uibModalInstance.close(
      new NewAssetCollectionResult(selected.Title, selected.assetCollectionId)
    );
  }

  private getSelectedCollection(): any {
    const item = _.find(this.assetCollections, col => {
      return col.assetCollectionId === this.selectedCollectionId;
    });

    return item;
  }

  private getSelectedCollectionName(): string {
    const item = _.find(this.assetCollections, col => {
      return col.assetCollectionId === this.selectedCollectionId;
    });

    return item ? item.title : "";
  }

  public cancel(): void {
    this.$uibModalInstance.dismiss("cancel");
  }
}

export class ExistingAssetCollectionResult {
  constructor(public title: string, public referenceNumber: string) {}
}

angular
  .module("PortalApp")
  .controller(
    "Rhinestone.ExistingCollectionFromQuoteDialogController",
    ExistingCollectionFromQuoteDialogController
  );
