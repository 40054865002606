import * as angular from "angular";
import { dontShowLoginDialog } from "./loginDialog.service";
import "./loginDialog.html";

export class LoginDialogController {
  public static $inject = ["$location", "$uibModalInstance"];

  constructor(
    private $location: angular.ILocationService,
    private $uibModalInstance: any
  ) {}

  public cancel() {
    sessionStorage.setItem(dontShowLoginDialog, "true");
    this.$uibModalInstance.dismiss("cancel");
  }

  public getReturnUrl(): string {
    const currentPath = this.$location.url();
    return currentPath === "/SignedOut" ? "" : currentPath;
  }
}
