import * as angular from "angular";

export class NavbarHeaderService {
  // Internal state
  private currentDocumentTitle: Rx.BehaviorSubject<
    string
  > = new Rx.BehaviorSubject<string>("");

  // Exposed state
  public title = this.currentDocumentTitle.asObservable();

  public static $inject: string[] = [];
  constructor() {}

  public setCurrentDocumentTitle(title: string): void {
    this.currentDocumentTitle.onNext(title);
  }
}

angular.module("PortalApp").service("navbarHeaderService", NavbarHeaderService);
