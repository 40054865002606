import * as angular from "angular";
import { AssetsService } from "./assets.service";
import { IDialogService } from "../Services/dialog.service";
import { INodeInfo } from "../Services/models/nodeInfo";
import { ILocalizedNotificationService } from "../Services/localized.notification.service";
import {
  AssetCollectionViewModel,
  AssetCollectionTemplateViewModel
} from "@rhinestone/portal-web-api";
import { IAssetCollectionBase } from "./AssetCollectionBase";

export class AssetCollectionController {
  public static $inject = [
    "$routeParams",
    "$rootScope",
    "$location",
    "assetsService",
    "dialogService",
    "localizedNotificationService"
  ];

  // Route parameter
  private collectionId: string;

  // From router config
  public isTemplate: boolean;
  public isArchived: boolean;

  public assetCollectionTitle = "";
  public errorMessage = "";
  public hasFullAccess: boolean;
  public hasWriteAccess: boolean;
  public hasReadAccess: boolean;
  public rootFolderId: string;
  public titleMaxLength: number = 80;
  public showEditToolsInNavbar: boolean = true;

  public collection: IAssetCollectionBase;

  // array of unsubscribe (event) functions
  private events = new Array<any>();

  constructor(
    private $routeParams: any,
    private $rootScope: angular.IRootScopeService,
    private $location: angular.ILocationService,
    private assetsService: AssetsService,
    private dialogService: IDialogService,
    private localizedNotificationService: ILocalizedNotificationService
  ) {
    this.collectionId = this.$routeParams.collectionId;
  }

  public $onInit() {
    let getAssetCollectionPromise: angular.IPromise<void>;
    if (this.isTemplate) {
      if (this.isArchived) {
        getAssetCollectionPromise = this.GetArchivedAssetCollectionTemplate();
      } else {
        getAssetCollectionPromise = this.GetAssetCollectionTemplate();
      }
    } else {
      getAssetCollectionPromise = this.GetAssetCollection();
    }

    getAssetCollectionPromise.then(() => this.assignLocalVariables());

    const uploadFileEvent = this.$rootScope.$on(
      "AssetCollection.UploadFileRequest",
      (event: angular.IAngularEvent, args) => {
        this.uploadFile();
      }
    );

    this.events.push(uploadFileEvent);

    const newHeadlineEvent = this.$rootScope.$on(
      "AssetCollection.NewHeadlineRequest",
      (event: angular.IAngularEvent, args) => {
        this.addNewFolder();
      }
    );

    this.events.push(newHeadlineEvent);
  }

  public $onDestroy() {
    this.events.forEach(unsubscribe => unsubscribe());
    this.events = [];
  }

  private assignLocalVariables() {
    this.assetCollectionTitle = this.collection.title;
    this.rootFolderId = this.collection.rootFolderId;
    this.isArchived = this.collection.isArchived;
    this.hasFullAccess = this.collection.hasSecurityAccess;
    this.hasWriteAccess = this.collection.hasWriteAccess;
    this.hasReadAccess = this.collection.hasReadAccess;
  }

  public async saveAssetCollection(collection: IAssetCollectionBase) {
    await this.assetsService.updateAssetCollection(
      collection.assetCollectionId,
      collection
    );
    this.localizedNotificationService.success("assets.saved_success");
    this.assetCollectionTitle = collection.title;
  }

  public copyAssetCollection(collection: IAssetCollectionBase) {
    const modalInstance = this.dialogService.openCopyAssetCollectionDialog(
      collection.assetCollectionId,
      collection.title
    );
    modalInstance.result.then(() => {
      this.localizedNotificationService.success("assets.copied_success");
      this.$location.path("/Samlinger");
    });
  }

  public copyAssetCollectionFromTemplate(collection: IAssetCollectionBase) {
    const modalInstance =
      this.dialogService.openCopyAssetCollectionFromTemplateDialog(
        collection.assetCollectionId,
        collection.isArchived
      );
    modalInstance.result.then(() => {
      this.localizedNotificationService.success("assets.copied_success");
      this.$location.path("/Samlinger");
    });
  }

  public copyAssetCollectionAsTemplate(collection: IAssetCollectionBase) {
    const modalInstance =
      this.dialogService.openCopyAssetCollectionAsTemplateDialog(
        collection.assetCollectionId
      );
    modalInstance.result.then((template: AssetCollectionTemplateViewModel) => {
      this.localizedNotificationService.success(
        "assets.copy_as_template_successful"
      );
      this.$location.path(
        `/AssetCollection/EditTemplate/${template.assetCollectionTemplateId}`
      );
    });
  }

  public addNewFolder = () => {
    const modalInstance = this.dialogService.openSaveAssetCollectionNodeDialog(
      this.collectionId,
      this.rootFolderId,
      { Name: "" } as INodeInfo,
      false
    );
    modalInstance.result.then(node => {
      this.$rootScope.$broadcast("AssetCollection.HeadingAdded", [
        this.collectionId,
        node
      ]);
    });
  };

  public uploadFile = () => {
    const modalInstance = this.dialogService.openUploadFileDialog(
      this.collectionId
    );
    modalInstance.result.then(() => {
      this.$rootScope.$broadcast(
        "AssetCollection.FileAdded",
        this.collectionId
      );
    });
  };

  public deleteAssetCollection = (collection: IAssetCollectionBase) => {
    const modalInstance =
      this.dialogService.openConfirmDeleteAssetCollectionDialog();
    modalInstance.result.then(() => {
      this.assetsService
        .deleteAssetCollection(collection.assetCollectionId)
        .then(() => {
          this.localizedNotificationService.success("assets.archived_success");
          this.$location.path("/Samlinger");
        });
    });
  };

  public deleteAssetCollectionTemplate = (collection: IAssetCollectionBase) => {
    const modalInstance =
      this.dialogService.openConfirmDeleteAssetCollectionDialog();
    modalInstance.result.then(() => {
      this.assetsService
        .deleteAssetCollectionTemplate(collection.assetCollectionId)
        .then(() => {
          this.localizedNotificationService.success("assets.archived_success");
          this.$location.path("/Samlinger");
        });
    });
  };

  private GetAssetCollection(): angular.IPromise<void> {
    return this.assetsService
      .getAssetCollection(this.collectionId)
      .then((data: AssetCollectionViewModel) => {
        this.collection = {
          ...data,
          isTemplate: false
        };
      });
  }

  private GetAssetCollectionTemplate(): angular.IPromise<void> {
    return this.assetsService
      .getTemplateAssetCollection(this.collectionId)
      .then((data: AssetCollectionTemplateViewModel) => {
        this.collection = {
          ...data,
          assetCollectionId: data.assetCollectionTemplateId,
          accessLevel: 0,
          isTemplate: true,
          isArchived: false
        };
      });
  }

  private GetArchivedAssetCollectionTemplate(): angular.IPromise<void> {
    return this.assetsService
      .getArchivedTemplateAssetCollection(this.collectionId)
      .then((data: AssetCollectionTemplateViewModel) => {
        this.collection = {
          ...data,
          assetCollectionId: data.assetCollectionTemplateId,
          accessLevel: 0,
          isTemplate: true,
          isArchived: true
        };
      });
  }
}

angular
  .module("PortalApp")
  .controller(
    "Rhinestone.AssetCollectionController",
    AssetCollectionController
  );
