import * as angular from "angular";
import { ILinkRenderService } from "../../Services/linkRender.service";
import { MultiLinkTileDto } from "../TilesTypes";

export class MultiLinkTileController {
  // Input binding
  public tile: MultiLinkTileDto;

  public static $inject = ["linkRenderService"];

  constructor(private linkService: ILinkRenderService) {}

  public isLinkExternal(linkUrl: string): boolean {
    return this.linkService.isLinkExternal(linkUrl);
  }
}

angular
  .module("PortalApp")
  .controller("Rhinestone.MultiLinkTileController", MultiLinkTileController);
