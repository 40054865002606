import * as angular from "angular";
import "./confirmDeleteCollectionDialog.html";
export class ConfirmDeleteCollectionDialogController {
  public static $inject = ["$uibModalInstance"];
  constructor(
    private $uibModalInstance: angular.ui.bootstrap.IModalServiceInstance
  ) {}

  public ok = () => {
    this.$uibModalInstance.close();
  };

  public cancel = () => {
    this.$uibModalInstance.dismiss();
  };
}

angular
  .module("PortalApp")
  .controller(
    "Rhinestone.ConfirmDeleteCollectionDialogController",
    ConfirmDeleteCollectionDialogController
  );
