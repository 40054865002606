import * as angular from "angular";
import { SearchEvents } from "../../Search/search.events";
import { IMediaService, ScreenSize } from "../media.service";
import { SidePaneController } from "./sidepane.controller";
import {
  ToggleVisibilitySidepaneSectionEventArgs,
  SidepaneSectionEvents,
  SetExpandedSidepaneSectionEventArgs
} from "./sidepaneSection.events";

export class SidePaneSectionController {
  // require
  private pane?: SidePaneController;

  // bindings, be careful about renaming, this component is used in many custom templates
  public icon: string;
  public sectionTitle: string;
  public startOpen: boolean;
  public disableAutoPadding: boolean;
  public startHidden: boolean;

  // state
  public id: number;
  public isExpanded = false;
  public isMobile = false;

  private eventUnbindHandlers: Array<() => void> = [];

  public static $inject = ["$element", "$scope", "mediaService"];

  constructor(
    private $element: JQuery,
    private $scope: angular.IScope,
    private mediaService: IMediaService
  ) {}

  public $onInit() {
    this.id = this.$scope.$id;
    if (this.pane) {
      this.pane.registerSection(this);
    }
    this.isExpanded = this.isExpandedOnInit();

    const toggleVisibilityUnbindHandler = this.$scope.$on(
      SidepaneSectionEvents.ToggleVisibility,
      (event, args: ToggleVisibilitySidepaneSectionEventArgs) => {
        this.startHidden = !args.isVisible;
      }
    );

    const setExpandedUnbindHandler = this.$scope.$on(
      SidepaneSectionEvents.SetExpanded,
      (event, args: SetExpandedSidepaneSectionEventArgs) => {
        this.isExpanded = args.isExpanded;
      }
    );

    const clearSearchUnbindHandler = this.$scope.$on(
      SearchEvents.SearchCleared,
      () => {
        this.isExpanded = this.isExpandedOnInit();
      }
    );

    this.eventUnbindHandlers.push(toggleVisibilityUnbindHandler);
    this.eventUnbindHandlers.push(clearSearchUnbindHandler);
    this.eventUnbindHandlers.push(setExpandedUnbindHandler);
  }

  public $postLink() {
    this.isMobile = this.mediaService.getScreenSize() === ScreenSize.Xs;
  }

  public $onDestroy() {
    this.eventUnbindHandlers.forEach(unsubscribe => unsubscribe());
  }

  public toggleSection() {
    if (this.pane.getExpanded()) {
      this.isExpanded = !this.isExpanded;
    } else {
      this.isExpanded = true;
    }
    this.pane.setExpanded(true);
  }

  public open() {
    if (this.isMobile) {
      this.findMobileModal().modal("show");
    }
  }

  public close() {
    if (this.isMobile) {
      this.findMobileModal().modal("hide");
    }
  }

  private findMobileModal(): JQuery {
    return this.$element.find(`#mobile-modal${this.id}`);
  }

  private isExpandedOnInit() {
    return this.startOpen ?? true;
  }
}

angular
  .module("rhSidePane")
  .controller(
    "Rhinestone.SidePaneSectionController",
    SidePaneSectionController
  );
