import * as angular from "angular";
import { IPortalDataHttpService } from "../Services/DataService/PortalDataHttpService";
import {
  SearchPageConfigurationModel,
  SearchTermsAnalyzerModel
} from "@rhinestone/portal-web-api";
import { Criteria } from "@rhinestone/portal-web-react";
import { IProductsService } from "../Services/products.service";
import {
  parseSearchString,
  getSingleProductCriteria
} from "./searchPageConfiguration.util";

export class SearchPageConfigurationService {
  public static $inject = ["portalDataService", "productsService", "$window"];
  private searchPageConfiguration: angular.IPromise<SearchPageConfigurationModel>;

  constructor(
    private portalDataService: IPortalDataHttpService,
    private productsService: IProductsService,
    private $window: angular.IWindowService
  ) {
    this.searchPageConfiguration = this.getSearchPageConfigurationFromServer();
  }

  public async getDefaultCriteriaConfiguration(): Promise<Criteria[]> {
    const [searchPageConfigurationFromServer, singleProductPortalProduct] =
      await Promise.all([
        this.searchPageConfiguration,
        this.productsService.getSingleProductPortalProduct()
      ]);

    // Each portal can have a default search string defined
    const defaultSearchString = parseSearchString(
      searchPageConfigurationFromServer.defaultSearchString
    );

    // Set the default product if user is using a portal in a single-product mode!
    const singleProductCriteria = getSingleProductCriteria(
      singleProductPortalProduct
    );

    return [...defaultSearchString, ...singleProductCriteria];
  }

  public async getSearchTermsAnalyzers(): Promise<SearchTermsAnalyzerModel[]> {
    const configuration = await this.searchPageConfiguration;
    return configuration.searchTermsAnalyzers.sort(a => a.order);
  }

  private getSearchPageConfigurationFromServer() {
    // If a searchPreview is specified then we add it as parameter
    // in case a the preview configuration contains a search page configuration
    let previewName = this.$window.sessionStorage.getItem("searchPreviewName");
    let searchPageConfigUrl = "PageConfigurations/Soeg";

    if (previewName) {
      searchPageConfigUrl += `?searchPreviewName=${previewName}`;
    }

    return this.portalDataService.get<SearchPageConfigurationModel>(
      searchPageConfigUrl
    );
  }
}

angular
  .module("PortalApp")
  .service("searchPageConfigurationService", SearchPageConfigurationService);
