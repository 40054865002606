import "./searchView.html";
import { BaseComponent } from "../Core/BaseComponent";
import * as angular from "angular";
import "./searchView.less";
import "./search.service";
import "./searchView.controller";
import "../SearchSubscriptions/searchSubscriptionDialog.service";
import "./searchPageConfigurationService";
import "../Search/searchCriteriaState.service";
import "../Search/RelatedDocuments/relatedDocuments.component";
import "./CriteriaPanel/criteriaPanel.component";
import "./Taxonomy/taxonomy.component";
import "./ProductTaxonomy/productTaxonomy.component";
import "./Pagination/pagination.component";
import "./MobileTabs/mobileTabs.component";
import "./AssetsSidePaneSection/assetsSidePaneSection.component";
import "./SearchAssistent/searchAssistent.component";
import "./taxonomyState.service";
import "./ViewSwitch/viewSwitch.component";
import "./savedSearchWidget/savedSearches.component";
import "./querystring.service";
import "./SearchResultMobile/searchResultMobile.component";
import "./ResultSorter/resultSorter.component";
import "./SearchResult/searchResult.component";
import "./searchResultsPdfExportButton.component";
import "./searchFilterGroup.component";

export class SearchViewComponent extends BaseComponent {
  constructor() {
    super(
      "Rhinestone.SearchViewController",
      "/templates/search/searchview.html"
    );
  }
}

angular
  .module("PortalApp")
  .component("rhSearchView", new SearchViewComponent());
