import { Criteria, CriteriaChangeType } from "@rhinestone/portal-web-react";
import {
  TaxonomyFilterKey,
  SearchResultsExportInfo,
  ProductDropdownModel,
  SearchFilterOptionModel
} from "@rhinestone/portal-web-api";
import _ = require("underscore");
import { Taxon } from "../../Services/models/taxon";

export function buildSearchTermsCriteriaDescription(
  request: SearchResultsExportInfo,
  providerKey: string,
  criteria: ReadonlyArray<Criteria>
): SearchResultsExportInfo {
  return {
    ...request,
    searchTerms: [
      ...request.searchTerms,
      ...criteria.filter(x => x.providerKey === providerKey).map(x => x.data)
    ]
  };
}

export function buildStatusCriteriaDescription(
  request: SearchResultsExportInfo,
  providerKey: string,
  criteria: ReadonlyArray<Criteria>,
  flags: Array<{
    isSelected: boolean;
    searchOption: SearchFilterOptionModel;
  }>,
  translateFunction: (x: string) => string
): SearchResultsExportInfo {
  if (providerKey !== "status") {
    return request;
  }

  const statusList = criteria
    .filter(x => x.providerKey === providerKey)
    .map(x => x.data);

  const translatedStatuses: string[] = statusList.map(x => {
    return translateFunction(
      flags.filter(y => y.searchOption.flag === x)[0].searchOption.titleResource
    );
  });

  return {
    ...request,
    statusLabels: [...request.statusLabels, ...translatedStatuses]
  };
}

export interface IRelatedCriteria extends Criteria {
  displayValue: string;
}

export function buildRelatedDocumentsCriteriaDescription(
  request: SearchResultsExportInfo,
  providerKey: string,
  criteria: ReadonlyArray<Criteria>
): SearchResultsExportInfo {
  return {
    ...request,
    relatedDocuments: [
      ...request.relatedDocuments,
      ...criteria
        .filter(criteria => criteria.providerKey === providerKey)
        .map(criteria => (criteria as IRelatedCriteria).displayValue)
    ]
  };
}

export function buildTaxonsCriteriaDescription(
  request: SearchResultsExportInfo,
  providerKey: string,
  criteria: ReadonlyArray<Criteria>,
  virtualTaxons: Taxon[],
  findTaxonFromKey: (key: string, virtualTaxons: Taxon[]) => Taxon,
  taxonomyFilterKey: TaxonomyFilterKey
): SearchResultsExportInfo {
  const taxons = criteria
    .filter(x => x.providerKey === providerKey)
    .map(x => findTaxonFromKey(x.data, virtualTaxons));

  // see buildProductCriteriaDescription for explanation for _.uniq
  return {
    ...request,
    taxons: _.uniq(
      [
        ...request.taxons,
        ...taxons.map(t => ({
          taxonomyFilterKey,
          taxonTitlePath: t.path.map(p => p.title).join("/")
        }))
      ],
      false,
      x => JSON.stringify(x).toUpperCase()
    )
  };
}

export function buildProductCriteriaDescription(
  request: SearchResultsExportInfo,
  providerKey: string,
  criteria: ReadonlyArray<Criteria>,
  products: ProductDropdownModel[]
): SearchResultsExportInfo {
  const productTitles = criteria
    .filter(x => x.providerKey === providerKey)
    .map(
      x =>
        _(products).find((p: ProductDropdownModel) => p.code === x.data).title
    );

  return {
    ...request,
    taxons:
      // filtering by product is treated as filtering by its taxonomy root key (equivalent due to the product configuration)
      // _.uniq is here avoid duplication when both product and taxon root key is selected. Upper case is to fix differences in letter casing
      _.uniq(
        [
          ...request.taxons,
          ...productTitles.map(t => ({
            taxonomyFilterKey: "Product",
            taxonTitlePath: t
          }))
        ],
        false,
        x => JSON.stringify(x).toUpperCase()
      ) as any
  };
}
