import "./searchTile.html";
import { BaseComponent } from "../../Core/BaseComponent";
import * as angular from "angular";
import { Binding } from "../../Utils/AngularBindings";
import "./searchTile.less";
import "./searchTile.controller";
import "../../Search/SearchBox/searchBox.component";

export class SearchTileComponent extends BaseComponent {
  constructor() {
    super(
      "Rhinestone.SearchTileController",
      "/templates/tiles/searchTile/searchTile.html"
    );
    this.bindings = {
      tile: Binding.oneWay
    };
  }
}

angular
  .module("PortalApp")
  .component("rhSearchTile", new SearchTileComponent());
