import * as angular from "angular";
import { BaseComponent } from "../../Core/BaseComponent";
import { Binding } from "../../Utils/AngularBindings";
import "./multiLinkTile.html";
import "./multiLinkTile.less";
import "./multiLinkTile.controller";

export class MultiLinkTileComponent extends BaseComponent {
  constructor() {
    super(
      "Rhinestone.MultiLinkTileController",
      "/templates/tiles/MultiLinkTile/multiLinkTile.html"
    );
    this.bindings = {
      tile: Binding.oneWay
    };
  }
}

angular
  .module("PortalApp")
  .component("rhMultiLinkTile", new MultiLinkTileComponent());
