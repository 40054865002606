import "./localDocumentTypeTaxonomyFilter.html";
import { BaseComponent } from "../../Core/BaseComponent";
import * as angular from "angular";
import { Binding } from "../../Utils/AngularBindings";
import "./localDocumentTypeTaxonomyFilter.controller";
export class LocalDocumentTypeTaxonomyFilterComponent extends BaseComponent {
  constructor() {
    super(
      "Rhinestone.LocalDocumentTypeTaxonomyFilterController",
      "/templates/search/LocalDocumentTypeTaxonomyFilter/LocalDocumentTypeTaxonomyFilter"
    );
    this.bindings = {
      onCriteriaChanged: Binding.outputEvent,
      onLoaded: Binding.outputEvent,
      criteria: Binding.oneWay
    };
  }
}

angular
  .module("PortalApp")
  .component(
    "rhLocalDocumentTypeTaxonomyFilter",
    new LocalDocumentTypeTaxonomyFilterComponent()
  );
