import "./productSearchFilter.html";
import { BaseComponent } from "../../Core/BaseComponent";
import * as angular from "angular";
import { Binding } from "../../Utils/AngularBindings";
import "./productSearchFilter.controller";
import "./multiSelectProductSearchFilter.component"
import "./singleSelectProductSearchFilter.component"

export class ProductSearchFilterComponent extends BaseComponent {
  constructor() {
    super(
      "Rhinestone.ProductSearchFilterController",
      "/templates/Search/ProductSearchFilter/productSearchFilter.html"
    );
    this.bindings = {
      providerKey: Binding.valueInput,
      color: Binding.valueInput,
      icon: Binding.valueInput,
      onCriteriaChanged: Binding.outputEvent,
      onLoaded: Binding.outputEvent,
      criteria: Binding.oneWay,
      products: Binding.oneWay,
      multiSelect: Binding.oneWay
    };
  }
}

angular
  .module("PortalApp")
  .component("rhProductSearchFilter", new ProductSearchFilterComponent());
