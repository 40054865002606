import * as angular from "angular";

declare var Modernizr: any;

export interface IDeviceDetectionService {
  isIosDevice(): boolean;
  isIE(): boolean;
}

export class DeviceDetectionService implements IDeviceDetectionService {
  public static $inject: string[] = [];

  constructor() {
    // We could put these some place shared.
    Modernizr.addTest(
      "ipad",
      (): boolean => {
        return !!navigator.userAgent.match(/iPad/i);
      }
    );
    Modernizr.addTest(
      "iphone",
      (): boolean => {
        return !!navigator.userAgent.match(/iPhone/i);
      }
    );
    Modernizr.addTest(
      "ipod",
      (): boolean => {
        return !!navigator.userAgent.match(/iPod/i);
      }
    );
    Modernizr.addTest(
      "MSIE",
      (): boolean => {
        return !!navigator.userAgent.match(/MSIE/i);
      }
    );
    Modernizr.addTest(
      "Trident",
      (): boolean => {
        return !!navigator.userAgent.match(/Trident/i);
      }
    );
  }

  public isIosDevice(): boolean {
    return Modernizr.ipad || Modernizr.iphone || Modernizr.ipod;
  }

  public isIE(): boolean {
    return (
      !!navigator.userAgent.match(/Trident/i) ||
      !!navigator.userAgent.match(/MSIE/i)
    );
  }
}

angular
  .module("PortalApp")
  .service("deviceDetectionService", DeviceDetectionService);
