import * as angular from "angular";
import { IPortalConfig } from "../Core/portal.provider";
import { SearchResultsExportInfo } from "@rhinestone/portal-web-api";

export class SearchResultsExportService {
  private baseUrl = `/api/Portals(${this.portal.identifier})/search`;

  public static $inject = ["$http", "portal"];

  constructor(
    private $http: angular.IHttpService,
    private portal: IPortalConfig
  ) {}

  public exportSearchResultsToPdfFile(
    request: SearchResultsExportInfo,
    languageCode: string
  ): angular.IPromise<any> {
    const url = `${this.baseUrl}/pdfExport?languageCode=${languageCode}`;
    return this.$http.post(url, request, { responseType: "arraybuffer" });
  }
}

angular
  .module("PortalApp")
  .service("searchResultsExportService", SearchResultsExportService);
