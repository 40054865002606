/*
    Originally this file was created to hold reference to all packages that should go into a "vendor" bundle
    to separate it out from our own code. This was achieved by webpack CommonChunksPlugin.
    However, this plugin doesn't exist any more since webpack 4 which we have been using for a more than half a year.
    imports should be with the components that require them (like we do in most places), and chunking is achieved in new ways
    (see webpack 4 docs)

    Though it does probably ensure correct load order of css and also correct load order of old javascript packages
    that is from an age before module loading, so be careful if tearing this apart
*/

/* vendor styles */
import "bootstrap/dist/css/bootstrap.css";
import "font-awesome/css/font-awesome.css";
import "ng-table/dist/ng-table.css";
import "angular-ui-tree/dist/angular-ui-tree.css";
import "angular-material/angular-material.css";
import "ui-select/dist/select.min.css";
import "./Scripts/horseyjs/horsey.css";
import "@fontsource/titillium-web/400.css";
import "@fontsource/titillium-web/600.css";
import "@fontsource/source-sans-pro";

/* extra needed vendor modules */
import "underscore";
import "notifyjs-browser";
import "bootstrap";
import "core-js/features/object/assign";
import "core-js/features/object/values";
import "core-js/features/object/entries";
import "core-js/modules/es.array.find";
import "core-js/modules/es.array.from";
import "core-js/modules/es.array.find-index";
import "core-js/modules/es.array.includes";
import "core-js/features/array/flat-map";
import "core-js/features/promise";
import "core-js/features/string";
import "core-js/features/symbol";
import "core-js/features/number";
import "core-js/web";
/* overriden bootstrap's scrollspy implementation */
import "./Scripts/bootstrap/scrollspy.js";

import "./Scripts/horseyjs/horsey.js";

import "angular";
import "angular-ui-bootstrap";
import "angular-aria"; // Angular Material dependency
import "angular-sanitize";
import "angular-animate"; // Angular Material dependency
import "angular-route";
import "angular-messages";
import "./Scripts/AngularIosFix/angular-material-ios-fix.js"; // fix path with resolve declare webpack config
import "ng-table";
import "angular-cookies";
import "angular-translate";
import "angular-translate-interpolation-messageformat";
import "angular-translate-loader-url";
import "angular-translate-handler-log";
import "angular-translate-storage-cookie"; // both imports are necessary
import "angular-translate-storage-local"; // if localStorage is not supported by browser, it will fallback to cookies

import "moment/moment.js";
import "moment/locale/da.js";
import "ui-select";
import "angular-moment";
import "angular-ui-tree";

import "rison";
import "rx";
import "rx-angular";
import "ng-infinite-scroll";
import "angular-local-storage";
import "whatwg-fetch";
