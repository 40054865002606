import * as angular from "angular";
import { IPortalDataHttpService } from "../../../Services/DataService/PortalDataHttpService";
import { ISearchSuggestionsProvider } from "./ISearchSuggestionsProvider";
import * as _ from "underscore";
import { ISearchSuggestionsProviderConfig } from "./ISearchSuggestionsProviderConfig";
import { ISearchCriteriaStateService } from "../../searchCriteriaState.service";
import { ISearchSuggestion } from "../ISearchSuggestion";
import { CriteriaChangeType } from "@rhinestone/portal-web-react";

export class DocumentTermsSearchSuggestionsProvider
  implements ISearchSuggestionsProvider {
  public static readonly criteriaProviderKey = "terms";

  public config: ISearchSuggestionsProviderConfig;

  public static $inject = ["portalDataService", "searchCriteriaStateService"];

  constructor(
    private portalDataHttpService: IPortalDataHttpService,
    private searchCriteriaStateService: ISearchCriteriaStateService
  ) {}

  public getSuggestions(query: string): angular.IPromise<ISearchSuggestion[]> {
    let url = `/search/terms/?currentTerm=${encodeURIComponent(query)}`;

    if (this.config.maxItems && this.config.maxItems > 0) {
      url += `&limit=${this.config.maxItems}`;
    }

    return this.portalDataHttpService.get<string[]>(url).then(data =>
      _(data).map(term => {
        return {
          title: term,
          payload: null
        };
      })
    );
  }

  public suggestionSelected(suggestion: ISearchSuggestion): void {
    this.searchCriteriaStateService.handleCriteriaChanged({
      changes: [
        {
          criteria: {
            providerKey:
              DocumentTermsSearchSuggestionsProvider.criteriaProviderKey,
            data: suggestion.title
          },
          type: CriteriaChangeType.Add
        }
      ]
    });
  }
}

angular
  .module("PortalApp")
  .service(
    "documentTermsSearchSuggestionsProvider",
    DocumentTermsSearchSuggestionsProvider
  );
