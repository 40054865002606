import * as angular from "angular";

// TODO: When we setup Gulp see how we can make this for production only.

export class CompilerConfig {
  public static $inject = ["$compileProvider"];

  constructor($compileProvider: angular.ICompileProvider) {
    $compileProvider.debugInfoEnabled(false);
  }
}

angular.module("PortalApp").config(CompilerConfig);
