import { AssetsService } from "../../Assets/assets.service";
import { SearchService } from "../search.service";
import * as angular from "angular";
import { AssetCollectionViewModel } from "@rhinestone/portal-web-api";
export class AssetsSidePaneSectionController {
  public latestAssetCollections: AssetCollectionViewModel[];

  public static $inject = ["assetsService", "searchService"];

  constructor(
    private assetsService: AssetsService,
    private searchService: SearchService
  ) {}

  public async $onInit() {
    // On initialization the controller/component we cannot rely on the
    // assetService.getLatestAssetCollections method as it has an internal caching that is not invalidated
    // when e.g. adding documents to asset collections from the document page after this has been migrated to React
    // and no longer shares the assetService instance used in these components on the search page.
    this.latestAssetCollections = await this.assetsService.getCollections(3);
  }

  public async addToAssetCollection(
    assetCollectionId: string,
    assetCollectionTitle: string
  ) {
    await this.searchService.addToAssetsCollection(
      assetCollectionId,
      assetCollectionTitle
    );

    await this.updateLatestAssetCollections();
  }

  public async openAddToNewMaterialCollectionDialog() {
    await this.searchService.openAddToNewMaterialCollectionDialog();
    await this.updateLatestAssetCollections();
  }

  public async openAddToExistingMaterialCollectionDialog() {
    await this.searchService.openAddToExistingMaterialCollectionDialog();
    await this.updateLatestAssetCollections();
  }

  private async updateLatestAssetCollections() {
    this.latestAssetCollections =
      await this.assetsService.getLatestAssetCollections();
  }

  public hasSelectedDocuments(): boolean {
    return this.searchService.hasSelectedDocuments();
  }

  public hasLatestCollections(): boolean {
    return this.assetsService.hasLatestCollections();
  }
}

angular
  .module("PortalApp")
  .controller(
    "Rhinestone.AssetsSidePaneSectionController",
    AssetsSidePaneSectionController
  );
