import * as angular from "angular";
import { ICurrentUserService } from "./currentUser.service";

angular.module("PortalApp").directive("allowPrivileges", [
  "currentUserService",
  (currentUserService: ICurrentUserService) => {
    return {
      restrict: "A",
      link: ($scope: any, element: JQuery, attrs: any) => {
        const privileges: string[] = attrs.allowPrivileges.split(",");

        if (!privileges || privileges.length === 0) {
          // if privileges weren't specified, just return from directive
          return;
        }

        if (!currentUserService.hasPrivilege(...privileges)) {
          // remove element if user hasn't any of privileges specified
          element.remove();
        }
      }
    };
  }
]);
