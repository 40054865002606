import * as angular from "angular";
import {
  SearchFilterGroupModel,
  SearchFilterModel,
  ProductDropdownModel,
  TaxonomyFilterKey
} from "@rhinestone/portal-web-api";
import {
  Criteria,
  CriteriaChange,
  CriteriaProvider
} from "@rhinestone/portal-web-react";
import { ISearchResponse } from "./searchResponse";
import { ISearchCriteriaStateService } from "./searchCriteriaState.service";
import { ICurrentUserService } from "../User/currentUser.service";

export class SearchFilterGroupController {
  public group: SearchFilterGroupModel;
  public criteria: Criteria[] = [];
  public userProducts: ProductDropdownModel[];
  public onCriteriaChanged: (action: { action: CriteriaChange }) => void;
  public onLoaded: (param: { provider: CriteriaProvider }) => void;
  public productCode: string;
  public onViewChanged: (param: {
    param: { viewName: string; isVisible: boolean; isActive: boolean };
  }) => void;
  public result: ISearchResponse;
  public static $inject = ["searchCriteriaStateService", "currentUserService"];

  public readonly documentTypeTaxonomyFilterKey: TaxonomyFilterKey =
    "DocumentType";

  constructor(
    private searchCriteriaStateService: ISearchCriteriaStateService,
    private currentUser: ICurrentUserService
  ) {
    // Required for using "this" when calling from React component
    this.registerCriteriaProvider = this.registerCriteriaProvider.bind(this);
    this.handleCriteriaChanged = this.handleCriteriaChanged.bind(this);
  }

  /**
   * If no shouldFilterBeVisible functions returns truthy, hide the entire group
   */
  public groupHasSomeVisibleSearchFilter(group: SearchFilterGroupModel) {
    return group.searchFilters.some((filter: SearchFilterModel) =>
      this.shouldFilterBeVisible(filter)
    );
  }

  public shouldFilterBeVisible(filter: SearchFilterModel) {
    switch (filter.typeName) {
      case "SearchFilterRefinementsModel":
        return this.hasRefinements();
      case "NewSearchFilterLocalDocumentTypeTaxonomyModel":
        return this.getLocalProductOrNull();
      default:
        return true;
    }
  }

  // 14/07/22:
  // This is based on an assumption that a CMS customer only have access to a single "local product".
  // This assumption is confirmed by our PO, Marianne Lyngfeldt
  public getLocalProductOrNull() {
    return this.userProducts?.find(
      x => x.localDocumentTypeTaxonomyDisplayTitle
    );
  }

  public isLocalDocumentTypeGroup() {
    return this.group.searchFilters.some(
      (x: any) => x.typeName === "NewSearchFilterLocalDocumentTypeTaxonomyModel"
    );
  }

  private hasRefinements(): boolean {
    return (
      this.result &&
      this.result.Refinements &&
      this.result.Refinements.length > 0
    );
  }

  public isUserAuthenticated(): boolean {
    return this.currentUser.isAuthenticated;
  }
  public handleCriteriaChanged(action: CriteriaChange): void {
    this.searchCriteriaStateService.handleCriteriaChanged(action);
  }

  public registerCriteriaProvider(provider: CriteriaProvider): void {
    this.searchCriteriaStateService.registerCriteriaProvider(provider);
  }
}

angular
  .module("PortalApp")
  .controller(
    "Rhinestone.SearchFilterGroupController",
    SearchFilterGroupController
  );
