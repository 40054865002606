import * as angular from "angular";

export interface INotificationService {
  success(message: string): void;
  warning(message: string): void;
  error(message: string): void;
  busy(message: string): angular.IPromise<JQuery>;
  hide(notification: angular.IPromise<JQuery>): void;
}

export class NotificationService implements INotificationService {
  public static $inject = ["$timeout", "config"];

  constructor(private $timeout: angular.ITimeoutService, private config: any) {}

  public success(message: string): void {
    const options = angular.copy(this.config.notificationOptions);
    options.className = "success";

    $.notify(message, options);
  }

  public warning(message: string): void {
    const options = angular.copy(this.config.notificationOptions);
    options.className = "warning";

    $.notify(message, options);
  }

  public error(message: string) {
    const options = angular.copy(this.config.notificationOptions);
    options.className = "error";
    $.notify(message, options);
  }

  public busy(message: string) {
    const options = angular.copy(this.config.notificationOptions);
    options.className = "info";
    options.clickToHide = false;
    options.autoHide = false;
    $.notify(message, options);

    const promise = this.$timeout(() => {
      const notification = $(".notifyjs-corner")
        .children()
        .first();

      // TODO: Add spinner
      // let spinner = angular.element('<rh-spinner></rh-spinner>');
      // notification.find('span[data-notify-text]').append(spinner);
      // this.$compile(spinner);

      return notification;
    });

    return promise;
  }

  public hide(promise: angular.IPromise<JQuery>) {
    // TODO
    // if ("promise has not resolved yet") {
    //     this.$timeout.cancel(promise);
    // } else {
    promise.then(notification => {
      notification.trigger("notify-hide");
    });
  }
}

angular.module("PortalApp").service("notificationService", NotificationService);
