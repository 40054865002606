/**
 * Following Bootstrap sizes
 * See http://getbootstrap.com/css/#grid-options
 */
import * as angular from "angular";

export enum ScreenSize {
  Xs = 0, //   0px - 767px
  Sm = 768, // 768px - 991px
  Md = 992 // 992px - ...px
}

export interface IMediaService {
  getScreenSize(): ScreenSize;
  getScreenSizeFromPixels(pixels: number): ScreenSize;
}

export class MediaService implements IMediaService {
  public static $inject = ["$window"];

  constructor(private $window: angular.IWindowService) {}

  public getScreenSize() {
    if (this.$window.matchMedia("(max-width: 767px)").matches) {
      return ScreenSize.Xs;
    } else if (this.$window.matchMedia("(min-width: 992px)").matches) {
      return ScreenSize.Md;
    } else {
      return ScreenSize.Sm;
    }
  }

  public getScreenSizeFromPixels(pixels: number) : ScreenSize {
    if (pixels < ScreenSize.Sm) {
      return ScreenSize.Xs;
    } else if (pixels < ScreenSize.Md) {
      return ScreenSize.Sm;    
    } else {
      return ScreenSize.Md;
    }
  }
}

angular.module("PortalApp").service("mediaService", MediaService);
