import * as angular from "angular";
import { DocumentFieldsetService } from "../../Documents/DocumentFieldset/documentFieldset.service";
import { ITocService } from "../../Documents/Toc/toc.service";
import _ = require("underscore");

export interface IRelatedDocumentsService {
  getRelationTitleFields(
    fullName: string,
    hiveId: string,
    bookmark: string
  ): Promise<{
    bookmarkTitle: string | null;
    [key: string]: string;
  }>;
}

export class RelatedDocumentsService implements IRelatedDocumentsService {
  public static $inject = ["documentFieldsetService", "tocService"];

  constructor(
    private documentFieldsetService: DocumentFieldsetService,
    private tocService: ITocService
  ) {}

  public async getRelationTitleFields(
    fullName: string,
    hiveId: string,
    bookmark: string
  ) {
    const [fieldSet, toc] = await Promise.all([
      this.documentFieldsetService.getFieldset(
        hiveId,
        fullName,
        "DefaultPortalFields"
      ),
      this.tocService.get(hiveId, fullName)
    ]);

    const tocEntry = _.find(toc.entries, x => x.target === bookmark);

    return {
      ...fieldSet,
      bookmarkTitle: tocEntry ? tocEntry.heading : null
    };
  }
}

angular
  .module("PortalApp")
  .service("relatedDocumentsService", RelatedDocumentsService);
