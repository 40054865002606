import "./subscriptionsView.html";
import { BaseComponent } from "../Core/BaseComponent";
import * as angular from "angular";
import "./subscriptionsView.controller";
import "../SearchSubscriptions/searchSubscription.service";
import "./subscriptionsActionsPane.component";
import "./subscriptionsList.component";

export class SubscriptionsViewComponent extends BaseComponent {
  constructor() {
    super(
      "Rhinestone.SubscriptionsViewController",
      "/templates/Subscriptions/subscriptionsView"
    );
  }
}

angular
  .module("PortalApp")
  .component("rhSubscriptionsView", new SubscriptionsViewComponent());
