import * as angular from "angular";
import { AssetsService } from "./assets.service";
import { ICopyAssetCollectionRequest } from "./copyAssetCollectionRequest";
import "./copyAssetCollectionDialog.html";

export class CopyAssetCollectionDialogController {
  public title: string;

  public static $inject = [
    "$uibModalInstance",
    "$translate",
    "assetsService",
    "assetCollectionId",
    "assetCollectionTitle"
  ];
  constructor(
    private $uibModalInstance: angular.ui.bootstrap.IModalServiceInstance,
    $translate: angular.translate.ITranslateService,
    private assetsService: AssetsService,
    private assetCollectionId: string,
    assetCollectionTitle: string
  ) {
    this.title =
      $translate.instant("assets.copy_of") + " " + assetCollectionTitle;
  }

  public copy = async () => {
    await this.assetsService.copyAssetCollection(this.assetCollectionId, {
      title: this.title
    } as ICopyAssetCollectionRequest);

    this.$uibModalInstance.close();
  };

  public cancel = () => {
    this.$uibModalInstance.dismiss();
  };
}

angular
  .module("PortalApp")
  .controller(
    "Rhinestone.CopyAssetCollectionDialogController",
    CopyAssetCollectionDialogController
  );
