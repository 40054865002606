import * as angular from "angular";
import { IPortalConfig } from "../../Core/portal.provider";
import { DocumentViewInfo } from "@rhinestone/portal-web-api";

export interface IDocumentListTileService {
  getDocumentView(documentViewName: string): angular.IPromise<DocumentViewInfo>;
}

export class DocumentListTileService implements IDocumentListTileService {
  private baseUrl = "";

  private view: DocumentViewInfo;

  public static $inject = ["$http", "$q", "portal"];

  constructor(
    private $http: angular.IHttpService,
    private $q: angular.IQService,
    private portal: IPortalConfig
  ) {
    this.baseUrl = `/api/Portals(${this.portal.identifier})`;
  }

  public getDocumentView(
    documentViewName: string
  ): angular.IPromise<DocumentViewInfo> {
    const deferred = this.$q.defer<DocumentViewInfo>();

    if (this.view) {
      deferred.resolve(this.view);
    } else {
      this.$http
        .get<DocumentViewInfo>(
          `${this.baseUrl}/DocumentViews/${documentViewName}`
        )
        .then(result => {
          this.view = result.data;
          deferred.resolve(this.view);
        })
        .catch(() => {
          deferred.reject("Could not get document view");
        });
    }

    return deferred.promise;
  }
}

angular
  .module("PortalApp")
  .service("documentListTileService", DocumentListTileService);
