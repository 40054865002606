import * as angular from "angular";

export class TileCardController {
  // Input bindings
  public tileTitle: string;
  public tileHeading: string;
  public imageUrl: string;
  public imageAltText: string;
  public isInitialized: boolean;
  public contentLoaded: boolean;

  public static $inject: any[] = [];

  constructor() {}

  public get headerHeight(): string {
    if (!this.imageUrl) {
      return `${this.tileTitle ? 70 : 0}px`;
    }

    return "";
  }
}

angular
  .module("PortalApp")
  .controller("Rhinestone.TileCardController", TileCardController);
