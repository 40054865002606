import * as angular from "angular";
import { AssetsService } from "./assets.service";

import { AssetCollectionViewModel } from "@rhinestone/portal-web-api";

export class AssetsListController {
  public archived: boolean;
  private list: AssetCollectionViewModel[];
  public table: any;
  public filter: string;
  private tableParamsOptions = {
    page: 1,
    count: 10,
    sorting: { lastModified: "desc" }
  };

  public static $inject = [
    "$scope",
    "$location",
    "$filter",
    "assetsService",
    "NgTableParams"
  ];

  constructor(
    private $scope: angular.IScope,
    private $location: angular.ILocationService,
    private $filter: angular.IFilterService,
    private assetsService: AssetsService,
    private NgTableParams: any
  ) {
    this.$scope.$watch("vm.filter.$", () => {
      if (this.table) {
        this.table.reload();
      }
    });
  }

  public $onInit() {
    if (this.archived) {
      this.assetsService.getArchivedCollections().then(data => {
        this.handleResponse(data, this.NgTableParams);
      });
    } else {
      this.assetsService.getAllCollections().then(data => {
        this.handleResponse(data, this.NgTableParams);
      });
    }
  }

  public navigateTo = (id: any) => {
    this.$location.path(`/Samlinger/Rediger/${id}`);
  };
  private handleResponse = (
    data: AssetCollectionViewModel[],
    NgTableParams: any
  ) => {
    this.list = data;
    this.initializeTable(NgTableParams);
  };

  private initializeTable = (NgTableParams: any) => {
    this.table = new NgTableParams(this.tableParamsOptions, {
      total: this.list.length,
      getData: (params: any) => this.getDataCallback(params)
    });
  };

  private getDataCallback = (params: any) => {
    const filteredData = this.$filter("filter")(this.list, this.filter);

    const orderedData = params.sorting()
      ? this.$filter("orderBy")(filteredData, params.orderBy())
      : filteredData;
    // Since angular doesn't plan to support different locales in their 'orderBy'-filter
    // Sort title by danish locale
    const orderByProperty = "title";
    if (params.orderBy() === `+${orderByProperty}`) {
      orderedData.sort((a, b) => a.title.localeCompare(b.title, "da"));
    } else if (params.orderBy() === `-${orderByProperty}`) {
      orderedData.sort((a, b) => b.title.localeCompare(a.title, "da"));
    }

    params.total(orderedData.length);

    return orderedData.slice(
      (params.page() - 1) * params.count(),
      params.page() * params.count()
    );
  };
}

angular
  .module("PortalApp")
  .controller("Rhinestone.AssetsListController", AssetsListController);
