import "./searchResult.html";
import * as angular from "angular";
import { BaseComponent } from "../../Core/BaseComponent";
import { Binding } from "../../Utils/AngularBindings";
import "./searchResult.less";
import "./searchResult.controller";
import "../SearchResultAbstract/searchResultAbstract.component";

export class SearchResultComponent extends BaseComponent {
  constructor() {
    super(
      "Rhinestone.SearchResultController",
      "/templates/Search/SearchResult/searchResult.html"
    );
    this.bindings = {
      result: Binding.oneWay,
      criteria: Binding.oneWay,
      isLoading: Binding.oneWay,
      hasError: Binding.oneWay,
      errorMessage: Binding.oneWay,
      resultView: Binding.oneWay,
      isAuthenticated: Binding.oneWay,
      onUpdateCurrentResult: Binding.outputEvent,
      onCriteriaChanged: Binding.outputEvent,
      isMobile: Binding.oneWay
    };
  }
}

angular
  .module("PortalApp")
  .component("rhSearchResult", new SearchResultComponent());
