/**
 * GET /mini-profiler-includes returns a <script> element
 * Re-create it in the DOM and append it to the document.
 *
 * Using ES5 for maximum compatibilty.
 *
 * This script is adapted from this GitHub Gist
 * https://gist.github.com/guillaume86/8f80aef3ca52fffa77834bdecd997e73
 */
export function loadMiniProfiler() {
  (function (window) {
    if ("MiniProfiler" in window)
      return console.warn("MiniProfiler already imported.");

    const ERROR_MESSAGE = "MiniProfiler could not be loaded: ";

    function getIncludes(callback: {
      (err: any, scriptHTML?: any): void;
      (arg0: string, arg1: string): void;
    }) {
      const xhr = new XMLHttpRequest();
      xhr.onload = function () {
        if (this.status !== 200)
          return callback(ERROR_MESSAGE + "request error.");
        callback(null, this.responseText);
      };
      xhr.onerror = function () {
        callback(ERROR_MESSAGE + "request error.");
      };
      xhr.open("GET", "/mini-profiler-includes", true);
      xhr.send();
    }

    function loadScript(scriptHtml: string) {
      if (!("DOMParser" in window) || !DOMParser.prototype.parseFromString) {
        console.error(
          ERROR_MESSAGE + "DOMParser::parseFromString not supported (IE9?)"
        );
        return;
      }

      try {
        const parser = new DOMParser();
        const doc = parser.parseFromString(scriptHtml, "text/html");
        const scriptParsed = doc.querySelector("script");
        if (!scriptParsed) return;

        const script = document.createElement("script");
        for (let i = 0; i < scriptParsed.attributes.length; i++) {
          const attribute = scriptParsed.attributes[i];
          script.setAttribute(attribute.name, attribute.value);
        }

        const target = document.getElementsByTagName("script")[0];
        target.parentNode.insertBefore(script, target);
      } catch (err) {
        console.error(ERROR_MESSAGE + err.toString());
      }
    }

    getIncludes(function (err, scriptHTML) {
      if (err) return console.error(err);
      if (!scriptHTML) return;
      loadScript(scriptHTML);
    });
  })(window);
}
