import "./multiSelectProductSearchFilter.html";
import { BaseComponent } from "../../Core/BaseComponent";
import * as angular from "angular";
import { Binding } from "../../Utils/AngularBindings";
import "./multiSelectProductSearchFilter.controller";
import "./multiSelectProductSearchFilter.less";
export class MultiSelectProductSearchFilterComponent extends BaseComponent {
  constructor() {
    super(
      "Rhinestone.MultiSelectProductSearchFilterController",
      "/templates/Search/ProductSearchFilter/multiSelectProductSearchFilter.html"
    );
    this.bindings = {
      providerKey: Binding.oneWay,
      onCriteriaChanged: Binding.outputEvent,
      criteria: Binding.oneWay,
      products: Binding.oneWay
    };
  }
}

angular
  .module("PortalApp")
  .component(
    "rhMultiSelectProductSearchFilter",
    new MultiSelectProductSearchFilterComponent()
  );
