import * as angular from "angular";
import { SearchPageConfigurationService } from "../searchPageConfigurationService";
import {
  buildCriteriaFromTerms,
  CriteriaProvider,
  Criteria
} from "@rhinestone/portal-web-react";
import { buildSearchTermsCriteriaDescription } from "../Criterias/buildCriteriaDescriptionFunctions";
import { ISearchCriteriaStateService } from "../searchCriteriaState.service";

export class SearchTermsService {
  // hard coding default terms provider key and chip configuration
  // this could be made configurable in the future if needed
  public defaultTermsProviderKey: string = "terms";
  public defaultTermsChipIcon: string = "fa fa-search";
  public defaultTermsChipColor: string = "white";
  public static $inject = [
    "searchPageConfigurationService",
    "searchCriteriaStateService"
  ];
  constructor(
    private searchPageConfigurationService: SearchPageConfigurationService,
    private searchCriteriaStateService: ISearchCriteriaStateService
  ) {}

  public async buildTermsCriteria(termsInput: string) {
    return buildCriteriaFromTerms(
      termsInput,
      this.defaultTermsProviderKey,
      await this.searchPageConfigurationService.getSearchTermsAnalyzers()
    );
  }

  public async getTermsCriteriaProvider(): Promise<CriteriaProvider[]> {
    return [
      ...(
        await this.searchPageConfigurationService.getSearchTermsAnalyzers()
      ).map(ta =>
        this.buildTermsCriteriaProvider(
          ta.criteriaProviderKey,
          ta.icon,
          ta.color,
          criteria => criteria.data.value
        )
      ),
      this.buildTermsCriteriaProvider(
        this.defaultTermsProviderKey,
        this.defaultTermsChipIcon,
        this.defaultTermsChipColor,
        criteria => criteria.data
      )
    ];
  }

  private buildTermsCriteriaProvider(
    providerKey: string,
    chipIcon: string,
    chipColor: string,
    resolveDisplayValue: (criteria: Criteria) => string
  ): CriteriaProvider {
    return {
      key: providerKey,
      getCriteriaViewModel: criteria => {
        if (criteria.providerKey !== providerKey) {
          return null;
        }
        return {
          displayValue: resolveDisplayValue(criteria),
          criteria,
          longDisplayValue: undefined,
          displayValueResource: undefined,
          icon: chipIcon,
          color: chipColor
        };
      },
      buildCriteriaDescription: request =>
        buildSearchTermsCriteriaDescription(
          request,
          providerKey,
          this.searchCriteriaStateService.criteriaChangedSubject.getValue()
            .criteria
        )
    };
  }
}

angular.module("PortalApp").service("searchTermsService", SearchTermsService);
