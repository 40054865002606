import "./sidepaneSection.html";
import { BaseComponent } from "../../Core/BaseComponent";
import * as angular from "angular";
import { Binding } from "../../Utils/AngularBindings";
import "./sidepaneSection.less";
import "./sidepaneSection.controller";
export class SidePaneSection extends BaseComponent {
  constructor() {
    super(
      "Rhinestone.SidePaneSectionController",
      "/templates/Layout/SidePane/sidePaneSection.html",
      true
    );
    this.require = {
      pane: "?^rhSidePane"
    };
    this.bindings = {
      icon: Binding.valueInput,
      sectionTitle: Binding.valueInput,
      startOpen: Binding.twoWay,
      disableAutoPadding: Binding.valueInput,
      startHidden: Binding.twoWay
    };
  }
}

angular
  .module("rhSidePane")
  .component("rhSidePaneSection", new SidePaneSection());
