import * as angular from "angular";
import { IErrorService } from "../../Layout/error.service";
import { IPortalConfig } from "../../Core/portal.provider";
export interface IPortalDataHttpService {
  /**
   * Relative url to the portal web api (without portal id part)
   *
   * Handles both with and without '/' prefix
   * @param relativeUrl
   * @returns {}
   */
  get<T>(relativeUrl: string): angular.IPromise<T>;
  resolve<T>(value: T): angular.IPromise<T>;
}
export class PortalDataHttpService implements IPortalDataHttpService {
  private baseUrl = "";

  public static $inject = ["errorService", "$http", "$q", "portal"];

  constructor(
    private errorService: IErrorService,
    private $http: angular.IHttpService,
    private $q: angular.IQService,
    private portal: IPortalConfig
  ) {
    this.baseUrl = `/api/Portals(${this.portal.identifier})`;
  }

  public get<T>(relativeUrl: string): angular.IPromise<T> {
    const deferred = this.$q.defer<T>();
    const url = this.createUrl(relativeUrl);

    this.$http
      .get<T>(url)
      .then(result => {
        deferred.resolve(result.data);
      })
      .catch(error => {
        this.printError(error, url);
        deferred.reject(error);
      });
    return deferred.promise;
  }

  public put<T>(relativeUrl: string, data: any): angular.IPromise<T> {
    const deferred = this.$q.defer<T>();
    const url = this.createUrl(relativeUrl);

    this.$http
      .put<T>(url, data)
      .then(result => {
        deferred.resolve(result.data);
      })
      .catch(error => {
        this.printError(error, url);
        deferred.reject(error);
      });
    return deferred.promise;
  }

  public post<T>(
    relativeUrl: string,
    data: any,
    config?: angular.IRequestShortcutConfig
  ): angular.IPromise<T> {
    const deferred = this.$q.defer<T>();
    const url = this.createUrl(relativeUrl);

    this.$http
      .post<T>(url, data, config)
      .then(result => {
        deferred.resolve(result.data);
      })
      .catch(error => {
        this.printError(error, url);
        deferred.reject(error);
      });
    return deferred.promise;
  }

  public delete<T>(relativeUrl: string): angular.IPromise<T> {
    const deferred = this.$q.defer<T>();
    const url = this.createUrl(relativeUrl);

    this.$http
      .delete<T>(url)
      .then(result => {
        deferred.resolve(result.data);
      })
      .catch(error => {
        this.printError(error, url);
        deferred.reject(error);
      });
    return deferred.promise;
  }

  public resolve<T>(value: T): angular.IPromise<T> {
    return this.$q((resolve, _) => {
      resolve(value);
    });
  }

  private createUrl(relativeUrl: string): string {
    const normalizedRelativeUrl =
      relativeUrl.substring(0, 1) === "/" ? relativeUrl : `/${relativeUrl}`;
    const url = `${this.baseUrl}${normalizedRelativeUrl}`;
    return encodeURI(url);
  }

  private printError(error: any, url: string): void {
    const message = `Error happened calling portal web api: ${url}`;
    console.log(message);
    console.log(error);
    this.errorService.registerError(message);
  }
}

angular.module("PortalApp").service("portalDataService", PortalDataHttpService);
