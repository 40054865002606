import * as _ from "underscore";
import * as angular from "angular";
import { SimpleChange } from "../../../TypeDefinitions/angularextensions";
import {
  Criteria,
  CriteriaChangeType,
  CriteriaChange,
  CriteriaProvider
} from "@rhinestone/portal-web-react";
import { ITaxonomyStateService } from "../taxonomyState.service";
import {
  TaxonomyFilterKey,
  ProductDropdownModel
} from "@rhinestone/portal-web-api";
import { isNullOrUndefined } from "./../../Utils/isNullOrEmpty";
import {
  ToggleVisibilitySidepaneSectionEventArgs,
  SidepaneSectionEvents
} from "./../../Layout/SidePane/sidepaneSection.events";

export class ProductTaxonomyController {
  // Bindings
  public readonly productProviderKey: string;
  public criteria: ReadonlyArray<Criteria>;
  public readonly icon: string;
  public readonly color: string;
  public onCriteriaChanged: (outparams: { action: CriteriaChange }) => {};
  public onLoaded: (param: { provider: CriteriaProvider }) => void;
  public products: ProductDropdownModel[] = [];
  public readonly productCode: string;

  // State
  public selectedTaxonomyName: string = "";
  public readonly productTaxonomyFilterKey: TaxonomyFilterKey = "Product";
  public productTaxonomyShouldShow: boolean = false;

  public static $inject = ["$scope", "taxonomyStateService"];

  private subscriber: Rx.IDisposable;

  constructor(
    private $scope: angular.IScope,
    private taxonomyStateService: ITaxonomyStateService
  ) {}

  public $onInit() {
    this.subscriber = this.taxonomyStateService.onInitialLoadCompleted
      // always use safeApply method with when using RXjs with Angularjs
      // https://xgrommx.github.io/rx-book/content/rxjs_bindings/angular/observable_methods/safe_apply.html
      // something about subscription handlers being invoked correctly on angularjs scope
      .safeApply(this.$scope, value => {
        if (!value) return;

        this.setSelectedProductTaxonomy();
      })
      .subscribe();
  }

  public $onDestroy() {
    // unsubscribe from initial load event
    this.subscriber.dispose();
  }

  public $onChanges(changes: {
    criteria?: SimpleChange<ReadonlyArray<Criteria>>;
    products?: ProductDropdownModel[];
  }) {
    if (changes.products && this.productCode) {
      this.setSelectedProductTaxonomy();
    }

    if (!changes.criteria) {
      return;
    }

    let productCriteriaCurrent: Criteria[] = [];
    if (!_.isEmpty(changes.criteria.currentValue)) {
      productCriteriaCurrent = changes.criteria.currentValue.filter(
        x => x.providerKey === this.productProviderKey
      );
    }

    let productCriteriaPrevious: Criteria[] = [];
    if (!_.isEmpty(changes.criteria.previousValue)) {
      productCriteriaPrevious = changes.criteria.previousValue.filter(
        x => x.providerKey === this.productProviderKey
      );
    }

    this.handleProductCriteriaChanges(
      productCriteriaPrevious,
      productCriteriaCurrent
    );
  }

  private handleProductCriteriaChanges(
    productCriteriaPrevious: Criteria[],
    productCriteriaCurrent: Criteria[]
  ) {
    const productIsInScope = !isNullOrUndefined(
      productCriteriaCurrent.find(x => x.data === this.productCode)
    );
    const productWasInScope = !isNullOrUndefined(
      productCriteriaPrevious.find(x => x.data === this.productCode)
    );

    if (productIsInScope) {
      this.productTaxonomyShouldShow = true;
      this.setSelectedProductTaxonomy();
      return;
    }
    if (productWasInScope) {
      this.productTaxonomyShouldShow = false;
      this.setSelectedProductTaxonomy();
      return;
    }
  }

  public registerCriteriaProvider(provider: CriteriaProvider): void {
    this.onLoaded({ provider });
  }

  public handleCriteriaChanged(action: CriteriaChange): void {
    this.onCriteriaChanged({ action });
  }

  private sendSidepaneSectionVisibilityEvent(isVisible: boolean) {
    this.$scope.$emit(
      SidepaneSectionEvents.ToggleVisibility,
      new ToggleVisibilitySidepaneSectionEventArgs(isVisible)
    );
  }

  private setSelectedProductTaxonomy() {
    if (
      !this.taxonomyStateService.onInitialLoadCompleted.getValue() ||
      !this.products
    ) {
      // it's very important that the logic below does not get executed if initial load of taxonomies or products has not completed
      // This is because the logic is based on this premise and gets rerun during $onChanges and $onInit cycles.
      // If somehow it is applied before initial load of taxonomies is truly completed (if taxonomyStateService fires initialLoadComplete to soon an)
      // weird side effects can occur since logic will not make sense and criteria might be removed as a result

      return;
    }

    const product = this.products.find(x => x.code === this.productCode);

    if (
      this.productTaxonomyShouldShow &&
      this.productHasNavigationTaxonomy(product)
    ) {
      this.showProductTaxonomy(product);
    } else {
      this.hideProductTaxonomy(product);
    }
  }
  private showProductTaxonomy(product: ProductDropdownModel): void {
    this.selectedTaxonomyName = product.productNavigationTaxonomyName;
    this.sendSidepaneSectionVisibilityEvent(true);
  }
  private hideProductTaxonomy(product: ProductDropdownModel): void {
    this.selectedTaxonomyName = "";
    this.sendSidepaneSectionVisibilityEvent(false);
  }
  private productHasNavigationTaxonomy(product: ProductDropdownModel): boolean {
    return !isNullOrUndefined(product.productNavigationTaxonomyName);
  }
}

angular
  .module("PortalApp")
  .controller(
    "Rhinestone.ProductTaxonomyController",
    ProductTaxonomyController
  );
