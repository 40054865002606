import "./assetsTreeView.html";
import "./assetsTreeViewNodes.html";
import { BaseComponent } from "../Core/BaseComponent";
import * as angular from "angular";
import { Binding } from "../Utils/AngularBindings";
import "./assetsTreeView.controller";
export class AssetCollectionTreeViewComponent extends BaseComponent {
  constructor() {
    super(
      "Rhinestone.AssetsTreeViewController",
      "/templates/Assets/assetsTreeView"
    );
    this.bindings = {
      assetCollection: Binding.oneWay,
      onAddNewFolder: Binding.outputEvent,
      onUploadFile: Binding.outputEvent
    };
  }
}

angular
  .module("PortalApp")
  .component("rhAssetTreeview", new AssetCollectionTreeViewComponent());
