import "./navbar.html";
import * as angular from "angular";
import { BaseComponent } from "../../Core/BaseComponent";
import "./navbar.less";
import "./navbar.controller";
import "./navBarHeaderService";
import "../../Localization/localizationSwitcher.component";
export class NavbarComponent extends BaseComponent {
  constructor() {
    super(
      "Rhinestone.NavbarController",
      "/templates/Layout/Navbar/navbar.html"
    );
  }
}

angular.module("PortalApp").component("rhNavbar", new NavbarComponent());
