import { ISearchResponse, SearchResultItem } from "../searchResponse";
import * as angular from "angular";
import * as _ from "underscore";

import { SimpleChange } from "../../../TypeDefinitions/angularextensions";
import { SearchViewType } from "@rhinestone/portal-web-api";
import { UrlTokens } from "@rhinestone/portal-web-react";

export class SearchResultMobileController {
  /* Input bindings */
  public isLoading: boolean;
  public result: ISearchResponse;
  public loadMore: (outputParams: { pageNumber: number }) => void;
  public isMobile: boolean;

  /* Internal state */
  public pageCount: number;
  public resultTemplate: string;
  public hasResults: boolean = false;

  /* Infinite scroll state */
  public resultArray: ReadonlyArray<SearchResultItem> = []; // Array of currently loaded results
  public currentPage: number = 1; // To keep track of most recent "page" added to the result list

  public static $inject: any[] = ["$location"];

  constructor(private $location: angular.ILocationService) {}

  public $onChanges(changes: {
    result?: SimpleChange<ISearchResponse>;
    resultView?: SimpleChange<SearchViewType>;
  }) {
    if (changes.result) {
      this.updateSearchResult(changes.result.currentValue);
    }

    if (changes.resultView) {
      this.onNewResultViewSet(changes.resultView.currentValue);
    }
  }

  public canLoadMore() {
    return (
      !this.isLoading &&
      this.result &&
      this.resultArray &&
      this.result.TotalCount > this.resultArray.length
    );
  }

  public getNextPage = _.debounce(() => {
    // This is used as we observed some weird behaviour with the infinite-scroll-disabled attribute.
    // (it caused this function to trigger twice)
    if (!this.canLoadMore()) {
      return;
    }

    this.loadMore({
      pageNumber: this.currentPage + 1
    });
  }, 300);

  public updateSearchResult(searchResponse: ISearchResponse) {
    if (!searchResponse) {
      this.resetResult();
    } else {
      this.onNewResponseSet(searchResponse);
    }
  }

  public resetResult() {
    this.currentPage = 1;
    this.resultArray = [];
    this.hasResults = false;
  }

  public onNewResponseSet(searchResponse: ISearchResponse) {
    this.hasResults = searchResponse.TotalCount > 0;
    this.currentPage = searchResponse.PageNumber;
    if (searchResponse) {
      if (searchResponse.PageNumber === 1) {
        this.resultArray = searchResponse.Results;
      } else {
        this.resultArray = searchResponse.Results.concat(this.resultArray);
      }
    } else {
      this.resultArray = [];
    }
  }

  public onNewResultViewSet(resultView: SearchViewType) {
    if (resultView == null) {
      return;
    }
    this.resultTemplate = `/templates${resultView.templateName}`;
  }

  public openDocument($event: Event, relativeIndex: number, url: string) {
    this.$location
      .url(url)
      .search({ ...this.$location.search(), [UrlTokens.showExact]: "true" });
  }
}

angular
  .module("PortalApp")
  .controller(
    "Rhinestone.SearchResultMobileController",
    SearchResultMobileController
  );
