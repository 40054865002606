import * as angular from "angular";
import { IHtmlRenderService } from "./IHtmlRenderService";

export interface ILinkRenderService {
  isLinkExternal(url: string): boolean;
}

export class LinkRenderService
  implements IHtmlRenderService, ILinkRenderService {
  public static $inject: string[] = [];

  constructor() {}

  public isLinkExternal(url: string): boolean {
    return (
      url !== undefined &&
      url !== null &&
      (url.indexOf("http://") === 0 || url.indexOf("https://") === 0) &&
      url.indexOf(
        window.location.protocol + "//" + window.location.hostname
      ) !== 0
    );
  }

  // Add target="_blank" to external links
  // This also removes root html tag but this is done anyway when rendering on the view
  public processHtml(html: string): string {
    const jqInput = $("<div>").html(html);
    let jqOutput = this.addBlankTargetToExternalLinksJQuery(jqInput);
    jqOutput = this.useNgScrollForHashLinksJQuery(jqOutput);
    return jqOutput.html();
  }

  // Add target="_blank" to external links
  // jq - provide the scope where <a> tags will be affected
  private addBlankTargetToExternalLinksJQuery(jq: JQuery): JQuery {
    jq.find("a")
      .filter('[href^="http://"],[href^="https://"]')
      .not(
        `a[href^="${window.location.protocol}//${window.location.hostname}"]`
      )
      .attr("target", "_blank");

    return jq;
  }

  private useNgScrollForHashLinksJQuery(jq: JQuery): JQuery {
    jq.find("a")
      .filter('[href^="#"]')
      .attr("scroll-to",""); 
    return jq;
  }
}

angular.module("PortalApp").service("linkRenderService", LinkRenderService);
