import * as angular from "angular";
import { PortalDataHttpService } from "../../Services/DataService/PortalDataHttpService";
import { DocumentVersionSubscription } from "./documentVersionSubscription";
import { ExistingDocumentVersionSubscription } from "./existingDocumentVersionSubscription";
import { CreateOrUpdateDocumentVersionSubscriptionModel } from "@rhinestone/portal-web-api";

export class DocumentVersionSubscriptionService {
  public static $inject = ["portalDataService"];

  constructor(private portalDataService: PortalDataHttpService) {}

  public async getExistingSubscription(
    hiveId: string,
    fullName: string
  ): Promise<ExistingDocumentVersionSubscription> {
    try {
      const subscriptions = await this.getSubscriptionsForDocument(
        hiveId,
        fullName
      );
      return subscriptions.length === 0
        ? null
        : {
            isSuspended: subscriptions[0].suspended
          };
    } catch (exception) {
      if (exception.status === 401) {
        return null;
      }

      throw exception;
    }
  }

  public async create(
    hiveId: string,
    fullName: string,
    name: string
  ): Promise<CreateOrUpdateDocumentVersionSubscriptionModel> {
    return await this.portalDataService.put<CreateOrUpdateDocumentVersionSubscriptionModel>(
      "User/DocumentVersionSubscriptions/",
      {
        hiveId,
        fullName,
        name
      }
    );
  }

  public async update(
    subscription: DocumentVersionSubscription
  ): Promise<CreateOrUpdateDocumentVersionSubscriptionModel> {
    return await this.portalDataService.put<CreateOrUpdateDocumentVersionSubscriptionModel>(
      "User/DocumentVersionSubscriptions/",
      {
        frequency: subscription.frequency,
        suspended: subscription.suspended,
        emailAddress: subscription.emailAddress,
        subscriptionId: subscription.subscriptionId,
        documentId: subscription.documentId,
        name: subscription.name
      }
    );
  }

  public async updateUsingTicket(
    subscription: DocumentVersionSubscription,
    email: string,
    ticket: string
  ): Promise<DocumentVersionSubscription> {
    return await this.portalDataService.put<DocumentVersionSubscription>(
      `EmailAccounts(${email})/DocumentVersionSubscriptions?ticket=${ticket}`,
      subscription
    );
  }

  public async deleteByHiveAndFullName(
    hiveId: string,
    fullName: string
  ): Promise<{}> {
    return await this.portalDataService.delete(
      `User/DocumentVersionSubscriptions/${hiveId}/${fullName}`
    );
  }

  public async deleteById(subscriptionId: string): Promise<{}> {
    return await this.portalDataService.delete(
      `User/DocumentVersionSubscriptions/${subscriptionId}`
    );
  }

  public async deleteByIdUsingTicket(
    subscriptionId: string,
    email: string,
    ticket: string
  ): Promise<{}> {
    return await this.portalDataService.delete(
      `EmailAccounts(${email})/DocumentVersionSubscriptions/${subscriptionId}?ticket=${ticket}`
    );
  }

  public async getSubscriptionsForUser(): Promise<
    DocumentVersionSubscription[]
  > {
    return this.confirmAll(
      await this.portalDataService.get<DocumentVersionSubscription[]>(
        "User/DocumentVersionSubscriptions/"
      )
    );
  }

  public async getSubscriptionsByEmail(
    email: string,
    ticket: string
  ): Promise<DocumentVersionSubscription[]> {
    const url = `EmailAccounts(${email})/DocumentVersionSubscriptions?ticket=${ticket}`;
    return this.confirmAll(
      await this.portalDataService.get<DocumentVersionSubscription[]>(url)
    );
  }

  public async getNextVersionFullName(
    hiveId: string,
    fullName: string
  ): Promise<string> {
    return await this.portalDataService.get<string>(
      `NextDocumentVersion/h/${hiveId}/${fullName}`
    );
  }

  private async getSubscriptionsForDocument(
    hiveId: string,
    fullName: string
  ): Promise<DocumentVersionSubscription[]> {
    const subscriptions = await this.portalDataService.get<
      DocumentVersionSubscription[]
    >(`User/DocumentVersionSubscriptions/${hiveId}/${fullName}`);
    return this.confirmAll(subscriptions);
  }

  private confirmAll(
    subscriptions: DocumentVersionSubscription[]
  ): DocumentVersionSubscription[] {
    subscriptions.forEach(s => {
      s.confirmed = true;
    });
    return subscriptions;
  }
}

angular
  .module("PortalApp")
  .service(
    "documentVersionSubscriptionService",
    DocumentVersionSubscriptionService
  );
