import { AssetsService } from "./assets.service";
import * as angular from "angular";
import { DocumentModel } from "../Models/DocumentModel";
import * as _ from "underscore";
import { ILocalizedNotificationService } from "../Services/localized.notification.service";
import { AssetCollectionViewModel } from "@rhinestone/portal-web-api";
import "./existingCollectionDialog.html";

export class ExistingCollectionDialogController {
  public static $inject = [
    "assetsService",
    "$uibModalInstance",
    "localizedNotificationService",
    "documents"
  ];

  public assetCollections: AssetCollectionViewModel[];

  public selectedCollectionId: string = "";

  public get documentCount(): number {
    return this.documents.length;
  }

  constructor(
    private assetsService: AssetsService,
    private $uibModalInstance: angular.ui.bootstrap.IModalServiceInstance,
    private localizedNotificationService: ILocalizedNotificationService,
    private documents: DocumentModel[]
  ) {
    this.loadCollections();
  }

  public loadCollections(): any {
    return this.assetsService.getCollections().then(data => {
      this.assetCollections = data;
    });
  }

  public canAdd(): boolean {
    return this.selectedCollectionId !== "";
  }

  public async add(): Promise<void> {
    await this.assetsService.addToCollection(
      this.selectedCollectionId,
      this.documents
    );

    const selectedItem = this.getSelectedCollectionName();
    const translationKey =
      this.documents.length === 1
        ? "assets.doc_added_success"
        : "assets.docs_added_success";
    this.localizedNotificationService.success(translationKey, {
      title: selectedItem
    });
    this.$uibModalInstance.close();
  }

  public cancel(): void {
    this.$uibModalInstance.dismiss("cancel");
  }

  private getSelectedCollectionName(): string {
    const item = _.find(this.assetCollections, col => {
      return col.assetCollectionId === this.selectedCollectionId;
    });

    return item ? item.title : "";
  }
}

angular
  .module("PortalApp")
  .controller(
    "Rhinestone.ExistingCollectionDialogController",
    ExistingCollectionDialogController
  );
