import * as angular from "angular";

export class LocalStorageConfig {
  public static $inject = ["localStorageServiceProvider"];

  constructor(
    $localStorageServiceProvider: angular.local.storage.ILocalStorageServiceProvider
  ) {
    $localStorageServiceProvider.setPrefix("rh-portal");
  }
}

angular.module("PortalApp").config(LocalStorageConfig);
