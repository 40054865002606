import * as angular from "angular";
import { AssetsService } from "./assets.service";
import { IDialogService } from "../Services/dialog.service";
import { IDeviceDetectionService } from "../Services/devicedetection.service";
import {
  IAssetCollectionRelease,
  IReleaseArtifact
} from "../Services/models/assetCollectionRelease";
import { AssetReleaseState } from "../Services/models/assetReleaseState";
import * as _ from "underscore";
import { ILocalizedNotificationService } from "../Services/localized.notification.service";
import { AssetCollectionViewModel } from "@rhinestone/portal-web-api";

export class AssetCollectionReleaseController {
  // Component bindings
  private assetCollection: AssetCollectionViewModel;

  private assetCollectionTitle: string;
  private list: IAssetCollectionRelease[];
  public table: any;
  private tableParamsOptions = { count: 0, sorting: { RequestedAt: "desc" } };
  private timerPromise: angular.IPromise<any>;
  private refreshInterval: number = 10000;
  private firstRefreshInterval: number = 5000;

  public isArchived: boolean;
  public hasReadAccess: boolean;

  public static $inject = [
    "$scope",
    "$filter",
    "$timeout",
    "$q",
    "$translate",
    "assetsService",
    "dialogService",
    "localizedNotificationService",
    "NgTableParams",
    "deviceDetectionService"
  ];

  constructor(
    private $scope: angular.IScope,
    private $filter: angular.IFilterService,
    private $timeout: angular.ITimeoutService,
    private $q: angular.IQService,
    private $translate: angular.translate.ITranslateService,
    private assetsService: AssetsService,
    private dialogService: IDialogService,
    private localizedNotificationService: ILocalizedNotificationService,
    private NgTableParams: any,
    private deviceDetectionService: IDeviceDetectionService
  ) {}

  public $onInit() {
    this.$scope.$on("$destroy", () => {
      this.stopTimer();
    });

    this.assetCollectionTitle = this.assetCollection.title;
    this.isArchived = this.assetCollection.isArchived;
    this.hasReadAccess = this.assetCollection.hasReadAccess;

    this.getReleases().then(data => {
      this.list = data;
      this.initializeTable(this.NgTableParams);
      this.timerPromise = this.$timeout(
        this.keepUpdatingReleasesTillPublished,
        this.refreshInterval
      );
    });
  }

  public openPublishDialog = () => {
    this.stopTimer();
    const modalInstance = this.dialogService.openPublishAssetCollectionDialog(
      this.assetCollection.assetCollectionId,
      this.assetCollectionTitle
    );
    modalInstance.result.then(
      () => {
        this.getReleases().then(data => {
          this.list = data;
          this.reloadTable();
          this.startTimer();
        });
      },
      () => {
        this.startTimer();
      }
    );
  };
  public isIosDevice(): boolean {
    return this.deviceDetectionService.isIosDevice();
  }

  public removeZipArtifactsOnIDeviceFilter(item: IReleaseArtifact): boolean {
    if (!this.isIosDevice()) {
      return true;
    } else {
      return item.MimeType !== "application/zip";
    }
  }

  public getMimeTypeIcon = (mimeType: any): string => {
    switch (mimeType) {
      case "application/pdf":
        return "fa-file-pdf-o";
      case "application/zip":
      case "application/octet-stream":
        return "fa-file-archive-o";
    }

    return "";
  };

  public getMimeTypeCaption = (item: IReleaseArtifact): string => {
    let caption = "";
    switch (item.MimeType) {
      case "application/pdf":
        caption = "Pdf";
        break;
      case "application/zip":
      case "application/octet-stream":
        caption = "Zip";
        break;
    }

    caption += " " + this.getCommentsCaption(item.Flags);
    return caption;
  };

  private getReleases = (): angular.IPromise<IAssetCollectionRelease[]> => {
    const data = this.assetsService.getAssetCollectionReleases(
      this.assetCollection.assetCollectionId
    );
    const defer = this.$q.defer<IAssetCollectionRelease[]>();
    if (this.isIosDevice()) {
      data.then(d => {
        const results: IAssetCollectionRelease[] = [];
        _.each(d, (item: IAssetCollectionRelease) => {
          const filteredArtifacts = _.filter(
            item.Artifacts,
            (art: IReleaseArtifact) => {
              return this.removeZipArtifactsOnIDeviceFilter(art);
            }
          );
          item.Artifacts = filteredArtifacts;
          results.push(item);
        });
        defer.resolve(results);
      });
    } else {
      return data;
    }
    return defer.promise;
  };

  private stopTimer = () => {
    if (this.timerPromise) {
      this.$timeout.cancel(this.timerPromise);
    }
  };

  private startTimer = () => {
    this.timerPromise = this.$timeout(
      this.keepUpdatingReleasesTillPublished,
      this.firstRefreshInterval
    );
  };

  private getCommentsCaption = (flags: any): string => {
    const translationKey =
      flags === 1 ? "assets.with_comment" : "assets.without_comment";
    const translation = this.$translate.instant(translationKey);
    return translation;
  };

  private keepUpdatingReleasesTillPublished = () => {
    return this.assetsService
      .getAssetCollectionReleases(this.assetCollection.assetCollectionId)
      .then((data: IAssetCollectionRelease[]) => {
        const hadPendingRows = this.updatePendingRows(data);
        if (hadPendingRows) {
          this.timerPromise = this.$timeout(
            this.keepUpdatingReleasesTillPublished,
            this.refreshInterval
          );
        }
      });
  };

  private updatePendingRows = (data: IAssetCollectionRelease[]): boolean => {
    const pendingRows = _.filter(
      this.list,
      row => row.Status === AssetReleaseState.Pending
    );
    _.forEach(pendingRows, item => {
      const row = _.find(
        data,
        element => element.ReleaseNumber === item.ReleaseNumber
      );
      if (row) {
        item.Status = row.Status;
        const filteredArtifacts = _.filter(
          row.Artifacts,
          (art: IReleaseArtifact) => {
            return this.removeZipArtifactsOnIDeviceFilter(art);
          }
        );

        item.Artifacts = filteredArtifacts;
        if (item.Status === AssetReleaseState.Complete) {
          const description = item.Description ? item.Description : "";
          this.localizedNotificationService.success(
            "assets.release_complete_success",
            { title: item.Title, description }
          );
        }
      }
    });
    return pendingRows.length > 0;
  };

  private reloadTable = () => {
    if (this.table) {
      this.table.reload();
    }
  };

  private initializeTable = (NgTableParams: any) => {
    this.tableParamsOptions.count = this.list.length;
    this.table = new NgTableParams(this.tableParamsOptions, {
      total: this.list.length,
      counts: [],
      getData: (params: any) => this.getDataCallback(params)
    });
  };

  private getDataCallback = (params: any) => {
    const orderedData = params.sorting()
      ? this.$filter("orderBy")(this.list, params.orderBy())
      : this.list;
    params.total((orderedData as any).inlineCount);
    return orderedData;
  };
}

angular
  .module("PortalApp")
  .controller(
    "Rhinestone.AssetCollectionReleaseController",
    AssetCollectionReleaseController
  );
