import { IHttpResponse } from "angular";
import angular = require("angular");

export class IsAuthHttpInterceptor implements angular.IHttpInterceptor {
  constructor(private $injector: any) {}

  handleResponse = (response: IHttpResponse<any>) => {
    if (!response.config.url.includes("/api/")) return response;

    // We resolve the service here to avoid circular dependency
    // Optimally we would want to refactor auth state to some shared service
    // we could inject to avoid circular dependency
    const currentUserService = this.$injector.get("currentUserService");
    if (!currentUserService.isAuthenticated) return response;

    const authHeader = response.headers("x-is-authenticated-on-server");
    if (!authHeader) return response;

    const isAuthOnServer: boolean = JSON.parse(authHeader.toLowerCase());

    if (!isAuthOnServer) {
      currentUserService.logout();
    }

    return response;
  };

  handleResponseError = (rejection: any) => {
    if (!rejection.config.url.includes("/api/")) throw rejection;

    // We resolve the service here to avoid circular dependency
    // Optimally we would want to refactor auth state to some shared service
    // we could inject to avoid cicrular dependency
    const currentUserService = this.$injector.get("currentUserService");
    if (!currentUserService.isAuthenticated) throw rejection;

    // https://docs.angularjs.org/api/ng/service/$http#$http-returns
    // If the status code is -1, then the request did not complete (timeout or cancelled)
    // Therefore there are no headers and we cannot do the x-is-authenticated-on-server check below
    if (rejection.status === -1) throw rejection;

    const isAuthOnServer: boolean = JSON.parse(
      rejection.headers("x-is-authenticated-on-server")?.toLowerCase()
    );

    if (!isAuthOnServer) {
      currentUserService.logout();
    }

    throw rejection;
  };

  response = this.handleResponse;
  responseError = this.handleResponseError;
}
