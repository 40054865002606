import * as angular from "angular";
import * as _ from "underscore";
import { SimpleChange } from "../../../TypeDefinitions/angularextensions";
import { ICriteriaGroupController } from "../Criterias/ICriteriaGroupController";
import { SearchEvents } from "../search.events";
import { ITaxonomyStateService } from "./../taxonomyState.service";
import { TaxonomyToFlatTaxonListConverter } from "./../TaxonomyToFlatTaxonListConverter";
import { buildTaxonsCriteriaDescription } from "../Criterias/buildCriteriaDescriptionFunctions";
import { ITaxonomyService } from "../../Services/taxonomy.service";
import { Taxon } from "../../Services/models/taxon";
import { Taxonomy } from "./../../Services/models/taxonomy";
import { TaxonomyKeyMap } from "../Taxonomy/taxonomyKeyMap";
import { buildTaxonomyKeyMap } from "../Taxonomy/buildTaxonomyKeyMap";
import { findTaxonFromKey } from "../Taxonomy/findTaxonFromKey";
import { TaxonomyFilterKey } from "@rhinestone/portal-web-api";
import {
  CriteriaChange,
  CriteriaProvider,
  Criteria,
  CriteriaChangeType
} from "@rhinestone/portal-web-react";

export class LocalTaxonomyController implements ICriteriaGroupController {
  // Part of the binding
  public readonly documentTypeTaxonomyId: string;
  public readonly icon: string;
  public readonly color: string;
  public readonly providerKey: string;
  public onCriteriaChanged: (outparams: { action: CriteriaChange }) => {};
  public onLoaded: (param: { provider: CriteriaProvider }) => void;
  public criteria: ReadonlyArray<Criteria>;
  public readonly taxonomyFilterKey: TaxonomyFilterKey;
  public readonly showEmptyTaxons: boolean;
  public readonly showFacetCount: boolean;

  // view bound "virtualized" taxonomy
  private taxonomyKeyMap: TaxonomyKeyMap[];
  // The list is called virtual because initially children have been decoupled for performance
  private virtualTaxons: Taxon[] = [];

  private readonly showEmptyTaxonsDependOnCriteria: string[] = ["products"];
  public loadingTaxonomies: boolean = true;
  public taxonomyLoadError: boolean = false;
  public noTaxonomies: boolean = false;

  private taxonomyLoadCanceller: {
    canceller: angular.IDeferred<any>;
    isCancelled: boolean;
  };

  public static $inject = [
    "$q",
    "$scope",
    "taxonomyService",
    "taxonomyStateService"
  ];

  constructor(
    private $q: angular.IQService,
    private $scope: angular.IScope & { taxonomy: Taxonomy },
    private taxonomyService: ITaxonomyService,
    private taxonomyStateService: ITaxonomyStateService
  ) {}

  public $onInit() {
    this.$scope.$on(SearchEvents.SearchCleared, this.collapseAll.bind(this));

    if (this.documentTypeTaxonomyId) {
      // only register component as an initial taxonomy loader if document type id is bound initially
      this.taxonomyStateService.registerComponentAsInitialTaxonomyLoader();
    }
  }

  private collapseAll() {
    // https://github.com/angular-ui-tree/angular-ui-tree/blob/master/examples/js/basic-example.js
    this.$scope.$broadcast("angular-ui-tree:collapse-all");
  }

  public $onDestroy() {
    this.taxonomyStateService.clear();
  }

  public $onChanges(changes: {
    criteria?: SimpleChange<ReadonlyArray<Criteria>>;
    documentTypeTaxonomyId?: SimpleChange<string>;
    providerKey?: SimpleChange<string>;
  }) {
    if (
      changes.criteria &&
      changes.criteria.currentValue &&
      this.virtualTaxons.length > 0
    ) {
      this.syncTaxonSelectedState();
    }

    this.reloadTaxonomy(changes);

    if (
      changes.providerKey &&
      !_.isEmpty(changes.providerKey.previousValue) &&
      _.isEmpty(changes.providerKey.currentValue)
    ) {
      this.onCriteriaChanged({
        action: {
          changes: this.buildRemovalChanges(changes.providerKey.previousValue)
        }
      });
    }
  }

  private syncTaxonSelectedState() {
    if (!this.criteria) {
      return;
    }

    const taxonKeysInCriteria = this.criteria
      .filter(c => c.providerKey === this.providerKey)
      .map(c => c.data.Taxon);

    this.virtualTaxons.forEach(taxon =>
      this.syncTaxonState(taxon, taxonKeysInCriteria)
    );
  }

  private syncTaxonState(virtualTaxon: Taxon, taxonKeysInCriteria: string[]) {
    const isSelected = taxonKeysInCriteria.some(
      key => key === virtualTaxon.key
    );

    virtualTaxon.isSelected = isSelected;

    if (isSelected && virtualTaxon.parent) {
      this.lazyLoadAndExpand(virtualTaxon.parent);
    }
  }

  private lazyLoadAndExpand(virtualTaxon: Taxon) {
    this.lazyAddChildren(virtualTaxon);
    virtualTaxon.expanded = true;

    if (virtualTaxon.parent) {
      this.lazyLoadAndExpand(virtualTaxon.parent);
    }
  }

  private reloadTaxonomy(changes: {
    criteria?: SimpleChange<ReadonlyArray<Criteria>>;
    documentTypeTaxonomyId?: SimpleChange<string>;
  }) {
    let reloadTaxonomy = false;
    let criteria: ReadonlyArray<Criteria> = [];
    if (changes.documentTypeTaxonomyId) {
      reloadTaxonomy = true;
    }

    if (
      this.showEmptyTaxons === false &&
      this.showEmptyTaxonsDependOnCriteria &&
      changes.criteria
    ) {
      const showEmptyTaxonsCriteriaPrevious = this.showEmptyTaxonsCriteria(
        changes.criteria.previousValue
      );
      const showEmptyTaxonsCriteriaCurrent = this.showEmptyTaxonsCriteria(
        changes.criteria.currentValue
      );
      criteria = showEmptyTaxonsCriteriaCurrent;

      if (
        !_.isEqual(
          showEmptyTaxonsCriteriaPrevious,
          showEmptyTaxonsCriteriaCurrent
        )
      ) {
        reloadTaxonomy = true;
      }
    }

    if (reloadTaxonomy) {
      this.loadTaxonomy(criteria);
    }
  }

  private showEmptyTaxonsCriteria(
    criteria: ReadonlyArray<Criteria>
  ): Criteria[] {
    if (!_.isEmpty(criteria) && this.showEmptyTaxonsDependOnCriteria) {
      return criteria.filter(
        v => this.showEmptyTaxonsDependOnCriteria.indexOf(v.providerKey) !== -1
      );
    }

    return [];
  }

  private async loadTaxonomy(criteria: ReadonlyArray<Criteria>) {
    if (_.isEmpty(this.documentTypeTaxonomyId)) {
      return;
    }

    try {
      // cancel previous taxonomy load if any
      this.taxonomyLoadCanceller?.canceller.resolve();
      this.setupCancellation();

      this.loadingTaxonomies = true;
      const taxonomy = await this.taxonomyService.getLocalTaxonomyTree(
        this.documentTypeTaxonomyId,
        {
          showDocuments: false,
          showEmptyTaxons: this.showEmptyTaxons !== false, // Defaults to true
          criteria
        }
      );

      this.taxonomyLoadCanceller = undefined;

      this.taxonomyStateService.addTaxonomy(this.providerKey, taxonomy);

      // build light weight map of taxonomy nested structure for later lookup
      this.taxonomyKeyMap = buildTaxonomyKeyMap(taxonomy);

      // Build virtual taxonomy clone
      // we need to sit the taxonomy on the $scope because of the recursive template being used as view
      this.$scope.taxonomy = this.buildVirtualTaxonomyClone(taxonomy);

      // Make sure taxon selected state is synced
      this.syncTaxonSelectedState();

      this.noTaxonomies = taxonomy.children.length === 0;

      // Signal that this component is ready as a criteria provider
      this.onLoaded({ provider: this.getCriteriaProvider() });
      this.taxonomyLoadError = false;
    } catch (e) {
      // If error is not due to cancellation then show it
      if (!this.taxonomyLoadCanceller?.isCancelled) {
        this.taxonomyLoadError = true;
        console.error(e);
      }
    }

    this.loadingTaxonomies = false;
    this.$scope.$apply();
    this.taxonomyStateService.registerInitialTaxonomyLoadFinished();
  }

  private setupCancellation() {
    this.taxonomyLoadCanceller = {
      canceller: this.$q.defer(),
      isCancelled: false
    };
    this.taxonomyLoadCanceller.canceller.promise.then(() => {
      this.taxonomyLoadCanceller.isCancelled = true;
    });
  }

  private buildVirtualTaxonomyClone(taxonomy: Taxonomy): Taxonomy {
    const converter = new TaxonomyToFlatTaxonListConverter();
    this.virtualTaxons = converter.toFlatTaxonList(taxonomy);
    return taxonomy;
  }

  public enterWasPressedKey(event: any) {
    if (event && event.type === "keyup") {
      return event.keyCode === 13;
    }

    return true;
  }

  public lazyLoadedToggle(scope: any, event: any) {
    if (!this.enterWasPressedKey(event)) {
      return;
    }

    // Toggles treeview nodes, and ensures that lazy loaded nodes gets added
    const virtualTaxonModel: Taxon = scope.$modelValue;
    virtualTaxonModel.expanded = !virtualTaxonModel.expanded;

    this.lazyAddChildren(virtualTaxonModel);
  }

  private lazyAddChildren(virtualTaxonModel: Taxon) {
    if (!this.shouldLoadChildren(virtualTaxonModel)) return;

    // The taxon has children that is not yet added
    // Find child keys in keymap
    const childrenKeys = this.taxonomyKeyMap
      .find(x => x.key === virtualTaxonModel.key)
      .children.map(c => c.key);

    // Set children objects so that they can be rendered
    virtualTaxonModel.children = childrenKeys
      .map(key => this.virtualTaxons.find(t => t.key === key))
      .filter(x => !!x);
  }

  private shouldLoadChildren(virtualTaxonModel: Taxon): boolean {
    return (
      virtualTaxonModel.hasChildren && virtualTaxonModel.children.length === 0
    );
  }

  public multiSelectTaxon(taxon: Taxon): void {
    const shouldAddCriteria = !taxon.isSelected;
    this.onCriteriaChanged({
      action: {
        changes: LocalTaxonomyController.buildTaxonCriteria(
          this.providerKey,
          taxon,
          this.documentTypeTaxonomyId,
          shouldAddCriteria ? CriteriaChangeType.Add : CriteriaChangeType.Remove
        ).changes
      }
    });
  }

  public singleSelectTaxon(taxon: Taxon): void {
    this.onCriteriaChanged({
      action: {
        changes: this.buildRemovalChanges(this.providerKey).concat(
          LocalTaxonomyController.buildTaxonCriteria(
            this.providerKey,
            taxon,
            this.documentTypeTaxonomyId,
            CriteriaChangeType.Add
          ).changes
        )
      }
    });
  }

  private buildRemovalChanges(
    providerKey: string
  ): Array<{ type: CriteriaChangeType; criteria: Criteria }> {
    return this.getExistingTaxonomyCriterias(providerKey).map(c => {
      return {
        type: CriteriaChangeType.Remove,
        criteria: c
      };
    });
  }

  private getExistingTaxonomyCriterias(providerKey: string): Criteria[] {
    if (!this.criteria) {
      throw Error(
        "Expecting current criteria list to be bound from parent component. Please check view bindings."
      );
    }

    return this.criteria.filter(c => c.providerKey === providerKey);
  }

  public static buildTaxonCriteria(
    providerKey: string,
    taxon: Taxon,
    documentTypeTaxonomyId: string,
    changeType: CriteriaChangeType
  ): CriteriaChange {
    const criteriaChanges: CriteriaChange = {
      changes: [
        {
          type: changeType,
          criteria: {
            providerKey: "LocalDocumentType",
            data: {
              DocumentTypeTaxonomyId: documentTypeTaxonomyId,
              Taxon: taxon.key
            }
          }
        }
      ]
    };

    // When a taxon is selected, we unselect all ancestors and children.
    // Example: If the sub-taxon AD-DOM is selected while the parent "LAWS" is selected, all LAWS are still selected
    // unless we de-select the parent, then only AD-DOM and any other sub-taxons selected will be chosen for search.
    // Likewise, if a parent is selected while a sub-taxon is selected, it's now indirectly selected twice, so we remove
    // the child as all children of the parent is now selected.
    if (changeType === CriteriaChangeType.Add) {
      LocalTaxonomyController.uncheckAncestors(
        providerKey,
        taxon,
        criteriaChanges
      );
      LocalTaxonomyController.uncheckChildren(
        providerKey,
        taxon,
        criteriaChanges
      );
    }

    return criteriaChanges;
  }

  private static uncheckAncestors(
    providerKey: string,
    taxon: Taxon,
    criteriaChanges: CriteriaChange
  ): void {
    LocalTaxonomyController.getTaxonAncestors(taxon).forEach(t => {
      criteriaChanges.changes.push({
        type: CriteriaChangeType.Remove,
        criteria: {
          providerKey,
          data: t.key
        }
      });
    });
  }

  private static uncheckChildren(
    providerKey: string,
    taxon: Taxon,
    criteriaChanges: CriteriaChange
  ): void {
    taxon.children.forEach(t => {
      if (t.isSelected) {
        LocalTaxonomyController.uncheckChildrenRecursively(
          providerKey,
          t,
          criteriaChanges
        );
      }
    });
  }

  private static uncheckChildrenRecursively(
    providerKey: string,
    taxon: Taxon,
    criteriaChanges: CriteriaChange
  ): void {
    criteriaChanges.changes.push({
      type: CriteriaChangeType.Remove,
      criteria: {
        providerKey,
        data: taxon.key
      }
    });

    taxon.children.forEach(t => {
      if (t.isSelected) {
        this.uncheckChildrenRecursively(providerKey, t, criteriaChanges);
      }
    });
  }

  private getCriteriaProvider(): CriteriaProvider {
    return {
      key: this.providerKey,
      getCriteriaViewModel: criteria => {
        if (criteria.providerKey !== this.providerKey) {
          return null;
        }
        const taxon = findTaxonFromKey(criteria.data.Taxon, this.virtualTaxons);
        return {
          displayValue: taxon.longTitle,
          longDisplayValue: taxon.path.map(p => p.title).join("/"),
          displayValueResource: undefined,
          criteria,
          icon: this.icon,
          color: this.color
        };
      },
      buildCriteriaDescription: request =>
        buildTaxonsCriteriaDescription(
          request,
          this.providerKey,
          this.criteria,
          this.virtualTaxons,
          findTaxonFromKey,
          this.taxonomyFilterKey
        )
    };
  }

  private static getTaxonAncestors(taxon: Taxon) {
    const ancestors = [];
    while (taxon.parent) {
      ancestors.push(taxon.parent);
      taxon = taxon.parent;
    }
    return ancestors;
  }
}

angular
  .module("PortalApp")
  .controller("Rhinestone.LocalTaxonomyController", LocalTaxonomyController);
