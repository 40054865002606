import * as angular from "angular";
import { Binding } from "../../Utils/AngularBindings";
import { BaseComponent } from "./../../Core/BaseComponent";
import "./relatedDocuments.controller";
import "./relatedDocuments.html";
import "./relatedDocuments.service";
export class RelatedDocumentsComponent extends BaseComponent {
  constructor() {
    super(
      "Rhinestone.RelatedDocumentsController",
      "/templates/Search/RelatedDocuments/relatedDocuments.html"
    );
    this.bindings = {
      onCriteriaChanged: Binding.outputEvent,
      criteriaList: Binding.oneWay,
      providerKey: Binding.valueInput,
      onLoaded: Binding.outputEvent
    };
  }
}

angular
  .module("PortalApp")
  .component("rhRelatedDocuments", new RelatedDocumentsComponent());
