import * as angular from "angular";

export class SubscriptionsActionsPaneController {
  // Output bindings
  public onUpdateAll: ({ suspended }: { suspended: boolean }) => void;
  public onSubscribeToAll: () => void;

  // Input binding
  public subscriptionsExist: boolean;

  public static $inject: string[] = [];

  public updateAll(suspended: boolean) {
    this.onUpdateAll({ suspended });
  }

  public subscribeToAll() {
    this.onSubscribeToAll();
  }
}

angular
  .module("PortalApp")
  .controller(
    "Rhinestone.SubscriptionsActionsPaneController",
    SubscriptionsActionsPaneController
  );
