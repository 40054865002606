import * as angular from "angular";

export class SpinnerController {
  // bindings
  public size = 1;

  public static $inject: any[] = [];

  constructor() {}
}

angular
  .module("PortalApp")
  .controller("Rhinestone.SpinnerController", SpinnerController);
