import "./productTaxonomy.html";
import { BaseComponent } from "../../Core/BaseComponent";
import * as angular from "angular";
import { Binding } from "../../Utils/AngularBindings";
import "./productTaxonomy.less";
import "./productTaxonomy.controller";
export class ProductTaxonomyComponent extends BaseComponent {
  constructor() {
    super(
      "Rhinestone.ProductTaxonomyController",
      "/templates/Search/ProductTaxonomy/productTaxonomy.html"
    );
    this.bindings = {
      productProviderKey: Binding.valueInput,
      products: Binding.oneWay,
      criteria: Binding.oneWay,
      icon: Binding.valueInput,
      color: Binding.valueInput,
      onCriteriaChanged: Binding.outputEvent,
      onLoaded: Binding.outputEvent,
      productCode: Binding.oneWay
    };
  }
}

angular
  .module("PortalApp")
  .component("rhProductTaxonomy", new ProductTaxonomyComponent());
