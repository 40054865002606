import * as angular from "angular";
import { IPortalConfig } from "../../Core/portal.provider";

export class QuickLinksController {
  public static $inject = ["portal"];
  constructor(public portal: IPortalConfig) {}

  public $onChanges(changes: any) {}
}

angular
  .module("PortalApp")
  .controller("Rhinestone.QuickLinksController", QuickLinksController);
