import "./statusSearchFilter.html";
import { BaseComponent } from "../../Core/BaseComponent";
import * as angular from "angular";
import { Binding } from "../../Utils/AngularBindings";
import "./statusSearchFilter.less";
import "./statusSearchFilter.controller";
export class StatusSearchFilterComponent extends BaseComponent {
  constructor() {
    super(
      "Rhinestone.StatusSearchFilterController",
      "/templates/search/StatusSearchFilter/StatusSearchFilter"
    );
    this.bindings = {
      filter: Binding.oneWay,
      criteria: Binding.oneWay,
      color: Binding.valueInput,
      icon: Binding.valueInput,
      providerKey: Binding.valueInput,
      onCriteriaChanged: Binding.outputEvent,
      onLoaded: Binding.outputEvent
    };
  }
}

angular
  .module("PortalApp")
  .component("rhStatusSearchFilter", new StatusSearchFilterComponent());
