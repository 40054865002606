import { TaxonomyKeyMap } from "./taxonomyKeyMap";
import { Taxon } from "../../Services/models/taxon";
import { Taxonomy } from "./../../Services/models/taxonomy";

export const buildTaxonomyKeyMap = (taxonomy: Taxonomy) => {
  const flatKeyMap: TaxonomyKeyMap[] = [];

  const mapChildren = (taxon: Taxon) => {
    const mappedTaxon: TaxonomyKeyMap = {
      key: taxon.key,
      children: taxon.children.map(mapChildren)
    };

    flatKeyMap.push(mappedTaxon);
    return mappedTaxon;
  };

  taxonomy.children.forEach(mapChildren);
  return flatKeyMap;
};
