import * as angular from "angular";
import * as bootstrap from "bootstrap";
import { INodeInfo } from "../Services/models/nodeInfo";
import { NodeType } from "../Services/models/nodeType";
import "./confirmDeleteNodeDialog.html";
export class ConfirmDeleteNodeController {
  public dialogTitle: string;
  public isHeading: boolean;

  public static $inject = ["$uibModalInstance", "$translate", "assetItem"];

  constructor(
    private $uibModalInstance: angular.ui.bootstrap.IModalServiceInstance,
    private $translate: angular.translate.ITranslateService,
    public assetItem: INodeInfo
  ) {
    this.isHeading = assetItem.ItemType === NodeType.Folder;

    const dialogTitleTranslationKey = this.isHeading
      ? "assets.delete_heading"
      : "assets.delete_item";
    this.$translate(dialogTitleTranslationKey).then(translation => {
      this.dialogTitle = translation;
    });
  }

  public cancel = () => {
    this.$uibModalInstance.dismiss("cancel");
  };

  public confirm = () => {
    this.$uibModalInstance.close("ok");
  };
}

angular
  .module("PortalApp")
  .controller(
    "Rhinestone.ConfirmDeleteNodeController",
    ConfirmDeleteNodeController
  );
