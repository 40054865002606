import { ProductDropdownModel } from "@rhinestone/portal-web-api";
import {
  Criteria,
  CriteriaChange,
  CriteriaChangeType
} from "@rhinestone/portal-web-react";
import { SimpleChange } from "../../../TypeDefinitions/angularextensions";
import _ = require("underscore");

export class SelectProductSearchFilterBase {
  // Bindings
  public readonly providerKey: string;
  public onCriteriaChanged: (action: { action: CriteriaChange }) => void;
  public criteria: ReadonlyArray<Criteria>;
  public products: ProductDropdownModel[] = [];
  public ownedProducts: ProductDropdownModel[] = [];

  constructor() {}

  protected getActionsForOrphanedTaxons(
    productNavigationTaxonomyName: string
  ): Array<{
    readonly criteria: Criteria;
    readonly type: CriteriaChangeType;
  }> {
    const criteria: Criteria[] = [];
    _.each(this.criteria, x => {
      const taxon = _.find(
        this.products,
        y => x.providerKey === y.productNavigationTaxonomyName
      );
      if (
        taxon &&
        taxon.productNavigationTaxonomyName !== productNavigationTaxonomyName
      ) {
        criteria.push({ providerKey: x.providerKey, data: x.data });
      }
    });
    return _.map(criteria, x => ({
      type: CriteriaChangeType.Remove,
      criteria: x
    }));
  }

  protected criteriaChanged(changes: SimpleChange<Criteria[]>) {
    if (changes.isFirstChange()) {
      return true;
    }

    const previous = changes.previousValue.filter(
      criteria => criteria.providerKey === this.providerKey
    );
    const current = changes.currentValue.filter(
      criteria => criteria.providerKey === this.providerKey
    );

    return (
      previous.length !== current.length ||
      previous.some((criteria, index) => criteria.data !== current[index].data)
    );
  }

  protected createChange(
    type: CriteriaChangeType,
    productCode: string
  ): { readonly criteria: Criteria; readonly type: CriteriaChangeType } {
    return {
      type,
      criteria: {
        providerKey: this.providerKey,
        data: productCode
      }
    };
  }
}
