import * as rison from "rison";
import * as _ from "underscore";
import { TaxonUrlParams } from "./taxonUrlParams";
import { Taxon } from "../../Services/models/taxon";
import { Taxonomy } from "../../Services/models/taxonomy";

export namespace TaxonomyPathBuilder {
  export function getSerializedPath(
    taxonKey: string,
    productCode: string,
    taxonomy: Taxonomy
  ): string {
    const path = getPath(taxonKey, taxonomy);
    const taxonUrlParams = new TaxonUrlParams();
    taxonUrlParams.productCode = productCode;
    _.each(path, x =>
      taxonUrlParams.taxons.push({
        key: x.key,
        title: x.title
      })
    );
    return rison.encode_uri(taxonUrlParams);
  }

  export function getPath(taxonKey: string, taxonomy: Taxonomy): Taxon[] {
    let current = findNode(taxonKey, taxonomy);
    const result: Taxon[] = [];
    if (current != null) {
      result.push(current);
      while (current.parent != null) {
        result.push(current.parent);
        current = current.parent;
      }
      return result.reverse();
    }
    return null;
  }

  function findNode(taxonKey: string, taxonomy: Taxonomy): Taxon {
    for (const child of taxonomy.children) {
      const found = findNodeRecursive(child, taxonKey);
      if (found != null) {
        return found;
      }
    }
    return null;
  }

  function findNodeRecursive(taxon: Taxon, key: string): Taxon {
    if (taxon.key === key) {
      return taxon;
    } else {
      if (taxon.children.length === 0) {
        return null;
      } else {
        let result: Taxon = null;
        for (let i = 0; result == null && i < taxon.children.length; i++) {
          result = findNodeRecursive(taxon.children[i], key);
        }
        return result;
      }
    }
  }
}
