import "./assetsReleases.html";
import { BaseComponent } from "../Core/BaseComponent";
import * as angular from "angular";
import { Binding } from "../Utils/AngularBindings";
import "./assetsReleases.controller";
export class AssetReleasesComponent extends BaseComponent {
  constructor() {
    super(
      "Rhinestone.AssetCollectionReleaseController",
      "/templates/assets/assetsReleases"
    );
    this.bindings = {
      assetCollection: Binding.oneWay
    };
  }
}

angular
  .module("PortalApp")
  .component("rhAssetReleases", new AssetReleasesComponent());
