import * as angular from "angular";
import { ICurrentUserService } from "../../User/currentUser.service";
import { IPortalConfig } from "../../Core/portal.provider";
import { LoginTileDto } from "../TilesTypes";

export class LoginTileController {
  public isInitialized: boolean = false;
  public currentUrl: string;
  public tile: LoginTileDto;
  public imageUrl: string;

  public static $inject = ["$location", "currentUserService", "portal"];

  constructor(
    private $location: angular.ILocationService,
    private currentUser: ICurrentUserService,
    private portal: IPortalConfig
  ) {}

  public $onInit() {
    this.imageUrl = `/api/Portals(${this.portal.identifier})${this.tile.imagePath}`;
    this.currentUrl = this.$location.url();
    this.isInitialized = true;
  }
}

angular
  .module("PortalApp")
  .controller("Rhinestone.LoginTileController", LoginTileController);
