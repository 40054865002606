import { IPortalDataHttpService } from "../Services/DataService/PortalDataHttpService";
import * as angular from "angular";
import { PortalLocalizationModel } from "@rhinestone/portal-web-api";

export interface ILocalizationService {
  getLocalizations(): angular.IPromise<PortalLocalizationModel[]>;
}

export class LocalizationService implements ILocalizationService {
  public getLocalizations(): angular.IPromise<PortalLocalizationModel[]> {
    return this.portalDataService.get<PortalLocalizationModel[]>(
      "localizations"
    );
  }

  public static $inject = ["portalDataService"];

  constructor(private portalDataService: IPortalDataHttpService) {}
}
angular.module("PortalApp").service("localizationService", LocalizationService);
