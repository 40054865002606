import { SearchSubscription } from "./searchSubscription";
import * as angular from "angular";
import { PortalDataHttpService } from "../Services/DataService/PortalDataHttpService";

export class SearchSubscriptionService {
  public static $inject = ["$q", "portalDataService"];

  constructor(
    private $q: angular.IQService,
    private portalDataService: PortalDataHttpService
  ) {}

  public async getSubscription(
    email: string,
    subscriptionId: string,
    ticket: string
  ): Promise<SearchSubscription> {
    const url = `/EmailAccounts(${email})/SearchSubscriptions(${subscriptionId})?ticket=${ticket}`;
    return await this.portalDataService.get<SearchSubscription>(url);
  }

  public async confirmSubscription(
    email: string,
    subscriptionId: string,
    ticket: string
  ): Promise<SearchSubscription> {
    return await this.portalDataService.put<SearchSubscription>(
      `EmailAccounts(${email})/SearchSubscriptions(${subscriptionId})/Confirm?ticket=${ticket}`,
      {}
    );
  }

  public async getSearchSubscriptionsByEmail(
    email: string,
    ticket: string
  ): Promise<SearchSubscription[]> {
    const url = `EmailAccounts(${email})/SearchSubscriptions?ticket=${ticket}`;
    return await this.portalDataService.get<SearchSubscription[]>(url);
  }

  public async create(
    searchSubscription: SearchSubscription
  ): Promise<SearchSubscription> {
    return await this.portalDataService.post<SearchSubscription>(
      "SearchSubscriptions",
      searchSubscription
    );
  }

  public async update(
    searchSubscription: SearchSubscription,
    email: string,
    ticket: string
  ): Promise<void> {
    const url = `EmailAccounts(${email})/SearchSubscriptions(${
      searchSubscription.subscriptionId
    })?ticket=${ticket}`;

    await this.portalDataService.put<void>(url, searchSubscription);
  }

  public async delete(
    subscriptionId: string,
    email: string,
    ticket: string
  ): Promise<void> {
    await this.portalDataService.delete<void>(
      `EmailAccounts(${email})/SearchSubscriptions(${subscriptionId})?ticket=${ticket}`
    );
  }
}

angular
  .module("PortalApp")
  .service("searchSubscriptionService", SearchSubscriptionService);
