import "./tileCardBody.html";
import { BaseComponent } from "../../../Core/BaseComponent";
import * as angular from "angular";

export class TileCardBodyComponent extends BaseComponent {
  constructor() {
    super(
      "Rhinestone.TileCardController",
      "/templates/tiles/tilecard/tilecardbody/tileCardBody.html",
      true
    );
  }
}

angular
  .module("PortalApp")
  .component("rhTileCardBody", new TileCardBodyComponent());
