import { SimpleChange } from "../../../TypeDefinitions/angularextensions";
import {
  Criteria,
  CriteriaChangeType,
  CriteriaChange
} from "@rhinestone/portal-web-react";
import * as angular from "angular";
import * as _ from "underscore";
import { ProductDropdownModel } from "@rhinestone/portal-web-api";
import { SelectProductSearchFilterBase } from "./selectProductSearchFilterBase";

export class SingleSelectProductSearchFilterController extends SelectProductSearchFilterBase {
  // State
  public selectedProduct: ProductDropdownModel;

  constructor() {
    super();
  }

  public $onChanges(changes: {
    criteria?: SimpleChange<Criteria[]>;
    products?: SimpleChange<ProductDropdownModel[]>;
  }) {
    if (changes.products) {
      this.selectProduct();
    }

    if (changes.criteria && this.criteriaChanged(changes.criteria)) {
      this.selectProduct();
    }
  }

  public selectedProductChanged(
    product: ProductDropdownModel,
    previousProductCode: string
  ): void {
    const action: CriteriaChange = { changes: [] };

    if (!_.isEmpty(previousProductCode)) {
      action.changes.push(
        this.createChange(CriteriaChangeType.Remove, previousProductCode)
      );
    }

    if (!_.isEmpty(product)) {
      action.changes.push(
        this.createChange(CriteriaChangeType.Add, product.code)
      );
      // we should also remove taxons from previous product when product is changed
      action.changes.push.apply(
        action.changes,
        this.getActionsForOrphanedTaxons(product.productNavigationTaxonomyName)
      );
    }

    if (action.changes.length > 0) {
      this.onCriteriaChanged({ action });
    }
  }

  private selectProduct(): void {
    const criteriaIndex = _.findIndex(
      this.criteria,
      x => x.providerKey === this.providerKey
    );

    if (criteriaIndex >= 0) {
      const productIndex = _.findIndex(
        this.products,
        x => x.code === this.criteria[criteriaIndex].data
      );
      if (productIndex >= 0) {
        this.selectedProduct = this.products[productIndex];
      }
    } else {
      this.selectedProduct = null;
    }
  }
}

angular
  .module("PortalApp")
  .controller(
    "Rhinestone.SingleSelectProductSearchFilterController",
    SingleSelectProductSearchFilterController
  );
