import { ISearchResponse } from "./searchResponse";
import { Criteria } from "@rhinestone/portal-web-react";

export class SearchEvents {
  public static NewSearch = "search_new";
  public static SearchCleared = "search_cleared";
  public static ResetSearch = "search_reset";
}

export class NewSearchEventArgs {
  constructor(
    public result: ISearchResponse,
    public criteria: ReadonlyArray<Criteria>
  ) {}
}
