import "./assetsListSearchBox.html";
import { BaseComponent } from "../Core/BaseComponent";
import * as angular from "angular";
import { Binding } from "../Utils/AngularBindings";
import "./assetsListSearchBox.controller";

export class AssetsListSearchBoxComponent extends BaseComponent {
  constructor() {
    super(
      "Rhinestone.AssetsListSearchBoxController",
      "/templates/Assets/assetsListSearchBox"
    );
    this.bindings = {
      filter: Binding.twoWay
    };
  }
}

angular
  .module("PortalApp")
  .component("rhAssetsListSearchBox", new AssetsListSearchBoxComponent());
