import * as angular from "angular";
import * as _ from "underscore";
import { SearchRefinementItem, TaxonCriteria } from "../searchResponse";
import { ICriteriaGroupController } from "../Criterias/ICriteriaGroupController";
import {
  Criteria,
  CriteriaChangeType,
  CriteriaChange,
  CriteriaProvider
} from "@rhinestone/portal-web-react";

import { SimpleChange } from "../../../TypeDefinitions/angularextensions";
import { buildTaxonsCriteriaDescription } from "../Criterias/buildCriteriaDescriptionFunctions";
import { TaxonomyFilterKey } from "@rhinestone/portal-web-api";
import { findTaxonFromKey } from "../Taxonomy/findTaxonFromKey";
import { TaxonomyToFlatTaxonListConverter } from "../TaxonomyToFlatTaxonListConverter";
import { ITaxonomyService } from "../../Services/taxonomy.service";
import { Taxon } from "../../Services/models/taxon";
import { Taxonomy } from "../../Services/models/taxonomy";

interface Flag {
  isSelected: boolean;
  refinementOption: SearchRefinementItem;
}

export class RefinementsSearchFilterController
  implements ICriteriaGroupController
{
  public static $inject = ["taxonomyService"];

  public flags: Flag[] = [];

  // bindings
  public allRefinements: SearchRefinementItem[] = [];
  public refinements: SearchRefinementItem[] = [];
  public readonly color: string;
  public readonly icon: string;
  public readonly taxonomyFilterKey: TaxonomyFilterKey;
  public criteria: Criteria[] = [];
  public onCriteriaChanged: (action: { action: CriteriaChange }) => void;
  public onLoaded: (param: { provider: CriteriaProvider }) => void;
  public onViewChanged: (param: {
    param: { viewName: string; isVisible: boolean; isActive: boolean };
  }) => void;

  // state
  // dictionary with flat list of taxons for every taxonomy in refinements criteria
  // dictionary key is taxonomy name.
  private virtualTaxons: { [index: string]: Taxon[] } = {};

  constructor(private taxonomyService: ITaxonomyService) {}

  public $onInit() {
    this.updateFlags();
  }

  public $onChanges(changes: {
    criteria?: SimpleChange<Criteria[]>;
    allRefinements?: SimpleChange<SearchRefinementItem[]>;
  }) {
    if (changes.criteria) {
      this.criteria = changes.criteria.currentValue;
    }

    if (changes.allRefinements && changes.allRefinements.currentValue) {
      changes.allRefinements.currentValue.forEach(refinement => {
        refinement.TaxonCriteria.forEach(taxonCriteria => {
          if (
            Object.keys(this.virtualTaxons).indexOf(
              taxonCriteria.TaxonomyName
            ) < 0
          ) {
            this.taxonomyService
              .getTaxonomyTree(taxonCriteria.TaxonomyName, {
                showDocuments: false
              })
              .then(taxonomy => {
                this.buildVirtualTaxonomyClone(taxonomy);
                // Register criteria provider
                this.onLoaded({
                  provider: this.getCriteriaProvider(taxonCriteria)
                });
              });
          }
        });
      });
    }

    this.updateFlags();
  }

  private getCriteriaProvider(taxonCriteria: TaxonCriteria): CriteriaProvider {
    const { TaxonomyName } = taxonCriteria;
    return {
      key: TaxonomyName,
      getCriteriaViewModel: criteria => {
        if (criteria.providerKey !== TaxonomyName) {
          return null;
        }
        const taxon = findTaxonFromKey(
          criteria.data,
          this.virtualTaxons[TaxonomyName]
        );
        return {
          displayValue: taxon.longTitle,
          longDisplayValue: taxon.path.map(p => p.title).join("/"),
          displayValueResource: undefined,
          criteria,
          icon: this.icon,
          color: this.color
        };
      },
      buildCriteriaDescription: request => {
        return buildTaxonsCriteriaDescription(
          request,
          TaxonomyName,
          this.criteria,
          this.virtualTaxons[TaxonomyName],
          findTaxonFromKey,
          this.taxonomyFilterKey
        );
      }
    };
  }

  public toggleFlag({ isSelected, refinementOption }: Flag): void {
    const changeType = isSelected
      ? CriteriaChangeType.Remove
      : CriteriaChangeType.Add;

    this.onViewChanged({
      param: {
        viewName: refinementOption.ViewName,
        isVisible: !isSelected,
        isActive: !isSelected
      }
    });

    this.onCriteriaChanged({
      action: this.buildCriteriaChange(refinementOption, changeType)
    });
  }

  private buildVirtualTaxonomyClone(taxonomy: Taxonomy): void {
    const converter = new TaxonomyToFlatTaxonListConverter();
    this.virtualTaxons[taxonomy.name] = converter.toFlatTaxonList(taxonomy);
  }

  private buildCriteriaChange(
    option: SearchRefinementItem,
    changeType: CriteriaChangeType
  ): CriteriaChange {
    return {
      changes: option.TaxonCriteria.map(x => ({
        type: changeType,
        criteria: {
          providerKey: x.TaxonomyName,
          data: x.TaxonKey
        }
      }))
    };
  }

  private updateFlags() {
    if (!this.refinements) {
      return;
    }

    const flags = this.refinements.map(refinement => {
      const isSelected = this.criteriaExists(
        this.criteria,
        refinement.TaxonCriteria
      );

      return {
        isSelected,
        refinementOption: refinement
      };
    });

    flags.forEach(flag => this.setSearchViewVisibility(flag));

    this.flags = flags;
  }

  private setSearchViewVisibility({ isSelected, refinementOption }: Flag) {
    this.onViewChanged({
      param: {
        viewName: refinementOption.ViewName,
        isVisible: isSelected,
        isActive: false
      }
    });
  }

  private criteriaExists(
    criteria: Criteria[],
    taxonCriteria: TaxonCriteria[]
  ): boolean {
    return taxonCriteria.every(criteriaA =>
      criteria.some(criteriaB => {
        return (
          criteriaA.TaxonomyName === criteriaB.providerKey &&
          criteriaA.TaxonKey === criteriaB.data
        );
      })
    );
  }
}

angular
  .module("PortalApp")
  .controller(
    "Rhinestone.RefinementsSearchFilterController",
    RefinementsSearchFilterController
  );
