import "./unauthorized.html";
import { Constants } from "../app.constants";
import { BaseComponent } from "../Core/BaseComponent";
import * as angular from "angular";
import "./unauthorized.controller";
export class UnauthorizedComponent extends BaseComponent {
  constructor() {
    super(
      "Rhinestone.UnauthorizedController",
      `${Constants.TemplateBaseUrl}/Error/unauthorized`
    );
  }
}

angular
  .module("PortalApp")
  .component("rhUnauthorized", new UnauthorizedComponent());
