import * as angular from "angular";

export class SelectConfig {
  public static $inject = ["uiSelectConfig"];

  constructor(uiSelectConfig: any) {
    uiSelectConfig.theme = "bootstrap";
    uiSelectConfig.resetSearchInput = true;
  }
}

angular.module("PortalApp").config(SelectConfig);
