import * as angular from "angular";
import { ICurrentUserService } from "./currentUser.service";
import { userHasFeature } from "./userHasFeature";
import { Feature } from "@rhinestone/portal-web-react";

export class UserFeaturesService {
  public static $inject = ["currentUserService"];

  constructor(private currentUserService: ICurrentUserService) {}

  public hasFeature(feature: Feature): boolean {
    const user = this.currentUserService.user;
    return userHasFeature(user, feature.toString());
  }
}

angular.module("PortalApp").service("userFeaturesService", UserFeaturesService);
