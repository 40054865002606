import "./mobileMenu.html";
import * as angular from "angular";
import { BaseComponent } from "../../Core/BaseComponent";
import { Binding } from "../../Utils/AngularBindings";
import "./mobileMenu.less";
import "./mobileMenu.controller";
export class MobileMenuComponent extends BaseComponent {
  constructor() {
    super(
      "Rhinestone.MobileMenuController",
      "/templates/Layout/MobileMenu/MobileMenu.html",
      true
    );
    this.bindings = {
      position: Binding.valueInput,
      icon: Binding.valueInput,
      sectionTitle: Binding.valueInput
    };
  }
}

angular
  .module("rhSidePane")
  .component("rhMobileMenu", new MobileMenuComponent());
