import "./layout.html";
import { BaseComponent } from "../Core/BaseComponent";
import * as angular from "angular";
import "./layout.less";
import "./layout.controller";
import "./Navbar/navbar.component";
import "../Utils/keep-scroll-position";
import "./QuickLinks/quickLinks.component";
import "./SidePane/sidepane.component";
import "./MobileMenu/mobileMenu.component";
import "./MainContent/mainContent.component";
import "../Controls/spinner.component";
import "../Controls/error.Component";

export class LayoutComponent extends BaseComponent {
  constructor() {
    super("Rhinestone.LayoutController", "/templates/layout/layout.html");
  }
}

angular.module("PortalApp").component("rhLayout", new LayoutComponent());
