import * as angular from "angular";

export class ModalAlertController {
  public static $inject = ["$scope", "$uibModalInstance"];

  constructor($scope: any, $uibModalInstance: any) {
    $scope.cancel = () => {
      $uibModalInstance.dismiss("cancel");
    };
  }
}

angular
  .module("PortalApp")
  .controller("Rhinestone.ModalAlertController", ModalAlertController);
