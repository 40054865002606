import * as angular from "angular";
import { AssetsService } from "./assets.service";
import "./uploadFileDialog.html";

export class UploadFileController {
  private files: any;

  public errorText: string;
  public isUploading: boolean = false;
  public fileSelected: boolean = false;

  public static $inject = [
    "$scope",
    "$uibModalInstance",
    "$translate",
    "assetsService",
    "assetCollectionId"
  ];

  constructor(
    private $scope: any,
    private $uibModalInstance: angular.ui.bootstrap.IModalServiceInstance,
    private $translate: angular.translate.ITranslateService,
    private assetsService: AssetsService,
    private assetCollectionId: string
  ) {
    this.files = [];

    this.$scope.selectedFile = (element: any) => {
      this.errorText = "";
      this.$scope.$apply(() => {
        this.files = element.files;
        this.fileSelected = true;

        for (const file of this.files) {
          if (file.size > 1000000000) {
            this.errorText = this.$translate.instant(
              "assets.uploaded_file_too_large"
            );
            this.fileSelected = false;
            return;
          }

          if (
            file.name.toLowerCase().indexOf("pdf", file.name.length - 3) === -1
          ) {
            this.errorText = this.$translate.instant(
              "assets.only_pdf_accepted"
            );
            this.fileSelected = false;
          }
        }
      });
    };
  }

  // Angular don't handle ng-change events on input type='file' and the old workaround
  // onchange="angular.element(this).scope().selectedFile(this)" doesn't work in a Modal where the code is
  // outside of the scope.
  public initChangeEvent = (clickEvent: any) => {
    clickEvent.target.removeEventListener("change", () => {
      this.$scope.selectedFile(clickEvent.target);
    });
    clickEvent.target.addEventListener("change", () => {
      this.$scope.selectedFile(clickEvent.target);
    });
  };

  public upload = () => {
    this.errorText = "";
    this.isUploading = true;

    for (const file of this.files) {
      const fileData = new FormData();
      fileData.append("file", file);
      this.assetsService
        .uploadFile(this.assetCollectionId, fileData)
        .then(this.uploadSucceeded, this.uploadFailed);
    }
  };

  private uploadSucceeded = (data: any) => {
    this.isUploading = false;
    this.$uibModalInstance.close(data);
  };

  private uploadFailed = (data: any) => {
    this.isUploading = false;
    this.errorText = this.$translate.instant("assets.upload_failed");

    if (data && data.Message) {
      this.errorText += data.Message;
    }
  };

  public cancel = () => {
    this.errorText = "";
    this.$uibModalInstance.dismiss("cancel");
  };

  public canUpload = () => {
    return this.fileSelected && !this.isUploading;
  };
}

angular
  .module("PortalApp")
  .controller("Rhinestone.UploadFileController", UploadFileController);
