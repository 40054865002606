import "./tilesLayout.html";
import { BaseComponent } from "../../Core/BaseComponent";
import * as angular from "angular";
import { Binding } from "../../Utils/AngularBindings";
import "./tilesLayout.less";
import "./tilesLayout.controller";
import "../DocumentListTile/documentListTile.component";
import "../DocumentTile/documentTile.component";
import "../LinkTile/linkTile.component";
import "../SearchTile/searchTile.component";
import "../MultiLinkTile/multiLinkTile.component";
import "../ProductTile/productTile.component";
import "../LoginTile/loginTile.component";
import "../TextTile/textTile.component";
import "../TileCard/tileCard.component";
import "../CarouselTile/carouselTile.component";

export class TilesLayoutComponent extends BaseComponent {
  constructor() {
    super(
      "Rhinestone.TilesLayoutController",
      "/templates/tiles/tileslayout/tileslayout.html"
    );

    this.bindings = {
      tiles: Binding.oneWay
    };
  }
}

angular
  .module("PortalApp")
  .component("rhTilesLayout", new TilesLayoutComponent());
