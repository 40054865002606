import { KeepScrollPositionService } from "../Services/keepScrollPositionService";
import * as angular from "angular";

interface ScrollTarget {
  scrollY?: number;
  latestTimeToTry: number;
}

const SCROLL_RESTORATION_TIMEOUT_MS = 30000;

// The keepScrollPosition directive is used to scroll to the remembered scroll position when you navigate to the same page (url) you have already been.
// the solution is partly based on https://github.com/brigade/delayed-scroll-restoration-polyfill, but modified to work.
angular.module("rhUtils").directive("keepScrollPosition", [
  "$route",
  "$window",
  "keepScrollPositionService",
  (
    $route: any,
    $window: angular.IWindowService,

    keepScrollPositionService: KeepScrollPositionService
  ) => {
    // state to track decision whether to restore scroll position across route events (routeChangeStart and routeChangeSuccess)
    // would be cool to pass in som event args, instead of mutating/tracking this here, but did not find a way to do that
    let shouldTryToScroll: boolean | undefined = undefined;

    // Try to scroll to the scrollTarget, but only if we can actually scroll
    // there. Otherwise keep trying until we time out, then scroll as far as we can.
    const tryToScrollTo = (scrollTarget: ScrollTarget) => {
      let hasScrolled = false;
      const body = document.body;
      const html = document.documentElement;

      // From http://stackoverflow.com/a/1147768
      const documentHeight = Math.max(
        body.scrollHeight,
        body.offsetHeight,
        html.clientHeight,
        html.scrollHeight,
        html.offsetHeight
      );

      if (
        documentHeight - window.innerHeight >= scrollTarget.scrollY ||
        Date.now() > scrollTarget.latestTimeToTry
      ) {
        window.scrollTo(0, scrollTarget.scrollY);
        hasScrolled = true;
      }

      if (!hasScrolled) {
        requestAnimationFrame(() => tryToScrollTo(scrollTarget));
      }
    };

    return (scope: any, element: any, attrs: any) => {
      scope.$on("$routeChangeStart", () => {
        if ($route.current) {
          keepScrollPositionService.setScrollPosition(
            $route.current,
            $window.pageYOffset
          );
        }

        // We can compare window location with th angular from route in this event to know if this
        // is history navigation or link navigation.
        shouldTryToScroll =
          calculateIfWeShouldScrollWhenRouteChangeEnds($route);
      });

      scope.$on("$routeChangeSuccess", () => {
        const latestShouldTryToScroll = shouldTryToScroll;
        shouldTryToScroll = undefined;
        if (!latestShouldTryToScroll) return;

        const prevScrollY =
          keepScrollPositionService.getScrollPosition($route.current) || 0;

        if (prevScrollY !== 0) {
          requestAnimationFrame(() => {
            tryToScrollTo({
              scrollY: prevScrollY,
              latestTimeToTry: Date.now() + SCROLL_RESTORATION_TIMEOUT_MS
            });
          });
        }
      });
    };
  }
]);

function calculateIfWeShouldScrollWhenRouteChangeEnds($route: any) {
  /*
   We only want to restore scroll position if user is navigating using back/forward gestures
   If navigating to new places using links we never want to restore scroll position
   (for example, when re-entering a document through a link we don't want to scroll to some previous scroll position, 
   even though the user has just been on the document, but if re-entering document because you clicked forward in history it makes sense for the user to restore scroll position)

   There is no easy builtin way in javascript to detect whether user has navigated using back/forward gestures or at least i couldn't find a simple solution

   However, i found that Angularjs routeChangeStart event fires differently when clicking links versus navigating back/forwards:
   When clicking links the event fires BEFORE the browser has changed location, but when using browser back/forward gestures
   the event fires AFTER the browser location change.

   We exploit this here, by checking if current route regexp matches the window location path.
   $route.current always point to the route we are coming from and it has a $$route property containing the regexp describing the route pattern
   If the the window.location matches the regular expression defined by the "from" route we know we are are navigating via links, and
   if it doesn't match it means that user navigated using history back/forward

  */
  // We need to build original url path in order to compare to current window location
  // because i couldn't find current route path in any args to event
  // Replace path parameters in route expression
  // Example:
  // $route.current.originalPath = '/h/:hiveId/:fullName*'
  // and
  // $route.current.pathParams = '{ "hiveId":"91ba9fef-182f-46b8-8630-eadb20f79190", "fullName":"konkurrencelov-verdict-pdf-hrd20100830-000d"}'
  //
  // then result will be '/h/91ba9fef-182f-46b8-8630-eadb20f79190/konkurrencelov-verdict-pdf-hrd20100830-000d'
  const calculatedAngularJsCurrentRoutePath = Object.keys(
    $route.current.pathParams
  )
    .reduce(
      (accumulator, next) =>
        accumulator.replace(`:${next}`, $route.current.pathParams[next]),
      $route.current.originalPath
    )
    .replace("*", "");

  return window.location.pathname !== calculatedAngularJsCurrentRoutePath;
}
