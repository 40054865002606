import { SavedSearchesService } from "./savedSearch.service";
import { QueryStringService } from "../querystring.service";
import { ISavedSearchQuery, SavedSearchQuery } from "./savedSearchQuery";
import List = _.List;
import * as _ from "underscore";
import { SearchRequestClient } from "@rhinestone/portal-web-react";
import "./deleteSavedSearchDialog.html";
import "./newSavedSearchDialog.html";
import "./overwriteSavedSearchDialog.html";

//// New SavedSearch
export class NewSavedSearchModalController {
  public static $inject = [
    "$uibModalInstance",
    "$translate",
    "savedSearchesService",
    "queryStringService",
    "existingQueries",
    "searchRequest"
  ];
  private errorMessage = "";
  private error = false;
  private name = "";
  public saving = false;

  constructor(
    private $uibModalInstance: angular.ui.bootstrap.IModalServiceInstance,
    private $translate: angular.translate.ITranslateService,
    private savedSearchesService: SavedSearchesService,
    private queryStringService: QueryStringService,
    private existingQueries: List<ISavedSearchQuery>,
    private searchRequest: SearchRequestClient
  ) {}

  public ok(): void {
    this.saving = true;
    const existing = _.find(
      this.existingQueries,
      (iterator: ISavedSearchQuery) =>
        iterator.name.toLowerCase() === this.name.toLowerCase()
    );
    if (existing) {
      this.error = true;
      this.saving = false;

      this.$translate("search.saved_search_exists").then(translation => {
        this.errorMessage = translation;
      });
      return;
    }
    const query = new SavedSearchQuery(
      this.name,
      this.queryStringService.getQueryStringAsJsonString(),
      this.searchRequest
    );
    this.savedSearchesService
      .saveSearchQuery(query)
      .then(_ => {
        this.$uibModalInstance.close();
      })
      .catch(_ => {
        this.error = true;
        this.$translate("search.saved_search_saving_error").then(
          translation => {
            this.errorMessage = translation;
          }
        );
      })
      .finally(() => {
        this.saving = false;
      });
  }

  public cancel(): void {
    this.$uibModalInstance.dismiss("cancel");
  }
}
