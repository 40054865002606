import * as angular from "angular";
import { IMediaService, ScreenSize } from "../Layout/media.service";

export interface IMobileDetectorService {
  isMobile(): boolean;
}

export class MobileDetectorService implements IMobileDetectorService {
  public static $inject: string[] = ["mediaService"];

  constructor(private mediaService: IMediaService) {}

  public isMobile(): boolean {
    return this.mediaService.getScreenSize() === ScreenSize.Xs;
  }
}

angular
  .module("PortalApp")
  .service("mobileDetectorService", MobileDetectorService);
