import * as angular from "angular";
import * as _ from "underscore";
import { ICriteriaGroupController } from "../Criterias/ICriteriaGroupController";
import {
  ProductDropdownModel,
  TaxonomyFilterKey
} from "@rhinestone/portal-web-api";
import {
  CriteriaChange,
  CriteriaProvider,
  Criteria
} from "@rhinestone/portal-web-react";

export class NewLocalDocumentTypeTaxonomyFilterController
  implements ICriteriaGroupController
{
  // TODO: These three values are incorrectly implemented by the interface, this is a filter, not a filter group/section
  public color: string;
  public icon: string;
  public providerKey?: string;

  // Bindings
  public onCriteriaChanged: (outparams: { action: CriteriaChange }) => {};
  public onLoaded: (param: { provider: CriteriaProvider }) => void;
  public criteria: ReadonlyArray<Criteria>;
  public products: ProductDropdownModel[];
  public showEmptyTaxons: boolean;
  public showFacetCount: boolean;

  public readonly localDocumentTypeTaxonomyFilterKey: TaxonomyFilterKey =
    "LocalDocumentType";
  public localDocumentTypeTaxonomyIds: string[];

  public static $inject: string[] = ["$rootScope", "currentUserService"];

  public localDocTypeTaxonomyInfo: {
    name: string;
    providerKey: string;
  };

  public localDocTypeTaxonomyIsLoaded: boolean;

  constructor() {}

  public $onChanges() {
    const localDocTypeTaxonomyIds = this.products
      ? this.products
          .filter(p => !!p.localDocumentTypeTaxonomyId)
          .map(p => p.localDocumentTypeTaxonomyId)
      : [];
    //Get unique ID's Multiple local products, may use the same document type
    this.localDocumentTypeTaxonomyIds = Array.from(
      new Set(localDocTypeTaxonomyIds)
    );
  }

  public handleCriteriaChanged(action: CriteriaChange): void {
    this.onCriteriaChanged({ action });
  }

  public registerCriteriaProvider(provider: CriteriaProvider): void {
    // Maybe a little hacky, but we have hidden the local doctype taxonomy component
    // until we know it is actually loaded.
    // We use the existing infrastructure of criteria provider concept that signals when it is loaded
    // and check that is the providerKey for the local doctype taxonomy, that is now loaded
    // and toggle visibility state
    if (
      this.localDocTypeTaxonomyInfo &&
      provider.key === this.localDocTypeTaxonomyInfo.providerKey
    ) {
      this.localDocTypeTaxonomyIsLoaded = true;
    }
    this.onLoaded({ provider });
  }
}

angular
  .module("PortalApp")
  .controller(
    "Rhinestone.NewLocalDocumentTypeTaxonomyFilterController",
    NewLocalDocumentTypeTaxonomyFilterController
  );
