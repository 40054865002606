import * as angular from "angular";

export class KeepScrollPositionService {
  private scrollPosCache: { [key: string]: number } = {};

  public setScrollPosition(
    currentRoute: string,
    scrollPositionValue: number
  ): void {
    this.scrollPosCache[getUrlHashCode(currentRoute)] = scrollPositionValue;
  }

  public getScrollPosition(currentRoute: string): number | undefined {
    return this.scrollPosCache[getUrlHashCode(currentRoute)];
  }

  public clearScrollPositionsCache(): void {
    this.scrollPosCache = {};
  }
}

const getUrlHashCode = (route: any) =>
  route.originalPath + JSON.stringify(route.params);

angular
  .module("PortalApp")
  .service("keepScrollPositionService", KeepScrollPositionService);
