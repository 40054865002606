import { Taxon } from "../../Services/models/taxon";
import * as _ from "underscore";

export function findTaxonFromKey(key: string, virtualTaxons: Taxon[]): Taxon {
  const taxon = _.find(virtualTaxons, t => t.key === key);
  if (taxon === undefined) {
    throw Error(`Unable to find a taxon with key: ${key}`);
  }
  return taxon;
}
