import "./assetTemplatesList.html";
import { BaseComponent } from "../Core/BaseComponent";
import * as angular from "angular";
import { Binding } from "../Utils/AngularBindings";
import "./assetTemplatesList.controller";
import "./assetTemplates.service";

export class AssetTemplatesListComponent extends BaseComponent {
  constructor() {
    super(
      "Rhinestone.AssetTemplatesListController",
      "/templates/Assets/assetTemplatesList"
    );
    this.bindings = {
      archived: Binding.oneWay
    };
  }
}

angular
  .module("PortalApp")
  .component("rhAssetTemplatesList", new AssetTemplatesListComponent());
