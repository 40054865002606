import * as angular from "angular";
import { IMobileMenu, MobileMenuService } from "./mobile-menu.service";

export class MobileMenuController implements IMobileMenu {
  // Input bindings
  private position: string;
  private icon: string;
  private sectionTitle: string;

  private expanded: boolean = false;

  public static $inject = ["mobileMenuService"];
  constructor(private mobileMenuService: MobileMenuService) {}

  public $onInit() {
    this.mobileMenuService.registerMobileMenu(this);
  }

  public $onDestroy() {
    this.mobileMenuService.unregisterMobileMenu(this);
  }

  public $onChanges(changes: any) {}

  public menuName: string = "mobileMenu";

  public showMenu(): void {
    this.expanded = true;
  }

  public hideMenu(): void {
    this.expanded = false;
  }

  public closeAllMenus() {
    this.mobileMenuService.hideAllMenus();
  }

  public toggleSidePane() {
    this.expanded = !this.expanded;
    if (this.expanded) {
      this.mobileMenuService.willShowMenu(this);
    }
  }
}

angular
  .module("rhSidePane")
  .controller("Rhinestone.MobileMenuController", MobileMenuController);
