import * as angular from "angular";
import { ISavedSearchQuery } from "./savedSearchQuery";
import { IPortalConfig } from "../../Core/portal.provider";
import { SearchRequestMapper } from "@rhinestone/portal-web-react";

export class SavedSearchesService {
  private baseUrl = "";
  public static $inject = ["$http", "$q", "portal"];

  constructor(
    private $http: angular.IHttpService,
    private $q: angular.IQService,
    private portal: IPortalConfig
  ) {
    this.baseUrl = `/api/portals(${this.portal.identifier})/profile/searches`;
  }

  public getSavedSearches(): angular.IPromise<ISavedSearchQuery[]> {
    return this.$http.get(this.baseUrl).then(
      (
        response: angular.IHttpPromiseCallbackArg<ISavedSearchQuery[]>
      ): ISavedSearchQuery[] => {
        return response.data;
      }
    );
  }

  public saveSearchQuery(query: ISavedSearchQuery): angular.IPromise<{}> {
    if (!query.savedSearchQueryId) {
      // Create
      return this.$http.post(this.baseUrl, this.createServerQuery(query));
    } else {
      // Update
      const url = `${this.baseUrl}/${query.savedSearchQueryId}`;
      const json = JSON.stringify(this.createServerQuery(query));
      return this.$http.put(url, json);
    }
  }

  private createServerQuery(query: ISavedSearchQuery) {
    return {
      name: query.name,
      queryStringParams: query.queryStringParams,
      searchRequest: SearchRequestMapper.mapToServerSearchRequest(
        query.searchRequest
      ),
      savedSearchQueryId: query.savedSearchQueryId,
      showRemoveButton: query.showRemoveButton
    };
  }

  public deleteSavedSearchQuery(
    query: ISavedSearchQuery
  ): angular.IPromise<{}> {
    if (!query.savedSearchQueryId) {
      const deferred = this.$q.defer();
      deferred.reject("No saved search query id");
      return deferred.promise;
    }
    const url = `${this.baseUrl}/${query.savedSearchQueryId}`;
    return this.$http.delete(url);
  }
}

angular
  .module("PortalApp")
  .service("savedSearchesService", SavedSearchesService);
