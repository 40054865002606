import * as angular from "angular";
import "./searchResultsExport.service";
import { ISearchResponse } from "./searchResponse";
import { IDocumentPrintService } from "../Documents/DocumentPrint/documentPrint.service";
import { ICurrentUserService } from "../User/currentUser.service";
import { SearchResultsExportService } from "./searchResultsExport.service";
import { SearchRequestMapper } from "@rhinestone/portal-web-react";
import { SortingInfo } from "./IResultLocation";
import { IPortalConfig } from "../Core/portal.provider";
import { SearchResultsExportInfo } from "@rhinestone/portal-web-api";
import {
  CriteriaProvider,
  SearchRequestClient
} from "@rhinestone/portal-web-react";

export const enum SearchResultsSortDirection {
  Ascending = 0,
  Descending = 1
}

export class SearchResultsPdfExportButtonController {
  // Binding
  public searchResults: ISearchResponse;
  private searchRequest: SearchRequestClient;
  public criteriaProviders: CriteriaProvider[];
  public sorting: SortingInfo;

  // State
  public exportInProgress: boolean = false;
  public errorOcurred: boolean = false;

  public static $inject = [
    "$translate",
    "$scope",
    "documentPrintService",
    "searchResultsExportService",
    "currentUserService",
    "portal"
  ];

  constructor(
    private $translate: angular.translate.ITranslateService,
    private $scope: angular.IScope,
    private documentPrintService: IDocumentPrintService,
    private searchResultsExportService: SearchResultsExportService,
    private currentUser: ICurrentUserService,
    public portal: IPortalConfig
  ) {}

  public async exportSearchResultsToPdf() {
    this.exportInProgress = true;
    this.errorOcurred = false;

    let request: SearchResultsExportInfo = {
      organizationName: this.currentUser.user.organizationName,
      userName: this.currentUser.user.displayName,
      query: this.searchRequest,
      sortDirection: this.getSortDirection(),
      sortField: this.sorting.sortName,
      searchTerms: [],
      statusLabels: [],
      relatedDocuments: [],
      taxons: []
    };

    this.criteriaProviders.forEach(provider => {
      if (provider.buildCriteriaDescription) {
        request = provider.buildCriteriaDescription(request);
      }
    });

    // Replace client query with server query without "skip" and "take" parameters
    request.query = SearchRequestMapper.mapToServerSearchRequestWithoutSkipAndTake(
      request.query
    );

    try {
      const response = await this.searchResultsExportService.exportSearchResultsToPdfFile(
        request,
        this.$translate.use()
      );

      const fileName = this.$translate.instant("search.pdf_file_name");
      this.documentPrintService.convertToBlobAndOpenAsPdf(
        response.data,
        fileName
      );
    } catch (error) {
      this.errorOcurred = true;
    } finally {
      this.exportInProgress = false;
      this.$scope.$apply();
    }
  }

  public searchLimitExceeded(): boolean {
    if (!this.searchResults) {
      return false;
    }

    return (
      this.searchResults.TotalCount >
      this.portal.searchResultsPdfExportMaxResults
    );
  }

  private getSortDirection(): SearchResultsSortDirection | undefined {
    if (
      this.sorting.sortDescending === undefined ||
      this.sorting.sortDescending === null
    ) {
      return undefined;
    }

    return this.sorting.sortDescending === true
      ? SearchResultsSortDirection.Descending
      : SearchResultsSortDirection.Ascending;
  }
}

angular
  .module("PortalApp")
  .controller(
    "Rhinestone.SearchResultsPdfExportButtonController",
    SearchResultsPdfExportButtonController
  );
