// This functions converts a number of months to a danish string describing a sentence length, with some special cases.
// E.g. 13 months => "1 år, 1 måned", 800 months => "Livstid"
// Made specifically for the RA portal, which has a special search result templates which needs this type of display.

const specialCasesDanish: { [index: number]: string } = {
  500: "Behandlingsdom",
  600: "Anbringelsesdom",
  700: "Forvaring",
  800: "Livstid",
  900: "Andet",
  1000: "Ikke nærmere oplyst"
};

export function convertMonthsToSentenceString(monthsString: string): string {
  const totalMonths = parseInt(monthsString);
  if (isNaN(totalMonths)) return "";
  if (totalMonths === 0) return "0";

  const specialCase = specialCasesDanish[totalMonths];
  if (specialCase) return specialCase;

  const years = Math.floor(totalMonths / 12);
  const months = totalMonths % 12;

  if (years == 0 && months == 1) return `${months} måned`;
  if (years == 0 && months > 1) return `${months} måneder`;
  if (years > 0 && months == 0) return `${years} år`;
  if (years > 0 && months == 1) return `${years} år, ${months} måned`;
  return `${years} år, ${months} måneder`;
}
