import "./loginTile.html";
import { BaseComponent } from "../../Core/BaseComponent";
import * as angular from "angular";
import { Binding } from "../../Utils/AngularBindings";
import "./loginTile.less";
import "./loginTile.controller";
export class LoginTileComponent extends BaseComponent {
  constructor() {
    super(
      "Rhinestone.LoginTileController",
      "/templates/tiles/loginTile/loginTile.html"
    );
    this.bindings = {
      tile: Binding.oneWay
    };
  }
}

angular.module("PortalApp").component("rhLoginTile", new LoginTileComponent());
