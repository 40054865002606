import { IProductTileService } from "./productTile.service";
import { IErrorableTile } from "../IErrorableTile";
import * as angular from "angular";
import { ProductModel } from "@rhinestone/portal-web-api";
import { ProductTileDto } from "../TilesTypes";

export class ProductTileController {
  public isInitialized: boolean = false;
  public contentLoaded: boolean = false;
  public hasError: boolean = false;

  public tile: ProductTileDto & IErrorableTile;
  public product: ProductModel;
  public productImageUrl: string;

  public static $inject = ["productTileService"];

  constructor(private productTileService: IProductTileService) {}

  public $onInit() {
    this.productImageUrl = this.productTileService.getProductImageUrl(
      this.tile.productCode
    );

    this.productTileService
      .getProduct(this.tile.productCode)
      .then(product => {
        this.product = product;
        this.contentLoaded = true;
      })
      .catch(() => {
        this.hasError = true;
        this.tile.hasError = true;
      })
      .finally(() => {
        this.isInitialized = true;
      });
  }
}

angular
  .module("PortalApp")
  .controller("Rhinestone.ProductTileController", ProductTileController);
