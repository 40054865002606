import "./singleSelectProductSearchFilter.html";
import { BaseComponent } from "../../Core/BaseComponent";
import * as angular from "angular";
import { Binding } from "../../Utils/AngularBindings";
import "./singleSelectProductSearchFilter.controller";
export class SingleSelectProductSearchFilterComponent extends BaseComponent {
  constructor() {
    super(
      "Rhinestone.SingleSelectProductSearchFilterController",
      "/templates/Search/ProductSearchFilter/singleSelectProductSearchFilter.html"
    );
    this.bindings = {
      providerKey: Binding.oneWay,
      onCriteriaChanged: Binding.outputEvent,
      criteria: Binding.oneWay,
      products: Binding.oneWay
    };
  }
}

angular
  .module("PortalApp")
  .component("rhSingleSelectProductSearchFilter", new SingleSelectProductSearchFilterComponent());
