import "./mainContent.html";
import { BaseComponent } from "../../Core/BaseComponent";
import * as angular from "angular";
import "./mainContent.less";
import "./mainContent.controller";

export class MainContentComponent extends BaseComponent {
  constructor() {
    super(
      "Rhinestone.MainContentController",
      "/templates/Layout/MainContent/mainContent.html",
      true
    );

    this.require = {
      layout: "^^rhLayout"
    };
  }
}

angular
  .module("rhSidePane")
  .component("rhMainContent", new MainContentComponent());
