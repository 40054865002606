import { ModalAlertController } from "../Documents/ModalAlertController";
import * as angular from "angular";
import { DocumentModel } from "../Models/DocumentModel";
import { CreateAssetCollectionRequest } from "../Models/CreateAssetCollectionRequest";
import { CreateAssetCollectionFromQuoteRequest } from "../Models/createAssetCollectionFromQuoteRequest";
import { IAssetCollectionMember } from "../Models/assetCollectionMember";
import { Permission } from "../Services/models/permission";
import { IAddMembersRequest } from "./addMembersRequest";
import { ICopyAssetCollectionRequest } from "./copyAssetCollectionRequest";
import { IAssetCollectionRelease } from "../Services/models/assetCollectionRelease";
import { PublishAssetCollectionRequest } from "../Services/models/publishAssetCollectionRequest";
import { INodeInfo } from "../Services/models/nodeInfo";
import { IMoveNodeRequest } from "../Services/models/moveNodeRequest";
import { ICurrentUserService, UserEvents } from "../User/currentUser.service";
import { IDialogService } from "../Services/dialog.service";
import { ISelectionStatusService } from "../Documents/SelectionStatusService";
import { IDeviceDetectionService } from "../Services/devicedetection.service";
import { IPortalConfig } from "../Core/portal.provider";
import { Logger } from "../Core/logger.service";
import { CurrentDocumentContext } from "../Documents/CurrentDocumentContext";
import { ILocalizedNotificationService } from "../Services/localized.notification.service";
import {
  AssetCollectionViewModel,
  AssetCollectionTemplateViewModel
} from "@rhinestone/portal-web-api";

export class AssetsService {
  private latestCollections: AssetCollectionViewModel[];

  private currentCollection: AssetCollectionViewModel;

  public static $inject = [
    "$rootScope",
    "$http",
    "$uibModal",
    "currentUserService",
    "localizedNotificationService",
    "dialogService",
    "portal"
  ];

  constructor(
    private $rootScope: angular.IRootScopeService,
    private $http: angular.IHttpService,
    private $uibModal: angular.ui.bootstrap.IModalService,
    private currentUser: ICurrentUserService,
    private localizedNotificationService: ILocalizedNotificationService,
    private dialogService: IDialogService,
    private portal: IPortalConfig
  ) {
    if (this.currentUser.isAuthenticated) {
      this.refreshLatestCollections();
    } else {
      this.$rootScope.$on(UserEvents.UserAuthenticated, () => {
        this.refreshLatestCollections();
      });
    }
  }

  private async refreshLatestCollections() {
    this.latestCollections = await this.getCollections(3);
  }

  public addToAssetsCollection(
    assetCollectionId: string,
    title: string,
    document: {
      fullName: string;
      hiveId: string;
      revisionNumber: number;
    }
  ) {
    this.addToCollection(assetCollectionId, [document]).then(() => {
      this.localizedNotificationService.success("assets.doc_added_success", {
        title
      });
      this.refreshLatestCollections();
    });
  }

  public hasLatestCollections(): boolean {
    return this.latestCollections && this.latestCollections.length > 0;
  }

  public openAddToExistingMaterialCollectionDialog(
    document: CurrentDocumentContext
  ) {
    const documents = [
      {
        fullName: document.fullName,
        hiveId: document.hiveId,
        revisionNumber: document.revisionNumber
      }
    ];
    const modalInstance =
      this.dialogService.openAddToExistingMaterialCollectionDialog(documents);

    modalInstance.result.then(() => {
      this.refreshLatestCollections();
    });

    return false;
  }

  public openAddToNewMaterialCollectionDialog(
    document: CurrentDocumentContext
  ) {
    const documents = [
      {
        fullName: document.fullName,
        hiveId: document.hiveId,
        revisionNumber: document.revisionNumber
      }
    ];
    const modalInstance =
      this.dialogService.openAddToNewMaterialCollectionDialog(documents);

    modalInstance.result.then(() => {
      this.refreshLatestCollections();
    });
  }

  public showAssetQuoteNoSelectionDialog() {
    this.$uibModal.open({
      templateUrl: "/templates/assets/AddToAssetCollectionNoSelectionDialog",
      controller: ModalAlertController
    });
  }

  public getAssetCollection(
    collectionId: string
  ): angular.IPromise<AssetCollectionViewModel> {
    // TODO: Caching! Cache header based on LastModified?

    const url = `/api/Portals(${this.portal.identifier})/AssetCollections(${collectionId})/Single`;
    return this.$http
      .get(url)
      .then(
        (
          response: angular.IHttpPromiseCallbackArg<AssetCollectionViewModel>
        ): AssetCollectionViewModel => {
          this.currentCollection = response.data;
          return response.data;
        }
      );
  }

  public getTemplateAssetCollection(
    collectionId: string
  ): angular.IPromise<AssetCollectionTemplateViewModel> {
    const url = `/api/Portals(${this.portal.identifier})/AssetCollectionTemplates(${collectionId})`;

    return this.$http
      .get(url)
      .then(
        (
          response: angular.IHttpPromiseCallbackArg<AssetCollectionTemplateViewModel>
        ): AssetCollectionTemplateViewModel => {
          return response.data;
        }
      );
  }

  public getArchivedTemplateAssetCollection(
    collectionId: string
  ): angular.IPromise<AssetCollectionTemplateViewModel> {
    const url = `/api/Portals(${this.portal.identifier})/AssetCollectionTemplates(${collectionId})/Archived`;

    return this.$http
      .get(url)
      .then(
        (
          response: angular.IHttpPromiseCallbackArg<AssetCollectionTemplateViewModel>
        ): AssetCollectionTemplateViewModel => {
          return response.data;
        }
      );
  }

  public getAllCollections(): angular.IPromise<AssetCollectionViewModel[]> {
    let url = `/api/Portals(${this.portal.identifier})/AssetCollections/My`;
    url += this.getQueryStringParameters(null, "Read");
    return this.$http
      .get(url)
      .then(
        (
          response: angular.IHttpPromiseCallbackArg<AssetCollectionViewModel[]>
        ): AssetCollectionViewModel[] => {
          return response.data as AssetCollectionViewModel[];
        }
      );
  }

  public getCollections(
    count?: number,
    permission?: string
  ): angular.IPromise<AssetCollectionViewModel[]> {
    let url = `/api/Portals(${this.portal.identifier})/AssetCollections/My`;

    url += this.getQueryStringParameters(count, permission);

    return this.$http
      .get(url)
      .then(
        (
          response: angular.IHttpPromiseCallbackArg<AssetCollectionViewModel[]>
        ): AssetCollectionViewModel[] => {
          return response.data as AssetCollectionViewModel[];
        }
      );
  }

  private getQueryStringParameters(
    count?: number,
    permission?: string
  ): string {
    const params: any[] = [];
    if (count) {
      params.push("count");
    }

    if (permission) {
      params.push("permission");
    }

    if (params.length === 0) {
      return "";
    }

    const settings: any = {
      count,
      permission
    };

    let url = "?";

    params.forEach((key, index) => {
      const value = settings[key];
      url += `${key}=${value}`;
      if (index < params.length - 1) {
        url += "&";
      }
    });

    return url;
  }

  public getArchivedCollections(
    count?: number
  ): angular.IPromise<AssetCollectionViewModel[]> {
    let url = `/api/Portals(${this.portal.identifier})/AssetCollections/My/Archived`;
    if (count) {
      url += `?count=${count}`;
    }

    return this.$http
      .get(url)
      .then(
        (
          response: angular.IHttpPromiseCallbackArg<AssetCollectionViewModel[]>
        ): AssetCollectionViewModel[] => {
          return response.data as AssetCollectionViewModel[];
        }
      );
  }

  public async addToCollection(
    assetCollectionId: string,
    documents: DocumentModel[]
  ): Promise<{}> {
    const url = `/api/Portals(${this.portal.identifier})/Assets/${assetCollectionId}`;
    const response = await this.$http.post(url, documents);
    await this.refreshLatestCollections();
    return response.data;
  }

  public addTextQuoteToCollection(
    assetCollectionId: string,
    documentId: string,
    documentRevisionNumber: number,
    textSelection: any
  ): angular.IPromise<{}> {
    const url = `/api/Portals(${this.portal.identifier})/Assets(${assetCollectionId})/Annotation/${documentId}/${documentRevisionNumber}`;
    return this.$http
      .post(url, textSelection)
      .then(
        (response: angular.IHttpPromiseCallbackArg<{}>): {} => response.data
      );
  }

  public addPdfQuoteToCollection(
    assetCollectionId: string,
    documentId: string,
    documentRevisionNumber: number,
    pdfSelection: any
  ): angular.IPromise<{}> {
    const url = `/api/Portals(${this.portal.identifier})/Assets(${assetCollectionId})/PdfQuote/${documentId}/${documentRevisionNumber}`;
    return this.$http
      .post(url, pdfSelection)
      .then((response: angular.IHttpPromiseCallbackArg<{}>): {} => {
        return response.data;
      });
  }

  public createCollection(
    request: CreateAssetCollectionRequest
  ): angular.IPromise<{}> {
    const url = `/api/Portals(${this.portal.identifier})/Assets`;
    return this.$http
      .post(url, request)
      .then(async (response: angular.IHttpPromiseCallbackArg<{}>) => {
        await this.refreshLatestCollections();
        return response.data;
      });
  }

  public createCollectionFromQuote(
    documentId: string,
    documentRevisionNumber: number,
    request: CreateAssetCollectionFromQuoteRequest
  ): angular.IPromise<{}> {
    const url = `/api/Portals(${this.portal.identifier})/Assets/Annotation/${documentId}/${documentRevisionNumber}`;
    return this.$http
      .post(url, request)
      .then(
        (response: angular.IHttpPromiseCallbackArg<{}>): {} => response.data
      );
  }

  public createCollectionFromPdfQuote(
    documentId: string,
    documentRevisionNumber: number,
    request: CreateAssetCollectionFromQuoteRequest
  ): angular.IPromise<{}> {
    const url = `/api/Portals(${this.portal.identifier})/Assets/PdfQuote/${documentId}/${documentRevisionNumber}`;
    return this.$http
      .post(url, request)
      .then(
        (response: angular.IHttpPromiseCallbackArg<{}>): {} => response.data
      );
  }

  public getCollectionMembers(
    assetCollectionId: string
  ): angular.IPromise<IAssetCollectionMember[]> {
    const url = `/api/Portals(${this.portal.identifier})/AssetCollections(${assetCollectionId})/users`;
    return this.$http
      .get(url)
      .then(
        (
          response: angular.IHttpPromiseCallbackArg<IAssetCollectionMember[]>
        ): IAssetCollectionMember[] => {
          return response.data as IAssetCollectionMember[];
        }
      );
  }

  public getPermissions(): angular.IPromise<Permission[]> {
    const url = `/api/Portals(${this.portal.identifier})/Assets/permissions`;
    return this.$http
      .get(url)
      .then(
        (
          response: angular.IHttpPromiseCallbackArg<Permission[]>
        ): Permission[] => {
          return response.data as Permission[];
        }
      );
  }

  public addMembersToCollection(
    assetCollectionId: string,
    request: IAddMembersRequest
  ): angular.IPromise<{}> {
    const url = `/api/Portals(${this.portal.identifier})/Assets(${assetCollectionId})/users`;
    return this.$http
      .post(url, request)
      .then(
        (response: angular.IHttpPromiseCallbackArg<{}>): {} => response.data
      );
  }

  public removeMemberFromCollection(
    assetCollectionId: string,
    userId: string
  ): angular.IPromise<{}> {
    const url = `/api/Portals(${this.portal.identifier})/Assets(${assetCollectionId})/users/${userId}/remove`;
    return this.$http
      .delete(url, {})
      .then(
        (response: angular.IHttpPromiseCallbackArg<{}>): {} => response.data
      );
  }

  public deleteAssetCollection(
    assetCollectionId: string
  ): angular.IPromise<{}> {
    const url = `/api/Portals(${this.portal.identifier})/AssetCollections(${assetCollectionId})`;
    return this.$http
      .delete(url, {})
      .then(
        async (response: angular.IHttpPromiseCallbackArg<{}>): Promise<{}> => {
          await this.refreshLatestCollections();
          return response.data;
        }
      );
  }

  public deleteAssetCollectionTemplate(
    assetCollectionTemplateId: string
  ): angular.IPromise<{}> {
    const url = `/api/Portals(${this.portal.identifier})/AssetCollectionTemplates(${assetCollectionTemplateId})`;
    return this.$http
      .delete(url, {})
      .then((response: angular.IHttpPromiseCallbackArg<{}>): {} => {
        return response.data;
      });
  }

  public copyAssetCollection(
    assetCollectionId: string,
    request: ICopyAssetCollectionRequest
  ): angular.IPromise<{}> {
    const url = `/api/Portals(${this.portal.identifier})/AssetCollections(${assetCollectionId})/copy`;
    return this.$http
      .post(url, request)
      .then(
        async (response: angular.IHttpPromiseCallbackArg<{}>): Promise<{}> => {
          await this.refreshLatestCollections();
          return response.data;
        }
      );
  }

  public createAssetCollectionFromTemplate(
    assetCollectionTemplateId: string
  ): angular.IPromise<{}> {
    const url = `/api/Portals(${this.portal.identifier})/AssetCollectionTemplates(${assetCollectionTemplateId})/assetCollection`;
    return this.$http
      .post(url, {})
      .then(
        async (response: angular.IHttpPromiseCallbackArg<{}>): Promise<{}> => {
          await this.refreshLatestCollections();
          return response.data;
        }
      );
  }

  public createAssetCollectionFromArchivedTemplate(
    assetCollectionTemplateId: string
  ): angular.IPromise<{}> {
    const url = `/api/Portals(${this.portal.identifier})/AssetCollectionTemplates(${assetCollectionTemplateId})/assetCollectionFromArchive`;
    return this.$http
      .post(url, {})
      .then(
        async (response: angular.IHttpPromiseCallbackArg<{}>): Promise<{}> => {
          await this.refreshLatestCollections();
          return response.data;
        }
      );
  }

  public copyAssetCollectionAsTemplate(
    assetCollectionId: string
  ): angular.IPromise<AssetCollectionViewModel> {
    const url = `/api/Portals(${this.portal.identifier})/AssetCollections(${assetCollectionId})/template`;
    return this.$http
      .post(url, {})
      .then(
        async (
          response: angular.IHttpPromiseCallbackArg<AssetCollectionViewModel>
        ): Promise<AssetCollectionViewModel> => {
          await this.refreshLatestCollections();
          return response.data;
        }
      );
  }

  public updateAssetCollection(
    assetCollectionId: string,
    request: AssetCollectionViewModel
  ): angular.IPromise<{}> {
    const url = `/api/Portals(${this.portal.identifier})/AssetCollections(${assetCollectionId})/update`;
    return this.$http
      .put(url, request)
      .then(
        async (response: angular.IHttpPromiseCallbackArg<{}>): Promise<{}> => {
          await this.refreshLatestCollections();
          return response.data;
        }
      );
  }

  public getAssetCollectionReleases(
    assetCollectionId: string
  ): angular.IPromise<IAssetCollectionRelease[]> {
    const url = `/api/Portals(${this.portal.identifier})/AssetCollections(${assetCollectionId})/releases`;
    return this.$http
      .get(url)
      .then(
        (
          response: angular.IHttpPromiseCallbackArg<IAssetCollectionRelease[]>
        ): IAssetCollectionRelease[] => {
          return response.data as IAssetCollectionRelease[];
        }
      );
  }

  public async getLatestAssetCollections(): Promise<
    AssetCollectionViewModel[]
  > {
    if (!this.latestCollections) {
      await this.refreshLatestCollections();
    }

    return this.latestCollections;
  }

  public publishCollection(
    assetCollectionId: string,
    request: PublishAssetCollectionRequest
  ): angular.IPromise<{}> {
    const url = `/api/Portals(${this.portal.identifier})/AssetCollections(${assetCollectionId})/Releases`;
    return this.$http
      .post(url, request)
      .then(
        (response: angular.IHttpPromiseCallbackArg<{}>): {} => response.data
      );
  }

  public getTreeView(
    assetCollectionId: string,
    isTemplate: boolean
  ): angular.IPromise<INodeInfo[]> {
    const controllerName = isTemplate
      ? "AssetCollectionTemplates"
      : "AssetCollections";
    const url = `/api/Portals(${this.portal.identifier})/${controllerName}(${assetCollectionId})/Nodes`;
    return this.$http
      .get(url)
      .then(
        (
          response: angular.IHttpPromiseCallbackArg<INodeInfo[]>
        ): INodeInfo[] => {
          return response.data as INodeInfo[];
        }
      );
  }

  public moveNode(
    assetCollectionId: string,
    assetItemId: string,
    request: IMoveNodeRequest
  ): angular.IPromise<{}> {
    const url = `/api/Portals(${this.portal.identifier})/AssetCollections(${assetCollectionId})/Item(${assetItemId})/Moves`;
    return this.$http
      .post(url, request)
      .then(
        (response: angular.IHttpPromiseCallbackArg<{}>): {} => response.data
      );
  }

  public removeNode(
    assetCollectionId: string,
    nodeId: string
  ): angular.IPromise<{}> {
    const url = `/api/Portals(${this.portal.identifier})/AssetCollections(${assetCollectionId})/Nodes(${nodeId})`;
    return this.$http
      .delete(url)
      .then(
        (response: angular.IHttpPromiseCallbackArg<{}>): {} => response.data
      );
  }

  public createNode(
    assetCollectionId: string,
    folderId: string,
    index: number,
    nodeName: string
  ): angular.IPromise<{}> {
    const url = `/api/Portals(${this.portal.identifier})/AssetCollections(${assetCollectionId})/Nodes(${folderId})/ChildNodes(${index})`;
    return this.$http
      .post(url, { Name: nodeName })
      .then(
        (response: angular.IHttpPromiseCallbackArg<INodeInfo>): {} =>
          response.data
      );
  }

  public updateNode(
    assetCollectionId: string,
    node: INodeInfo
  ): angular.IPromise<{}> {
    const url = `/api/Portals(${this.portal.identifier})/AssetCollections(${assetCollectionId})/Nodes(${node.ItemId})`;
    return this.$http
      .put(url, node)
      .then(
        (response: angular.IHttpPromiseCallbackArg<{}>): {} => response.data
      );
  }

  public moveChildren(
    assetCollectionId: string,
    folderId: string,
    request: IMoveNodeRequest
  ): angular.IPromise<{}> {
    const url = `/api/Portals(${this.portal.identifier})/AssetCollections(${assetCollectionId})/Folders(${folderId})/Children/Moves`;
    return this.$http
      .post(url, request)
      .then(
        (response: angular.IHttpPromiseCallbackArg<{}>): {} => response.data
      );
  }

  public moveSiblingsBelow(
    assetCollectionId: string,
    folderId: string,
    fromIndex: number,
    request: IMoveNodeRequest
  ): angular.IPromise<{}> {
    const url = `/api/Portals(${this.portal.identifier})/AssetCollections(${assetCollectionId})/Folders(${folderId})/ChildrenOnOrAfter(${fromIndex})/Moves`;
    return this.$http
      .post(url, request)
      .then(
        (response: angular.IHttpPromiseCallbackArg<{}>): {} => response.data
      );
  }

  public moveSiblingsAbove(
    assetCollectionId: string,
    folderId: string,
    fromIndex: number,
    request: IMoveNodeRequest
  ): angular.IPromise<{}> {
    const url = `/api/Portals(${this.portal.identifier})/AssetCollections(${assetCollectionId})/Folders(${folderId})/ChildrenBefore(${fromIndex})/Moves`;
    return this.$http
      .post(url, request)
      .then(
        (response: angular.IHttpPromiseCallbackArg<{}>): {} => response.data
      );
  }

  public uploadFile(
    assetCollectionId: string,
    formData: any
  ): angular.IPromise<{}> {
    const url = `/api/Portals(${this.portal.identifier})/AssetCollections(${assetCollectionId})/File`;
    const config = {
      headers: {
        "Content-Type": undefined as any
      },
      errorsHandled: true
    };

    return this.$http
      .post(url, formData, config)
      .then(
        (response: angular.IHttpPromiseCallbackArg<{}>): {} => response.data
      );
  }

  public getNodeInfo(
    assetCollectionId: string,
    itemId: string
  ): angular.IPromise<INodeInfo> {
    const url = `/api/Portals(${this.portal.identifier})/AssetCollections(${assetCollectionId})/Node(${itemId})`;

    return this.$http
      .get(url)
      .then(
        (response: angular.IHttpPromiseCallbackArg<INodeInfo>): INodeInfo => {
          return response.data;
        }
      );
  }

  public getTemplateNodeInfo(
    assetCollectionTemplateId: string,
    itemId: string
  ): angular.IPromise<INodeInfo> {
    const url = `/api/Portals(${this.portal.identifier})/AssetCollectionTemplates(${assetCollectionTemplateId})/Node(${itemId})`;

    return this.$http
      .get(url)
      .then(
        (response: angular.IHttpPromiseCallbackArg<INodeInfo>): INodeInfo => {
          return response.data;
        }
      );
  }
}

angular.module("PortalApp").service("assetsService", AssetsService);
