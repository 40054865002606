import * as angular from "angular";
import { Binding } from "../../Utils/AngularBindings";
import { BaseComponent } from "./../../Core/BaseComponent";
import "./sidepane.controller";
import "./sidepane.html";
import "./sidepane.less";
import "./sidepane.service";
import "./sidepaneStorage.service";
import "./sidepaneSection.component";
export class SidePaneComponent extends BaseComponent {
  constructor() {
    super(
      "Rhinestone.SidePaneController",
      "/templates/Layout/SidePane/sidepane.html",
      true
    );

    this.bindings = {
      position: Binding.valueInput,
      id: Binding.oneWay,
      expanded: Binding.oneWay
    };

    this.require = {
      layout: "^^rhLayout"
    };
  }
}

angular.module("rhSidePane").component("rhSidePane", new SidePaneComponent());
