import * as angular from "angular";
import * as _ from "underscore";
import {
  SearchRequestClient,
  deserializeSearchRequest,
  serializeSearchRequest,
  UrlTokens
} from "@rhinestone/portal-web-react";

export class UrlService {
  public static $inject = ["$location"];

  constructor(private $location: angular.ILocationService) {}

  public updateUrl(request: SearchRequestClient): void {
    // replace ensures that any changes made that the updated url replaces the last entry in the browser history
    // We want this here as the url is updated to reflect the current search, but we don't want the user to push the back button once for every
    // changed search parameter to get back to the previous page.
    // Alternatively we should make sure that changes to the url is reflected in the current search.
    this.$location.replace();

    this.$location.search(UrlTokens.search, serializeSearchRequest(request));
  }

  public updateSearch(search: string): void {
    this.$location.replace();
    this.$location.search(search);
  }

  public getSearchRequestFromUrl(): SearchRequestClient {
    const search = this.$location.search();
    return deserializeSearchRequest(search[UrlTokens.search]);
  }

  public getSearchPageDeeplink(request: SearchRequestClient): string {
    return `${UrlTokens.search}=${serializeSearchRequest(request)}`;
  }

  public clearSearch(): void {
    this.$location.search(UrlTokens.search, null);
  }

  public hasActiveSearch(): boolean {
    const searchUrl = this.$location.search();
    return searchUrl[UrlTokens.search] !== undefined;
  }
}

angular.module("PortalApp").service("urlService", UrlService);
