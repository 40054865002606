import "./productPageView.html";
import { BaseComponent } from "../Core/BaseComponent";
import * as angular from "angular";
import "./productPageView.less";
import "./productPageView.controller";
import "./productPage.service";
import "./ProductPageTaxonomy/productPageTaxonomy.component";
export class ProductPageViewComponent extends BaseComponent {
  constructor() {
    super(
      "Rhinestone.ProductPageViewController",
      "/templates/productPage/productPageView.html"
    );
  }
}

angular
  .module("PortalApp")
  .component("rhProductPageView", new ProductPageViewComponent());
