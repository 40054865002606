import { ISearchSuggestionsProvider } from "./ISearchSuggestionsProvider";
import * as angular from "angular";
import "./DocumentsSearchSuggestionsProvider";
import "./DocumentTermsSearchSuggestionsProvider";
import "./TaxonsSearchSuggestionsProvider";
import { SearchSuggestionsProviderModel } from "@rhinestone/portal-web-api";

export class SearchSuggestionsProviderFactory {
  public static $inject = ["$q", "$injector", "$translate"];

  constructor(
    private $q: angular.IQService,
    private $injector: angular.auto.IInjectorService
  ) {}

  public create(
    providerModel: SearchSuggestionsProviderModel
  ): angular.IPromise<ISearchSuggestionsProvider> {
    const name = providerModel.name;
    const nameStartingFromLowerCase =
      name.charAt(0).toLowerCase() + name.slice(1);
    let provider: ISearchSuggestionsProvider;
    try {
      provider = this.$injector.get(
        nameStartingFromLowerCase
      ) as ISearchSuggestionsProvider;
    } catch (e) {
      this.logInstantiateError(e, name);
    }

    const deferred = this.$q.defer<ISearchSuggestionsProvider>();

    provider.config = {
      color: providerModel.color,
      icon: providerModel.icon,
      maxItems: providerModel.maxItems,
      payload: JSON.parse(providerModel.payload),
      title: providerModel.title,
      availableOnMobile: providerModel.availableOnMobile
    };

    deferred.resolve(provider);

    return deferred.promise;
  }

  private logInstantiateError(e: any, providerName: string) {
    console.error(
      `Cannot instantiate search suggestions provider of name: ${providerName}`
    );
    console.error(e);
  }
}
angular
  .module("PortalApp")
  .service(
    "searchSuggestionsProviderFactory",
    SearchSuggestionsProviderFactory
  );
