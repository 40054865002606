import * as angular from "angular";
import { ProductModel, ProductPageDto } from "@rhinestone/portal-web-api";
import { PortalDataHttpService } from "../Services/DataService/PortalDataHttpService";

export class ProductPageService {
  public static $inject = ["portalDataService"];

  constructor(private portalDataService: PortalDataHttpService) {}

  public async getProduct(productCode: string): Promise<ProductModel> {
    return await this.portalDataService.get<ProductModel>(
      `products/${productCode}`
    );
  }

  public async getProductPageConfiguration(
    productCode: string
  ): Promise<ProductPageDto> {
    return await this.portalDataService.get<ProductPageDto>(
      `productPages/${productCode}`
    );
  }
}

angular.module("PortalApp").service("productPageService", ProductPageService);
