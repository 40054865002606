import { IPortalDataHttpService } from "./DataService/PortalDataHttpService";
import * as angular from "angular";
import { ProductModel } from "@rhinestone/portal-web-api";
import { IPortalConfig } from "../Core/portal.provider";
import { ICurrentUserService } from "../User/currentUser.service";

export interface IProductsService {
  getUserProducts(): angular.IPromise<ProductModel[]>;
  getSingleProductPortalProduct(): Promise<ProductModel>;
}

export class ProductsService implements IProductsService {
  public static $inject = [
    "portalDataService",
    "portal",
    "currentUserService",
    "$q"
  ];
  /**
   * using this boolean to make sure products are only fetched once
   */
  private isInitialized = false;
  private userProductsDeferrer: angular.IDeferred<ProductModel[]> =
    this.$q.defer<ProductModel[]>();

  constructor(
    private portalDataService: IPortalDataHttpService,
    private portal: IPortalConfig,
    private currentUserService: ICurrentUserService,
    private $q: angular.IQService
  ) {}

  /**
   * Returns cached user products if available, otherwise fetches them from the server
   * Because callers of this method are depending on angular.IPromise we cant use regular
   * Promise and async/await here
   * */
  public getUserProducts() {
    if (this.isInitialized) {
      // We cache userProducts for lifetime of the application
      return this.userProductsDeferrer.promise;
    }

    this.isInitialized = true;

    // Wait for authentication to be determined before deciding how to get products
    this.currentUserService.isAuthenticatedAsync().then(isAuthenticated => {
      if (!isAuthenticated) {
        this.userProductsDeferrer.resolve([]);
      } else {
        this.portalDataService
          .get<ProductModel[]>("GetOrganizationPortalProductAccessForUser")
          .then(products => {
            this.userProductsDeferrer.resolve(products);
          });
      }
    });

    return this.userProductsDeferrer.promise;
  }

  /**
   * If portal is set to use *bypassFrontPageAfterLogin* and the logged in user has
   * exactly one product, return that product..
   */
  public async getSingleProductPortalProduct() {
    const { bypassFrontPageAfterLogin, isUserAuthenticatedOnServer } =
      this.portal;

    if (!bypassFrontPageAfterLogin || !isUserAuthenticatedOnServer) {
      return null;
    }

    const userProducts = await this.getUserProducts();
    if (userProducts.length !== 1) {
      return null;
    }

    return userProducts[0];
  }
}

angular.module("PortalApp").service("productsService", ProductsService);
