import * as angular from "angular";
import { TextTileDto } from "../TilesTypes";

export class TextTileController {
  public isInitialized: boolean = false;
  public tile: TextTileDto;

  public static $inject = ["$scope"];

  public $onInit() {
    this.isInitialized = true;
  }
}

angular
  .module("PortalApp")
  .controller("Rhinestone.TextTileController", TextTileController);
