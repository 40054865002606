import "./searchResultsPdfExportButton.html";
import * as angular from "angular";
import "./searchResultsPdfExportButton.controller";
import { BaseComponent } from "../Core/BaseComponent";
import { Binding } from "../Utils/AngularBindings";

export class SearchResultsPdfExportButtonComponent extends BaseComponent {
  constructor() {
    super(
      "Rhinestone.SearchResultsPdfExportButtonController",
      "/templates/Search/SearchResultsPdfExportButton.html"
    );
    this.bindings = {
      searchResults: Binding.oneWay,
      searchRequest: Binding.oneWay,
      criteriaProviders: Binding.oneWay,
      sorting: Binding.oneWay
    };
  }
}

angular
  .module("PortalApp")
  .component(
    "rhSearchResultsPdfExportButton",
    new SearchResultsPdfExportButtonComponent()
  );
