import * as angular from "angular";

export class AssetsListSearchBoxController {
  // Component bindings
  public filter: string;

  public static $inject: string[] = [];

  constructor() {}

  public $onInit() {}
}

angular
  .module("PortalApp")
  .controller(
    "Rhinestone.AssetsListSearchBoxController",
    AssetsListSearchBoxController
  );
