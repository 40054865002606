import * as angular from "angular";
import * as _ from "underscore";
import { SearchService } from "../Search/search.service";
import { TaxonDocument } from "./models/TaxonDocument";
import { Taxon } from "./models/taxon";
import { Taxonomy } from "./models/taxonomy";
import { TaxonomyMatches, TaxonMatch } from "../Search/searchResponse";
import { Criteria, SearchRequestClient } from "@rhinestone/portal-web-react";

export interface TaxonomyTreeOptions {
  criteria?: ReadonlyArray<Criteria>;
  showDocuments?: boolean;
  showEmptyTaxons?: boolean;
}

export interface ITaxonomyService {
  getTaxonomyTree(
    taxonomyName: string,
    options?: TaxonomyTreeOptions,
    cancellationPromise?: angular.IPromise<any>
  ): Promise<Taxonomy>;

  getLocalTaxonomyTree(
    localTaxonomyName: string,
    options?: TaxonomyTreeOptions,
    cancellationPromise?: angular.IPromise<any>
  ): Promise<Taxonomy>;

  buildTaxon(
    jsonData: any,
    taxonomy: Taxonomy,
    parentTaxon: Taxon,
    taxonMatches: TaxonMatch[]
  ): any;
}

export class TaxonomyService implements ITaxonomyService {
  public static $inject = ["searchService"];

  constructor(private searchService: SearchService) {}

  public async getTaxonomyTree(
    taxonomyName: string,
    options: TaxonomyTreeOptions = {},
    cancellationPromise?: angular.IPromise<any>
  ): Promise<Taxonomy> {
    const searchRequestClient = this.buildSearchRequestClient(
      taxonomyName,
      options
    );
    const response = (await this.searchService.executeSearch(
      searchRequestClient,
      cancellationPromise
    )) as any;

    const taxonomyMatch: TaxonomyMatches = _(response.TaxonomyMatch).find(
      (x: TaxonomyMatches) => x.TaxonomyName === taxonomyName
    );

    const taxonomy: Taxonomy = {
      name: taxonomyName,
      children: []
    };

    response.Results.Taxons.forEach((x: any) =>
      this.buildTaxon(
        x,
        taxonomy,
        null,
        taxonomyMatch ? taxonomyMatch.TaxonCounts : []
      )
    );

    return taxonomy;
  }

  public async getLocalTaxonomyTree(
    documentTypeTaxonomyId: string,
    options: TaxonomyTreeOptions = {},
    cancellationPromise?: angular.IPromise<any>
  ): Promise<Taxonomy> {
    const searchRequestClient = this.buildLocalTaxonomySearchRequestClient(
      documentTypeTaxonomyId,
      options
    );
    const response = (await this.searchService.executeSearch(
      searchRequestClient,
      cancellationPromise
    )) as any;

    const taxonomyMatch: TaxonomyMatches = _(response.TaxonomyMatch).first();
    const taxonomy: Taxonomy = {
      name: documentTypeTaxonomyId,
      children: []
    };

    response.Results.Taxons.forEach((x: any) =>
      this.buildTaxon(
        x,
        taxonomy,
        null,
        taxonomyMatch ? taxonomyMatch.TaxonCounts : []
      )
    );

    return taxonomy;
  }

  private buildSearchRequestClient(
    taxonomyName: string,
    options: TaxonomyTreeOptions
  ): SearchRequestClient {
    const searchRequestClient: SearchRequestClient = {
      fieldSetName: "SearchResultFields",
      criteria: options.criteria,
      formatter: {
        name: "Taxonomy",
        taxonomyName,
        takeEmptyTaxons: options.showEmptyTaxons
      }
    };

    if (options.showDocuments === false) {
      searchRequestClient.take = 0;
    }

    return searchRequestClient;
  }

  private buildLocalTaxonomySearchRequestClient(
    documentTypeTaxonomyId: string,
    options: TaxonomyTreeOptions
  ): SearchRequestClient {
    const searchRequestClient: SearchRequestClient = {
      fieldSetName: "SearchResultFields",
      criteria: options.criteria,
      localformatter: {
        name: "LocalDocumentTypeFormatter", // Must match Name property of configured search formatter, which is hardcoded for LocalDocumentTypeTaxonomySearchResultFormatter
        documentTypeTaxonomyId,
        takeEmptyTaxons: options.showEmptyTaxons
      }
    };

    if (options.showDocuments === false) {
      searchRequestClient.take = 0;
    }

    return searchRequestClient;
  }

  public buildTaxon(
    jsonData: any,
    taxonomy: Taxonomy,
    parentTaxon: Taxon,
    taxonMatches: TaxonMatch[]
  ) {
    const taxonMatch = _(taxonMatches).find(
      (x: TaxonMatch) => x.TaxonKey === jsonData.TaxonKey
    );
    const newTaxon = this.jsonToTaxon(jsonData, taxonMatch);

    if (parentTaxon !== null) {
      parentTaxon.children.push(newTaxon);
      newTaxon.parent = parentTaxon;
      // copy taxons path from parent
      newTaxon.path.push(...parentTaxon.path);
    } else {
      taxonomy.children.push(newTaxon);
    }

    newTaxon.path.push({
      key: newTaxon.key,
      title: newTaxon.title,
      longTitle: newTaxon.longTitle,
      taxonomyName: taxonomy.name
    });

    _.each(jsonData.Taxons, (x: any) =>
      this.buildTaxon(x, taxonomy, newTaxon, taxonMatches)
    );
  }

  private jsonToTaxon(json: any, taxonMatch?: TaxonMatch): Taxon {
    return {
      key: json.TaxonKey,
      count: taxonMatch ? taxonMatch.Count : 0,
      children: [],
      title: json.ShortTitle,
      longTitle: json.LongTitle,
      hasChildren:
        json.Taxons.length > 0 ||
        json.Documents.length > 0 ||
        (taxonMatch && taxonMatch.Count > 0),
      documents: json.Documents.map((x: any) =>
        this.jsonToDocument(x, json.TaxonKey)
      ),
      parent: null,
      expanded: false,
      isSelected: false,
      path: []
    };
  }

  private jsonToDocument(json: any, taxonKey: string): TaxonDocument {
    return {
      fullName: json.FullName,
      hiveId: json.HiveId,
      taxonKey,
      title: json.title,
      docdate: json.docdate
    };
  }
}

angular.module("PortalApp").service("taxonomyService", TaxonomyService);
