import * as angular from "angular";
import { TemplateHttpInterceptor } from "../Interceptors/portalTemplate.interceptor";
import { IsAuthHttpInterceptor } from "../Interceptors/isAuthenticated.interceptor";

export class HttpProviderConfig {
  public static $inject = ["$provide", "$httpProvider"];

  constructor($provide: any, $httpProvider: angular.IHttpProvider) {
    // Register template interceptor
    $provide.factory("templateInterceptor", [
      "portal",
      (portal: any) => new TemplateHttpInterceptor(portal)
    ]);

    $provide.factory("isAuthInterceptor", [
      "$injector",
      ($injector: any) => new IsAuthHttpInterceptor($injector)
    ]);

    $httpProvider.interceptors.push("templateInterceptor");
    $httpProvider.interceptors.push("isAuthInterceptor");

    // Add X-Requested-With header
    $httpProvider.defaults.headers.common["X-Requested-With"] =
      "XMLHttpRequest";

    $httpProvider.defaults.headers.common.ticket = "";
  }
}

angular.module("PortalApp").config(HttpProviderConfig);
