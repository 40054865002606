import * as angular from "angular";
import { ISearchResponse, SearchRefinementItem } from "./searchResponse";
import { AssetsService } from "../Assets/assets.service";
import { IDialogService } from "../Services/dialog.service";
import { IPortalConfig } from "../Core/portal.provider";
import * as _ from "underscore";

import { ILocalizedNotificationService } from "../Services/localized.notification.service";
import { DocumentModel } from "../Models/DocumentModel";
import {
  SearchFilterGroupModel,
  SearchViewType
} from "@rhinestone/portal-web-api";
import { GoogleTagManagerService } from "../Services/google-tag-manager.service";
import {
  SearchRequestClient,
  SearchRequestMapper,
  SearchRequestServer
} from "@rhinestone/portal-web-react";

export class SearchService {
  private baseUrl = "";

  public views: SearchViewType[] = [];

  private selectedDocuments: DocumentModel[] = [];

  public static $inject = [
    "$window",
    "$http",
    "$q",
    "assetsService",
    "dialogService",
    "googleTagManagerService",
    "localizedNotificationService",
    "portal",
    "$translate"
  ];

  constructor(
    private $window: angular.IWindowService,
    private $http: angular.IHttpService,
    private $q: angular.IQService,
    private assetsService: AssetsService,
    private dialogService: IDialogService,
    private googleTagManagerService: GoogleTagManagerService,
    private localizedNotificationService: ILocalizedNotificationService,
    private portal: IPortalConfig,
    private $translate: angular.translate.ITranslateService
  ) {
    this.baseUrl = `/api/Portals(${this.portal.identifier})/search`;
  }

  public getSearchViews(): angular.IPromise<SearchViewType[]> {
    const deferred = this.$q.defer<SearchViewType[]>();

    if (this.views && this.views.length > 0) {
      deferred.resolve(this.views);
    } else {
      this.$http
        .get<SearchViewType[]>(`${this.baseUrl}/views`)
        .then(result => {
          this.views = _(result.data).sortBy(x => x.priority);
          deferred.resolve(this.views);
        })
        .catch(() => {
          deferred.reject("Could not get search views");
        });
    }

    return deferred.promise;
  }

  public async getDefaultSearchView(): Promise<SearchViewType> {
    return this.selectDefaultSearchView(await this.getSearchViews());
  }

  public selectDefaultSearchView(
    searchViews: SearchViewType[]
  ): SearchViewType {
    return searchViews[0];
  }

  public getSearchFilterGroups(): angular.IPromise<SearchFilterGroupModel[]> {
    let url = `${this.baseUrl}/filterGroups`;

    // If a searchPreview is active then we add it as parameter
    const activePreview = sessionStorage.getItem("searchPreviewName");
    if (activePreview) {
      url += `?searchPreviewName=${activePreview}`;
    }

    return this.$http
      .get<SearchFilterGroupModel[]>(url)
      .then(
        (
          response: angular.IHttpPromiseCallbackArg<SearchFilterGroupModel[]>
        ): SearchFilterGroupModel[] => {
          return response.data as SearchFilterGroupModel[];
        }
      );
  }

  public async getQueryRefinements(): Promise<SearchRefinementItem[]> {
    const queryRefinements = await this.$http.get<SearchRefinementItem[]>(
      `${this.baseUrl}/queryRefinements`
    );

    return queryRefinements.data;
  }

  public getSearchTerms(currentTerm: string): angular.IPromise<string[]> {
    const url = `${this.baseUrl}/terms?currentTerm=${encodeURIComponent(
      currentTerm
    )}`;

    return this.$http
      .get(url)
      .then((response: angular.IHttpPromiseCallbackArg<string[]>): string[] => {
        return response.data as string[];
      });
  }

  public executeSearch(
    request: SearchRequestClient,
    cancellationPromise?: angular.IPromise<any>
  ): angular.IPromise<ISearchResponse> {
    const serverRequest = SearchRequestMapper.mapToServerSearchRequest(request);
    return this.executeSearchFromServerRequest(
      serverRequest,
      cancellationPromise
    );
  }

  public executeSearchWithGtmTracking(
    request: SearchRequestClient,
    cancellationPromise?: angular.IPromise<any>
  ): angular.IPromise<ISearchResponse> {
    const serverRequest = SearchRequestMapper.mapToServerSearchRequest(request);
    const terms = serverRequest.criteria.terms;
    if (terms) {
      terms.forEach(term => {
        this.googleTagManagerService.trackSearchTerm(term);
      });
    }
    return this.executeSearchFromServerRequest(
      serverRequest,
      cancellationPromise
    );
  }

  public clearSelectedDocuments(): void {
    this.selectedDocuments = [];
  }

  private findSelectedIndex(
    fullName: string,
    hiveId: string,
    revisionNumber: number
  ): number {
    return _.findIndex<DocumentModel>(this.selectedDocuments, doc => {
      return (
        doc.fullName === fullName &&
        doc.hiveId === hiveId &&
        doc.revisionNumber === revisionNumber
      );
    });
  }

  public toggleDocumentSelection = (
    fullName: string,
    hiveId: string,
    revisionNumber: number
  ) => {
    const index = this.findSelectedIndex(fullName, hiveId, revisionNumber);
    if (index === -1) {
      const newItem = { fullName, hiveId, revisionNumber };
      this.selectedDocuments.splice(0, 0, newItem);
    } else {
      this.selectedDocuments.splice(index, 1);
    }
  };

  public isSelected = (
    fullName: string,
    hiveId: string,
    revisionId: number
  ) => {
    return this.findSelectedIndex(fullName, hiveId, revisionId) !== -1;
  };

  public hasSelectedDocuments = () => {
    return this.selectedDocuments.length > 0;
  };

  public addToAssetsCollection = async (
    assetCollectionId: string,
    title: string
  ) => {
    if (this.selectedDocuments.length === 0) {
      return;
    }

    await this.assetsService.addToCollection(
      assetCollectionId,
      this.selectedDocuments
    );

    const translationId =
      this.selectedDocuments.length === 1
        ? "assets.document_added"
        : "assets.documents_added";
    this.localizedNotificationService.success(translationId, { title });
    this.clearSelectedDocuments();
  };

  public async openAddToNewMaterialCollectionDialog() {
    if (this.selectedDocuments.length === 0) {
      return;
    }

    await this.dialogService.openAddToNewMaterialCollectionDialog(
      this.selectedDocuments
    ).result;

    this.clearSelectedDocuments();
  }

  public async openAddToExistingMaterialCollectionDialog() {
    if (this.selectedDocuments.length === 0) {
      return;
    }

    await this.dialogService.openAddToExistingMaterialCollectionDialog(
      this.selectedDocuments
    ).result;

    this.clearSelectedDocuments();
  }

  private AddTranslatableStatusIndex(searchResponse: ISearchResponse) {
    Array.prototype.forEach.call(searchResponse.Results, (x: any) => {
      if (!("status" in x)) {
        return;
      }

      if (x.status.toLowerCase() !== "unknown") {
        x.statusIndex = `status.${x.status.toLowerCase()}`;
      } else {
        x.statusIndex = "";
        x.status = "";
      }
    });
  }

  private enrichSearchResponse(
    request: SearchRequestServer,
    searchResponse: ISearchResponse
  ) {
    searchResponse.PageNumber = request.page;
    searchResponse.Descending = false; // default
    if (request.ordering && request.ordering.fieldName) {
      searchResponse.SortName = request.ordering.fieldName;
      searchResponse.Descending = request.ordering.descending;
    }
  }

  private executeSearchFromServerRequest(
    request: SearchRequestServer,
    cancellationPromise?: angular.IPromise<any>
  ): angular.IPromise<ISearchResponse> {
    const url = `/api/v1/portals/${this.portal.identifier}/search`;

    // Set search preview parameters if specified
    // This is intended for testing of new search configurations on a specific environment.
    this.setSearchPreviewParameters(request);
    return this.$http
      .post(url, request, { timeout: cancellationPromise })
      .then(
        (
          response: angular.IHttpPromiseCallbackArg<ISearchResponse>
        ): ISearchResponse => {
          const searchResponse: ISearchResponse = response.data;
          this.AddTranslatableStatusIndex(searchResponse);
          this.enrichSearchResponse(request, searchResponse);

          return searchResponse;
        }
      )
      .finally(() => {
        this.clearSelectedDocuments();
      });
  }

  private setSearchPreviewParameters(request: SearchRequestServer) {
    const previewName =
      this.$window.sessionStorage.getItem("searchPreviewName");

    if (previewName) {
      request.preview = {
        previewName: previewName
      };
    }
  }
}

angular.module("PortalApp").service("searchService", SearchService);
