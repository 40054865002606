import * as angular from "angular";
import { AssetsService } from "./assets.service";
import { INodeInfo } from "../Services/models/nodeInfo";
import "./saveAssetCollectionNodeDialog.html";

export class SaveAssetCollectionNodeDialogController {
  public dialogTitle: string;
  public buttonText: string;
  private rootFolderChildrenCount: number = 0;

  public static $inject = [
    "$uibModalInstance",
    "$translate",
    "assetsService",
    "assetCollectionId",
    "rootFolderId",
    "node",
    "editMode"
  ];

  constructor(
    private $uibModalInstance: angular.ui.bootstrap.IModalServiceInstance,
    private $translate: angular.translate.ITranslateService,
    private assetsService: AssetsService,
    private assetCollectionId: string,
    private rootFolderId: string,
    public node: INodeInfo,
    private editMode: boolean
  ) {
    const dialogTitleTranslationKey = editMode
      ? "assets.edit_heading"
      : "assets.add_heading";
    this.$translate(dialogTitleTranslationKey).then(translation => {
      this.dialogTitle = translation;
    });

    const buttonTextTranslationKey = editMode ? "assets.save" : "assets.add";
    this.$translate(buttonTextTranslationKey).then(translation => {
      this.buttonText = translation;
    });

    if (!editMode) {
      this.assetsService.getTreeView(assetCollectionId, false).then(data => {
        this.rootFolderChildrenCount = data.length;
      });
    }
  }

  public saveNode = () => {
    if (this.editMode) {
      this.assetsService
        .updateNode(this.assetCollectionId, this.node)
        .then(() => {
          this.$uibModalInstance.close();
        });
    } else {
      this.$uibModalInstance.close(this.node);
    }
  };

  public canSaveNode = () => {
    return this.node.Name;
  };

  public cancel = () => {
    this.$uibModalInstance.dismiss();
  };
}

angular
  .module("PortalApp")
  .controller(
    "Rhinestone.SaveAssetCollectionNodeDialogController",
    SaveAssetCollectionNodeDialogController
  );
