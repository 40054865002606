import "./savedSearches.html";
import { BaseComponent } from "../../Core/BaseComponent";
import * as angular from "angular";
import { Binding } from "../../Utils/AngularBindings";
import "./savedSearches.less";
import "./savedSearches.controller";
import "./savedSearch.service";
export class SavedSearchesComponent extends BaseComponent {
  constructor() {
    super(
      "Rhinestone.SavedSearchesController",
      "/templates/search/savedSearchWidget/savedSearches"
    );
    this.bindings = {
      loading: Binding.oneWay,
      queryParams: Binding.outputEvent
    };
  }
}

angular
  .module("PortalApp")
  .component("rhSavedSearches", new SavedSearchesComponent());
