import "./tileCardHeader.html";
import { BaseComponent } from "../../../Core/BaseComponent";
import * as angular from "angular";

export class TileCardHeaderComponent extends BaseComponent {
  constructor() {
    super(
      "Rhinestone.TileCardController",
      "/templates/tiles/tilecard/tilecardheader/tileCardHeader.html",
      true
    );
  }
}

angular
  .module("PortalApp")
  .component("rhTileCardHeader", new TileCardHeaderComponent());
