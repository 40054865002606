import * as angular from "angular";
import { AssetsService } from "./assets.service";
import { ILocalizedNotificationService } from "../Services/localized.notification.service";
import { HttpStatusCode } from "../Core/constants";

import "./copyAssetCollectionAsTemplateDialog.html";

export class CopyAssetCollectionAsTemplateDialogController {
  public static $inject = [
    "$uibModalInstance",
    "$translate",
    "localizedNotificationService",
    "assetsService",
    "assetCollectionId"
  ];
  constructor(
    private $uibModalInstance: angular.ui.bootstrap.IModalServiceInstance,
    $translate: angular.translate.ITranslateService,
    private localizedNotificationService: ILocalizedNotificationService,
    private assetsService: AssetsService,
    private assetCollectionId: string
  ) {}

  public copyAsTemplate = async () => {
    try {
      const template = await this.assetsService.copyAssetCollectionAsTemplate(
        this.assetCollectionId
      );
      this.$uibModalInstance.close(template);
    } catch (err) {
      // Handle Conflict(409): A template with same title already exist
      if (err.status === HttpStatusCode.Conflict) {
        this.localizedNotificationService.error(
          "assets.copy_as_template_error_conflict"
        );
        return;
      }

      throw err;
    }
  };

  public cancel = () => {
    this.$uibModalInstance.dismiss();
  };
}

angular
  .module("PortalApp")
  .controller(
    "Rhinestone.CopyAssetCollectionAsTemplateDialogController",
    CopyAssetCollectionAsTemplateDialogController
  );
