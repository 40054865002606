import * as angular from "angular";
import { SpaConfigDto } from "@rhinestone/portal-web-api";
export class MaterialConfig {
  public static $inject = ["$mdThemingProvider", "rhinestoneConfig"];

  constructor($mdThemingProvider: any, rhinestoneConfig: SpaConfigDto) {
    $mdThemingProvider
      .theme("default")
      .primaryPalette(rhinestoneConfig.angularMaterialPrimaryColor);
  }
}

angular.module("PortalApp").config(MaterialConfig);
