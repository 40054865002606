import * as angular from "angular";
import * as bootstrap from "bootstrap";
import { IAssetCollectionMember } from "../Models/assetCollectionMember";
import { INodeInfo } from "./models/nodeInfo";
import { NewCollectionDialogController } from "../Assets/newCollectionDialog.controller";
import { ExistingCollectionDialogController } from "../Assets/ExistingCollectionDialogController";
import { NewCollectionFromQuoteDialogController } from "../Assets/NewCollectionFromQuoteDialogController";
import { ExistingCollectionFromQuoteDialogController } from "../Assets/ExistingCollectionFromQuoteDialogController";
import { AddMembersToAssetCollectionDialogController } from "../Assets/addMemberToAssetCollectionDialog.controller";
import { ConfirmDeleteCollectionDialogController } from "../Assets/confirmDeleteCollectionDialog.controller";
import { PublishAssetCollectionDialogController } from "../Assets/publishAssetCollectionDialog.controller";
import { CopyAssetCollectionDialogController } from "../Assets/copyAssetCollectionDialog.controller";
import { CopyAssetCollectionAsTemplateDialogController } from "../Assets/copyAssetCollectionAsTemplateDialog.controller";

import { ModalAlertController } from "../Documents/ModalAlertController";
import { SaveAssetCollectionNodeDialogController } from "../Assets/saveAssetCollectionNodeDialog.controller";
import { ConfirmDeleteNodeController } from "../Assets/confirmDeleteNodeController";
import { UploadFileController } from "../Assets/UploadFileController";
import { ConfirmDeleteAnnotationDialogController } from "../Annotations/ConfirmDeleteAnnotationDialogController";

import { DocumentModel } from "../Models/DocumentModel";
import { CopyAssetCollectionFromTemplateDialogController } from "../Assets/copyAssetCollectionFromTemplateDialog.controller";

import "../Annotations/ConfirmDeleteDialog.html";
import "../Annotations/AnnotateMultibleSelectionDialog.html";
import "../Annotations/AnnotationNoSelectionDialog.html";

export interface IDialogService {
  openAddToNewMaterialCollectionDialog(
    documents: DocumentModel[]
  ): angular.ui.bootstrap.IModalServiceInstance;
  openAddToExistingMaterialCollectionDialog(
    documents: DocumentModel[]
  ): angular.ui.bootstrap.IModalServiceInstance;
  openAddToNewCollectionFromQuoteDialog(): angular.ui.bootstrap.IModalServiceInstance;
  openAddToExistingCollectionFromQuoteDialog(): angular.ui.bootstrap.IModalServiceInstance;

  openAddMemberToAssetCollectionDialog(
    assetCollectionId: string,
    currentMembers: IAssetCollectionMember[]
  ): angular.ui.bootstrap.IModalServiceInstance;
  openConfirmDeleteAssetCollectionDialog(): angular.ui.bootstrap.IModalServiceInstance;
  openPublishAssetCollectionDialog(
    assetCollectionId: string,
    assetCollectionTitle: string
  ): angular.ui.bootstrap.IModalServiceInstance;
  openCopyAssetCollectionDialog(
    assetCollectionId: string,
    assetCollectionTitle: string
  ): angular.ui.bootstrap.IModalServiceInstance;
  openCopyAssetCollectionFromTemplateDialog(
    assetCollectionTemplateId: string,
    isArchived: boolean
  ): angular.ui.bootstrap.IModalServiceInstance;
  openCopyAssetCollectionAsTemplateDialog(
    assetCollectionId: string
  ): angular.ui.bootstrap.IModalServiceInstance;

  openMultipleRangeSelection(): angular.ui.bootstrap.IModalServiceInstance;
  openNoSelectionDialog(): angular.ui.bootstrap.IModalServiceInstance;

  openSaveAssetCollectionNodeDialog(
    assetCollectionId: string,
    rootFolderId: string,
    node: INodeInfo,
    editMode: boolean
  ): angular.ui.bootstrap.IModalServiceInstance;

  openConfirmDeleteNodeDialog(
    assetItem: INodeInfo
  ): angular.ui.bootstrap.IModalServiceInstance;
  openUploadFileDialog(
    assetCollectionId: string
  ): angular.ui.bootstrap.IModalServiceInstance;

  openConfirmDeleteAnnotationDialog(): angular.ui.bootstrap.IModalServiceInstance;
}

export class DialogService implements IDialogService {
  public static $inject = ["$uibModal"];

  constructor(private $uibModal: angular.ui.bootstrap.IModalService) {}

  public openAddToNewMaterialCollectionDialog(
    documents: Array<{ [propertyName: string]: any }>
  ): angular.ui.bootstrap.IModalServiceInstance {
    const settings = this.createSettings(
      "/templates/assets/newCollectionDialog",
      NewCollectionDialogController
    );
    settings.resolve = {
      documents: () => documents
    };

    return this.openDialog(settings);
  }

  public openAddToExistingMaterialCollectionDialog(
    documents: Array<{ [propertyName: string]: any }>
  ): angular.ui.bootstrap.IModalServiceInstance {
    const settings = this.createSettings(
      "/templates/assets/existingCollectionDialog",
      ExistingCollectionDialogController
    );
    settings.resolve = {
      documents: () => documents
    };

    return this.openDialog(settings);
  }

  public openAddToNewCollectionFromQuoteDialog(): angular.ui.bootstrap.IModalServiceInstance {
    const settings = this.createSettings(
      "/templates/assets/NewCollectionFromQuoteDialog",
      NewCollectionFromQuoteDialogController
    );

    return this.openDialog(settings);
  }

  public openAddToExistingCollectionFromQuoteDialog(): angular.ui.bootstrap.IModalServiceInstance {
    const settings = this.createSettings(
      "/templates/assets/ExistingCollectionFromQuoteDialog",
      ExistingCollectionFromQuoteDialogController
    );

    return this.openDialog(settings);
  }

  public openAddMemberToAssetCollectionDialog(
    assetCollectionId: string,
    currentMembers: IAssetCollectionMember[]
  ): angular.ui.bootstrap.IModalServiceInstance {
    const settings = this.createSettings(
      "/templates/assets/AddMemberToAssetCollectionDialog",
      AddMembersToAssetCollectionDialogController
    );
    settings.resolve = {
      assetCollectionId: () => assetCollectionId,
      currentMembers: () => currentMembers
    };

    return this.openDialog(settings);
  }

  public openConfirmDeleteAssetCollectionDialog(): angular.ui.bootstrap.IModalServiceInstance {
    const settings = this.createSettings(
      "/templates/assets/ConfirmDeleteCollectionDialog",
      ConfirmDeleteCollectionDialogController
    );
    return this.openDialog(settings);
  }

  public openPublishAssetCollectionDialog(
    assetCollectionId: string,
    assetCollectionTitle: string
  ): angular.ui.bootstrap.IModalServiceInstance {
    const settings = this.createSettings(
      "/templates/assets/PublishAssetCollectionDialog",
      PublishAssetCollectionDialogController
    );
    settings.resolve = {
      assetCollectionId: () => assetCollectionId,
      assetCollectionTitle: () => assetCollectionTitle
    };

    return this.openDialog(settings);
  }

  public openCopyAssetCollectionFromTemplateDialog(
    assetCollectionTemplateId: string,
    isArchived: boolean
  ): angular.ui.bootstrap.IModalServiceInstance {
    const settings = this.createSettings(
      "/templates/assets/CopyAssetCollectionFromTemplateDialog",
      CopyAssetCollectionFromTemplateDialogController
    );
    settings.resolve = {
      assetCollectionTemplateId: () => assetCollectionTemplateId,
      isArchived: () => isArchived
    };

    return this.openDialog(settings);
  }

  public openCopyAssetCollectionDialog(
    assetCollectionId: string,
    assetCollectionTitle: string
  ): angular.ui.bootstrap.IModalServiceInstance {
    const settings = this.createSettings(
      "/templates/assets/CopyAssetCollectionDialog",
      CopyAssetCollectionDialogController
    );
    settings.resolve = {
      assetCollectionId: () => assetCollectionId,
      assetCollectionTitle: () => assetCollectionTitle
    };

    return this.openDialog(settings);
  }

  public openCopyAssetCollectionAsTemplateDialog(
    assetCollectionId: string
  ): angular.ui.bootstrap.IModalServiceInstance {
    const settings = this.createSettings(
      "/templates/assets/CopyAssetCollectionAsTemplateDialog",
      CopyAssetCollectionAsTemplateDialogController
    );
    settings.resolve = {
      assetCollectionId: () => assetCollectionId
    };

    return this.openDialog(settings);
  }

  public openMultipleRangeSelection(): angular.ui.bootstrap.IModalServiceInstance {
    const settings = this.createSettings(
      "/templates/Annotations/AnnotateMultipleSelectionsDialog",
      ModalAlertController
    );
    return this.openDialog(settings);
  }

  public openNoSelectionDialog(): angular.ui.bootstrap.IModalServiceInstance {
    const settings = this.createSettings(
      "/templates/Annotations/AnnotateNoSelectionDialog",
      ModalAlertController
    );
    return this.openDialog(settings);
  }

  public openSaveAssetCollectionNodeDialog(
    assetCollectionId: string,
    rootFolderId: string,
    node: INodeInfo,
    editMode: boolean
  ): angular.ui.bootstrap.IModalServiceInstance {
    const settings = this.createSettings(
      "/templates/assets/SaveAssetCollectionNodeDialog",
      SaveAssetCollectionNodeDialogController
    );
    settings.resolve = {
      assetCollectionId: () => assetCollectionId,
      node: () => node,
      editMode: () => editMode,
      rootFolderId: () => rootFolderId
    };

    return this.openDialog(settings);
  }

  public openConfirmDeleteNodeDialog(
    assetItem: INodeInfo
  ): angular.ui.bootstrap.IModalServiceInstance {
    const settings = this.createSettings(
      "/templates/assets/ConfirmDeleteNodeDialog",
      ConfirmDeleteNodeController
    );
    settings.resolve = {
      assetItem: () => assetItem
    };

    return this.openDialog(settings);
  }

  public openUploadFileDialog(
    assetCollectionId: string
  ): angular.ui.bootstrap.IModalServiceInstance {
    const settings = this.createSettings(
      "/templates/Assets/UploadFileDialog",
      UploadFileController
    );
    settings.resolve = {
      assetCollectionId: () => assetCollectionId
    };

    return this.openDialog(settings);
  }

  public openConfirmDeleteAnnotationDialog(): angular.ui.bootstrap.IModalServiceInstance {
    const settings = this.createSettings(
      "/templates/Annotations/ConfirmDeleteDialog",
      ConfirmDeleteAnnotationDialogController
    );
    return this.openDialog(settings);
  }

  private openDialog = (settings: angular.ui.bootstrap.IModalSettings) => {
    return this.$uibModal.open(settings);
  };

  private createSettings(
    templateUrl: string,
    controller: Function
  ): angular.ui.bootstrap.IModalSettings {
    // Don't animate modal on IE (animation courses misplaced cursor)
    const userAgent = window.navigator.userAgent.toLowerCase();
    const animate = !/(msie|trident)/.test(userAgent);

    const settings: angular.ui.bootstrap.IModalSettings = {
      animation: animate,
      templateUrl,
      controller,
      controllerAs: "vm",
      bindToController: true
    };

    return settings;
  }
}

angular.module("PortalApp").service("dialogService", DialogService);
