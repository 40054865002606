import * as angular from "angular";
import { ISearchSuggestionsProvider } from "./Providers/ISearchSuggestionsProvider";
import {
  Criteria,
  CriteriaChange,
  CriteriaProvider,
  CriteriaChangeType
} from "@rhinestone/portal-web-react";
import { SearchAssistantService } from "./searchAssistant.service";
import { SearchEvents } from "../search.events";
import { ISuggestion } from "./Providers/ISuggestion";
// @ts-ignore: We cast to Horsey on-use as type-def for module are missing
import * as horsey from "../../../Scripts/horseyjs/horsey";
import { SearchTermsService } from "./searchTerms.service";

export class SearchAssistentController {
  public currentCriterias: ReadonlyArray<Criteria>;
  public searchText: string;
  public onCriteriaChanged: (action: { action: CriteriaChange }) => void;
  public onLoaded: (param: { provider: CriteriaProvider }) => void;

  public isLoading: boolean = false;

  private readonly minimumQueryLength = 3;
  private searchInputField: JQuery;

  public static $inject = [
    "$element",
    "$scope",
    "$translate",
    "searchAssistantService",
    "searchTermsService"
  ];

  constructor(
    private $element: JQuery,
    private $scope: angular.IScope,
    private $translate: angular.translate.ITranslateService,
    private searchAssistantService: SearchAssistantService,
    private searchTermsService: SearchTermsService
  ) {}

  public async $onInit() {
    const termsProviders =
      await this.searchTermsService.getTermsCriteriaProvider();
    termsProviders.forEach(tp =>
      this.onLoaded({
        provider: tp
      })
    );
  }

  // This is needed because we need to find a dom element in the child component, and we need a callback to know when the child is linked.
  public onSearchBoxLinked() {
    this.searchInputField = this.$element.find("#search-input");
    this.$scope.$on(
      SearchEvents.SearchCleared,
      this.clearAndSetFocus.bind(this)
    );

    (horsey as Horsey)(this.$element.find("input")[0], {
      // suggestion fetching
      source: (data: any, done: any) =>
        this.loadSuggestionsForHorsey(data, done),

      // Suggestion selection
      predictNextSearch: (info: { selection: ISuggestion }) => {
        // get the suggestion selected by the user
        // perform provider action
        info.selection.provider.suggestionSelected(info.selection.item);

        this.searchText = "";
      },
      // Suggestion text mapping from data source
      getText: (suggestion: ISuggestion) => {
        return suggestion.item.title;
      },
      renderItem: (li: HTMLElement, suggestion: ISuggestion) => {
        const faIconClass = suggestion.provider.config.icon;
        const bgColor = suggestion.provider.config.color;
        const fullTitle =
          suggestion.item.payload && suggestion.item.payload.fullTitle
            ? suggestion.item.payload.fullTitle
            : "";

        $(li)
          .append(
            `<div class="title" title="${fullTitle}"><i class="fa ${faIconClass} info-icon"></i> 
              <span>${suggestion.item.title}</span>
            </div>`
          )
          .css({ "border-color": bgColor });
      },
      renderCategory: (
        div: HTMLElement,
        data: {
          id: string;
          provider: ISearchSuggestionsProvider;
          list: ISuggestion[];
        }
      ) => {
        const translation = this.$translate.instant(data.id);
        if (translation === "") {
          console.error(
            `Translation missing for search assistant category, key: ${data.id}`
          );
        }

        div.innerHTML = `
                <div class="header">
                    <span>${translation}</span>
                </div>`;
      },
      debounce: 200,
      cache: false
    });
  }

  private loadSuggestionsForHorsey(
    inputData: any,
    done: (error: any, result: any) => void
  ) {
    if (inputData.input.length < this.minimumQueryLength) {
      return;
    }

    this.isLoading = true;

    this.searchAssistantService.loadSuggestions(
      inputData.input,
      groupedResult => {
        this.isLoading = false;
        const result = groupedResult.map(group => {
          return {
            id: group.provider.config.title,
            list: group.result.map(suggestion => {
              return {
                item: suggestion,
                provider: group.provider
              }; // link a search suggestion with the provider that found it
            }),
            provider: group.provider
          };
        });
        done(null, result);
      }
    );
  }

  private clearAndSetFocus = () => {
    this.searchInputField.focus();
    this.searchInputField.val("");
  };

  public async searchForTerms() {
    if (
      this.currentCriterias.length > 0 ||
      (this.currentCriterias.length === 0 &&
        this.searchText &&
        this.searchText !== "")
    ) {
      this.onCriteriaChanged({
        action: {
          changes: (
            await this.searchTermsService.buildTermsCriteria(this.searchText)
          ).map(criteria => ({ criteria, type: CriteriaChangeType.Add }))
        }
      });
    } else if (
      this.currentCriterias.length === 0 &&
      (this.searchText === undefined || this.searchText === "")
    ) {
      this.onCriteriaChanged({
        action: {
          changes: (await this.searchTermsService.buildTermsCriteria("*")).map(
            criteria => ({ criteria, type: CriteriaChangeType.Add })
          )
        }
      });
    }
    this.searchText = "";
  }
}

angular
  .module("PortalApp")
  .controller(
    "Rhinestone.SearchAssistentController",
    SearchAssistentController
  );
