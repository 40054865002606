import * as angular from "angular";
import { BaseComponent } from "../../Core/BaseComponent";
import { Binding } from "../../Utils/AngularBindings";
import "./searchResultAbstract.less";
import "./searchResultAbstract.controller";
import { Criteria } from "@rhinestone/portal-web-react";
import "./searchResultAbstract.html";
export class SearchResultAbstractComponent extends BaseComponent {
  constructor() {
    super(
      "Rhinestone.SearchResultAbstractController",
      "/templates/Search/SearchResultAbstract/searchResultAbstract.html"
    );
    this.bindings = {
      valueToDisplay: Binding.oneWay,
      documentType: Binding.oneWay,
      criteria: Binding.oneWay
    };
  }
}

angular
  .module("PortalApp")
  .component("rhSearchResultAbstract", new SearchResultAbstractComponent());
