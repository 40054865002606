import * as angular from "angular";
import { IPortalConfig } from "../../Core/portal.provider";
import { SearchTileDto } from "../TilesTypes";
import { ICurrentUserService } from "../../User/currentUser.service";
import { SearchService } from "../../Search/search.service";
import { SearchPageConfigurationService } from "../../Search/searchPageConfigurationService";
import {
  Criteria,
  getProductCriteriaFromProductCode,
  encodeSearchRequest,
  SearchRequestClient
} from "@rhinestone/portal-web-react";
import { SearchTermsService } from "../../Search/SearchAssistent/searchTerms.service";

export class SearchTileController {
  public searchText = "";
  public isInitialized: boolean = false;
  public tile: SearchTileDto;
  public imageUrl: string;
  public static $inject = [
    "$location",
    "$timeout",
    "$routeParams",
    "currentUserService",
    "portal",
    "searchService",
    "searchPageConfigurationService",
    "searchTermsService"
  ];

  constructor(
    private $location: angular.ILocationService,
    private $timeout: angular.ITimeoutService,
    private $routeParams: any,
    private currentUser: ICurrentUserService,
    private portal: IPortalConfig,
    private searchService: SearchService,
    private searchPageConfigurationService: SearchPageConfigurationService,
    private searchTermsService: SearchTermsService
  ) {}
  public $onInit() {
    if (this.tile.imagePath) {
      this.imageUrl = `/api/Portals(${this.portal.identifier})${this.tile.imagePath}`;
    }
    this.isInitialized = true;
  }

  // provides information if the tile is displayed in the context of a product - product page and on which one.
  // it's is depended on the router config
  // this may be extracted later to some service giving information about wider location context.
  public getProductFromLocationContext(): string {
    return this.$routeParams.productCode;
  }

  public checkFlag(value: number, flag: number): boolean {
    // Bitwise & to check enum flags.
    // This is disallowed by eslint as it's a very rare use-case
    // and mostly enabled to prevent mistype of "&&" with "&".
    return (value & flag) > 0;
  }

  public async navigateToSearchPageWithTerms() {
    const productCode = this.getProductFromLocationContext();
    const productCriteria = getProductCriteriaFromProductCode(productCode);
    const searchTermCriteria = await this.searchTermsService.buildTermsCriteria(
      this.searchText
    );

    const searchRequest = await this.createSearchRequest([
      ...productCriteria,
      ...searchTermCriteria
    ]);

    const searchQueryString = encodeSearchRequest(searchRequest);

    // Wrap in timeout to inform angular async function is doing something with state
    this.$timeout(() => {
      this.$location.url(`/Soeg?search=${searchQueryString}`);
    });
  }

  private async createSearchRequest(criteria: Criteria[]) {
    const [defaultSearchView, defaultCriteria] = await Promise.all([
      this.searchService.getDefaultSearchView(),
      this.searchPageConfigurationService.getDefaultCriteriaConfiguration()
    ]);

    const searchRequestClient: SearchRequestClient = {
      fieldSetName: defaultSearchView.documentFieldSetName,
      criteria: [...defaultCriteria, ...criteria],
      ordering: null,
      skip: 0,
      page: 1,
      take: defaultSearchView.defaultPageSize,
      resultViewName: defaultSearchView.name
    };

    return searchRequestClient;
  }
}

angular
  .module("PortalApp")
  .controller("Rhinestone.SearchTileController", SearchTileController);
