import * as angular from "angular";
import { IErrorService } from "../Layout/error.service";
import { IMobileDetectorService } from "../Services/mobileDetector.service";
import { ProductPageService } from "./productPage.service";
import { ProductPageDto, ProductModel } from "@rhinestone/portal-web-api";

export class ProductPageViewController {
  public taxonomyName: string;
  public productCode: string;
  public product: ProductModel;
  public productPage: ProductPageDto;
  public isMobile: boolean;
  public isTaxonTiles: boolean;
  public static $inject = [
    "$scope",
    "$location",
    "$routeParams",
    "errorService",
    "mobileDetectorService",
    "productPageService"
  ];

  constructor(
    private $scope: angular.IScope,
    private $location: angular.ILocationService,
    private $routeParams: any,
    private errorService: IErrorService,
    private mobileDetectorService: IMobileDetectorService,
    private productPageService: ProductPageService
  ) {
    this.productCode = this.$routeParams.productCode;
  }

  public async $onInit() {
    this.isMobile = this.mobileDetectorService.isMobile();
    this.isTaxonTiles = false;
    try {
      const [product, productPage] = await Promise.all([
        this.productPageService.getProduct(this.productCode),
        this.productPageService.getProductPageConfiguration(this.productCode)
      ]);
      this.product = product;
      this.isTaxonTiles = productPage.navigation.showGraphicalNavigation;
      this.productPage = productPage;
      this.taxonomyName = product.productNavigationTaxonomyName;
      this.$scope.$apply();
    } catch (response) {
      if (response.status === 404) {
        this.$location.url("/NotFound");
      } else if (response.status === 401 || response.status === 403) {
        this.$location.url("/Unauthorized");
      }
    }
  }
}

angular
  .module("PortalApp")
  .controller(
    "Rhinestone.ProductPageViewController",
    ProductPageViewController
  );
