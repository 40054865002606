import "./internal.html";
import { Constants } from "../app.constants";
import { BaseComponent } from "../Core/BaseComponent";
import * as angular from "angular";
import "./internal.controller";
export class InternalComponent extends BaseComponent {
  constructor() {
    super(
      "Rhinestone.InternalController",
      `${Constants.TemplateBaseUrl}/Error/internal`
    );
  }
}

angular.module("PortalApp").component("rhInternal", new InternalComponent());
