import * as angular from "angular";

export class ErrorController {
  public message: string;
  public static $inject: any[] = [];

  constructor() {}
  public $onInit() {}
}

angular
  .module("PortalApp")
  .controller("Rhinestone.ErrorController", ErrorController);
