import { LayoutController } from "../layout.controller";
import * as angular from "angular";
export class MainContentController {
  private layout: LayoutController;

  public static $inject: any[] = [];

  constructor() {}
}

angular
  .module("rhSidePane")
  .controller("Rhinestone.MainContentController", MainContentController);
