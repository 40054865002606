import "./documentTile.html";
import { BaseComponent } from "../../Core/BaseComponent";
import * as angular from "angular";
import { Binding } from "../../Utils/AngularBindings";
import "./documentTile.less";
import "./documentTile.controller";
export class DocumentTileComponent extends BaseComponent {
  constructor() {
    super(
      "Rhinestone.DocumentTileController",
      "/templates/tiles/documentTile/documentTile.html"
    );
    this.bindings = {
      tile: Binding.oneWay
    };
  }
}

angular
  .module("PortalApp")
  .component("rhDocumentTile", new DocumentTileComponent());
