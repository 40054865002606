import { IPortalProvider } from "./portal.provider";
import * as angular from "angular";
import { SpaConfigDto } from "@rhinestone/portal-web-api";

export class PortalConfig {
  public static $inject = ["portalProvider", "rhinestoneConfig"];

  constructor(portalProvider: IPortalProvider, rhinestoneConfig: SpaConfigDto) {
    portalProvider.configure(rhinestoneConfig);
  }
}

angular.module("PortalApp").config(PortalConfig);
