import { AssetsService } from "./assets.service";
import * as angular from "angular";
import { DocumentModel } from "../Models/DocumentModel";
import { CreateAssetCollectionRequest } from "../Models/CreateAssetCollectionRequest";
import { ILocalizedNotificationService } from "../Services/localized.notification.service";
import "./newCollectionDialog.html";

export class NewCollectionDialogController {
  public static $inject = [
    "assetsService",
    "$uibModalInstance",
    "localizedNotificationService",
    "documents"
  ];

  public title = "";
  public referenceNumber = "";
  public isBusy: boolean = false;

  public regexValidFilename = '[^\\\\/:*?"<>|]+';
  public titleMaxLength = 80;

  public get documentCount(): number {
    return this.documents.length;
  }

  constructor(
    private assetsService: AssetsService,
    private $uibModalInstance: angular.ui.bootstrap.IModalServiceInstance,
    private localizedNotificationService: ILocalizedNotificationService,
    private documents: DocumentModel[]
  ) {}

  public create(): void {
    this.isBusy = true;
    const request = new CreateAssetCollectionRequest(
      this.title,
      this.referenceNumber,
      this.documents
    );
    this.assetsService.createCollection(request).then(() => {
      const translationKey =
        this.documents.length === 1
          ? "assets.doc_added_success"
          : "assets.docs_added_success";
      this.localizedNotificationService.success(translationKey, {
        title: this.title
      });
      this.$uibModalInstance.close();
    });
  }

  public cancel(): void {
    this.$uibModalInstance.dismiss("cancel");
  }
}

angular
  .module("PortalApp")
  .controller(
    "Rhinestone.NewCollectionDialogController",
    NewCollectionDialogController
  );
