import "./resultSorter.html";
import * as angular from "angular";
import { BaseComponent } from "../../Core/BaseComponent";
import { Binding } from "../../Utils/AngularBindings";
import "./resultSorter.less";
import "./resultSorter.controller";
export class ResultSorterComponent extends BaseComponent {
  constructor() {
    super(
      "Rhinestone.ResultSorterController",
      "/templates/Search/ResultSorter/resultSorter.html"
    );
    this.bindings = {
      result: Binding.oneWay,
      onUpdateCurrentResult: Binding.outputEvent,
      resultView: Binding.oneWay
    };
  }
}

angular
  .module("PortalApp")
  .component("rhResultSorter", new ResultSorterComponent());
