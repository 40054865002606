import "./unsubscribeSubscriptionsView.html";
import { BaseComponent } from "../Core/BaseComponent";
import * as angular from "angular";
import "./unsubscribeSubscriptionsView.controller";
export class UnsubscribeSubscriptionsViewComponent extends BaseComponent {
  constructor() {
    super(
      "Rhinestone.UnsubscribeSubscriptionsViewController",
      "/templates/Subscriptions/unsubscribeSubscriptionsView"
    );
  }
}

angular
  .module("PortalApp")
  .component(
    "rhUnsubscribeSubscriptionsView",
    new UnsubscribeSubscriptionsViewComponent()
  );
