import * as angular from "angular";
import { SearchViewType } from "@rhinestone/portal-web-api";

export class ViewSwitchController {
  /* Input bindings */
  public views: SearchViewType[];
  public currentView: SearchViewType;

  /* Output bindings */
  public onViewChanged: (outputParams: { view: SearchViewType }) => void;

  public changeView(view: SearchViewType) {
    this.onViewChanged({ view });
  }

  public static $inject: any[] = [];
}
angular
  .module("PortalApp")
  .controller("Rhinestone.ViewSwitchController", ViewSwitchController);
