import {
  Criteria,
  CriteriaChange,
  CriteriaChangeType
} from "@rhinestone/portal-web-react";
import * as _ from "underscore";
import { ProductDropdownModel } from "@rhinestone/portal-web-api";
import { SimpleChange } from "../../../TypeDefinitions/angularextensions";
import { SelectProductSearchFilterBase } from "./selectProductSearchFilterBase";
import { ICurrentUserService } from "../../User/currentUser.service";
import * as angular from "angular";

export class MultiSelectProductSearchFilterController extends SelectProductSearchFilterBase {
  public selectedProducts: ProductDropdownModel[] = [];

  public ownedProducts: ProductDropdownModel[] = [];
  public unownedProducts: ProductDropdownModel[] = [];

  public static $inject = ["currentUserService"];

  constructor(public currentUserService: ICurrentUserService) {
    super();
    currentUserService.getUser().then(user => {
      this.ownedProducts = this.products.filter(
        x =>
          x.ownerOrganizationIdentifier.toLowerCase() ===
          user.organizationIdentifier.toLowerCase()
      );
      this.unownedProducts = this.products.filter(
        x => !this.ownedProducts.includes(x)
      );
    });
  }

  public $onChanges(changes: {
    criteria?: SimpleChange<Criteria[]>;
    products?: SimpleChange<ProductDropdownModel[]>;
  }) {
    if (changes.products) {
      this.selectProducts();
    }

    if (changes.criteria && this.criteriaChanged(changes.criteria)) {
      this.selectProducts();
    }
  }

  // Called when multi-select is changed by user-input.
  // Consider changing this to fire only when the select-menu's on-close
  // event instead of live-updating when changing products to save requests.
  public updateSelectedProducts() {
    const action: CriteriaChange = { changes: [] };
    const previouslySelectedProducts = this.getSelectedProductsFromCriteria();

    // Products to remove is products that is in the previously selected products, but not in the currently selected ones
    previouslySelectedProducts
      .filter(product => this.selectedProducts.indexOf(product) === -1)
      .forEach(product => {
        action.changes.push(
          this.createChange(CriteriaChangeType.Remove, product.code)
        );
      });

    // Products to add is products that is in the currently selected products, but not in the previously selected
    this.selectedProducts
      .filter(product => previouslySelectedProducts.indexOf(product) === -1)
      .forEach(product => {
        action.changes.push(
          this.createChange(CriteriaChangeType.Add, product.code)
        );

        // We should also remove taxons from previous product when product is changed
        action.changes.push.apply(
          action.changes,
          this.getActionsForOrphanedTaxons(
            product.productNavigationTaxonomyName
          )
        );
      });

    if (action.changes.length > 0) {
      this.onCriteriaChanged({ action });
    }
  }

  private selectProducts(): void {
    this.selectedProducts = this.getSelectedProductsFromCriteria();
  }

  private getSelectedProductsFromCriteria() {
    const productCriteria = this.criteria.filter(
      c => c.providerKey === this.providerKey
    );
    return this.products.filter(p =>
      productCriteria.find(c => c.data === p.code)
    );
  }
}

angular
  .module("PortalApp")
  .controller(
    "Rhinestone.MultiSelectProductSearchFilterController",
    MultiSelectProductSearchFilterController
  );
