import { ISavedSearchQuery } from "./savedSearchQuery";
import { SavedSearchesService } from "./savedSearch.service";
import * as angular from "angular";
import { NewSavedSearchModalController } from "./newSavedSearchModal.controller";
import List = _.List;
import * as _ from "underscore";
import { ILocalizedNotificationService } from "../../Services/localized.notification.service";
import { QueryStringService } from "./../querystring.service";
import {
  SearchRequestClient,
  deserializeSearchRequest
} from "@rhinestone/portal-web-react";

export class SavedSearchesController {
  // Input bindings
  private loading: boolean;
  private queryParams: () => SearchRequestClient;

  public savedSearchQueries: List<ISavedSearchQuery>;
  private modalInstance: any;

  public static $inject = [
    "$uibModal",
    "queryStringService",
    "savedSearchesService",
    "localizedNotificationService"
  ];

  constructor(
    private $uibModal: any,
    private queryStringService: QueryStringService,
    private savedSearchesService: SavedSearchesService,
    private localizedNotificationService: ILocalizedNotificationService
  ) {}

  public $onInit() {
    this.getSavedSearches();
  }

  public applySavedSearch(searchQueryId: string): void {
    const savedSearch = _.find(this.savedSearchQueries, iterator => {
      return iterator.savedSearchQueryId === searchQueryId;
    });
    this.queryStringService.applyQueryString(savedSearch.queryStringParams);
  }

  public getSavedSearches(): void {
    this.savedSearchesService.getSavedSearches().then(
      (response: ISavedSearchQuery[]) => {
        this.savedSearchQueries = response;
      },
      () => {
        // TODO: Log or?
      }
    );
  }

  private saveSearchQuery(
    searchQuery: ISavedSearchQuery
  ): angular.IPromise<{}> {
    const queryString = this.queryStringService.getQueryStringAsJson();
    searchQuery.searchRequest = deserializeSearchRequest(queryString.search);
    searchQuery.queryStringParams = this.queryStringService.getQueryStringAsJsonString();
    return this.savedSearchesService.saveSearchQuery(searchQuery);
  }

  public openNewQueryDialog(): void {
    const modal = this.$uibModal.open({
      templateUrl: "/templates/search/savedSearchWidget/newSavedSearchDialog",
      controller: NewSavedSearchModalController,
      controllerAs: "vm",
      bindToController: true,
      resolve: {
        existingQueries: () => this.savedSearchQueries,
        searchRequest: () => this.queryParams()
      }
    });
    modal.result.then(() => {
      this.localizedNotificationService.success("search.add_success");
      this.getSavedSearches();
    });
  }

  public savedSearchHoverIn(item: ISavedSearchQuery): void {
    _.reject(this.savedSearchQueries, (iterator: ISavedSearchQuery) => {
      return iterator.savedSearchQueryId !== item.savedSearchQueryId;
    }).forEach((value, index, arr) => {
      value.showRemoveButton = false;
    });
    item.showRemoveButton = true;
  }
  public savedSearchHoverOut(item: ISavedSearchQuery): void {
    item.showRemoveButton = false;
  }
  public removeSearchQuery(item: ISavedSearchQuery): void {
    const modalInstance = this.$uibModal.open({
      templateUrl:
        "/templates/search/savedSearchWidget/deleteSavedSearchDialog",
      controller: SavedSearchesController,
      controllerAs: "vm",
      bindToController: true
    });
    modalInstance.result.then(
      () => {
        this.savedSearchesService.deleteSavedSearchQuery(item).then(
          () => {
            this.localizedNotificationService.success("search.remove_success");
            // Clear from list
            this.savedSearchQueries = _.without(
              this.savedSearchQueries,
              _.findWhere(this.savedSearchQueries, {
                savedSearchQueryId: item.savedSearchQueryId
              })
            );
          },
          () => {
            this.localizedNotificationService.error("search.remove_error");
          }
        );
      },
      () => {
        // Do nothing on dismiss
      }
    );
  }

  public updateQuery(searchQuery: ISavedSearchQuery): void {
    // No need to set a ModalInstanceCtrl for confirm/cancel
    this.modalInstance = this.$uibModal.open({
      templateUrl:
        "/templates/search/savedSearchWidget/overwriteSavedSearchDialog",
      controller: SavedSearchesController,
      controllerAs: "vm",
      bindToController: true
    });
    this.modalInstance.result.then(
      () => {
        this.saveSearchQuery(searchQuery).then(
          () => {
            this.localizedNotificationService.success("search.update_success");
          },
          () => {
            this.localizedNotificationService.error("search.update_error");
          }
        );
      },
      () => {
        // do nothing on dismiss
      }
    );
  }
}

angular
  .module("PortalApp")
  .controller("Rhinestone.SavedSearchesController", SavedSearchesController);
