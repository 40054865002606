import "./mainPage.html";
import { BaseComponent } from "../Core/BaseComponent";
import * as angular from "angular";
import "./mainPage.less";
import "./mainPage.controller";
import "./mainPage.service";
import "../Tiles/TilesLayout/tilesLayout.component";

export class MainPageViewComponent extends BaseComponent {
  constructor() {
    super(
      "Rhinestone.MainPageViewController",
      "/templates/mainpage/mainpage.html"
    );
  }
}

angular
  .module("PortalApp")
  .component("rhMainPageView", new MainPageViewComponent());
