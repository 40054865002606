import * as _ from "underscore";
import { Taxonomy } from "./../Services/models/taxonomy";
import { Taxon } from "../Services/models/taxon";

export class TaxonomyToFlatTaxonListConverter {
  public buildTaxonsFlatList(taxonomy: Taxonomy): Taxon[] {
    const virtualTaxons: Taxon[] = [];
    const virtualClone: Taxonomy = deepCloneTaxonomy(taxonomy);

    _.forEach(virtualClone.children, x =>
      this.decoupleChildren(x, virtualTaxons)
    );
    return _.sortBy(virtualTaxons, "key");
  }

  // Create deep clone of taxonomy and decouple children for Treeview performance optimization
  // children will be lazyloaded based on key map
  public toFlatTaxonList(taxonomy: Taxonomy): Taxon[] {
    let virtualTaxons: Taxon[] = [];

    taxonomy.children.forEach(x => this.decoupleChildren(x, virtualTaxons));

    // Ensure sorted flat virtual taxon list for later search match count
    virtualTaxons = _.sortBy(virtualTaxons, "key");
    return virtualTaxons;
  }

  private decoupleChildren(taxon: Taxon, virtualTaxons: Taxon[]) {
    // First recurse
    _.forEach(taxon.children, x => this.decoupleChildren(x, virtualTaxons));

    // Map child state
    taxon.hasChildren = taxon.children.length > 0;

    // Save to global flat list for later retrieval
    virtualTaxons.push(taxon);

    // Decouple children
    taxon.children = [];
  }
}

function deepCloneTaxonomy(taxonomy: Taxonomy): Taxonomy {
  return {
    name: taxonomy.name,
    children: taxonomy.children.map(deepCloneTaxon)
  };
}

function deepCloneTaxon(taxon: Taxon): Taxon {
  const clonedTaxon = {
    ...taxon,
    path: [...taxon.path],
    children: taxon.children.map(deepCloneTaxon)
  };

  // Update child-parent reference
  clonedTaxon.children.map(childTaxon => ({
    ...childTaxon,
    parent: clonedTaxon
  }));

  return clonedTaxon;
}
