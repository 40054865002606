import "./documentListTile.html";
import { BaseComponent } from "../../Core/BaseComponent";
import * as angular from "angular";
import { Binding } from "../../Utils/AngularBindings";
import "./documentListTile.less";
import "./documentListTile.controller";
import "./documentListTile.service";
import "./SearchResultList.html";
export class DocumentListTileComponent extends BaseComponent {
  constructor() {
    super(
      "Rhinestone.DocumentListTileController",
      "/templates/tiles/documentListTile/documentListTile.html"
    );
    this.bindings = {
      tile: Binding.oneWay
    };
  }
}

angular
  .module("PortalApp")
  .component("rhDocumentListTile", new DocumentListTileComponent());
