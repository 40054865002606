import "./pagination.html";
import { BaseComponent } from "../../Core/BaseComponent";
import * as angular from "angular";
import { Binding } from "../../Utils/AngularBindings";
import "./pagination.less";
import "./pagination.controller";
export class PaginationComponent extends BaseComponent {
  constructor() {
    super(
      "Rhinestone.PaginationController",
      "/templates/Search/Pagination/pagination.html"
    );
    this.bindings = {
      loading: Binding.oneWay,
      onChangePage: Binding.outputEvent,
      currentPage: Binding.oneWay,
      totalItems: Binding.oneWay,
      pageSize: Binding.oneWay
    };
  }
}

angular
  .module("PortalApp")
  .component("rhPagination", new PaginationComponent());
