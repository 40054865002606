import * as angular from "angular";
import { TileDto } from "@rhinestone/portal-web-api";

export class TilesLayoutController {
  public tiles: TileDto[];
  public static $inject = ["$translate"];

  constructor(private $translate: angular.translate.ITranslateService) {}

  public currentLocaleMatchesTileLocale(
    tileSupportedLocales: string[]
  ): boolean {
    const currentLocale = this.$translate.use();
    return tileSupportedLocales.indexOf(currentLocale) > -1;
  }
}

angular
  .module("PortalApp")
  .controller("Rhinestone.TilesLayoutController", TilesLayoutController);
