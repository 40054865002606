import * as angular from "angular";

export interface IMobileMenu {
  hideMenu(): void;
  showMenu(): void;
  menuName: string;
}

export class MobileMenuService {
  private mobileMenus: IMobileMenu[] = [];

  public static $inject: string[] = [];
  public registerMobileMenu(menu: IMobileMenu): void {
    this.mobileMenus.push(menu);
  }

  public unregisterMobileMenu(menu: IMobileMenu): void {
    const i = this.mobileMenus.indexOf(menu);
    if (i > -1) {
      this.mobileMenus.splice(i, 1);
    }
  }

  public hideAllMenus(): void {
    this.mobileMenus.forEach(x => x.hideMenu());
  }

  public showMenu(menuName: "navbar" | "mobileMenu"): void {
    this.mobileMenus
      .filter(x => x.menuName === menuName)
      .forEach(x => x.showMenu());
  }

  public willShowMenu(menu: IMobileMenu): void {
    this.mobileMenus.filter(x => x !== menu).forEach(x => x.hideMenu());
  }
}

angular.module("PortalApp").service("mobileMenuService", MobileMenuService);
