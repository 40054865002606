import * as angular from "angular";

export class LogService {
  public static $inject = ["$logProvider"];

  constructor($logProvider: angular.ILogProvider) {
    $logProvider.debugEnabled(false);
  }
}

angular.module("PortalApp").config(LogService);
