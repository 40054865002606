import {
  PortalLocalizationModel,
  ProductDropdownModel,
  ProductModel
} from "@rhinestone/portal-web-api";
import { Feature } from "@rhinestone/portal-web-react";
import * as angular from "angular";
import * as _ from "underscore";

import { IPortalConfig } from "../../Core/portal.provider";
import { ILocalizationService } from "../../Localization/localization.service";
import { SearchEvents } from "../../Search/search.events";
import { ILocalizedNotificationService } from "../../Services/localized.notification.service";
import { IMobileDetectorService } from "../../Services/mobileDetector.service";
import { IProductsService } from "../../Services/products.service";
import { ICurrentUserService } from "../../User/currentUser.service";
import { UserFeaturesService } from "../../User/userFeatures.service";
import {
  IMobileMenu,
  MobileMenuService
} from "../MobileMenu/mobile-menu.service";
import { NavbarHeaderService } from "./navBarHeaderService";
import { IFeatureFlagsService } from "../../Services/featureflags.service";

export class NavbarController implements IMobileMenu {
  public portalId: string;
  public portalTitle: string;
  public organizationIdentifier: string;

  public products: ProductDropdownModel[] = [];
  public ownedProducts: ProductDropdownModel[] = [];
  public unownedProducts: ProductDropdownModel[] = [];

  public singleProductPortalProduct: ProductModel;

  public localizations: PortalLocalizationModel[];

  private documentTitle: string;
  private documentTitleDisposable: Rx.IDisposable;
  public isMobile: boolean;
  public hasAiAssistantFeature: boolean;

  private routeChangeListener: () => void;

  public static $inject = [
    "$location",
    "$q",
    "$rootScope",
    "$translate",
    "$scope",
    "currentUserService",
    "localizationService",
    "localizedNotificationService",
    "portal",
    "productsService",
    "amMoment",
    "$element",
    "mobileMenuService",
    "mobileDetectorService",
    "navbarHeaderService",
    "userFeaturesService",
    "featureFlagsService"
  ];

  constructor(
    private $location: angular.ILocationService,
    private $q: angular.IQService,
    private $rootScope: angular.IRootScopeService & { locale: string },
    private $translate: angular.translate.ITranslateService,
    private $scope: angular.IScope,
    private userService: ICurrentUserService,
    private localizationService: ILocalizationService,
    private localizedNotificationService: ILocalizedNotificationService,
    private portal: IPortalConfig,
    private productsService: IProductsService,
    private amMoment: any,
    private $element: JQuery,
    private mobileMenuService: MobileMenuService,
    private mobileDetectorService: IMobileDetectorService,
    private navbarHeaderService: NavbarHeaderService,
    private userFeaturesService: UserFeaturesService,
    private featureFlagsService: IFeatureFlagsService
  ) {}

  public $onInit() {
    this.portalId = this.portal.identifier;
    this.portalTitle = this.portal.title;
    this.isMobile = this.mobileDetectorService.isMobile();

    this.getAiAssistantFeature();
    this.getUserProducts();
    this.getLocalizations().then(() => {
      this.registerRootScopeLocale();
    });

    this.userService
      .getOrganizationIdentifier()
      .then(
        organizationIdentifier =>
          (this.organizationIdentifier = organizationIdentifier)
      );

    this.mobileMenuService.registerMobileMenu(this);
  }

  public $postLink() {
    this.$element.find(".navbar").on("show.bs.collapse", () => {
      this.mobileMenuService.willShowMenu(this);
      this.$scope.$apply();
    });

    this.$element.find(".navbar").on("click", "a.menu-item", () => {
      this.toggleMenu("hide");
    });

    this.documentTitleDisposable = this.navbarHeaderService.title
      .safeApply(this.$scope, value => {
        this.documentTitle = value;
      })
      .subscribe();
  }

  public hideMenu(): void {
    this.toggleMenu("hide");
  }

  public showMenu(): void {
    this.toggleMenu("show");
  }

  public menuName: string = "navbar";

  public $onDestroy() {
    this.routeChangeListener();
    this.mobileMenuService.unregisterMobileMenu(this);
    this.documentTitleDisposable.dispose();
  }

  public isAuthenticated(): boolean {
    return this.userService.isAuthenticated;
  }

  public showProductsMenu() {
    return (
      this.isAuthenticated() &&
      this.showNavElement("products") &&
      !this.singleProductPortalProduct
    );
  }

  public selectedLocalizationChanged(locale: string): void {
    this.$rootScope.locale = locale;
    this.$translate.use(locale);
    // Set the portal language to the moment component
    this.amMoment.changeLocale(locale);
  }

  public getReturnUrl(): string {
    const currentPath = this.$location.url();
    return currentPath === "/SignedOut" ? "" : currentPath;
  }

  private toggleMenu(option: "show" | "hide") {
    this.$element.find("#nav-menu").collapse(option);
  }

  private getLocalizations(): angular.IPromise<{}> {
    this.localizations = [];

    return this.$q(resolve => {
      this.localizationService.getLocalizations().then(localizations => {
        this.$rootScope.locale = this.$translate.use();
        this.localizations = localizations;
        resolve.apply({});
      });
    });
  }

  private registerRootScopeLocale(): void {
    this.$rootScope.$on("$translateChangeSuccess", () => {
      this.$rootScope.locale = this.$translate.use();
    });
  }

  private getUserProducts(): void {
    if (!this.portal.isUserAuthenticatedOnServer) {
      return;
    }

    this.productsService
      .getUserProducts()
      .then(x => {
        this.products = x;
        this.ownedProducts = this.products.filter(
          x =>
            x.ownerOrganizationIdentifier.toLowerCase() ===
            this.organizationIdentifier
        );
        this.unownedProducts = this.products.filter(
          x => !this.ownedProducts.includes(x)
        );
      })
      .catch(() => {
        this.products = [];
      })
      .finally(() => {
        if (this.isAuthenticated() && this.products.length === 0) {
          this.localizedNotificationService.error("search.no_products");
        }
      });

    this.productsService
      .getSingleProductPortalProduct()
      .then(singleProductPortalProduct => {
        this.singleProductPortalProduct = singleProductPortalProduct;
      })
      .catch(err => (this.singleProductPortalProduct = null));
  }

  public getHelpUrl(): string {
    if (this.isAuthenticated()) {
      return this.portal.authenticatedHelpUrl;
    } else {
      return this.portal.anonymousHelpUrl;
    }
  }

  public resetSearch() {
    this.$rootScope.$broadcast(SearchEvents.ResetSearch, {});
  }

  private showNavElement(navElementName: string): boolean {
    return _.contains(this.portal.navbarElements, navElementName);
  }

  public hasAssetCollectionsFeature(): boolean {
    return this.userFeaturesService.hasFeature(Feature.AssetCollection);
  }

  private getAiAssistantFeature() {
    this.featureFlagsService.isEnabled("AiAssistant").then(isEnabled => {
      this.hasAiAssistantFeature = isEnabled;
    });
  }
}

angular
  .module("PortalApp")
  .controller("Rhinestone.NavbarController", NavbarController);
