import "./spinner.html";
import { BaseComponent } from "../Core/BaseComponent";
import * as angular from "angular";
import { Binding } from "../Utils/AngularBindings";
import "./spinner.controller";
export class SpinnerComponent extends BaseComponent {
  constructor() {
    super(null, "/templates/controls/spinner");

    this.bindings = {
      size: Binding.valueInput
    };
  }
}

angular.module("PortalApp").component("rhSpinner", new SpinnerComponent());
