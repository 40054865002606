import * as angular from "angular";
import { UserFeaturesService } from "../User/userFeatures.service";
import { Feature } from "@rhinestone/portal-web-react";
import { IAssetCollectionBase } from "./AssetCollectionBase";

export class AssetCollectionDetailController {
  // Component bindings
  private assetCollection: IAssetCollectionBase;

  public static $inject = ["userFeaturesService"];

  constructor(private userFeaturesService: UserFeaturesService) {}

  public $onInit() {}

  public hasAssetCollectionTemplateFeature = () => {
    return this.userFeaturesService.hasFeature(Feature.AssetCollectionTemplate);
  };

  public isDisabled = () => {
    return (
      !this.assetCollection.hasWriteAccess ||
      this.assetCollection.isArchived ||
      this.assetCollection.isTemplate
    );
  };
}

angular
  .module("PortalApp")
  .controller(
    "Rhinestone.AssetCollectionDetailController",
    AssetCollectionDetailController
  );
