import * as angular from "angular";
import { IPortalConfig } from "../../Core/portal.provider";
import { ProductModel } from "@rhinestone/portal-web-api";

export interface IProductTileService {
  getProduct(code: string): angular.IPromise<ProductModel>;
  getProductImageUrl(code: string): string;
}

export class ProductTileService implements IProductTileService {
  private baseUrl = "";

  // public Product: ProductTile[] = [];

  public static $inject = ["$http", "$timeout", "$q", "portal"];

  constructor(
    private $http: angular.IHttpService,
    private $timeout: angular.ITimeoutService,
    private $q: angular.IQService,
    private portal: IPortalConfig
  ) {
    this.baseUrl = `/api/Portals(${this.portal.identifier})`;
  }

  public getProduct(code: string): angular.IPromise<ProductModel> {
    const deffered = this.$q.defer<ProductModel>();

    this.$http
      .get(`${this.baseUrl}/Products/${code}`)
      .then(result => {
        deffered.resolve(result.data as ProductModel);
      })
      .catch(response => {
        deffered.reject(response);
      });

    return deffered.promise;
  }

  public getProductImageUrl(code: string): string {
    return `${this.baseUrl}/Products/${code}/Image`;
  }
}

angular.module("PortalApp").service("productTileService", ProductTileService);
