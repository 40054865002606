import {
  Criteria,
  CriteriaChange,
  CriteriaProvider
} from "@rhinestone/portal-web-react";
import { ICriteriaGroupController } from "../Criterias/ICriteriaGroupController";
import * as angular from "angular";
import * as _ from "underscore";
import { ProductDropdownModel } from "@rhinestone/portal-web-api";
import { SimpleChange } from "../../../TypeDefinitions/angularextensions";
import { buildProductCriteriaDescription } from "../Criterias/buildCriteriaDescriptionFunctions";

export class ProductSearchFilterController implements ICriteriaGroupController {
  // Bindings
  public readonly providerKey: string;
  public readonly icon: string;
  public readonly color: string;
  public readonly multiSelect: boolean = false;
  public onCriteriaChanged: (action: { action: CriteriaChange }) => void;
  public onLoaded: (param: { provider: CriteriaProvider }) => void;
  public criteria: ReadonlyArray<Criteria>;
  public readonly products: ProductDropdownModel[] = [];

  public static $inject: string[] = [];

  public handleCriteriaChanged(action: CriteriaChange): void {
    this.onCriteriaChanged({ action });
  }

  constructor() {}

  public $onChanges(changes: {
    products?: SimpleChange<ProductDropdownModel[]>;
  }) {
    // re-register criteria provider when products change, since they can be loaded later or changed during lifetime of component
    if (changes.products) {
      this.onLoaded({ provider: this.getCriteriaProvider() });
    }
  }

  private getCriteriaProvider(): CriteriaProvider {
    return {
      key: this.providerKey,
      getCriteriaViewModel: criteria => {
        if (criteria.providerKey !== this.providerKey) {
          return null;
        }
        let productTitle = "";
        const index = _.findIndex(this.products, x => x.code === criteria.data);
        if (index >= 0) {
          productTitle = this.products[index].title;
        }

        return {
          displayValue: productTitle,
          longDisplayValue: productTitle,
          displayValueResource: undefined,
          criteria,
          icon: this.icon,
          color: this.color
        };
      },
      buildCriteriaDescription: request =>
        buildProductCriteriaDescription(
          request,
          this.providerKey,
          this.criteria,
          this.products
        )
    };
  }
}

angular
  .module("PortalApp")
  .controller(
    "Rhinestone.ProductSearchFilterController",
    ProductSearchFilterController
  );
