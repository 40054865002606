import * as angular from "angular";
import { SelectionEvents } from "../Annotations/SelectionEvents";

export interface ISelectionStatusService {
  hide(): any;
  show(): any;
  activate(): any;
  deActivate(): any;
  toggle(): any;
}

export class SelectionStatusService implements ISelectionStatusService {
  public visible = true;
  public active = false;

  public static $inject = ["$rootScope"];

  constructor(private $rootScope: angular.IRootScopeService) {}

  public toggle() {
    this.active = !this.active;
    this.$rootScope.$broadcast(
      SelectionEvents.TrackSelectionChanged,
      this.active
    );
  }

  public activate() {
    this.active = true;
    this.$rootScope.$broadcast(
      SelectionEvents.TrackSelectionChanged,
      this.active
    );
  }

  public deActivate() {
    this.active = false;
    this.$rootScope.$broadcast(
      SelectionEvents.TrackSelectionChanged,
      this.active
    );
  }

  public hide() {
    this.visible = false;
  }

  public show() {
    this.visible = true;
  }
}

angular
  .module("PortalApp")
  .service("selectionStatusService", SelectionStatusService);
