import "./mobileTabs.html";
import * as angular from "angular";
import { BaseComponent } from "../../Core/BaseComponent";
import { Binding } from "../../Utils/AngularBindings";
import "./mobileTabs.less";
import "./mobileTabs.controller";
export class MobileTabsComponent extends BaseComponent {
  constructor() {
    super(
      "Rhinestone.MobileTabsController",
      "/templates/Search/MobileTabs/mobileTabs.html",
      true
    );
    this.bindings = {
      resultView: Binding.oneWay,
      onUpdateCurrentResult: Binding.outputEvent,
      result: Binding.oneWay
    };
  }
}

angular
  .module("PortalApp")
  .component("rhMobileTabs", new MobileTabsComponent());
