import "./newLocalDocumentTypeTaxonomyFilter.html";
import { BaseComponent } from "../../Core/BaseComponent";
import * as angular from "angular";
import { Binding } from "../../Utils/AngularBindings";
import "./newLocalDocumentTypeTaxonomyFilter.controller";
import "../LocalTaxonomy/localTaxonomy.component";
export class NewLocalDocumentTypeTaxonomyFilterComponent extends BaseComponent {
  constructor() {
    super(
      "Rhinestone.NewLocalDocumentTypeTaxonomyFilterController",
      "/templates/search/NewLocalDocumentTypeTaxonomyFilter/NewLocalDocumentTypeTaxonomyFilter"
    );
    this.bindings = {
      onCriteriaChanged: Binding.outputEvent,
      onLoaded: Binding.outputEvent,
      criteria: Binding.oneWay,
      products: Binding.oneWay,
      showEmptyTaxons: Binding.oneWay,
      showFacetCount: Binding.oneWay
    };
  }
}

angular
  .module("PortalApp")
  .component(
    "rhNewLocalDocumentTypeTaxonomyFilter",
    new NewLocalDocumentTypeTaxonomyFilterComponent()
  );
