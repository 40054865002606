export class SelectionEvents {
  public static ToggleSelectionRequest = "Selection.ToggleRequest";
  public static TrackSelectionChanged = "Selection.TrackingChanged";
  public static SelectionChanged = "Selection.Changed";
}

export class SelectedChangedEventArgs {
  constructor(
    public hasSelection: boolean,
    public selectionTop: number,
    public selectionRange: any
  ) {}
}
