import * as angular from "angular";

// Log service that wraps angulars default log service,
// and adds the possibility to log through ui notifications for use on mobile devices (mainly iPads)
export class Logger {
  public static $inject = ["$log"];

  constructor(private $log: angular.ILogService) {}

  public debug(message: string) {
    // this.notificationService.warning(message);
    this.$log.debug(message);
  }
}

angular.module("PortalApp").service("logger", Logger);
