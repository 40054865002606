import "./notFound.html";
import { Constants } from "../app.constants";
import { BaseComponent } from "../Core/BaseComponent";
import * as angular from "angular";
import "./notFound.controller";
export class NotFoundComponent extends BaseComponent {
  constructor() {
    super(
      "Rhinestone.NotFoundController",
      `${Constants.TemplateBaseUrl}/Error/notFound`
    );
  }
}

angular.module("PortalApp").component("rhNotFound", new NotFoundComponent());
