import { AssetsService } from "./assets.service";
import { IDialogService } from "../Services/dialog.service";
import { IAssetCollectionMember } from "../Models/assetCollectionMember";
import * as angular from "angular";
import { ILocalizedNotificationService } from "../Services/localized.notification.service";
import { AssetCollectionViewModel } from "@rhinestone/portal-web-api";

export class AssetCollectionMembersController {
  // Component bindings
  private assetCollection: AssetCollectionViewModel;

  private assetCollectionTitle: any;
  private hasFullAccess: boolean;
  public members: IAssetCollectionMember[];
  public isArchived: boolean;

  public static $inject = [
    "assetsService",
    "dialogService",
    "localizedNotificationService"
  ];

  constructor(
    private assetsService: AssetsService,
    private dialogService: IDialogService,
    private localizedNotificationService: ILocalizedNotificationService
  ) {}

  public $onInit() {
    this.assetCollectionTitle = this.assetCollection.title;
    this.isArchived = this.assetCollection.isArchived;
    this.hasFullAccess = this.assetCollection.hasSecurityAccess;
    this.getMembers();
  }

  public addMembers = () => {
    const modalInstance =
      this.dialogService.openAddMemberToAssetCollectionDialog(
        this.assetCollection.assetCollectionId,
        this.members
      );
    modalInstance.result.then(newUsers => {
      const translationId =
        newUsers.length === 1 ? "assets.user_added" : "assets.users_added";
      this.localizedNotificationService.success(translationId, {
        number: newUsers.length
      });
      this.getMembers();
    });
  };

  public removeMember = (person: IAssetCollectionMember) => {
    this.assetsService
      .removeMemberFromCollection(
        this.assetCollection.assetCollectionId,
        person.Id
      )
      .then(() => {
        this.localizedNotificationService.success("assets.user_removed", {
          userName: person.DisplayName,
          assetName: this.assetCollectionTitle
        });
        this.getMembers();
      });
  };

  public canRemoveUser = (person: IAssetCollectionMember) => {
    return this.hasFullAccess && !person.IsCurrentUser;
  };

  public canAddUser = () => {
    return this.hasFullAccess && !this.isArchived;
  };

  private getMembers = () => {
    this.assetsService
      .getCollectionMembers(this.assetCollection.assetCollectionId)
      .then((data: IAssetCollectionMember[]) => {
        this.members = data;
      });
  };
}

angular
  .module("PortalApp")
  .controller(
    "Rhinestone.AssetCollectionMembersController",
    AssetCollectionMembersController
  );
