import "./searchAssistent.html";
import { BaseComponent } from "../../Core/BaseComponent";
import * as angular from "angular";
import { Binding } from "../../Utils/AngularBindings";
import "./searchAssistent.less";
import "./searchAssistent.controller";
import "./searchAssistant.service";
import "./searchTerms.service";
import "./Providers/SearchSuggestionsProviderFactory";
import "../SearchBox/searchBox.component";

export class SearchAssistentComponent extends BaseComponent {
  constructor() {
    super(
      "Rhinestone.SearchAssistentController",
      "/templates/Search/SearchAssistent/searchAssistent.html"
    );
    this.bindings = {
      currentCriterias: Binding.oneWay,
      onCriteriaChanged: Binding.outputEvent,
      onLoaded: Binding.outputEvent
    };
  }
}

angular
  .module("PortalApp")
  .component("rhSearchAssistent", new SearchAssistentComponent());
