import { IPortalConfig } from "../Core/portal.provider";
import * as angular from "angular";
import { UserViewModel } from "@rhinestone/portal-web-api";

export interface IUserService {
  getUsers(prefix: string, maxUsers?: number): Promise<UserViewModel[]>;
}

export class UserService implements IUserService {
  public static $inject = ["$http", "portal"];
  constructor(
    private $http: angular.IHttpService,
    private portal: IPortalConfig
  ) {}

  public async getUsers(
    prefix: string,
    maxUsers?: number
  ): Promise<UserViewModel[]> {
    const url = `/api/Portals(${
      this.portal.identifier
    })/users?q=${encodeURIComponent(prefix)}${
      typeof maxUsers === "number" ? `&take=${maxUsers}` : ""
    }`;

    return this.$http
      .get(url)
      .then(
        (response: angular.IHttpPromiseCallbackArg<UserViewModel[]>) =>
          response.data
      );
  }
}

angular.module("PortalApp").service("userService", UserService);
