import "./quickLinks.html";
import * as angular from "angular";
import { BaseComponent } from "../../Core/BaseComponent";
import { Binding } from "../../Utils/AngularBindings";
import "./quickLinks.less";
import "./quickLinks.controller";
export class QuickLinksComponent extends BaseComponent {
  constructor() {
    super(
      "Rhinestone.QuickLinksController",
      "/templates/Layout/QuickLinks/quickLinks.html",
      true
    );
    this.bindings = {
      documentContext: Binding.oneWay
    };
  }
}

angular
  .module("PortalApp")
  .component("rhQuickLinks", new QuickLinksComponent());
