import * as angular from "angular";
import {
  PortalConfigInfo,
  RhinestoneTheme
} from "@rhinestone/portal-web-react";
import { SpaConfigDto } from "@rhinestone/portal-web-api";

// The configuration object loaded in Angular application client side
export interface IPortalConfig extends PortalConfigInfo {
  identifier: string;
  title: string;
  displayName: string;
  authenticatedHelpUrl: string;
  anonymousHelpUrl: string;
  profileUrl: string;
  resetPasswordUrl: string;
  anchorScrollYOffset: number;
  defaultLanguage: string;
  isUserAuthenticatedOnServer: boolean;
  navbarElements: string[];
  showLogInBox: boolean;
  searchResultsPdfExportMaxResults: number;
  allowAnonymousAccess: boolean;
  bypassFrontPageAfterLogin: boolean;
  useAdvancedSendLinkForm: boolean;
  documentTicketAccessEnabled: boolean;
  enableNewDesign: boolean;
  angularMaterialPrimaryColor: string;
}

export class PortalConfig implements IPortalConfig {
  constructor(
    public identifier: string,
    public title: string,
    public displayName: string,
    public authenticatedHelpUrl: string,
    public anonymousHelpUrl: string,
    public profileUrl: string,
    public resetPasswordUrl: string,
    public anchorScrollYOffset: number,
    public defaultLanguage: string,
    public isUserAuthenticatedOnServer: boolean,
    public navbarElements: string[],
    public showLogInBox: boolean,
    public searchResultsPdfExportMaxResults: number,
    public allowAnonymousAccess: boolean,
    public bypassFrontPageAfterLogin: boolean,
    public useAdvancedSendLinkForm: boolean,
    public documentTicketAccessEnabled: boolean,
    public rhinestoneTheme: RhinestoneTheme,
    public enableNewDesign: boolean,
    public angularMaterialPrimaryColor: string
  ) {}
}
export interface IPortalProvider {
  configure(configInfo: SpaConfigDto): void;
}

export class PortalProvider
  implements angular.IServiceProvider, IPortalProvider {
  public config: IPortalConfig;

  public configure(configInfo: SpaConfigDto): void {
    this.config = new PortalConfig(
      configInfo.identifier,
      configInfo.title,
      configInfo.displayName,
      configInfo.authenticatedHelpUrl,
      configInfo.anonymousHelpUrl,
      configInfo.profileUrl,
      configInfo.resetPasswordUrl,
      configInfo.anchorScrollYOffset,
      configInfo.defaultLanguage,
      configInfo.isAuthenticated,
      // This parsing is pretty weird, but its legacy when porting from razor views to static html
      // In the backend, the navbarElements are saved as string that would become a javascript array
      // syntax: "['menu1', 'menu2', 'menu3']"
      configInfo.navbarElements.replace(/\[|\]|\s*'/g, "").split(","),
      configInfo.showLogInBox,
      configInfo.searchResultsPdfExportMaxResults,
      configInfo.allowAnonymousAccess,
      configInfo.bypassFrontPageAfterLogin,
      configInfo.useAdvancedSendLinkForm,
      configInfo.documentTicketAccessEnabled,
      configInfo.rhinestoneTheme,
      configInfo.enableNewDesign,
      configInfo.angularMaterialPrimaryColor
    );
  }

  public $get(): IPortalConfig {
    return this.config;
  }
}

angular.module("PortalApp").provider("portal", PortalProvider);
