import * as angular from "angular";

export class PaginationController {
  public static $inject = ["$location", "searchService"];

  // Input bindings
  public onChangePage: ({ page }: any) => void;
  public currentPage: number;
  public pageSize: number;
  public totalItems: number;

  public $onChanges(changes: any) {}
}
angular
  .module("PortalApp")
  .controller("Rhinestone.PaginationController", PaginationController);
