import * as angular from "angular";
import { IDocumentListTileService } from "./documentListTile.service";
import { SearchService } from "../../Search/search.service";
import { UrlService } from "../../Search/url.service";
import { ISearchResponse } from "../../Search/searchResponse";
import { IErrorableTile } from "../IErrorableTile";
import { DocumentListTileDto } from "../TilesTypes";
import { DocumentViewInfo } from "@rhinestone/portal-web-api";
import { SearchRequestClient } from "@rhinestone/portal-web-react";
import { deserializeSearchRequest } from "@rhinestone/portal-web-react";

export class DocumentListTileController {
  // Component bindings
  public tile: DocumentListTileDto & IErrorableTile;
  public documentQuery: SearchRequestClient;

  public isInitialized: boolean = false;
  public contentLoaded: boolean = false;
  public hasError: boolean = false;
  public errorMessage: string = "";
  public errorDetails: string = "";

  // View
  public viewTemplate: string;
  public viewName: string;
  public result: ISearchResponse;
  public searchPageLink: string;

  public static $inject = [
    "$location",
    "$translate",
    "$scope",
    "documentListTileService",
    "searchService",
    "urlService"
  ];

  constructor(
    private $location: angular.ILocationService,
    private $translate: angular.translate.ITranslateService,
    private $scope: angular.IScope,
    private documentListTileService: IDocumentListTileService,
    private searchService: SearchService,
    private urlService: UrlService
  ) {}

  public async $onInit() {
    if (await this.deserializeQuery()) {
      const documentView = await this.getDocumentView();
      if (documentView !== null) {
        this.fillDocumentQuery();
        if (await this.createSearchPageLink(documentView)) {
          await this.executeSearch();
        }
      }
    }
    this.$scope.$apply();
  }

  private async deserializeQuery(): Promise<boolean> {
    try {
      this.documentQuery = deserializeSearchRequest(
        decodeURIComponent(this.tile.query)
      );
      return true;
    } catch (error) {
      await this.handleError(error, "tiles.query_deserialize_error");
      return false;
    }
  }

  private async getDocumentView(): Promise<DocumentViewInfo> {
    let documentView: DocumentViewInfo;
    try {
      documentView = await this.documentListTileService.getDocumentView(
        this.tile.documentViewName
      );
      this.documentQuery.fieldSetName = documentView.documentFieldSetName;
      return documentView;
    } catch (error) {
      await this.handleError(error, "tiles.get_search_view_error");
      return null;
    }
  }

  private async fillDocumentQuery() {
    const queryToSerialize = this.documentQuery;
    if (!queryToSerialize.skip) {
      queryToSerialize.skip = 0;
    }
    if (!queryToSerialize.resultViewName) {
      queryToSerialize.resultViewName = "";
    }
    if (!queryToSerialize.page) {
      queryToSerialize.page = 0;
    }
  }

  private async createSearchPageLink(
    documentView: DocumentViewInfo
  ): Promise<boolean> {
    try {
      this.searchPageLink = `/Soeg?${this.urlService.getSearchPageDeeplink(
        this.documentQuery
      )}`;
      this.viewTemplate = `/templates${documentView.templateAssetPath}`;
      return true;
    } catch (error) {
      await this.handleError(error, "tiles.deep_link_error");
      return false;
    }
  }

  private async executeSearch() {
    try {
      const searchResponse = await this.searchService.executeSearch(
        this.documentQuery
      );
      this.result = searchResponse;
      this.contentLoaded = true;
    } catch (error) {
      await this.handleError(error, "tiles.execute_search_error");
    } finally {
      this.isInitialized = true;
    }
  }

  private async handleError(error: any, translationKey: string) {
    this.hasError = true;
    this.errorMessage = await this.$translate(translationKey);
    this.errorDetails = error.message;
    this.isInitialized = true;
    this.contentLoaded = true;
  }
}
angular
  .module("PortalApp")
  .controller(
    "Rhinestone.DocumentListTileController",
    DocumentListTileController
  );
