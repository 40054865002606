import * as angular from "angular";

export class InvalidTicketController {
  public static $inject: any[] = [];

  constructor() {}
}

angular
  .module("PortalApp")
  .controller("Rhinestone.InvalidTicketController", InvalidTicketController);
