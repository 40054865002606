import "./linkTile.html";
import { BaseComponent } from "../../Core/BaseComponent";
import * as angular from "angular";
import { Binding } from "../../Utils/AngularBindings";
import "./linkTile.less";
import "./linkTile.controller";
import "../../Services/linkRender.service";
export class LinkTileComponent extends BaseComponent {
  constructor() {
    super(
      "Rhinestone.LinkTileController",
      "/templates/tiles/linkTile/linkTile.html"
    );
    this.bindings = {
      tile: Binding.oneWay
    };
  }
}

angular.module("PortalApp").component("rhLinkTile", new LinkTileComponent());
