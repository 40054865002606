import { AnnotationContext } from "../Services/models/publishAssetCollectionRequest";
import * as angular from "angular";
import { AssetsService } from "./assets.service";
import { ILocalizedNotificationService } from "../Services/localized.notification.service";
import "./publishAssetCollectionDialog.html";

export class AnnotationTypeSelection {
  constructor(
    public description: string,
    public annotationType: AnnotationContext
  ) {}
}
export class PublishAssetCollectionDialogController {
  public description: string;
  public includeSeparatorPages: boolean;
  public includeAssetCollectionAnnotations: boolean;
  public isBusy = false;
  public isFailed = false;

  public static $inject = [
    "$uibModalInstance",
    "$translate",
    "assetsService",
    "localizedNotificationService",
    "assetCollectionId",
    "assetCollectionTitle"
  ];

  constructor(
    private $uibModalInstance: angular.ui.bootstrap.IModalServiceInstance,
    private $translate: angular.translate.ITranslateService,
    private assetsService: AssetsService,
    private localizedNotificationService: ILocalizedNotificationService,
    private assetCollectionId: string,
    private assetCollectionTitle: string
  ) {}

  public publish = async () => {
    this.isBusy = true;

    const request = {
      Description: this.description,
      IncludeSeparatorPages: this.includeSeparatorPages,
      IncludeAssetCollectionAnnotations: this.includeAssetCollectionAnnotations,
      LanguageCode: this.$translate.use()
    };

    try {
      await this.assetsService.publishCollection(
        this.assetCollectionId,
        request
      );
      this.localizedNotificationService.success("assets.publish_success", {
        title: this.assetCollectionTitle
      });
      this.$uibModalInstance.close();
    } catch (error) {
      this.localizedNotificationService.error("assets.publish_error", {
        title: this.assetCollectionTitle
      });
      this.isFailed = true;
    } finally {
      this.isBusy = false;
    }
  };

  public cancel = () => {
    this.$uibModalInstance.dismiss();
  };
}

angular
  .module("PortalApp")
  .controller(
    "Rhinestone.PublishAssetCollectionDialogController",
    PublishAssetCollectionDialogController
  );
