import "./tileCard.html";
import { BaseComponent } from "../../Core/BaseComponent";
import * as angular from "angular";
import "./tileCard.less";
import "./tileCard.controller";
import "./TileCardHeader/tileCardHeader.component";
import "./TileCardBody/tileCardBody.component";

export class TileCardComponent extends BaseComponent {
  constructor() {
    super(
      "Rhinestone.TileCardController",
      "/templates/tiles/tilecard/tileCard.html",
      true
    );

    this.bindings = {
      tileTitle: "<",
      tileHeading: "<",
      imageUrl: "<",
      imageAltText: "<",
      isInitialized: "<",
      contentLoaded: "<"
    };
  }
}

angular.module("PortalApp").component("rhTileCard", new TileCardComponent());
