import "./assetCollectionDetail.html";
import { BaseComponent } from "../Core/BaseComponent";
import * as angular from "angular";
import { Binding } from "../Utils/AngularBindings";
import "./assetCollectionDetail.controller";
export class AssetCollectionDetailComponent extends BaseComponent {
  constructor() {
    super(
      "Rhinestone.AssetCollectionDetailController",
      "/templates/assets/assetCollectionDetail"
    );
    this.bindings = {
      assetCollection: Binding.oneWay,
      onDelete: Binding.outputEvent,
      onDeleteTemplate: Binding.outputEvent,
      onCopy: Binding.outputEvent,
      onCopyAsTemplate: Binding.outputEvent,
      onCopyFromTemplate: Binding.outputEvent,
      onSave: Binding.outputEvent
    };
  }
}

angular
  .module("PortalApp")
  .component("rhAssetDetails", new AssetCollectionDetailComponent());
