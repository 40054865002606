import { AssetsService } from "./assets.service";
import * as angular from "angular";
import "./NewCollectionFromQuoteDialog.html";

export class NewCollectionFromQuoteDialogController {
  public static $inject = ["assetsService", "$uibModalInstance"];

  public title = "";
  public referenceNumber = "";

  constructor(
    private assetsService: AssetsService,
    private $uibModalInstance: angular.ui.bootstrap.IModalServiceInstance
  ) {}

  public create(): void {
    this.$uibModalInstance.close(
      new NewAssetCollectionResult(this.title, this.referenceNumber)
    );
  }

  public cancel(): void {
    this.$uibModalInstance.dismiss("cancel");
  }
}

export class NewAssetCollectionResult {
  constructor(public title: string, public referenceNumber: string) {}
}

angular
  .module("PortalApp")
  .controller(
    "Rhinestone.NewCollectionFromQuoteDialogController",
    NewCollectionFromQuoteDialogController
  );
