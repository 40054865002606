import * as angular from "angular";
import { IIdGeneratorService } from "./idGenerator.service";
import { CarouselTileDto } from "../TilesTypes";
import { TileDto } from "@rhinestone/portal-web-api";

export class CarouselTileController {
  public tile: CarouselTileDto;
  private carouselNumber: number;
  public innerTiles: TileDto[];

  constructor(
    private $rootElement: angular.IRootElementService,
    private idGeneratorService: IIdGeneratorService,
    private $scope: angular.IScope
  ) {}

  public static $inject = ["$rootElement", "idGeneratorService", "$scope"];

  public $onInit() {
    this.innerTiles = this.tile.innerTiles;
    this.carouselNumber = this.idGeneratorService.getNext();
    const deregister = this.$scope.$watch(
      () => {
        return document.getElementById(this.getCarouselId());
      },
      x => {
        if (x !== null) {
          deregister();
          const elCarousel = this.$rootElement.find(`#${this.getCarouselId()}`);
          if (this.tile.intervalInSeconds > 0) {
            elCarousel.carousel({
              interval: this.tile.intervalInSeconds * 1000, // Convert seconds to miliseconds
              pause: this.tile.pauseOnHover ? "hover" : "false"
            });
          } else {
            elCarousel.carousel("pause");
          }
        }
      }
    );
  }

  public getCarouselId(): string {
    return `carousel-tile-${this.carouselNumber}`;
  }
}
angular
  .module("PortalApp")
  .controller("Rhinestone.CarouselTileController", CarouselTileController);
