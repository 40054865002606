import * as angular from "angular";
import * as _ from "underscore";
import { SimpleChange } from "../../../TypeDefinitions/angularextensions";

import {
  Criteria,
  CriteriaChangeType,
  CriteriaChange,
  CriteriaProvider
} from "@rhinestone/portal-web-react";
import { IRelatedDocumentsService } from "./relatedDocuments.service";
import {
  IRelatedCriteria,
  buildRelatedDocumentsCriteriaDescription
} from "../Criterias/buildCriteriaDescriptionFunctions";

export class RelatedDocumentsController {
  // Input bindings
  public criteriaList: ReadonlyArray<Criteria>;
  public readonly providerKey: string;

  // output bindings
  public onCriteriaChanged: (params: { action: CriteriaChange }) => void;

  // internal state
  public title: string;
  public visible: boolean;
  public onLoaded: (param: { provider: CriteriaProvider }) => void;

  public static $inject = ["$translate", "relatedDocumentsService"];
  public initialized: boolean;

  constructor(
    private $translate: angular.translate.ITranslateService,
    private relatedDocumentsService: IRelatedDocumentsService
  ) {}

  public $onInit() {
    this.onLoaded({
      provider: this.getCriteriaProvider()
    });
  }

  public async $onChanges(changes: {
    criteriaList?: SimpleChange<Criteria[]>;
  }) {
    if (changes.criteriaList) {
      this.criteriaList = changes.criteriaList.currentValue;

      const previousFound = _.find(
        changes.criteriaList.previousValue || [],
        c => c.providerKey === this.providerKey
      );

      const found = _.find(
        changes.criteriaList.currentValue || [],
        c => c.providerKey === this.providerKey
      );
      if (found !== previousFound) {
        await this.loadTitleAsync(found as IRelatedCriteria);
      }
    }
  }

  public $onDestroy() {
    this.criteriaList = null;
  }

  public async loadTitleAsync(found: IRelatedCriteria) {
    if (found && found.displayValue === undefined) {
      const { relationGroup, hiveId, fullName, bookmark, relationQueryType } =
        found.data;
      found.displayValue = await this.getDisplayValue(
        relationGroup,
        hiveId,
        fullName,
        bookmark,
        relationQueryType
      );

      this.onCriteriaChanged({
        action: {
          changes: []
        }
      });
    }
  }

  private getCriteriaProvider(): CriteriaProvider {
    return {
      key: this.providerKey,
      getCriteriaViewModel: criteria => {
        if (criteria.providerKey !== this.providerKey) {
          return null;
        }
        return {
          displayValue: (criteria as IRelatedCriteria).displayValue,
          criteria,
          longDisplayValue: undefined,
          displayValueResource: undefined,
          icon: "fa fa-object-group",
          color: "white"
        };
      },
      buildCriteriaDescription: request =>
        buildRelatedDocumentsCriteriaDescription(
          request,
          this.providerKey,
          this.criteriaList
        )
    };
  }

  private async getDisplayValue(
    relationGroup: string,
    hiveId: string,
    fullName: string,
    bookmark: string,
    relationQueryType: string
  ) {
    const fieldSet = await this.relatedDocumentsService.getRelationTitleFields(
      fullName,
      hiveId,
      bookmark
    );

    return await this.$translate(
      this.getTranslationString(relationQueryType, bookmark),
      {
        ...fieldSet,
        relationGroup,
        bookmark
      }
    );
  }

  private getTranslationString(relationQueryType: string, bookmark: string) {
    switch (relationQueryType) {
      case "Bookmark":
        return !bookmark || bookmark.length === 0
          ? "search.no_bookmark_related_documents"
          : "search.bookmark_related_documents";
      case "All":
      case "TopOfDocument":
        return "search.all_related_documents";
      default:
        return "";
    }
  }

  public remove(): void {
    const found = _.find(
      this.criteriaList,
      c => c.providerKey === this.providerKey
    );
    if (!found) {
      return;
    }

    this.onCriteriaChanged({
      action: {
        changes: [
          {
            criteria: found,
            type: CriteriaChangeType.Remove
          }
        ]
      }
    });
  }
}
angular
  .module("PortalApp")
  .controller(
    "Rhinestone.RelatedDocumentsController",
    RelatedDocumentsController
  );
