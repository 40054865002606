import * as angular from "angular";
import { IPortalConfig } from "../Core/portal.provider";
import { TileDto } from "@rhinestone/portal-web-api";

export interface IMainPageService {
  getTiles(): angular.IPromise<TileDto[]>;
}

export class MainPageService implements IMainPageService {
  private baseUrl = "";

  public tiles: TileDto[] = [];

  public static $inject = ["$http", "$q", "portal"];

  constructor(
    private $http: angular.IHttpService,
    private $q: angular.IQService,
    private portal: IPortalConfig
  ) {
    this.baseUrl = `/api/Portals(${this.portal.identifier})`;
  }

  public getTiles(): angular.IPromise<TileDto[]> {
    const deferred = this.$q.defer<TileDto[]>();

    this.$http
      .get<TileDto[]>(`${this.baseUrl}/tiles`)
      .then(result => {
        deferred.resolve(result.data);
      })
      .catch(response => {
        deferred.reject(response);
      });

    return deferred.promise;
  }
}

angular.module("PortalApp").service("mainPageService", MainPageService);
