import * as angular from "angular";
import { DocumentService } from "../../Documents/document.service";
import { IErrorService } from "../../Layout/error.service";
import { IPortalDataHttpService } from "../../Services/DataService/PortalDataHttpService";
import { IErrorableTile } from "../IErrorableTile";
import { DocumentTileDto } from "../TilesTypes";

export class DocumentTileController {
  public isInitialized: boolean = false;
  public contentLoaded: boolean = false;
  public hasError: boolean = false;
  public tile: DocumentTileDto & IErrorableTile;
  public content: string;
  public pictureUrl: string;
  public documentTitle: string;

  public static $inject = [
    "errorService",
    "portalDataService",
    "documentService",
    "$q"
  ];

  constructor(
    private errorService: IErrorService,
    private portalDataService: IPortalDataHttpService,
    private documentService: DocumentService,
    private $q: angular.IQService
  ) {}

  public $onInit() {
    this.hasError = false;
    // It seems that the path is sometimes undefined, so we need to check for that
    // this will cause an error when rendering the tile, but it will not crash the whole page
    if (this.tile.path.indexOf("undefined") > -1) {
      console.error(
        "Trying to fetch data with path: " +
          this.tile.path +
          " which is not valid. This will cause an error when rendering the tile."
      );
      this.hasError = true;
      return;
    }

    const infoRequest = this.documentService.getDocumentInfoByPath(
      this.tile.path
    );

    const abstractRequest = this.getDocumentSection(
      this.tile.sectionNameToDisplay
    );

    this.$q
      .all([infoRequest, abstractRequest])
      .then(([info, abstract]) => {
        this.documentTitle = info.title;
        this.pictureUrl = this.extractImageUrl(abstract);
        this.content = this.removeImages(abstract);

        this.contentLoaded = true;
      })
      .catch(() => {
        this.errorService.registerError(
          "Vi kunne desværre ikke hente dokumentet."
        ); // TODO: log error in elastic
        this.hasError = true;
        this.tile.hasError = true; // this is read by parent tilesLayout to hide the whole tile, workaround to bootstrap columns with height=0 not collapsing
      })
      .finally(() => {
        this.isInitialized = true;
      });
  }

  public getDocumentSection(sectionName: string): angular.IPromise<string> {
    return this.portalDataService.get<string>(
      `Document/Schultz/${sectionName}/h/${this.tile.path}`
    );
  }

  private extractImageUrl(data: string) {
    const firstImg = $($.parseHTML(data)).find("img:first");
    return firstImg.attr("src");
  }

  private removeImages(data: string) {
    return $("<div>").html(data).find("img").remove().end().html();
  }
}

angular
  .module("PortalApp")
  .controller("Rhinestone.DocumentTileController", DocumentTileController);
