import "./newSearchSubscriptionDialog.html";

import { IRootScopeService } from "angular";

import { ILocalizedNotificationService } from "../../Services/localized.notification.service";
import { ICurrentUserService } from "../../User/currentUser.service";
import { SearchSubscription } from "../searchSubscription";
import { SearchSubscriptionService } from "../searchSubscription.service";
import { SubscriptionFrequency } from "../../Subscriptions/subscription";

export class NewSearchSubscriptionDialogController {
  public saving = false;

  public form = {
    name: "",
    email: "",
    frequency: SubscriptionFrequency.Weekly
  };
  public verifiedEmails: string[];

  public static $inject = [
    "$uibModalInstance",
    "$translate",
    "currentUserService",
    "localizedNotificationService",
    "searchSubscriptionService",
    "searchQuery",
    "$rootScope"
  ];

  constructor(
    private $uibModalInstance: any,
    private $translate: angular.translate.ITranslateService,
    private currentUser: ICurrentUserService,
    private localizedNotificationService: ILocalizedNotificationService,
    private searchSubscriptionService: SearchSubscriptionService,
    private searchQuery: {
      criteria: {
        [providerKey: string]: any[];
      };
    },
    private $rootScope: IRootScopeService
  ) {}

  public $onInit() {
    this.currentUser.getVerifiedEmails().then(result => {
      this.verifiedEmails = result;
      this.form.email = this.verifiedEmails[0];

      // Set border visible on btn-group to indicate focus
      $("label.btn > input:radio")
        .focus(event => {
          $(event.target).parent().addClass("btn-border-purple");
        })
        .blur(event => {
          $(event.target).parent().removeClass("btn-border-purple");
        });
    });
  }

  public async save() {
    this.saving = true;

    const searchSubscription = {
      name: this.form.name,
      emailAddress: this.form.email,
      frequency: this.form.frequency,
      query: this.searchQuery
    } as SearchSubscription;

    try {
      const sub = await this.searchSubscriptionService.create(
        searchSubscription
      );
      this.$uibModalInstance.close();
      if (sub.confirmed) {
        this.localizedNotificationService.success(
          "search_subscriptions.create_success",
          { description: sub.name }
        );
      } else {
        this.localizedNotificationService.success(
          "search_subscriptions.confirm_email",
          { emailAddress: sub.emailAddress }
        );
      }
    } catch (error) {
      switch (error.status) {
        case 409:
          break;
        default:
          break;
      }
    } finally {
      this.saving = false;
      this.$rootScope.$apply();
    }
  }

  public cancel(): void {
    this.$uibModalInstance.dismiss("cancel");
  }
}
