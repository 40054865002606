import * as angular from "angular";
import * as _ from "underscore";

import { ISearchSuggestionsProvider } from "./ISearchSuggestionsProvider";
import { ISearchSuggestionsProviderConfig } from "./ISearchSuggestionsProviderConfig";
import { ISearchCriteriaStateService } from "../../searchCriteriaState.service";
import { SearchService } from "../../search.service";
import { ISearchSuggestion } from "../ISearchSuggestion";
import { buildTrimmedTitle } from "./buildTrimmedTitle";
import {
  SearchRequestClient,
  buildTermsCriteria
} from "@rhinestone/portal-web-react";

export class DocumentsSearchSuggestionsProvider
  implements ISearchSuggestionsProvider
{
  private readonly titleMaxLength = 60;
  private readonly sectionMaxLength = 20;

  public config: ISearchSuggestionsProviderConfig;

  public static $inject = [
    "$q",
    "$location",
    "searchCriteriaStateService",
    "searchService"
  ];

  constructor(
    private $q: angular.IQService,
    private $location: angular.ILocationService,
    private searchCriteriaStateService: ISearchCriteriaStateService,
    private searchService: SearchService
  ) {}

  public getSuggestions(query: string): angular.IPromise<ISearchSuggestion[]> {
    const deferred = this.$q.defer<ISearchSuggestion[]>();

    const { criteria: currentCriteria } =
      this.searchCriteriaStateService.criteriaChangedSubject.getValue();
    const termsCriteria = buildTermsCriteria(query);

    const searchRequest: SearchRequestClient = {
      fieldSetName: this.config.payload.fieldsetName,
      ordering: this.config.payload.ordering,
      skip: 0,
      page: 1,
      take: this.config.maxItems === undefined ? 10 : this.config.maxItems,
      criteria: currentCriteria.concat(termsCriteria)
    };

    this.searchService.executeSearch(searchRequest).then(searchResponse => {
      const results = _(searchResponse.Results)
        .filter((x: any) => x.title !== null && x.title !== undefined)
        .map((x: any) => {
          const result = {
            title: x.title.substring(0, this.titleMaxLength),
            payload: {
              link: `/h/${x.HiveId}/${x.FullName}`,
              fullTitle: x.title
            }
          };

          // Adding a slice (high level section) to the displayed text and the url
          // INFO: item.SliceNumber==0 represents the document (with text beyond any slice - leftovers after slicing)
          // The logic here makes sure that the section is given at least 20 (sectionMaxLength) characters (if it needs) out of the 60 (titleMaxLength)
          // reserved for the whole displayed text
          if (x.SliceNumber > 0) {
            result.title = buildTrimmedTitle(
              result.title as string,
              x.slice_title as string,
              this.sectionMaxLength,
              this.titleMaxLength
            );

            result.payload.fullTitle = `${x.title} ${x.slice_title}`;
            result.payload.link = `${result.payload.link}#${x.SliceBookmark}`;
          }

          return result;
        });
      deferred.resolve(results);
    });

    return deferred.promise;
  }

  public suggestionSelected(suggestion: ISearchSuggestion): void {
    this.$location.url(suggestion.payload.link);
  }
}

angular
  .module("PortalApp")
  .service(
    "documentsSearchSuggestionsProvider",
    DocumentsSearchSuggestionsProvider
  );
