import { ILocalizationService } from "./localization.service";
import * as angular from "angular";
import { PortalLocalizationModel } from "@rhinestone/portal-web-api";

export class LocalizationSwitcherController {
  constructor(
    private localizationService: ILocalizationService,
    private $translate: angular.translate.ITranslateService
  ) {}

  public static $inject = ["localizationService", "$translate"];

  public localizations: PortalLocalizationModel[];
  public selectedLocale: string;

  public $onInit() {
    this.selectedLocale = this.$translate.use();

    this.localizations = [];
    this.localizationService.getLocalizations().then(localizations => {
      this.localizations = localizations;
    });
  }

  public selectedLocalizationChanged(): void {
    this.$translate.use(this.selectedLocale);
  }
}

angular
  .module("PortalApp")
  .controller(
    "Rhinestone.LocalizationSwitcherController",
    LocalizationSwitcherController
  );
