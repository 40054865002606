import "./signedOut.html";
import { Constants } from "../app.constants";
import { BaseComponent } from "../Core/BaseComponent";
import * as angular from "angular";
import "./signedOut.controller";

export class SignedOutComponent extends BaseComponent {
  constructor() {
    super(
      "Rhinestone.SignedOutController",
      `${Constants.TemplateBaseUrl}/Account/SignedOut`
    );
  }
}

angular.module("PortalApp").component("rhSignedOut", new SignedOutComponent());
