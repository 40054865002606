import "./subscriptionsActionsPane.html";
import { BaseComponent } from "../Core/BaseComponent";
import * as angular from "angular";
import "./subscriptionsActionsPane.controller";
import { Binding } from "../Utils/AngularBindings";

export class SubscriptionsActionsPaneComponent extends BaseComponent {
  constructor() {
    super(
      "Rhinestone.SubscriptionsActionsPaneController",
      "/templates/Subscriptions/subscriptionsActionsPane"
    );

    this.bindings = {
      onSubscribeToAll: Binding.outputEvent,
      onUpdateAll: Binding.outputEvent,
      subscriptionsExist: Binding.oneWay
    };
  }
}

angular
  .module("PortalApp")
  .component(
    "rhSubscriptionsActionsPane",
    new SubscriptionsActionsPaneComponent()
  );
