import * as angular from "angular";
import "../Account/signedOut.component";
import "../Assets/assetCollection.component";
import "../Assets/assets.component";
import "../Error/internal.component";
import "../Error/notFound.component";
import "../Error/unauthorized.component";
import "../Error/invalidTicket.component";
import "../MainPage/mainPage.component";
import "../ProductPage/productPageView.component";
import "../Search/searchView.component";
import "../SearchSubscriptions/ConfirmSubscriptionView/confirmSubscriptionView.component";
import "../Subscriptions/subscriptionsView.component";
import "../Subscriptions/unsubscribeSubscriptionsView.component";
import { PortalProvider } from "./portal.provider";
import { IProductsService } from "../Services/products.service";

export class RouterConfig {
  public static $inject = [
    "$locationProvider",
    "$routeProvider",
    "$anchorScrollProvider",
    "portalProvider"
  ];

  constructor(
    $locationProvider: any,
    $routeProvider: any,
    $anchorScrollProvider: any,
    portalProvider: PortalProvider
  ) {
    $locationProvider.html5Mode({
      enabled: true,
      requireBase: false
    });

    // Angular is trying to control anchor scrolling, this is not good for the React app, so we disable it here,
    // shouldn't have any effect on old site, but in case some anchor-links starts offsetting poorly, look into
    // conditionally disabling this when new design is used.
    $anchorScrollProvider.disableAutoScrolling();

    $routeProvider.caseInsensitiveMatch = true;
    const { allowAnonymousAccess } = portalProvider.$get();

    $routeProvider
      .when("/", {
        template: "<rh-main-page-view/>",
        resolveRedirectTo: [
          "productsService",
          async (productsService: IProductsService) => {
            const singleProduct =
              await productsService.getSingleProductPortalProduct();
            return singleProduct ? `/Product/${singleProduct.code}` : undefined;
          }
        ],
        data: { title: "Forside" }
      })
      .when("/Product/:productCode", {
        template: "<rh-product-page-view/>",
        authenticatedOnly: true,
        data: { title: "Produktforside" }
      })
      .when("/Soeg", {
        template: "<rh-search-view/>",
        reloadOnSearch: false, // prevents the router from reloading when updating the query parameters through the UrlService
        authenticatedOnly: !allowAnonymousAccess,
        data: { title: "Søgeside" }
      })
      .when("/Ai-assistent/:configKey?", {
        template: "<rh-react-ai-assistant-page/>",
        authenticatedOnly: true,
        data: { title: "Ai Assistant" }
      })
      .when("/Samlinger", {
        template: "<rh-assets/>",
        authenticatedOnly: true,
        data: { title: "Materialesamlinger" }
      })
      .when("/Samlinger/Rediger/:collectionId", {
        template: "<rh-asset-collection />",
        authenticatedOnly: true,
        data: { title: "Materialesamlinger - Rediger" }
      })
      .when("/AssetCollection/EditTemplate/:collectionId", {
        template:
          "<rh-asset-collection is-template='true' is-archived='false' />",
        authenticatedOnly: true,
        data: { title: "Materialesamlinger - Rediger skabelon" }
      })
      .when("/AssetCollection/EditArchivedTemplate/:collectionId", {
        template:
          "<rh-asset-collection is-template='true' is-archived='true' />",
        authenticatedOnly: true,
        data: { title: "Materialesamlinger - Rediger arkiveret skabelon" }
      })
      // Route is kept like this for now for backwards compatibility
      .when("/Samlinger/PdfView/:collectionId", {
        template: "<rh-react-asset-pdf-page />",
        authenticatedOnly: true,
        data: { title: "Materialesamlinger" }
      })
      .when("/new-design/Samlinger/PdfView/:collectionId", {
        reloadOnSearch: false,
        template: "<rh-react-asset-pdf-page />",
        authenticatedOnly: true,
        data: { title: "Materialesamlinger" }
      })
      // This is anonymous based document route (hence the 'a' prefix to path), that doesn't require authentication
      .when("/a/h/:hiveId/:fullName*", {
        template: "<rh-react-document-page />",
        reloadOnSearch: false,
        authenticatedOnly: false,
        data: { title: "Dokumentvisning" }
      })
      .when("/h/:hiveId/:fullName*", {
        template: "<rh-react-document-page />",
        reloadOnSearch: false,
        authenticatedOnly: !allowAnonymousAccess,
        data: { title: "Dokumentvisning" }
      })
      .when("/Abonnementer", {
        template: "<rh-subscriptions-view />",
        data: { title: "Nyhedsmail" }
      })
      .when("/Abonnementer/Bekraeft", {
        template: "<rh-confirm-search-subscription-view />",
        data: { title: "Nyhedsmail - Tilmeld" }
      })
      .when("/Abonnementer/Afmeld", {
        template: "<rh-unsubscribe-subscriptions-view />",
        data: { title: "Nyhedsmail - Afmeld" }
      })
      .when("/Account/MyProfile", {
        template: "<rh-react-my-profile-page />",
        reloadOnSearch: false,
        authenticatedOnly: true,
        data: { title: "Brugerprofil" }
      })
      .when("/Account/MyProfile/edit", {
        template: "<rh-react-edit-profile-page />",
        reloadOnSearch: false,
        authenticatedOnly: true,
        data: { title: "Rediger Brugerprofil" }
      })
      .when("/NotFound", {
        template: "<rh-not-found />",
        data: { title: "System - Siden findes ikke" }
      })
      .when("/Unauthorized", {
        template: "<rh-unauthorized />",
        data: { title: "System - Adgang nægtet" }
      })
      .when("/Internal", {
        template: "<rh-internal />",
        data: { title: "System - Internt" }
      })
      .when("/SignedOut", {
        template: "<rh-signed-out />",
        data: { title: "System - Logget ud" }
      })
      .when("/InvalidTicket", {
        template: "<rh-invalid-ticket />",
        data: { title: "System - Ugyldigt ticket" }
      })
      .when("/LoginError", {
        template: "<rh-react-login-error-page />",
        data: { title: "System - Login fejl" }
      })
      .otherwise({
        redirectTo: "/NotFound",
        data: { title: "System - Siden findes ikke" }
      });
  }
}

export interface RouteInfo {
  $$route: {
    caseInsensitiveMatch: boolean;
    reloadOnSearch: boolean;
    authenticatedOnly: boolean;
    originalPath: string;
  };
  params: any;
}

angular.module("PortalApp").config(RouterConfig);
