export enum SubscriptionType {
  Search,
  DocumentVersion
}

export enum SubscriptionFrequency {
  None,
  Daily,
  Weekly,
  Monthly
}

export interface Subscription {
  subscriptionId: string;
  name: string;
  emailAddress: string;
  frequency: SubscriptionFrequency;
  frequencyDisplayName: string;
  suspended: boolean;
  lastProcessedAt: Date;
  deleted: boolean;
  confirmed: boolean;
}
