import "./productPageTaxonomy.html";
import { BaseComponent } from "../../Core/BaseComponent";
import * as angular from "angular";
import { Binding } from "../../Utils/AngularBindings";
import "./taxonomy.less";
import "./productPageTaxonomy.controller";

export class ProductPageTaxonomyComponent extends BaseComponent {
  constructor() {
    super(
      "Rhinestone.ProductPageTaxonomyController",
      "/templates/ProductPage/ProductPageTaxonomy/productPageTaxonomy.html"
    );
    this.bindings = {
      productCode: Binding.oneWay,
      taxonomyName: Binding.oneWay,
      expandTo: Binding.oneWay
    };
  }
}

angular
  .module("PortalApp")
  .component("rhProductPageTaxonomy", new ProductPageTaxonomyComponent());
