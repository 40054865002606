import * as angular from "angular";
import { CurrentUserService } from "../User/currentUser.service";
import { SendTermsSearchGTMEvent } from "@rhinestone/portal-web-react";

export class GoogleTagManagerService {
  public static $inject: string[] = ["currentUserService"];

  constructor(private currentUserService: CurrentUserService) {}

  public trackSearchTerm(searchTerm: string) {
    const user = this.currentUserService.user;
    const organizationName = user ? user.organizationName : null;
    SendTermsSearchGTMEvent(searchTerm, organizationName);
  }
}

angular
  .module("PortalApp")
  .service("googleTagManagerService", GoogleTagManagerService);
