import "./localTaxonomy.html";
import "../Taxonomy/taxonomy.less";
import { BaseComponent } from "../../Core/BaseComponent";
import * as angular from "angular";
import { Binding } from "../../Utils/AngularBindings";
import "./localTaxonomy.controller";

export class LocalTaxonomyComponent extends BaseComponent {
  constructor() {
    super(
      "Rhinestone.LocalTaxonomyController",
      "/templates/search/LocalTaxonomy/localTaxonomy"
    );
    this.bindings = {
      icon: Binding.oneWay,
      color: Binding.oneWay,
      documentTypeTaxonomyId: Binding.oneWay,
      providerKey: Binding.oneWay,
      onCriteriaChanged: Binding.outputEvent,
      onLoaded: Binding.outputEvent,
      criteria: Binding.oneWay,
      taxonomyFilterKey: Binding.oneWay,
      showEmptyTaxons: Binding.oneWay,
      showFacetCount: Binding.oneWay
    };
  }
}

angular
  .module("PortalApp")
  .component("rhLocalTaxonomy", new LocalTaxonomyComponent());
