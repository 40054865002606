import * as angular from "angular";
import * as _ from "underscore";
import { SimpleChange } from "../../../TypeDefinitions/angularextensions";

import {
  Criteria,
  CriteriaChangeType,
  CriteriaChange,
  CriteriaProvider
} from "@rhinestone/portal-web-react";
import { ICriteriaGroupController } from "../Criterias/ICriteriaGroupController";
import { buildStatusCriteriaDescription } from "../Criterias/buildCriteriaDescriptionFunctions";
import {
  SearchFilterOptionModel,
  SearchFilterStatusModel
} from "@rhinestone/portal-web-api";

export class StatusSearchFilterController implements ICriteriaGroupController {
  public filter: SearchFilterStatusModel;
  public flags: Array<{
    isSelected: boolean;
    searchOption: SearchFilterOptionModel;
  }> = [];

  public onCriteriaChanged: (action: { action: CriteriaChange }) => void;
  public onLoaded: (param: { provider: CriteriaProvider }) => void;

  public criteria: ReadonlyArray<Criteria> = [];
  public readonly icon: string;
  public readonly color: string;
  public readonly providerKey: string;

  public static $inject = ["$translate"];

  constructor(private $translate: angular.translate.ITranslateService) {}

  public $onInit() {
    this.onLoaded({ provider: this.getCriteriaProvider() });
    this.reloadFlags();
  }

  public $onChanges(changes: { criteria?: SimpleChange<Criteria[]> }) {
    if (
      changes.criteria &&
      this.criteriaChanged(
        changes.criteria.previousValue,
        changes.criteria.currentValue
      )
    ) {
      this.criteria = changes.criteria.currentValue;
      this.reloadFlags();
    }
  }

  public toggleFlag(flag: {
    isSelected: boolean;
    searchOption: SearchFilterOptionModel;
  }): void {
    const changeType = flag.isSelected
      ? CriteriaChangeType.Remove
      : CriteriaChangeType.Add;

    this.onCriteriaChanged({
      action: this.buildCriteriaChange(flag.searchOption, changeType)
    });
  }

  private criteriaChanged(previous: Criteria[], current: Criteria[]): boolean {
    // TODO: this change check seems to assume to much.. maybe walk through previous list and assert if they are the same
    const myPrevious = this.filterCriteria(previous);
    const myCurrent = this.filterCriteria(current);

    if (myPrevious.length !== myCurrent.length) {
      return true;
    }

    for (let i = myPrevious.length; i--; ) {
      if (!_.isEqual(myPrevious[i], myCurrent[i])) {
        return true;
      }
    }
    return false;
  }

  private filterCriteria(criteria: ReadonlyArray<Criteria>): Criteria[] {
    if (!_.isArray(criteria)) {
      return [];
    }

    return criteria.filter(x => x.providerKey === this.providerKey);
  }

  private buildCriteriaChange(
    option: SearchFilterOptionModel,
    changeType: CriteriaChangeType
  ): CriteriaChange {
    return {
      changes: [
        {
          type: changeType,
          criteria: {
            data: option.flag,
            providerKey: this.providerKey
          }
        }
      ]
    };
  }

  private getCriteriaProvider(): CriteriaProvider {
    return {
      key: this.providerKey,
      getCriteriaViewModel: criteria => {
        if (criteria.providerKey !== this.providerKey) {
          return null;
        }

        return {
          longDisplayValue: undefined,
          displayValue: undefined,
          displayValueResource: this.findOptionFromFlag(criteria.data)
            .titleResource,
          criteria,
          icon: this.icon,
          color: this.color
        };
      },
      buildCriteriaDescription: request =>
        buildStatusCriteriaDescription(
          request,
          this.providerKey,
          this.criteria,
          this.flags,
          (key: string) => this.translateValue(key)
        )
    };
  }

  private translateValue(key: string): string {
    return this.$translate.instant(key);
  }

  private findOptionFromFlag(flag: string): SearchFilterOptionModel {
    return _.find(this.filter.options, option => option.flag === flag);
  }

  private reloadFlags() {
    const myCriterias = this.filterCriteria(this.criteria);

    const flags = this.filter.options.map(option => {
      return {
        searchOption: option,
        isSelected: this.criteriaExists(myCriterias, option.flag)
      };
    });
    this.flags = flags;
  }

  private criteriaExists(criteria: Criteria[], flagValue: string): boolean {
    return _(criteria).find((c: any) => c.data === flagValue) !== undefined;
  }
}

angular
  .module("PortalApp")
  .controller(
    "Rhinestone.StatusSearchFilterController",
    StatusSearchFilterController
  );
