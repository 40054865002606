import { SearchRequestClient } from "@rhinestone/portal-web-react";

export interface ISavedSearchQuery {
  name: string;
  queryStringParams: string;
  searchRequest: SearchRequestClient;
  savedSearchQueryId: string;
  showRemoveButton: boolean;
}

export class SavedSearchQuery implements ISavedSearchQuery {
  public savedSearchQueryId: string;

  constructor(
    public name: string,
    public queryStringParams: string,
    public searchRequest: SearchRequestClient
  ) {}

  public showRemoveButton: boolean;
}
