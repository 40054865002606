import { IPortalConfig, PortalConfig } from "./portal.provider";
import * as angular from "angular";
import { ICurrentUserService } from "../User/currentUser.service";
import { RouteInfo } from "./router.config";
import {
  SendPageViewGtmEvent,
  SendAnonymousPageViewGtmEvent
} from "@rhinestone/portal-web-react";

// import root app module
import "../app.module";

// import mini profiler
import { loadMiniProfiler } from "./MiniProfiler";

// import general configuration scripts for the application
import "./portal.config";
import "./router.config";
import "./httpProvider.config";
import "./compiler.config";
import "./logService.config";
import "./material.config";
import "./select.config";
import "./translate.config";
import "./localStorage.config";

// configure infinite scroll
angular.module("infinite-scroll").value("THROTTLE_MILLISECONDS", 250);

// shared const
import "./constants";

// shared services
import "./logger.service";
import "../Services/devicedetection.service";
import "../Services/DataService/PortalDataHttpService";
import "../Services/localized.notification.service";
import "../Services/notification.service";
import "../Services/products.service";
import "../Services/dialog.service";
import "../Layout/error.service";
import "../Services/mobileDetector.service";
import "../Layout/MobileMenu/mobile-menu.service";
import "../Assets/assets.service";
import "../Documents/SelectionStatusService";
import "../Search/url.service";
import "../Services/user.service";
import "../Controls/loginDialog.service";
import "../Services/keepScrollPositionService";
import "../Documents/DocumentFieldset/documentFieldset.service";
import "../Documents/Toc/toc.service";
import "../Documents/DocumentVersionSubscriptions/documentVersionSubscription.service";

// filters
import "../Filters/trustAsHtml.filter";
import "../Filters/urlEncode.filter";
import "../Filters/sentencingString.filter";
import {
  bootStrapErrorHandler,
  initializeApplication
} from "./app-bootstrap-helpers";
import { UserProfileDto, UserViewModel } from "@rhinestone/portal-web-api";
export class RunConfig {
  public static $inject = [
    "$window",
    "$rootScope",
    "$location",
    "currentUserService",
    "portal",
    "amMoment"
  ];

  constructor(
    $window: angular.IWindowService,
    $rootScope: angular.IRootScopeService,
    $location: angular.ILocationService,
    currentUser: ICurrentUserService,
    portal: IPortalConfig,
    amMoment: any
  ) {
    // initialize current user service during app run.
    // this ensures that we know whether a user is authenticated or nor at run time
    const getUserPromise = currentUser.getUser();
    const getUserProfilePromise = currentUser.getUserProfile();

    // Since web tracking on route change depends on Authenticated status
    // we wait for GetUser to complete before subscribing
    const subscribeRouteChangeAuthenticated = ([user, profile]: [
      UserViewModel,
      UserProfileDto
    ]) => {
      $rootScope.$on("$routeChangeSuccess", (_e: any, current: any, _p: any) =>
        SendPageViewGtmEvent(
          $location.path(),
          current.data?.title,
          user,
          profile
        )
      );
    };

    const subscribeRouteChangeAnonymous = () => {
      $rootScope.$on("$routeChangeSuccess", (_e: any, current: any, _p: any) =>
        SendAnonymousPageViewGtmEvent($location.path(), current.data?.title)
      );
    };

    Promise.all([getUserPromise, getUserProfilePromise])
      .then(subscribeRouteChangeAuthenticated)
      .catch(subscribeRouteChangeAnonymous);

    $rootScope.$on(
      "$locationChangeStart",
      (e: angular.IAngularEvent, newUrl, oldUrl) => {
        // HACK ALERT: This is due to the mashing of MVC and angular and
        // the manipulation of the url when searching.

        // allow login flow
        if (newUrl.indexOf("/Account") > 0) {
          if (newUrl !== oldUrl) {
            // prevent routing from continuing
            e.preventDefault();

            // force loading from server as login is still handled my Mvc controller
            $window.location.href = newUrl;
            return;
          }
        }
      }
    );

    // set the portal language to the moment component
    amMoment.changeLocale(portal.defaultLanguage);

    $rootScope.$on(
      "$routeChangeStart",
      (changeRouteEvent, nextRoute: RouteInfo, _currentRoute?: RouteInfo) => {
        // because we need to know the authenticated state on startup (because the routing cant wait for the currentUser service, to get authenticated state)
        // we use the bootstrap configuration property isUserAuthenticatedOnServer to decide if user is authenticated
        // at some point we will shift to pure client side authentication state management and Angular2, where we have the
        // possibility to bootstrap application using Promises so that App initialization will wait until async bootstrap calls have been completed
        // this way we do it now will still work since the currentUser service reloads the application if the gets logged out as per logic/decision making
        // in the currentUser service
        if (
          nextRoute.$$route &&
          nextRoute.$$route.authenticatedOnly &&
          !portal.isUserAuthenticatedOnServer
        ) {
          console.log("DENY anonymous access. Redirecting to login flow...");
          changeRouteEvent.preventDefault();
          $window.location.href = `/Account/SignIn?returnUrl=${encodeURIComponent(
            $location.url()
          )}`;
        }
      }
    );
  }
}

if (process.env.NODE_ENV !== "production") {
  // running in debug mode import MiniProfiler
  loadMiniProfiler();
}

angular.module("PortalApp").run(RunConfig);

// Initialize delayed bootstrapping of application
// by calling endpoint that provides SPA configuration data

// As a fallback for title we set it to hostname before fetching bootstrap data
document.title = location.hostname;
fetch("/bootstrap")
  .then(async res => {
    if (res.ok) {
      return res;
    }
    const aspNetCoreError = await res.json();
    throw Error(`${res.status}: ${aspNetCoreError.message}`);
  })
  .then(async res => {
    return initializeApplication(await res.json());
  })
  .catch(bootStrapErrorHandler);
