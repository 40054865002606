import * as angular from "angular";
import * as _ from "underscore";
import { SimpleChange } from "../../../TypeDefinitions/angularextensions";
import {
  Criteria,
  CriteriaChangeType,
  CriteriaChange,
  CriteriaChipModel,
  CriteriaProvider
} from "@rhinestone/portal-web-react";
export class CriteriaPanelController {
  // Input bindings
  public criteriaProviders: CriteriaProvider[];
  public criteriaList: ReadonlyArray<Criteria>;

  // output bindings
  public onCriteriaChanged: (params: { action: CriteriaChange }) => void;

  // internal state
  public criteriaChips: Array<{ item: CriteriaChipModel; id: string }> = [];

  public static $inject: any[] = [];
  public $onChanges(changes: {
    criteriaProviders?: SimpleChange<CriteriaProvider[]>;
    criteriaList?: SimpleChange<Criteria[]>;
  }) {
    if (changes.criteriaProviders) {
      this.criteriaProviders = changes.criteriaProviders.currentValue;
    }
    if (changes.criteriaList) {
      this.criteriaList = changes.criteriaList.currentValue;
    }

    this.syncCriteriaProviderList();
  }

  public $onDestroy() {
    // Make sure to clear references to providers, and these indirectly have references to Angular components (the criteria groups)
    // to be defensive against memory leaks.....
    this.criteriaProviders = null;
    this.criteriaList = null;
  }

  public getCriteriaTooltip(criteria: CriteriaChipModel) {
    return [criteria.displayValue, criteria.longDisplayValue]
      .filter(Boolean)
      .join(" - ");
  }

  public syncCriteriaProviderList() {
    if (!this.criteriaList) {
      return;
    }
    // sync current criteria list with actual view bound list for all known providers
    // We can just set whole new list on each sync call, because we use track by in ng-repeat which ensures performance
    this.criteriaChips = this.criteriaList
      .map(criteria => {
        const provider = _.find(
          this.criteriaProviders,
          cp => cp.key === criteria.providerKey
        );
        if (!provider) {
          return null;
        }
        return provider.getCriteriaViewModel(criteria);
      })
      .filter(cvm => cvm !== null)
      .map(cvm => {
        return {
          item: cvm,
          id: `${cvm.criteria.providerKey}-${cvm.displayValue ||
            cvm.displayValueResource}-${JSON.stringify(cvm.criteria.data)}` // id for ng-repeat
        };
      });
  }

  public remove(criteria: Criteria): void {
    this.onCriteriaChanged({
      action: {
        changes: [
          {
            criteria,
            type: CriteriaChangeType.Remove
          }
        ]
      }
    });
  }
}
angular
  .module("PortalApp")
  .controller("Rhinestone.CriteriaPanelController", CriteriaPanelController);
