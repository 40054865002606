import * as angular from "angular";
import * as _ from "underscore";
import { IPortalDataHttpService } from "../../Services/DataService/PortalDataHttpService";
import { IMobileDetectorService } from "../../Services/mobileDetector.service";
import { ISearchSuggestion } from "./ISearchSuggestion";
import { ISearchSuggestionsProvider } from "./Providers/ISearchSuggestionsProvider";
import { SearchSuggestionsProviderFactory } from "./Providers/SearchSuggestionsProviderFactory";
import { SearchSuggestionsProviderModel } from "@rhinestone/portal-web-api";

export interface IGroupedResult {
  provider: ISearchSuggestionsProvider;
  result: ISearchSuggestion[];
}

export class SearchAssistantService {
  private providers: ISearchSuggestionsProvider[];

  public static $inject = [
    "$q",
    "portalDataService",
    "searchSuggestionsProviderFactory",
    "mobileDetectorService"
  ];

  constructor(
    private $q: angular.IQService,
    private portalDataService: IPortalDataHttpService,
    private searchSuggestionsProviderFactory: SearchSuggestionsProviderFactory,
    private mobileDetectorService: IMobileDetectorService
  ) {
    this.getSearchAssistantProviders().then(x => {
      this.providers = x;
    });
  }

  public loadSuggestions(
    query: string,
    callBackWhenDone: (groupedResult: IGroupedResult[]) => void
  ) {
    // initiate call to all providers
    const results = this.providers.map(p => {
      const deferred = this.$q.defer<IGroupedResult>();

      // Maintain relation between provider and results by returning object with both
      // this will start calling terms providers
      p.getSuggestions(query)
        .then(result => {
          deferred.resolve({
            provider: p,
            result
          });
        })
        .catch(err => {
          console.log("Failed to get suggestions from provider:");
          console.warn(err);
          deferred.resolve(null);
        });

      return deferred.promise;
    });

    // Wait for all providers and then load with provider
    this.$q.all(results).then(
      items => {
        if (callBackWhenDone == null) {
          return;
        }
        // Failed suggestions will be null
        callBackWhenDone(items.filter(i => i != null));
      },
      err => {
        console.warn(err);
      }
    );
  }

  private getSearchAssistantProviders(): angular.IPromise<
    ISearchSuggestionsProvider[]
  > {
    const deferred = this.$q.defer<ISearchSuggestionsProvider[]>();

    this.portalDataService
      .get<SearchSuggestionsProviderModel[]>("SearchSuggestionsProviders")
      .then(providerModels => {
        const promises = providerModels.map(providerModel => {
          return this.searchSuggestionsProviderFactory.create(providerModel);
        });

        this.$q.all<ISearchSuggestionsProvider>(promises).then(providers => {
          if (this.mobileDetectorService.isMobile()) {
            providers = _.filter(
              providers,
              p => p.config.availableOnMobile === true
            );
          }

          deferred.resolve(_(providers).sortBy("config.order"));
        });
      });

    return deferred.promise;
  }
}

angular
  .module("PortalApp")
  .service("searchAssistantService", SearchAssistantService);
