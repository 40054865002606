import { IPortalConfig } from "../../Core/portal.provider";
import * as angular from "angular";

export class Toc {
  public entries: TocEntry[];
}

export class TocEntry {
  public heading: string;
  public target: string;
  public children: TocEntry[];
}

export interface ITocService {
  get(hiveId: string, fullName: string): angular.IPromise<Toc>;
}

export class TocService implements ITocService {
  public static $inject = ["$http", "$q", "portal"];

  constructor(
    private $http: angular.IHttpService,
    private $q: angular.IQService,
    private portal: IPortalConfig
  ) {}

  public get(hiveId: string, fullName: string): angular.IPromise<Toc> {
    const deferred = this.$q.defer<Toc>();

    this.$http
      .get<Toc>(
        `/api/Portals(${
          this.portal.identifier
        })/Documents/Toc/h/${hiveId}/${fullName}`
      )
      .then(result => {
        deferred.resolve(result.data);
      })
      .catch(() => {
        deferred.reject("Could not get table of content");
      });

    return deferred.promise;
  }
}

angular.module("PortalApp").service("tocService", TocService);
