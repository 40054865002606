import * as angular from "angular";
import * as _ from "underscore";
import {
  Criteria,
  CriteriaChangeType,
  CriteriaChange,
  CriteriaProvider
} from "@rhinestone/portal-web-react";

export interface ISearchCriteriaStateService {
  criteriaChangedSubject: Rx.BehaviorSubject<{
    criteria: ReadonlyArray<Criteria>;
    /**
     * Indicates if the criteria list is the default criteria list
     * Default criteria is a concept that a portal can be configured
     * with predefined criterias that will fill out on an empty search page
     */
    isDefaultCriteria: boolean;
  }>;
  criteriaProvidersSubject: Rx.BehaviorSubject<ReadonlyArray<CriteriaProvider>>;
  setCriteriaList(
    criteria: ReadonlyArray<Criteria>,
    isDefaultCriteria?: boolean
  ): void;
  handleCriteriaChanged(action: CriteriaChange): void;
  registerCriteriaProvider(provider: CriteriaProvider): void;
}

export class SearchCriteriaStateService implements ISearchCriteriaStateService {
  public criteriaProvidersSubject: Rx.BehaviorSubject<
    ReadonlyArray<CriteriaProvider>
  > = new Rx.BehaviorSubject<ReadonlyArray<CriteriaProvider>>([]);
  public criteriaChangedSubject = new Rx.BehaviorSubject<{
    criteria: ReadonlyArray<Criteria>;
    isDefaultCriteria: boolean;
  }>({
    criteria: [],
    isDefaultCriteria: false
  });

  public static $inject: string[] = [];

  constructor() {}

  public setCriteriaList(
    criteria: ReadonlyArray<Criteria>,
    isDefaultCriteria = false
  ): void {
    this.criteriaChangedSubject.onNext({ criteria, isDefaultCriteria });
  }

  public handleCriteriaChanged(action: CriteriaChange): void {
    // Get changes to remove
    const criteriasToRemove = action.changes
      .filter(c => c.type === CriteriaChangeType.Remove)
      .map(c => c.criteria);

    const { criteria: existingCriteria } =
      this.criteriaChangedSubject.getValue();

    // build new list
    const newCriteriaList = existingCriteria.filter(
      x => !_(criteriasToRemove).find((removed: any) => _(x).isEqual(removed))
    );

    const criteriasToAdd = action.changes
      .filter(c => c.type === CriteriaChangeType.Add)
      .map(c => c.criteria)
      .filter(
        c =>
          !_(newCriteriaList).find<Criteria>((existing: any) =>
            _.isEqual(existing, c)
          )
      );

    // And add new criterias
    const criteria = newCriteriaList.concat(criteriasToAdd);
    this.criteriaChangedSubject.onNext({ criteria, isDefaultCriteria: false });
  }

  public registerCriteriaProvider(provider: CriteriaProvider): void {
    const existingCriteriaProviders = this.criteriaProvidersSubject.getValue();

    const providerAlreadyExist = existingCriteriaProviders.some(
      p => p.key === provider.key
    );

    const newProviderList = providerAlreadyExist
      ? // Replace existing provider with new one
        existingCriteriaProviders.map(p =>
          p.key === provider.key ? provider : p
        )
      : // Add new provider
        [...existingCriteriaProviders, provider];

    this.criteriaProvidersSubject.onNext(newProviderList);
  }
}

angular
  .module("PortalApp")
  .service("searchCriteriaStateService", SearchCriteriaStateService);
