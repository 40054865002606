import * as angular from "angular";
import { Constants } from "../app.constants";
import { BaseComponent } from "../Core/BaseComponent";
import "./invalidTicket.controller";
import "./invalidTicket.html";
export class InvalidTicketComponent extends BaseComponent {
  constructor() {
    super(
      "Rhinestone.InvalidTicketController",
      `${Constants.TemplateBaseUrl}/Error/invalidTicket`
    );
  }
}

angular
  .module("PortalApp")
  .component("rhInvalidTicket", new InvalidTicketComponent());
