import * as angular from "angular";
import * as _ from "underscore";
import { SimpleChange } from "../../../TypeDefinitions/angularextensions";
import { MobileMenuService } from "../../Layout/MobileMenu/mobile-menu.service";
import { SidePaneService } from "../../Layout/SidePane/sidepane.service";
import { MobileDetectorService } from "../../Services/mobileDetector.service";
import { ITaxonomyService } from "../../Services/taxonomy.service";
import { Taxonomy } from "../../Services/models/taxonomy";
import { TaxonomyPathBuilder } from "./taxonomyPathBuilder";
import { SearchService } from "../../Search/search.service";
import { ProductPageService } from "../productPage.service";
import { SearchPageConfigurationService } from "../../Search/searchPageConfigurationService";
import { Taxon } from "../../Services/models/taxon";
import { isNullOrUndefined } from "./../../Utils/isNullOrEmpty";
import {
  Criteria,
  SearchRequestClient,
  serializeSearchRequest,
  UrlTokens
} from "@rhinestone/portal-web-react";
import { SearchViewType } from "@rhinestone/portal-web-api";

export class ProductPageTaxonomyController {
  public productCode: string;
  public taxonomyName: string;
  public expandTo: number | null;

  public loadingTaxonomies: boolean = true;
  public taxonomyLoadError: boolean = false;
  public noTaxonomies: boolean = false;
  public showTaxonDocuments: boolean = false;
  private defaultSearchView: SearchViewType;

  public static $inject = [
    "taxonomyService",
    "$scope",
    "$location",
    "$timeout",
    "mobileDetectorService",
    "mobileMenuService",
    "sidePaneService",
    "searchService",
    "searchPageConfigurationService",
    "productPageService"
  ];

  constructor(
    private taxonomyService: ITaxonomyService,
    private $scope: angular.IScope & { taxonomy: Taxonomy },
    private $location: angular.ILocationService,
    private $timeout: angular.ITimeoutService,
    private mobileDetectorService: MobileDetectorService,
    private mobileMenuService: MobileMenuService,
    private sidePaneService: SidePaneService,
    private searchService: SearchService,
    private searchPageConfigurationService: SearchPageConfigurationService,
    private productPageService: ProductPageService
  ) {}

  public $onChanges(changes: { taxonomyName?: SimpleChange<string> }) {
    if (
      changes.taxonomyName &&
      !isNullOrUndefined(changes.taxonomyName.currentValue)
    ) {
      this.loadProductNavigationTaxonomy();
    }

    if (
      changes.taxonomyName &&
      isNullOrUndefined(changes.taxonomyName.currentValue)
    ) {
      // taxonomy was loaded but it's set to null in the backend - we show an empty taxonomy pane
      this.loadingTaxonomies = false;
    }
  }

  public async $onInit() {
    try {
      this.loadingTaxonomies = true;

      const defaultSearchView = await this.searchService.getDefaultSearchView();
      this.defaultSearchView = defaultSearchView;
    } catch (e) {
      console.error("error loading default search view", e);
    }
  }

  public async loadProductNavigationTaxonomy() {
    try {
      const productPageConfiguration =
        await this.productPageService.getProductPageConfiguration(
          this.productCode
        );

      this.showTaxonDocuments =
        productPageConfiguration.navigation.showTaxonDocuments;

      this.$scope.taxonomy = await this.taxonomyService.getTaxonomyTree(
        this.taxonomyName,
        {
          criteria: [
            {
              providerKey: "products",
              data: this.productCode
            }
          ],
          showDocuments: productPageConfiguration.navigation.showTaxonDocuments,
          showEmptyTaxons: productPageConfiguration.navigation.showEmptyTaxons
        }
      );

      this.noTaxonomies = this.$scope.taxonomy.children.length === 0;

      this.taxonomyLoadError = false;
      const taxonKey = this.getTaxonKeyFromUrl();

      const expandTaxon = (taxon: Taxon, expandTo: number) => {
        const taxonLevel = this.getTaxonLevel(taxon);
        if (taxonLevel <= expandTo) {
          taxon.expanded = true;
          if (taxon.children) {
            for (const child of taxon.children) {
              expandTaxon(child, expandTo);
            }
          }
        }
      };

      if (this.expandTo) {
        for (const taxon of this.$scope.taxonomy.children) {
          expandTaxon(taxon, this.expandTo);
        }
      }
      if (angular.isDefined(taxonKey)) {
        this.expandTaxonomyPane();

        this.$timeout(() => {
          const taxons = TaxonomyPathBuilder.getPath(
            taxonKey,
            this.$scope.taxonomy
          );
          _.each(taxons, x => (x.expanded = true));
        });
      }
    } catch (e) {
      this.taxonomyLoadError = true;
      console.log("error loading a taxonomy", e);
    }

    this.loadingTaxonomies = false;
    this.$scope.$apply();
  }

  public getTaxonLevel(taxon: Taxon) {
    let level = 1;
    if (taxon.parent) {
      level += this.getTaxonLevel(taxon.parent);
    }
    return level;
  }
  public goToSearchPage(taxon: Taxon): void {
    this.createSearchUrl(taxon).then(searchQueryString => {
      // This timeout is needed because of some weird timing issue.
      // https://stackoverflow.com/questions/24143945/location-path-updates-after-the-second-click
      this.$timeout(() => {
        this.$location.url(`/Soeg?search=${searchQueryString}`);
      }, 0);
    });
  }

  private async createSearchUrl(taxon: Taxon): Promise<string> {
    const productTaxonomyCriteria: Criteria[] = [
      {
        providerKey: "products",
        data: this.productCode
      },
      {
        providerKey: this.taxonomyName,
        data: taxon.key
      }
    ];

    const defaultCriteria =
      await this.searchPageConfigurationService.getDefaultCriteriaConfiguration();

    const searchRequestClient: SearchRequestClient = {
      fieldSetName: this.defaultSearchView.documentFieldSetName,
      criteria: defaultCriteria.concat(productTaxonomyCriteria),
      ordering: null,
      skip: 0,
      page: 1,
      take: this.defaultSearchView.defaultPageSize,
      resultViewName: this.defaultSearchView.name
    };
    const uri = serializeSearchRequest(searchRequestClient);
    return encodeURI(uri);
  }

  private expandTaxonomyPane() {
    if (this.mobileDetectorService.isMobile() === true) {
      this.mobileMenuService.showMenu("mobileMenu");
    } else {
      this.sidePaneService.showSidePane("sidePane");
    }
  }

  private toggle(scope: any, event: any) {
    scope.toggle();
  }

  /**
   * Update the current URL with information on the state of the navigation-taxonomy.
   * This is called just before navigating away, so that using the back-arrow
   * will navigate here with the taxon open.
   */
  public updateCurrentUrlWithTaxonKey(taxonKey: string): void {
    this.$location.replace();
    this.$location.search({
      ...this.$location.search(),
      [UrlTokens.taxonToken]: taxonKey
    });
  }

  public getUrlWithTaxonPath(url: string, taxonKey: string): string {
    return `${url}?${
      UrlTokens.pathToken
    }=${TaxonomyPathBuilder.getSerializedPath(
      taxonKey,
      this.productCode,
      this.$scope.taxonomy
    )}&${UrlTokens.showExact}=true`;
  }

  private getTaxonKeyFromUrl(): string {
    const search = this.$location.search();
    return search[UrlTokens.taxonToken];
  }
}

angular
  .module("PortalApp")
  .controller(
    "Rhinestone.ProductPageTaxonomyController",
    ProductPageTaxonomyController
  );
