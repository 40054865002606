import * as angular from "angular";

export interface IErrorService {
  registerError(message: string): void;
}

export class ErrorService implements IErrorService {
  public errors: string[] = [];

  public static $inject: any[] = [];

  constructor() {}

  public registerError(message: string) {
    this.errors.push(message);
  }
}

angular.module("PortalApp").service("errorService", ErrorService);
