import "./criteriaPanel.html";
import { BaseComponent } from "./../../Core/BaseComponent";
import * as angular from "angular";
import { Binding } from "../../Utils/AngularBindings";
import "./criteriaPanel.less";
import "./criteriaPanel.controller";
export class CriteriaPanelComponent extends BaseComponent {
  constructor() {
    super(
      "Rhinestone.CriteriaPanelController",
      "/templates/Search/CriteriaPanel/criteriaPanel.html"
    );
    this.bindings = {
      onCriteriaChanged: Binding.outputEvent,
      criteriaList: Binding.oneWay,
      criteriaProviders: Binding.oneWay
    };
  }
}

angular
  .module("PortalApp")
  .component("rhCriteriaPanel", new CriteriaPanelComponent());
