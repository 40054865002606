import { SidePaneController } from "./SidePane/sidepane.controller";
import * as angular from "angular";

export class LayoutController {
  private leftSidePaneExpanded: boolean = false;
  private leftSidePaneShow: boolean = false;
  private leftSidePanePinned: boolean = false;
  private rightSidePaneExpanded: boolean = false;
  private rightSidePaneShow: boolean = false;
  private rightSidePanePinned: boolean = false;

  private sidepanes = new Array<SidePaneController>();

  public static $inject = ["$route"];

  constructor(private $route: any) {}

  public registerSidepane(sidePane: SidePaneController) {
    this.sidepanes.push(sidePane);
  }

  public deregisterSidepane(sidePane: SidePaneController) {
    const i = this.sidepanes.indexOf(sidePane);
    if (i > -1) {
      this.sidepanes.splice(i, 1);
    }
    this.fitMainContent(sidePane.position, false, false, true);
  }

  public toggleSidepanes() {
    this.sidepanes.forEach(pane => pane.toggleHide());
  }

  public fitMainContent(
    position: string,
    pinned: boolean,
    expanded: boolean,
    hidden: boolean
  ) {
    if (position === "left") {
      this.leftSidePaneExpanded = expanded;
      this.leftSidePaneShow = !hidden;
      this.leftSidePanePinned = pinned;
    }
    if (position === "right") {
      this.rightSidePaneExpanded = expanded;
      this.rightSidePaneShow = !hidden;
      this.rightSidePanePinned = pinned;
    }
  }
}

angular
  .module("PortalApp")
  .controller("Rhinestone.LayoutController", LayoutController);
