import { IPortalConfig } from "../Core/portal.provider";
import * as angular from "angular";

export class TemplateHttpInterceptor implements angular.IHttpInterceptor {
  public request: (config: angular.IRequestConfig) => angular.IRequestConfig;

  public static factory(portal: IPortalConfig) {
    return new TemplateHttpInterceptor(portal);
  }

  constructor(portal: IPortalConfig) {
    // function needs to be a property as we need a closure to ensure correct reference to the portalconfig
    this.request = (config: angular.IRequestConfig) => {
      if (config.url.indexOf("/templates/") !== -1) {
        config.url = config.url.replace(
          "/templates/",
          `/api/Portals(${portal.identifier})/templates/`
        );
      }
      return config;
    };
  }
}
