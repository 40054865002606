import * as angular from "angular";
import * as _ from "underscore";
import { ISearchSuggestionsProvider } from "./ISearchSuggestionsProvider";
import { ITaxonomyStateService } from "../../taxonomyState.service";
import { ITaxon } from "./ITaxon";
import { ISearchSuggestion } from "../ISearchSuggestion";
import { ISearchCriteriaStateService } from "../../searchCriteriaState.service";
import { ISearchSuggestionsProviderConfig } from "./ISearchSuggestionsProviderConfig";
import { CriteriaChangeType } from "@rhinestone/portal-web-react";
import { TaxonomyController } from "../../Taxonomy/taxonomy.controller";

interface TaxonsSearchSuggestionsProviderPayload {
  useLongTitle: boolean;
}

export class TaxonsSearchSuggestionsProvider
  implements ISearchSuggestionsProvider {
  public config: ISearchSuggestionsProviderConfig<
    TaxonsSearchSuggestionsProviderPayload
  >;
  private translationDict: { [key: string]: string } = {};

  public static $inject = [
    "$q",
    "$translate",
    "searchCriteriaStateService",
    "taxonomyStateService"
  ];

  constructor(
    private $q: angular.IQService,
    private $translate: angular.translate.ITranslateService,
    private searchCriteriaStateService: ISearchCriteriaStateService,
    private taxonomyStateService: ITaxonomyStateService
  ) {}

  public getSuggestions(query: string): angular.IPromise<ISearchSuggestion[]> {
    const deferred = this.$q.defer<ISearchSuggestion[]>();
    const suggestions: ISearchSuggestion[] = [];
    const flatTaxons = this.taxonomyStateService.getFlatTaxons();
    this.translateTaxonomyNames(flatTaxons);

    // get the value indicating whether to show taxon long title
    const useLongTitle: boolean =
      (this.config.payload && this.config.payload.useLongTitle) || false;
    const filteredTaxons = this.filterTaxons(flatTaxons, query, useLongTitle);

    _.forEach(filteredTaxons, taxon => {
      suggestions.push({
        title: `${taxon.translatedTaxonomyName}: ${
          useLongTitle ? taxon.taxonLongTitle : taxon.taxonTitle
        }`,
        payload: {
          criteriaProviderKey: taxon.criteriaProviderKey,
          taxonKey: taxon.taxonKey
        }
      });
    });

    deferred.resolve(_(suggestions).sortBy("title"));

    return deferred.promise;
  }

  public suggestionSelected(suggestion: ISearchSuggestion): void {
    const taxonomy = this.taxonomyStateService
      .getTaxonomies()
      .find(
        t => t.criteriaProviderKey === suggestion.payload.criteriaProviderKey
      );
    const taxon = taxonomy.flatTaxons.find(
      t => t.key === suggestion.payload.taxonKey
    );

    // We call through the TaxonomyController to get access to the same logic as when selecting a taxonomy from the list, like unselecting
    // parents and children of the newly selected taxon.
    // A better solution would be to just select a taxon where-ever and then have a centralized normalization engine to take care of
    // special cases. Thoughts for react-implementation!
    this.searchCriteriaStateService.handleCriteriaChanged(
      TaxonomyController.buildTaxonCriteria(
        taxonomy.criteriaProviderKey,
        taxon,
        CriteriaChangeType.Add
      )
    );
  }

  private translateTaxonomyNames(flatTaxons: ITaxon[]): void {
    const language = this.$translate.use();

    flatTaxons.forEach(taxon => {
      const translationKey = `taxonomies.${taxon.taxonomyName}`;
      const translationDictKey = `${language}-${translationKey}`;
      if (!this.translationDict.hasOwnProperty(translationDictKey)) {
        const translation = this.$translate.instant(translationKey);
        this.translationDict[translationDictKey] = translation;
      }

      if (this.translationDict[translationDictKey] !== translationKey) {
        taxon.translatedTaxonomyName = this.translationDict[translationDictKey];
      }
    });
  }

  private filterTaxons(taxons: ITaxon[], query: string, useLongTitle: boolean) {
    let filteredTaxons = _(taxons).sortBy(
      useLongTitle ? "taxonLongTitle" : "taxonTitle"
    );
    filteredTaxons = _.filter<ITaxon>(filteredTaxons, t =>
      this.compare(query, useLongTitle ? t.taxonLongTitle : t.taxonTitle)
    );

    if (this.config.maxItems && this.config.maxItems > 0) {
      return _.first(filteredTaxons, this.config.maxItems);
    }

    return filteredTaxons;
  }

  private compare(query: string, title: string): boolean {
    return title.toLocaleLowerCase().indexOf(query.toLocaleLowerCase()) >= 0;
  }
}

angular
  .module("PortalApp")
  .service("taxonsSearchSuggestionsProvider", TaxonsSearchSuggestionsProvider);
