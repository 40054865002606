import "./confirmSubscriptionView.html";
import { BaseComponent } from "../../Core/BaseComponent";
import * as angular from "angular";
import "./confirmSubscriptionView.controller";

export class ConfirmSearchSubscriptionsViewComponent extends BaseComponent {
  constructor() {
    super(
      "Rhinestone.ConfirmSearchSubscriptionViewController",
      "/templates/SearchSubscriptions/ConfirmSubscriptionView/confirmSubscriptionView"
    );
  }
}

angular
  .module("PortalApp")
  .component(
    "rhConfirmSearchSubscriptionView",
    new ConfirmSearchSubscriptionsViewComponent()
  );
