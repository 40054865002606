import * as angular from "angular";

export class SearchBoxController {
  public searchText: string;

  public isLoading: boolean = false;
  public autoFocus: boolean = false;

  public onSubmit: () => void;
  public onLinked: () => void;

  public static $inject = ["$element", "$translate"];

  constructor(
    private $element: JQuery,
    private $translate: angular.translate.ITranslateService
  ) {}

  public $postLink() {
    if (this.autoFocus !== undefined) {
      this.$element.find("#search-input").focus();
    }
    this.onLinked();
  }
}

angular
  .module("PortalApp")
  .controller("Rhinestone.SearchBoxController", SearchBoxController);
