import * as angular from "angular";
import * as _ from "underscore";
import { LayoutController } from "../layout.controller";
import { IMediaService, ScreenSize } from "../media.service";
import { ISidePane, SidePaneService } from "./sidepane.service";
import { SidePaneSectionController } from "./sidepaneSection.controller";
import { SidePaneStorageService } from "./sidepaneStorage.service";
import { AnnotationEvents } from "../../Annotations/AnnotationEvents";

export class SidePaneController implements ISidePane {
  public readonly instanceId: string = _.uniqueId();

  // bindings
  public id: string;
  public position: string;
  public expanded: boolean;

  // require
  private layout: LayoutController;

  // state
  public sections = new Array<SidePaneSectionController>();
  private isExpanded = false;
  private isHidden = false;
  private isPinned = false;

  private clickAnywhereHandler: (e: Event) => void;

  // inject
  public static $inject = [
    "$element",
    "$rootScope",
    "$scope",
    "mediaService",
    "sidePaneService",
    "sidePaneStorageService"
  ];

  constructor(
    private $element: JQuery,
    private $rootScope: angular.IScope,
    private $scope: angular.IScope,
    private mediaService: IMediaService,
    private sidePaneService: SidePaneService,
    private sidePaneStorageService: SidePaneStorageService
  ) {}

  public $onInit() {
    this.layout.registerSidepane(this);
    this.sidePaneService.registerSidepane(this);
    this.initializePinnedAndExpandedState();
    this.layout.fitMainContent(
      this.position,
      this.isPinned,
      this.isExpanded,
      this.isHidden
    );

    this.clickAnywhereHandler = (e: Event) => {
      const ancestors = jQuery(e.target).parents();
      const targetIsInsidePane = ancestors.filter(this.$element).length > 0;
      const targetIsInsideAnOverlay =
        ancestors.filter('[role="presentation"]').length > 0;
      if (targetIsInsidePane || targetIsInsideAnOverlay) {
        return;
      }
      this.closeIfNotPinned();
      this.$scope.$apply();
    };
    document.body.addEventListener("click", this.clickAnywhereHandler);
  }

  public sidePaneName: string = "sidePane";

  public hide(): void {
    this.closeIfNotPinned();
    this.sections.forEach(section => section.close());
  }

  public show(): void {
    this.setExpanded(true);
  }

  public registerSection(section: SidePaneSectionController) {
    this.sections.push(section);
  }

  public setExpanded(expanded: boolean) {
    this.isExpanded = expanded;
    this.layout.fitMainContent(
      this.position,
      this.isPinned,
      this.isExpanded,
      this.isHidden
    );
    this.sidePaneStorageService.storeExpandedState(this.id, this.isExpanded);
  }

  public toggleExpand() {
    this.isExpanded = !this.isExpanded;
    this.layout.fitMainContent(
      this.position,
      this.isPinned,
      this.isExpanded,
      this.isHidden
    );
    // Annotations absolute position needs to be recalculated on sidebar toggles,
    // as it has a re-scaling effect on the document view/annotations-container.
    // It's important to wait until the sidebar animation has finished,
    // hence the 200ms delay is added in the first parameter.
    this.reflowAnnotations(200);
    this.sidePaneStorageService.storeExpandedState(this.id, this.isExpanded);
  }

  public togglePin() {
    this.isPinned = !this.isPinned;
    this.layout.fitMainContent(
      this.position,
      this.isPinned,
      this.isExpanded,
      this.isHidden
    );
    // Annotations absolute position needs to be recalculated on sidebar pin state changes,
    // as it has a re-scaling effect on the document view/annotations-container.
    // It's important to wait until the sidebar animation has finished,
    // hence the 200ms delay is added in the first parameter.
    this.reflowAnnotations(200);
    this.sidePaneStorageService.storedPinnedState(this.id, this.isPinned);
  }

  public closeIfNotPinned() {
    if (!this.isPinned && this.mediaService.getScreenSize() !== ScreenSize.Xs) {
      this.setExpanded(false);
    }
  }

  public toggleHide() {
    this.isHidden = !this.isHidden;
    this.layout.fitMainContent(
      this.position,
      this.isPinned,
      this.isExpanded,
      this.isHidden
    );
  }

  public getExpanded(): boolean {
    return this.isExpanded;
  }

  public $onDestroy() {
    this.layout.deregisterSidepane(this);
    this.sidePaneService.unregisterSidePane(this);
    document.body.removeEventListener("click", this.clickAnywhereHandler);
  }

  private reflowAnnotations(delayMs = 0) {
    setTimeout(
      () => this.$rootScope.$broadcast(AnnotationEvents.Reflow),
      delayMs
    );
  }

  private initializePinnedAndExpandedState() {
    const screenSize = this.mediaService.getScreenSize();
    const storedExpandedState = this.sidePaneStorageService.getExpandedState(
      this.id
    );
    const storedPinnedState = this.sidePaneStorageService.getPinnedState(
      this.id
    );

    switch (screenSize) {
      case ScreenSize.Xs:
        this.isHidden = true;
        break;
      case ScreenSize.Md:
        if (isNullOrUndefined(storedExpandedState)) {
          this.isExpanded = isNullOrUndefined(this.expanded)
            ? true
            : this.expanded;
        }
        if (isNullOrUndefined(storedPinnedState)) {
          this.isPinned = true;
        }
        break;
      default:
        break;
    }

    if (!isNullOrUndefined(storedExpandedState)) {
      this.isExpanded = storedExpandedState;
    }

    if (!isNullOrUndefined(storedPinnedState)) {
      this.isPinned = storedPinnedState;
    }
  }
}

const isNullOrUndefined = (value: any) => value === null || value === undefined;

angular
  .module("rhSidePane")
  .controller("Rhinestone.SidePaneController", SidePaneController);
