import * as angular from "angular";
import * as _ from "underscore";
import { SimpleChange } from "../../../TypeDefinitions/angularextensions";
import {
  Criteria,
  CriteriaChangeType,
  CriteriaChange,
  CriteriaProvider
} from "@rhinestone/portal-web-react";
import { ICriteriaGroupController } from "../Criterias/ICriteriaGroupController";
import { NewSearchEventArgs, SearchEvents } from "../search.events";
import { ITaxonomyStateService } from "./../taxonomyState.service";
import { TaxonomyToFlatTaxonListConverter } from "./../TaxonomyToFlatTaxonListConverter";
import { buildTaxonsCriteriaDescription } from "../Criterias/buildCriteriaDescriptionFunctions";
import { TaxonomyFilterKey } from "@rhinestone/portal-web-api";
import { ITaxonomyService } from "../../Services/taxonomy.service";
import { TaxonomyKeyMap } from "./taxonomyKeyMap";
import { Taxon } from "../../Services/models/taxon";
import { Taxonomy } from "./../../Services/models/taxonomy";
import { buildTaxonomyKeyMap } from "./buildTaxonomyKeyMap";
import { findTaxonFromKey } from "./findTaxonFromKey";
import {
  SetExpandedSidepaneSectionEventArgs,
  SidepaneSectionEvents,
  ToggleVisibilitySidepaneSectionEventArgs
} from "../../Layout/SidePane/sidepaneSection.events";

export class TaxonomyController implements ICriteriaGroupController {
  // Part of the binding
  public readonly taxonomyName: string;
  public readonly icon: string;
  public readonly color: string;
  public readonly providerKey: string;
  public onCriteriaChanged: (outparams: { action: CriteriaChange }) => {};
  public onLoaded: (param: { provider: CriteriaProvider }) => void;
  public criteria: ReadonlyArray<Criteria>;
  public readonly taxonomyFilterKey: TaxonomyFilterKey;
  public readonly showEmptyTaxons: boolean;
  public readonly showFacetCount: boolean;
  public readonly showEmptyTaxonsDependOnCriteria: string[];

  // view bound "virtualized" taxonomy
  private taxonomyKeyMap: TaxonomyKeyMap[];
  // The list is called virtual because initially children have been decoupled for performance
  private virtualTaxons: Taxon[] = [];
  private virtualTaxonIndices: Map<string, number> = new Map<string, number>();

  public loadingTaxonomies: boolean = true;
  public taxonomyLoadError: boolean = false;
  public noTaxonomies: boolean = false;
  private taxonomyLoadCanceller: {
    canceller: angular.IDeferred<any>;
    isCancelled: boolean;
  };

  public static $inject = [
    "$q",
    "$scope",
    "taxonomyService",
    "taxonomyStateService"
  ];

  constructor(
    private $q: angular.IQService,
    private $scope: angular.IScope & { taxonomy: Taxonomy },
    private taxonomyService: ITaxonomyService,
    private taxonomyStateService: ITaxonomyStateService
  ) {}

  public $onInit() {
    this.$scope.$on(SearchEvents.SearchCleared, this.collapseAll.bind(this));

    // updateCounts is used when showFacetCount is true
    this.$scope.$on(
      SearchEvents.NewSearch,
      (_event: angular.IAngularEvent, args: NewSearchEventArgs) => {
        this.updateCounts(args);
      }
    );
    this.$scope.$on(SearchEvents.SearchCleared, this.updateCounts.bind(this));

    if (this.taxonomyName) {
      // only register component as an initial taxonomy loader if taxonomyName is bound initially
      this.taxonomyStateService.registerComponentAsInitialTaxonomyLoader();
    }
  }

  private collapseAll() {
    // https://github.com/angular-ui-tree/angular-ui-tree/blob/master/examples/js/basic-example.js
    this.$scope.$broadcast("angular-ui-tree:collapse-all");
  }

  public $onDestroy() {
    this.taxonomyStateService.clear();
    this.taxonomyLoadCanceller?.canceller.resolve();
  }

  public $onChanges(changes: {
    criteria?: SimpleChange<ReadonlyArray<Criteria>>;
    taxonomyName?: SimpleChange<string>;
    providerKey?: SimpleChange<string>;
  }) {
    if (
      changes.criteria &&
      changes.criteria.currentValue &&
      this.virtualTaxons.length > 0
    ) {
      this.syncTaxonSelectedState();
    }

    this.reloadTaxonomy(changes);

    if (
      changes.providerKey &&
      !_.isEmpty(changes.providerKey.previousValue) &&
      _.isEmpty(changes.providerKey.currentValue)
    ) {
      // On changes of the acutal taxonomy, remove any taxon filters of that taxonomy
      const removedChanges = this.buildRemovalChanges(
        changes.providerKey.previousValue
      );
      if (removedChanges?.length > 0)
        this.onCriteriaChanged({
          action: {
            changes: removedChanges
          }
        });
    }
  }

  private syncTaxonSelectedState() {
    if (!this.criteria) {
      return;
    }
    const taxonKeysInCriteria = this.criteria
      .filter(c => c.providerKey === this.providerKey)
      .map(c => c.data);

    this.virtualTaxons.forEach(taxon =>
      this.syncTaxonState(taxon, taxonKeysInCriteria)
    );
  }

  private syncTaxonState(virtualTaxon: Taxon, taxonKeysInCriteria: string[]) {
    const isSelected = taxonKeysInCriteria.some(
      key => key === virtualTaxon.key
    );

    virtualTaxon.isSelected = isSelected;

    if (isSelected && virtualTaxon.parent) {
      this.lazyLoadAndExpand(virtualTaxon.parent);
    }
  }

  private lazyLoadAndExpand(virtualTaxon: Taxon) {
    this.lazyAddChildren(virtualTaxon);
    virtualTaxon.expanded = true;

    if (virtualTaxon.parent) {
      this.lazyLoadAndExpand(virtualTaxon.parent);
    }
  }

  public shouldShow(taxon: Taxon): boolean {
    if (this.showEmptyTaxons) return true;
    return taxon.count ? taxon.count > 0 : false;
  }

  public hasChildren(taxon: Taxon): boolean {
    return this.shouldShow(taxon) && taxon.hasChildren;
  }

  public visibleChildren(taxon: Taxon): Taxon[] {
    if (!taxon) return [];
    const v = _.filter(taxon.children, c => this.shouldShow(c));
    return v;
  }

  // eslint-disable-next-line complexity
  private reloadTaxonomy(changes: {
    criteria?: SimpleChange<ReadonlyArray<Criteria>>;
    taxonomyName?: SimpleChange<string>;
  }) {
    let reloadTaxonomy = false;
    let criteria: ReadonlyArray<Criteria> = [];
    if (changes.taxonomyName) {
      reloadTaxonomy = true;
    }

    if (
      this.showEmptyTaxons === false &&
      this.showEmptyTaxonsDependOnCriteria &&
      changes.criteria
    ) {
      const showEmptyTaxonsCriteriaPrevious = this.showEmptyTaxonsCriteria(
        changes.criteria.previousValue
      );
      const showEmptyTaxonsCriteriaCurrent = this.showEmptyTaxonsCriteria(
        changes.criteria.currentValue
      );
      criteria = showEmptyTaxonsCriteriaCurrent;

      if (
        !_.isEqual(
          showEmptyTaxonsCriteriaPrevious,
          showEmptyTaxonsCriteriaCurrent
        )
      ) {
        reloadTaxonomy = true;
      }
    }

    if (reloadTaxonomy) {
      this.loadTaxonomy(criteria);
    }
  }

  private updateCounts(arg: NewSearchEventArgs) {
    if (!arg.result) {
      this.loadTaxonomy([]);
      return;
    }

    if (!this.showFacetCount) return;
    const taxonomies = arg.result.TaxonomyMatch;
    const matches = _.find(
      taxonomies,
      (t: any) => t.TaxonomyName === this.taxonomyName
    );
    if (!matches) return;

    let searchHasUnknownTaxonKey = false;
    let modifiedTaxons = new Set<Taxon>();
    // Update taxon counts based on search response
    _.forEach(matches.TaxonCounts, (taxon: any) => {
      if (!this.virtualTaxonIndices.has(taxon.TaxonKey)) {
        searchHasUnknownTaxonKey = true;
        modifiedTaxons = new Set<Taxon>();
        return;
      }
      const index = this.virtualTaxonIndices.get(taxon.TaxonKey);
      const virtualTaxon = this.virtualTaxons[index];
      virtualTaxon.count = taxon.Count;
      modifiedTaxons.add(virtualTaxon);
    });

    // Update child status if we do not show empty taxons
    if (this.showEmptyTaxons === false) {
      _.forEach(this.virtualTaxons, (virtualTaxon, _) => {
        const realTaxon = this.taxonomyKeyMap.find(
          t => t.key === virtualTaxon.key
        );
        if (!realTaxon) return;
        if (realTaxon.children.length === 0) return;
        if (virtualTaxon.expanded) {
          this.lazyAddChildren(virtualTaxon);
          virtualTaxon.children = virtualTaxon.children =
            this.visibleChildren(virtualTaxon);
        }
        if (
          realTaxon.children.every(
            t =>
              !this.virtualTaxons[this.virtualTaxonIndices.get(t.key)].count ||
              this.virtualTaxons[this.virtualTaxonIndices.get(t.key)].count ===
                0
          )
        )
          virtualTaxon.hasChildren = false;
        else virtualTaxon.hasChildren = true;
      });
    }

    // Check if all taxons are hidden
    if (this.showEmptyTaxons === false) {
      this.noTaxonomies = this.virtualTaxons.every(
        virtualTaxon => virtualTaxon.count === 0
      );
    }

    if (searchHasUnknownTaxonKey) this.loadTaxonomy(arg.criteria);
  }

  private showEmptyTaxonsCriteria(
    criteria: ReadonlyArray<Criteria>
  ): Criteria[] {
    if (!_.isEmpty(criteria) && this.showEmptyTaxonsDependOnCriteria) {
      return criteria.filter(
        v => this.showEmptyTaxonsDependOnCriteria.indexOf(v.providerKey) !== -1
      );
    }

    return [];
  }

  private async loadTaxonomy(criteria: ReadonlyArray<Criteria>) {
    if (_.isEmpty(this.taxonomyName)) {
      return;
    }

    try {
      // cancel previous taxonomy load if any
      this.taxonomyLoadCanceller?.canceller.resolve();
      this.loadingTaxonomies = true;
      this.setupCancellation();

      const taxonomy = await this.taxonomyService.getTaxonomyTree(
        this.taxonomyName,
        {
          showDocuments: false,
          showEmptyTaxons: this.showEmptyTaxons !== false, // Defaults to true
          criteria
        },
        this.taxonomyLoadCanceller.canceller.promise
      );
      this.taxonomyLoadCanceller = undefined;
      this.taxonomyStateService.addTaxonomy(this.providerKey, taxonomy);

      // build light weight map of taxonomy nested structure for later lookup
      this.taxonomyKeyMap = buildTaxonomyKeyMap(taxonomy);

      // Build virtual taxonomy clone
      // we need to sit the taxonomy on the $scope because of the recursive template being used as view
      this.$scope.taxonomy = this.buildVirtualTaxonomyClone(taxonomy);

      // Make sure taxon selected state is synced
      this.syncTaxonSelectedState();

      this.noTaxonomies =
        taxonomy.children.length === 0 ||
        this.virtualTaxons.every(virtualTaxon => virtualTaxon.count === 0);

      // Signal that this component is ready as a criteria provider
      this.onLoaded({ provider: this.getCriteriaProvider() });

      if (this.hasSelectedTaxonomies()) {
        this.$scope.$emit(
          SidepaneSectionEvents.SetExpanded,
          new SetExpandedSidepaneSectionEventArgs(true)
        );
      }

      this.taxonomyLoadError = false;
    } catch (e: any) {
      if (!this.taxonomyLoadCanceller?.isCancelled) {
        this.taxonomyLoadError = true;
        console.error(e);
      }
    }

    // If there is a canceller set, it means a new request was initiated so we don't override loading state
    if (this.loadingTaxonomies && this.taxonomyLoadCanceller) return;

    this.loadingTaxonomies = false;
    this.$scope.$apply();
    this.taxonomyStateService.registerInitialTaxonomyLoadFinished();
  }

  private hasSelectedTaxonomies() {
    const hasSelectedTaxonomies =
      this.virtualTaxons.find(x => x.isSelected || x.parent?.isSelected) !==
      undefined;
    return hasSelectedTaxonomies;
  }

  private setupCancellation() {
    this.taxonomyLoadCanceller = {
      canceller: this.$q.defer(),
      isCancelled: false
    };
    this.taxonomyLoadCanceller.canceller.promise.then(() => {
      this.taxonomyLoadCanceller.isCancelled = true;
    });
  }

  private buildVirtualTaxonomyClone(taxonomy: Taxonomy): Taxonomy {
    const converter = new TaxonomyToFlatTaxonListConverter();
    this.virtualTaxons = converter.toFlatTaxonList(taxonomy);
    const newVirtualTaxonIndices = new Map<string, number>();
    let i = 0;
    _.forEach(this.virtualTaxons, v => newVirtualTaxonIndices.set(v.key, i++));
    this.virtualTaxonIndices = newVirtualTaxonIndices;
    return taxonomy;
  }

  public enterWasPressedKey(event: any) {
    if (event && event.type === "keyup") {
      return event.keyCode === 13;
    }

    return true;
  }

  public lazyLoadedToggle(scope: any, event: any) {
    if (!this.enterWasPressedKey(event)) {
      return;
    }

    // Toggles treeview nodes, and ensures that lazy loaded nodes gets added
    const virtualTaxonModel: Taxon = scope.$modelValue;
    virtualTaxonModel.expanded = !virtualTaxonModel.expanded;

    this.lazyAddChildren(virtualTaxonModel);
  }

  private lazyAddChildren(virtualTaxonModel: Taxon) {
    //if (!this.shouldLoadChildren(virtualTaxonModel)) return;

    // The taxon has children that is not yet added
    // Find child keys in keymap
    const childrenKeys = this.taxonomyKeyMap
      .find(x => x.key === virtualTaxonModel.key)
      .children.map(c => c.key);

    // Set children objects so that they can be rendered
    virtualTaxonModel.children = childrenKeys
      .map(key => this.virtualTaxons.find(t => t.key === key))
      .filter(x => !!x)
      .filter(x => this.shouldShow(x));
  }

  private shouldLoadChildren(virtualTaxonModel: Taxon): boolean {
    return (
      virtualTaxonModel.hasChildren && virtualTaxonModel.children.length === 0
    );
  }

  public multiSelectTaxon(taxon: Taxon): void {
    const shouldAddCriteria = !taxon.isSelected;
    this.onCriteriaChanged({
      action: {
        changes: TaxonomyController.buildTaxonCriteria(
          this.providerKey,
          taxon,
          shouldAddCriteria ? CriteriaChangeType.Add : CriteriaChangeType.Remove
        ).changes
      }
    });
  }

  public singleSelectTaxon(taxon: Taxon): void {
    this.onCriteriaChanged({
      action: {
        changes: this.buildRemovalChanges(this.providerKey).concat(
          TaxonomyController.buildTaxonCriteria(
            this.providerKey,
            taxon,
            CriteriaChangeType.Add
          ).changes
        )
      }
    });
  }

  private buildRemovalChanges(
    providerKey: string
  ): Array<{ type: CriteriaChangeType; criteria: Criteria }> {
    return this.getExistingTaxonomyCriterias(providerKey).map(c => {
      return {
        type: CriteriaChangeType.Remove,
        criteria: c
      };
    });
  }

  private getExistingTaxonomyCriterias(providerKey: string): Criteria[] {
    if (!this.criteria) {
      throw Error(
        "Expecting current criteria list to be bound from parent component. Please check view bindings."
      );
    }

    return this.criteria.filter(c => c.providerKey === providerKey);
  }

  public static buildTaxonCriteria(
    providerKey: string,
    taxon: Taxon,
    changeType: CriteriaChangeType
  ): CriteriaChange {
    const criteriaChanges: CriteriaChange = {
      changes: [
        {
          type: changeType,
          criteria: {
            providerKey,
            data: taxon.key
          }
        }
      ]
    };

    // When a taxon is selected, we unselect all ancestors and children.
    // Example: If the sub-taxon AD-DOM is selected while the parent "LAWS" is selected, all LAWS are still selected
    // unless we de-select the parent, then only AD-DOM and any other sub-taxons selected will be chosen for search.
    // Likewise, if a parent is selected while a sub-taxon is selected, it's now indirectly selected twice, so we remove
    // the child as all children of the parent is now selected.
    if (changeType === CriteriaChangeType.Add) {
      TaxonomyController.uncheckAncestors(providerKey, taxon, criteriaChanges);
      TaxonomyController.uncheckChildren(providerKey, taxon, criteriaChanges);
    }

    return criteriaChanges;
  }

  private static uncheckAncestors(
    providerKey: string,
    taxon: Taxon,
    criteriaChanges: CriteriaChange
  ): void {
    TaxonomyController.getTaxonAncestors(taxon).forEach(t => {
      criteriaChanges.changes.push({
        type: CriteriaChangeType.Remove,
        criteria: {
          providerKey,
          data: t.key
        }
      });
    });
  }

  private static uncheckChildren(
    providerKey: string,
    taxon: Taxon,
    criteriaChanges: CriteriaChange
  ): void {
    taxon.children.forEach(t => {
      if (t.isSelected) {
        TaxonomyController.uncheckChildrenRecursively(
          providerKey,
          t,
          criteriaChanges
        );
      }
    });
  }

  private static uncheckChildrenRecursively(
    providerKey: string,
    taxon: Taxon,
    criteriaChanges: CriteriaChange
  ): void {
    criteriaChanges.changes.push({
      type: CriteriaChangeType.Remove,
      criteria: {
        providerKey,
        data: taxon.key
      }
    });

    taxon.children.forEach(t => {
      if (t.isSelected) {
        this.uncheckChildrenRecursively(providerKey, t, criteriaChanges);
      }
    });
  }

  private getCriteriaProvider(): CriteriaProvider {
    return {
      key: this.providerKey,
      getCriteriaViewModel: criteria => {
        if (criteria.providerKey !== this.providerKey) {
          return null;
        }
        const taxon = findTaxonFromKey(criteria.data, this.virtualTaxons);
        return {
          displayValue: taxon.longTitle,
          longDisplayValue: taxon.path.map(p => p.title).join("/"),
          displayValueResource: undefined,
          criteria,
          icon: this.icon,
          color: this.color
        };
      },
      buildCriteriaDescription: request =>
        buildTaxonsCriteriaDescription(
          request,
          this.providerKey,
          this.criteria,
          this.virtualTaxons,
          findTaxonFromKey,
          this.taxonomyFilterKey
        )
    };
  }

  private static getTaxonAncestors(taxon: Taxon) {
    const ancestors = [];
    while (taxon.parent) {
      ancestors.push(taxon.parent);
      taxon = taxon.parent;
    }
    return ancestors;
  }
}

angular
  .module("PortalApp")
  .controller("Rhinestone.TaxonomyController", TaxonomyController);
