import * as angular from "angular";
import _ = require("underscore");
import { Criteria } from '@rhinestone/portal-web-react';

export class SearchResultAbstractController {
  // Input binding
  public valueToDisplay: string;
  public documentType: string;
  public criteria: ReadonlyArray<Criteria>;

  public isVisible(): boolean {
    return this.valueToDisplay &&
    this.documentType &&
    this.documentType.toLowerCase() === "dom" &&
    (!this.criteria || this.criteria
        .filter(x => x.providerKey === "terms")
        .filter(x => x.data !== "*").length === 0);
  }

  public static $inject: string[] = [];
  
  public $onInit() {
  }
}

angular
  .module("PortalApp")
  .controller(
    "Rhinestone.SearchResultAbstractController",
    SearchResultAbstractController
  );
