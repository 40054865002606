import { IDictionary } from "../../Utils/IDictionary";
import * as angular from "angular";
import { IPortalConfig } from "../../Core/portal.provider";

export class DocumentFieldsetService {
  public static $inject = ["$http", "$q", "portal"];

  constructor(
    private $http: angular.IHttpService,
    private $q: angular.IQService,
    private portal: IPortalConfig
  ) {}

  public getFieldset(
    hiveId: string,
    fullName: string,
    fieldsetName: string
  ): angular.IPromise<IDictionary<string>> {
    const deffered = this.$q.defer<IDictionary<string>>();

    this.$http
      .get<IDictionary<string>>(
        `/api/Portals(${this.portal.identifier})/Documents/FieldSetValues(${fieldsetName})/${hiveId}/${fullName}`
      )
      .then(result => {
        deffered.resolve(result.data);
      })
      .catch(() => {
        deffered.reject();
      });

    return deffered.promise;
  }
}

angular
  .module("PortalApp")
  .service("documentFieldsetService", DocumentFieldsetService);
