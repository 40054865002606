export class SidepaneSectionEvents {
  public static ToggleVisibility = "SidepaneSection.ToggleVisibility";
  public static SetExpanded = "SidepaneSection.SetExpanded";
}

export class SetExpandedSidepaneSectionEventArgs {
  constructor(public isExpanded: boolean) {}
}

export class ToggleVisibilitySidepaneSectionEventArgs {
  constructor(public isVisible: boolean) {}
}
