import "./assetsSidePaneSection.html";
import { BaseComponent } from "../../Core/BaseComponent";
import * as angular from "angular";
import "./assetsSidePaneSection.controller";
export class AssetsSidePaneSectionComponent extends BaseComponent {
  constructor() {
    super(
      "Rhinestone.AssetsSidePaneSectionController",
      "/templates/Search/AssetsSidepaneSection/assetsSidePaneSection.html"
    );
  }
}

angular
  .module("PortalApp")
  .component("rhAssetsSidePaneSection", new AssetsSidePaneSectionComponent());
