import { IErrorService } from "../Layout/error.service";
import { IMainPageService } from "./mainPage.service";
import * as angular from "angular";
import { LoginDialogService } from "../Controls/loginDialog.service";
import { TileDto } from "@rhinestone/portal-web-api";

export class MainPageViewController {
  public tiles: TileDto[] = [];

  public static $inject = [
    "errorService",
    "mainPageService",
    "loginDialogService"
  ];

  constructor(
    private errorService: IErrorService,
    private service: IMainPageService,
    private loginDialogService: LoginDialogService
  ) {}

  public $onInit() {
    this.loginDialogService.initializeLoginPrompt();

    this.service
      .getTiles()
      .then(tiles => {
        this.tiles = tiles;
      })
      .catch(() => {
        this.errorService.registerError("Fejl ved hentning af data.");
      });
  }
}

angular
  .module("PortalApp")
  .controller("Rhinestone.MainPageViewController", MainPageViewController);
