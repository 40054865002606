import * as angular from "angular";

export interface IFeatureFlagsService {
  isEnabled(name: string): angular.IPromise<boolean>;
}

export class FeatureFlagsService implements IFeatureFlagsService {
  public static $inject = ["$http"];
  constructor(private $http: angular.IHttpService) {}

  public isEnabled(name: string) {
    return this.$http
      .get<boolean>(`/api/featureflag/${name}`)
      .then((response: angular.IHttpPromiseCallbackArg<boolean>): boolean => {
        return response.data;
      });
  }
}

angular.module("PortalApp").service("featureFlagsService", FeatureFlagsService);
