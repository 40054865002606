import { PortalProvider } from "./portal.provider";
import * as angular from "angular";

export class TranslateConfig {
  public static $inject = ["$translateProvider", "portalProvider"];

  constructor(
    $translateProvider: angular.translate.ITranslateProvider,
    portalProvider: PortalProvider
  ) {
    const portal = portalProvider.$get();

    $translateProvider.useMissingTranslationHandlerLog();
    $translateProvider.useSanitizeValueStrategy("escapeParameters");
    const url = `/api/Portals(${portal.identifier})/translation`;
    $translateProvider.useUrlLoader(url);
    $translateProvider.preferredLanguage(portal.defaultLanguage);

    // use local storage to remember selected language
    $translateProvider.useLocalStorage();
  }
}

angular.module("PortalApp").config(TranslateConfig);
