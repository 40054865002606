import * as angular from "angular";
import { AssetsService } from "./assets.service";
import "./copyAssetCollectionFromTemplateDialog.html";
export class CopyAssetCollectionFromTemplateDialogController {
  public static $inject = [
    "$uibModalInstance",
    "assetsService",
    "assetCollectionTemplateId",
    "isArchived"
  ];
  constructor(
    private $uibModalInstance: angular.ui.bootstrap.IModalServiceInstance,
    private assetsService: AssetsService,
    private assetCollectionTemplateId: string,
    private isArchived: boolean
  ) {}

  public copyFromTemplate = async () => {
    if (this.isArchived) {
      await this.assetsService.createAssetCollectionFromArchivedTemplate(
        this.assetCollectionTemplateId
      );
    } else {
      await this.assetsService.createAssetCollectionFromTemplate(
        this.assetCollectionTemplateId
      );
    }

    this.$uibModalInstance.close();
  };

  public cancel = () => {
    this.$uibModalInstance.dismiss();
  };
}

angular
  .module("PortalApp")
  .controller(
    "Rhinestone.CopyAssetCollectionFromTemplateDialogController",
    CopyAssetCollectionFromTemplateDialogController
  );
