import * as angular from "angular";

export const defaults = {
  version: "1.0.0",
  pageSize: 10,
  notificationOptions: {
    className: "success",
    showDuration: 250,
    pos: "top-right",
    style: "bootstrap",
    showAnimation: "slideDown",
    clickToHide: true
  }
};

export const HttpStatusCode = {
  OK: 200,
  Unauthorized: 401,
  Forbidden: 403,
  NotFound: 404,
  Conflict: 409
};

angular.module("PortalApp").constant("config", defaults);
