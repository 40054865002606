import "./assetCollection.html";
import { BaseComponent } from "../Core/BaseComponent";
import * as angular from "angular";
import "./assetCollection.controller";
import "./assetsTreeView.component";
import "./assetCollectionDetail.component";
import "./assetsMembers.component";
import "./assetsReleases.component";
import { Binding } from "../Utils/AngularBindings";
export class AssetCollectionComponent extends BaseComponent {
  constructor() {
    super(
      "Rhinestone.AssetCollectionController",
      "/templates/assets/assetCollection"
    );

    this.bindings = {
      isTemplate: Binding.oneWay,
      isArchived: Binding.oneWay
    };
  }
}

angular
  .module("PortalApp")
  .component("rhAssetCollection", new AssetCollectionComponent());
