import "./taxonomy.html";
import { BaseComponent } from "../../Core/BaseComponent";
import * as angular from "angular";
import { Binding } from "../../Utils/AngularBindings";
import "./taxonomy.less";
import "./taxonomy.controller";

export class TaxonomyComponent extends BaseComponent {
  constructor() {
    super(
      "Rhinestone.TaxonomyController",
      "/templates/search/taxonomy/taxonomy"
    );
    this.bindings = {
      icon: Binding.oneWay,
      color: Binding.oneWay,
      taxonomyName: Binding.oneWay,
      providerKey: Binding.oneWay,
      onCriteriaChanged: Binding.outputEvent,
      onLoaded: Binding.outputEvent,
      criteria: Binding.oneWay,
      taxonomyFilterKey: Binding.oneWay,
      showEmptyTaxons: Binding.oneWay,
      showFacetCount: Binding.oneWay,
      showEmptyTaxonsDependOnCriteria: Binding.oneWay
    };
  }
}

angular.module("PortalApp").component("rhTaxonomy", new TaxonomyComponent());
