import * as angular from "angular";

angular.module("PortalApp", [
  "rx",
  "ngSanitize",
  "ngRoute",
  "ngAnimate",
  "ngCookies",
  "ui.bootstrap",
  "ngTable",
  "angularMoment",
  "ui.tree",
  "ngMaterial",
  "ui.select",
  "ngMessages",
  "pascalprecht.translate",
  "rhSidePane",
  "rhDocuments",
  "rhUtils",
  "infinite-scroll",
  "LocalStorageModule"
]);

// import global styles
import "../GlobalStyles/layout.css";
import "../GlobalStyles/forms.css";
import "../GlobalStyles/search.css";
import "../GlobalStyles/assets.css";
import "../GlobalStyles/GlobalComponentStyles.less";
import "../GlobalStyles/css-element-queries.css";

// Import the module files
import "./Utils/utils.module";
import "./Layout/SidePane/sidepane.module";
import "./Services/services.module";
import "./Documents/documents.module";

import "./Services/google-tag-manager.service";
import "./Core/portal.provider";
import "./User/currentUser.service";
import "./User/userFeatures.service";
import "./Tiles/CarouselTile/idGenerator.service";
import "./Services/taxonomy.service";
import "./Documents/DocumentPrint/documentPrint.service";
import "./Services/featureflags.service";

// Root layout view
import "./Layout/layout.component";

// Import react2angular components from portal-web-react
// Make sure to import after rest of app to make sure angular-app is booted
import registerReactComponents from "@rhinestone/portal-web-react";
registerReactComponents("PortalApp");
