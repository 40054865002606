import { ICurrentUserService } from "../../User/currentUser.service";
import * as angular from "angular";
import { IPortalConfig } from "../../Core/portal.provider";
import { ILinkRenderService } from "../../Services/linkRender.service";
import { LinkTileDto } from "../TilesTypes";

export class LinkTileController {
  public isInitialized: boolean = false;
  public hasErrorOrAuthenticated: boolean = false;
  public tile: LinkTileDto;
  public isLinkUrlExternal: boolean;
  public imageUrl: string;

  public static $inject = ["currentUserService", "portal", "linkRenderService"];

  constructor(
    private currentUser: ICurrentUserService,
    private portal: IPortalConfig,
    private linkService: ILinkRenderService
  ) {}

  public $onInit() {
    if (this.tile.imagePath) {
      this.imageUrl = `/api/Portals(${this.portal.identifier})${this.tile.imagePath}`;
    }
    this.isInitialized = true;
    this.isLinkUrlExternal = this.linkService.isLinkExternal(this.tile.linkUrl);
  }

  public checkFlag(value: number, flag: number): boolean {
    // Bitwise & to check enum flags.
    // This is disallowed by eslint as it's a very rare use-case
    // and mostly enabled to prevent mistype of "&&" with "&".
    return (value & flag) > 0;
  }
}

angular
  .module("PortalApp")
  .controller("Rhinestone.LinkTileController", LinkTileController);
