import * as angular from "angular";

export class UnauthorizedController {
  public static $inject: any[] = [];

  constructor() {}
}

angular
  .module("PortalApp")
  .controller("Rhinestone.UnauthorizedController", UnauthorizedController);
