import * as angular from "angular";
import { UserFeaturesService } from "../User/userFeatures.service";
import { Feature } from "@rhinestone/portal-web-react";

export class AssetsController {
  public assetCollectionId: string;

  public static $inject = ["$routeParams", "userFeaturesService"];

  constructor(
    private $routeParams: any,
    private userFeaturesService: UserFeaturesService
  ) {
    this.assetCollectionId = this.$routeParams.collectionId;
  }

  public hasAssetCollectionTemplateFeature = (): boolean => {
    return this.userFeaturesService.hasFeature(Feature.AssetCollectionTemplate);
  };
}

angular
  .module("PortalApp")
  .controller("Rhinestone.AssetsController", AssetsController);
