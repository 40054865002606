import { IDictionary } from "../Utils/IDictionary";
import * as angular from "angular";
export class BaseComponent implements angular.IComponentOptions {
  public bindings: IDictionary<string>;
  public controllerAs: string;
  public require: IDictionary<string>;

  constructor(
    public controller: string,
    public templateUrl: string,
    public transclude: boolean | IDictionary<string> = false
  ) {
    this.controllerAs = "vm";
  }
}
