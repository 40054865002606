import { UserViewModel } from "@rhinestone/portal-web-api";

export function userHasFeature(user: UserViewModel, featureId: string) {
  if (!user || !user.features) {
    return false;
  }

  return user.features.some(
    x => x.featureId.toUpperCase() === featureId.toUpperCase()
  );
}
