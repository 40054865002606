import * as angular from "angular";

export class TrustAsHtml {
  public static Factory(): any {
    const filterFac: any = ($sce: angular.ISCEService) => {
      return (html: string) => {
        return $sce.trustAsHtml(html);
      };
    };
    filterFac.$inject = ["$sce"];
    return filterFac;
  }
}

angular.module("PortalApp").filter("trustHtml", TrustAsHtml.Factory());
