import * as angular from "angular";
import * as _ from "underscore";
import { ICriteriaGroupController } from "../Criterias/ICriteriaGroupController";

import { TaxonomyFilterKey } from "@rhinestone/portal-web-api";
import {
  ICurrentUserService,
  UserEvents
} from "../../User/currentUser.service";
import {
  CriteriaChange,
  CriteriaProvider,
  Criteria
} from "@rhinestone/portal-web-react";

export class LocalDocumentTypeTaxonomyFilterController
  implements ICriteriaGroupController
{
  // TODO: These three values are incorrectly implemented by the interface, this is a filter, not a filter group/section
  public color: string;
  public icon: string;
  public providerKey?: string;

  // Bindings
  public onCriteriaChanged: (outparams: { action: CriteriaChange }) => {};
  public onLoaded: (param: { provider: CriteriaProvider }) => void;
  public criteria: ReadonlyArray<Criteria>;

  public readonly localDocumentTypeTaxonomyFilterKey: TaxonomyFilterKey =
    "LocalDocumentType";

  public static $inject: string[] = ["$rootScope", "currentUserService"];

  public localDocTypeTaxonomyInfo: {
    name: string;
    providerKey: string;
  };

  public localDocTypeTaxonomyIsLoaded: boolean;

  constructor(
    private $rootScope: angular.IRootScopeService,
    private currentUser: ICurrentUserService
  ) {}

  public $onInit() {
    if (this.currentUser.isAuthenticated) {
      this.setupLocalDocumentTypeTaxonomyInfo();
    } else {
      this.$rootScope.$on(UserEvents.UserAuthenticated, () => {
        this.setupLocalDocumentTypeTaxonomyInfo();
      });
    }
  }

  private setupLocalDocumentTypeTaxonomyInfo() {
    // There is a convention in rhinestone that local doctype taxonomies
    // are named using the syntax DokumentType_[OrganizationIdentifier]
    // TODO: This naming convention is no longer true.
    const localTaxonomyCode = `DokumentType_${this.currentUser.user.organizationIdentifier}`;
    this.localDocTypeTaxonomyInfo = {
      name: localTaxonomyCode,
      providerKey: localTaxonomyCode
    };
  }

  public handleCriteriaChanged(action: CriteriaChange): void {
    this.onCriteriaChanged({ action });
  }

  public registerCriteriaProvider(provider: CriteriaProvider): void {
    // Maybe a little hacky, but we have hidden the local doctype taxonomy component
    // until we know it is actually loaded.
    // We use the existing infrastructure of criteria provider concept that signals when it is loaded
    // and check that is the providerKey for the local doctype taxonomy, that is now loaded
    // and toggle visibility state
    if (
      this.localDocTypeTaxonomyInfo &&
      provider.key === this.localDocTypeTaxonomyInfo.providerKey
    ) {
      this.localDocTypeTaxonomyIsLoaded = true;
    }
    this.onLoaded({ provider });
  }
}

angular
  .module("PortalApp")
  .controller(
    "Rhinestone.LocalDocumentTypeTaxonomyFilterController",
    LocalDocumentTypeTaxonomyFilterController
  );
