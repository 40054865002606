import { IResultLocation } from "./IResultLocation";
import * as angular from "angular";
import { SearchService } from "./search.service";
import { UrlService } from "./url.service";
import { ICurrentUserService } from "../User/currentUser.service";
import { ISearchSubscriptionDialogService } from "../SearchSubscriptions/searchSubscriptionDialog.service";
import { NewSearchEventArgs, SearchEvents } from "./search.events";
import { ISearchResponse, SearchRefinementItem } from "./searchResponse";
import * as _ from "underscore";

import { SearchPageConfigurationService } from "./searchPageConfigurationService";
import { ISearchCriteriaStateService } from "./searchCriteriaState.service";
import {
  Criteria,
  CriteriaChange,
  CriteriaProvider,
  Feature,
  SearchRequestClient,
  SearchRequestMapper
} from "@rhinestone/portal-web-react";
import { IMobileDetectorService } from "../Services/mobileDetector.service";
import { UserFeaturesService } from "../User/userFeatures.service";
import { IPortalConfig } from "../Core/portal.provider";
import {
  SearchViewType,
  SearchFilterGroupModel,
  ProductDropdownModel,
  SearchFilterModel
} from "@rhinestone/portal-web-api";
import { RefinementFlag } from "./RefinementFlag";
import { LoginDialogService } from "../Controls/loginDialog.service";
import { IProductsService } from "../Services/products.service";
import { ITaxonomyStateService } from "./taxonomyState.service";

export class SearchViewController {
  // State
  private isInitialized: boolean = false;
  public defaultSearchCriteria: ReadonlyArray<Criteria>;
  public request: SearchRequestClient;
  public userProducts: ProductDropdownModel[];

  public isLoading: boolean = false;
  public hasError: boolean = false;
  public errorMessage: string;
  public criteria: ReadonlyArray<Criteria>;
  public criteriaProviders: ReadonlyArray<CriteriaProvider>;

  // Query
  public searchFilterGroups: SearchFilterGroupModel[] = [];
  public mobileSearchFilterGroup: SearchFilterGroupModel[] = [];
  public queryRefinements: SearchRefinementItem[] = [];

  // View
  public resultView: SearchViewType;
  public availableViews: SearchViewType[];

  // Result
  public result: ISearchResponse;

  private inflightSearchRequestSubscription: Rx.IDisposable = null;
  private searchCriteriaSubscription: Rx.IDisposable = null;
  private initiateSearchExecutionSubscription: Rx.IDisposable = null;
  private searchCriteriaProvidersSubscription: Rx.IDisposable = null;

  private searchCanceller: angular.IDeferred<any>;

  public isMobile: boolean;

  public currentResultLocation: IResultLocation = {
    pageNumber: 1,
    sortName: null,
    sortDescending: null
  };

  private broadcastOnSearchResult: boolean = false;

  public static $inject = [
    "$q",
    "$rootScope",
    "$scope",
    "searchService",
    "urlService",
    "currentUserService",
    "searchSubscriptionDialogService",
    "searchPageConfigurationService",
    "mobileDetectorService",
    "searchCriteriaStateService",
    "userFeaturesService",
    "portal",
    "loginDialogService",
    "productsService",
    "taxonomyStateService"
  ];

  constructor(
    private $q: angular.IQService,
    private $rootScope: angular.IRootScopeService,
    private $scope: angular.IScope,
    private searchService: SearchService,
    private urlService: UrlService,
    private currentUser: ICurrentUserService,
    private searchSubscriptionDialogService: ISearchSubscriptionDialogService,
    private searchPageConfigurationService: SearchPageConfigurationService,
    private mobileDetectorService: IMobileDetectorService,
    private searchCriteriaStateService: ISearchCriteriaStateService,
    private userFeaturesService: UserFeaturesService,
    public portal: IPortalConfig,
    private loginDialogService: LoginDialogService,
    private productsService: IProductsService,
    private taxonomyStateService: ITaxonomyStateService
  ) {}

  public $onInit() {
    this.$rootScope.$on(SearchEvents.ResetSearch, () => {
      this.resetSearch();
    });

    this.isMobile = this.mobileDetectorService.isMobile();
    this.loginDialogService.initializeLoginPrompt();

    this.handleViewChange = this.handleViewChange.bind(this);

    this.setupSearchCriteriaSubscriber();

    this.setupInitiateSearchExecutionSubscriber();

    this.searchCriteriaProvidersSubscription =
      this.searchCriteriaStateService.criteriaProvidersSubject
        .safeApply(this.$scope, value => {
          this.criteriaProviders = value;
        })
        .subscribe();

    this.searchService.getSearchViews().then((views: SearchViewType[]) => {
      this.setDefaultResultViewState(views);

      try {
        if (this.urlService.hasActiveSearch()) {
          this.executeSearchFromUrl();
        } else {
          this.searchPageConfigurationService
            .getDefaultCriteriaConfiguration()
            .then(criteria => {
              this.defaultSearchCriteria = criteria;

              this.searchCriteriaStateService.setCriteriaList(criteria, true);
            });
        }
      } catch (e) {
        console.log("Error loading search request state from url.");
        console.log(e);
        this.resetSearchPageState();
      }

      this.isInitialized = true;

      this.productsService.getUserProducts().then(data => {
        this.userProducts = data;
      });

      this.searchService.getSearchFilterGroups().then(data => {
        this.searchFilterGroups = data;
        this.mobileSearchFilterGroup = this.toMobileSearchFilterGroup(data);
      });

      this.searchService.getQueryRefinements().then(data => {
        this.queryRefinements = data;
      });

      this.broadcastOnSearchResult = true;
    });
  }

  private setupSearchCriteriaSubscriber() {
    // Updates local criteria state every time the criterias are changed
    this.searchCriteriaSubscription =
      this.searchCriteriaStateService.criteriaChangedSubject
        .safeApply(this.$scope, value => {
          // Sync  over state to current controller which are being passed down to children using bindings
          this.criteria = value.criteria;
        })
        .subscribe();
  }

  private setupInitiateSearchExecutionSubscriber() {
    // Separate subscriber for search execution so we wait till user has stopped changing criterias before executing search
    this.initiateSearchExecutionSubscription =
      this.searchCriteriaStateService.criteriaChangedSubject
        .safeApply(this.$scope, value => {
          // In some cases we dont want to actually perform search
          if (!this.isInitialized || value.isDefaultCriteria) {
            return;
          }

          this.currentResultLocation.pageNumber = 1;
          this.executeSearch();
        })
        .subscribe();
  }

  public $onDestroy() {
    this.searchCriteriaSubscription?.dispose();
    this.searchCriteriaProvidersSubscription?.dispose();
    this.initiateSearchExecutionSubscription?.dispose();
    this.cancelInflightSearchRequestSubscription();
  }

  public openNewSearchSubscriptionDialog(): void {
    const criteria = SearchRequestMapper.mapToServerRequestCriteria(
      this.criteria
    );
    this.searchSubscriptionDialogService.openNewSearchSubscriptionDialog({
      criteria
    });
  }

  public handleCriteriaChanged(action: CriteriaChange): void {
    this.searchCriteriaStateService.handleCriteriaChanged(action);
  }

  public registerCriteriaProvider(provider: CriteriaProvider): void {
    this.searchCriteriaStateService.registerCriteriaProvider(provider);
  }

  public isUserAuthenticated(): boolean {
    return this.currentUser.isAuthenticated;
  }

  public changePageNumber(page: number): void {
    const newResultLocation: IResultLocation = {
      pageNumber: page,
      sortName: this.currentResultLocation.sortName,
      sortDescending: this.currentResultLocation.sortDescending
    };

    this.changePage(newResultLocation);
  }

  public changePage(resultLocation: IResultLocation): void {
    if (this.isInitialized && !this.isLoading) {
      this.currentResultLocation = resultLocation;
      this.getPage();
    }
  }

  /**
   * Called when one of the view-switches are clicked and the active view is to be changed.
   */
  public changeView(viewType: SearchViewType): void {
    this.setViewType(viewType);
    const hasOrderingOption = viewType.orderingOptions.some(
      option => option.fieldName === this.currentResultLocation.sortName
    );

    if (!hasOrderingOption) {
      this.setDefaultResultLocation();
    }

    // Changing view does not change number of results, but it may change result count per page.
    // So we have to reset pageNumber when changing views
    this.currentResultLocation.pageNumber = 1;

    this.executeSearch();
  }

  /**
   * Called whenever the availableViews are made visible/invisible or active/inactive.
   *
   * @param param options about the new state for the given view.
   */
  public handleViewChange(param: {
    viewName: string;
    isVisible: boolean;
    isActive: boolean;
  }): void {
    const view = _(this.availableViews).find(
      (v: SearchViewType) => v.name === param.viewName
    );

    if (!view) {
      return;
    }

    view.hidden = !param.isVisible;

    if (param.isActive) {
      this.setViewType(view);
      this.setDefaultResultLocation();
    }

    if (this.resultView.hidden) {
      this.setViewType(
        this.searchService.selectDefaultSearchView(this.availableViews)
      );
      this.setDefaultResultLocation();
    }
  }

  public async resetSearch(): Promise<void> {
    this.cancelInflightSearchRequestSubscription();
    this.urlService.clearSearch();
    if (this.defaultSearchCriteria === undefined) {
      this.defaultSearchCriteria =
        await this.searchPageConfigurationService.getDefaultCriteriaConfiguration();
    }
    this.searchCriteriaStateService.setCriteriaList(
      this.defaultSearchCriteria,
      true
    );
    this.result = null;

    this.resetViewType();
    this.setDefaultResultLocation(); // Calling after resetViewType as the defaults is taken from the ViewType
    this.taxonomyStateService.clear();
    this.broadcastSearchCleared();
    this.isInitialized = true;
  }

  public hasAssetCollectionsFeature(): boolean {
    return this.userFeaturesService.hasFeature(Feature.AssetCollection);
  }

  /**
   * Not all search-filters works, or should be be shown, on mobile devices.
   */
  public toMobileSearchFilterGroup(
    groups: SearchFilterGroupModel[]
  ): SearchFilterGroupModel[] {
    const mobileFriendlySearchFilters = [
      "SearchFilterTaxonomyModel",
      "SearchFilterLocalDocumentTypeTaxonomyModel"
    ];

    return groups
      .map((group: any) => ({
        ...group,
        searchFilters: group.searchFilters.filter(
          (searchFilter: SearchFilterModel) =>
            mobileFriendlySearchFilters.indexOf(searchFilter.typeName) > -1
        )
      }))
      .filter((group: any) => group.searchFilters.length > 0);
  }

  public createSearchRequest(): SearchRequestClient {
    const request: SearchRequestClient = {
      fieldSetName: this.resultView.documentFieldSetName,
      criteria: this.criteria,
      resultViewName: this.resultView.name,
      ordering: this.currentResultLocation.sortName
        ? {
            fieldName: this.currentResultLocation.sortName,
            descending: this.currentResultLocation.sortDescending
          }
        : null,
      take: this.resultView.defaultPageSize,
      skip:
        this.resultView.defaultPageSize *
        (this.currentResultLocation.pageNumber - 1),
      // TODO: This is added for backwards compatibility with rigsadvokatens existing url. Pending cleanup with implementing "Nyhedsservice"
      page: this.currentResultLocation.pageNumber,
      snippets: true,
      portalColumnHighlights: this.resultView.portalColumnHighlights
    };

    return request;
  }

  private resetSearchPageState(): void {
    this.resetSearch();
    this.setViewType(
      this.searchService.selectDefaultSearchView(this.availableViews)
    );
  }

  private setDefaultResultViewState(views: SearchViewType[]): void {
    this.availableViews = views;
    this.resultView = this.searchService.selectDefaultSearchView(
      this.availableViews
    ); // Set default active view
    this.setDefaultResultLocation();
  }

  private setDefaultResultLocation(): void {
    const orderingOption = this.resultView.orderingOptions.filter(
      option => option.fieldName === this.resultView.defaultOrderingOption
    )[0];

    this.currentResultLocation = {
      pageNumber: 1,
      sortName: this.resultView.defaultOrderingOption,
      sortDescending: orderingOption
        ? orderingOption.defaultDirectionDescending
        : false
    };
  }

  private executeSearch(): void {
    this.broadcastOnSearchResult = true;
    this.getPage();
  }

  private cancelInflightSearchRequestSubscription(): void {
    this.inflightSearchRequestSubscription?.dispose();
    this.isLoading = false;
    this.searchCanceller?.resolve();
  }

  private getPage(): void {
    if (!this.canSearch()) {
      this.result = null;
      this.urlService.clearSearch();
      this.setDefaultResultLocation();
      this.broadcastSearchCleared();
      return;
    }

    this.cancelInflightSearchRequestSubscription();

    this.request = this.createSearchRequest();
    this.urlService.updateUrl(this.request);
    this.isLoading = true;
    this.hasError = false;

    this.searchCanceller = this.$q.defer<any>();

    this.inflightSearchRequestSubscription = Rx.Observable.fromPromise(
      this.searchService.executeSearchWithGtmTracking(
        this.request,
        this.searchCanceller.promise
      )
    )
      .catch((error: any) => {
        console.log(error);
        this.isLoading = false;
        this.searchCanceller = undefined;

        // If the request is cancelled we do not want to show an error
        // https://docs.angularjs.org/api/ng/service/$http#$http-returns
        if (error.xhrStatus === "abort") {
          return Rx.Observable.empty();
        }

        this.hasError = true;
        this.buildErrorMessage(error);

        return Rx.Observable.empty();
      })
      .safeApply(this.$scope, (response: ISearchResponse) => {
        this.result = response;
        this.isInitialized = true;
        this.isLoading = false;
        this.searchCanceller = undefined;
        if (this.broadcastOnSearchResult) {
          this.broadcastSearchNew(this.result);
        }
        this.broadcastOnSearchResult = false;
      })
      .subscribe();
  }

  private buildErrorMessage(error: any) {
    this.errorMessage = "Der er sket en fejl!";
    const errorDetails = new Array<string>();

    if (error.status) {
      errorDetails.push(`Status: ${error.status}`);
    }
    if (error.statusText) {
      errorDetails.push(`StatusText: ${error.statusText}`);
    }
    if (error.xhrStatus) {
      errorDetails.push(`XhrStatus: ${error.xhrStatus}`);
    }
    if (error.headers && error.headers("X-Correlationidentifier")) {
      errorDetails.push(
        `Correlationidentifier: ${error.headers("X-Correlationidentifier")}`
      );
    }
    if (error.data) {
      errorDetails.push(`Data: ${error.data}`);
    }
    if (errorDetails.length > 0) {
      this.errorMessage += ` (${errorDetails.join(", ")})`;
    }
  }

  private broadcastSearchNew(searchResult: ISearchResponse): void {
    this.$rootScope.$broadcast(
      SearchEvents.NewSearch,
      new NewSearchEventArgs(searchResult, this.criteria)
    );
  }

  private broadcastSearchCleared(): void {
    this.$rootScope.$broadcast(SearchEvents.SearchCleared, {});
  }

  private setViewType(resultView: SearchViewType): void {
    if (resultView) {
      this.resultView = resultView;
    }
  }

  private setViewTypeByName(resultViewName: string): void {
    const view = _(this.availableViews).find(
      (x: SearchViewType) => x.name === resultViewName
    );
    if (view) {
      this.setViewType(view);
    }
  }
  private resetViewType(): void {
    if (this.resultView) {
      if (this.resultView.flags.indexOf(RefinementFlag.Hidden) > -1) {
        this.resultView.hidden = true;
        this.setViewType(
          this.searchService.selectDefaultSearchView(this.availableViews)
        );
      }
    } else {
      this.setViewType(
        this.searchService.selectDefaultSearchView(this.availableViews)
      );
    }
  }

  private canSearch = () => {
    return this.resultView && this.criteria.length > 0;
  };

  private updateFromRequest(request: SearchRequestClient): void {
    try {
      this.searchCriteriaStateService.setCriteriaList(request.criteria);

      // update current result location
      this.currentResultLocation = {
        pageNumber: request.page,
        sortName:
          request.ordering == null
            ? this.resultView.defaultOrderingOption
            : request.ordering.fieldName,
        sortDescending:
          request.ordering == null ? true : request.ordering.descending
      };
      // update view type
      this.setViewTypeByName(request.resultViewName);
    } catch (error) {
      // Clear any saved search if it cannot be read back'
      console.log(
        "error recreating view state from existing search request. Resetting search page....."
      );
      this.resetSearchPageState();
    }
  }

  private executeSearchFromUrl(): void {
    const request = this.urlService.getSearchRequestFromUrl();
    this.updateFromRequest(request);
    this.isInitialized = true;
    this.executeSearch();
  }
}

angular
  .module("PortalApp")
  .controller("Rhinestone.SearchViewController", SearchViewController);
