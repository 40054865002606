import * as angular from "angular";

export interface IIdGeneratorService {
  getNext(): number;
}

export class IdGeneratorService implements IIdGeneratorService {
  private current: number = 0;

  constructor() {}

  public static $inject: any[] = [];

  public getNext(): number {
    return this.current++;
  }
}

angular.module("PortalApp").service("idGeneratorService", IdGeneratorService);
