import "./localizationSwitcher.html";
import { BaseComponent } from "../Core/BaseComponent";
import * as angular from "angular";
import "./localizationSwitcher.less";
import "./localization.service";
import "./localizationSwitcher.controller";
export class LocalizationSwitcherComponent extends BaseComponent {
  constructor() {
    super(
      "Rhinestone.LocalizationSwitcherController",
      "/templates/Localization/localizationSwitcher.html"
    );
  }
}

angular
  .module("PortalApp")
  .component("rhLocalizationSwitcher", new LocalizationSwitcherComponent());
