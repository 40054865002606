import * as angular from "angular";
import * as _ from "underscore";
import { ISearchResponse } from "../searchResponse";
import { IResultLocation } from "../IResultLocation";
import { SimpleChange } from "../../../TypeDefinitions/angularextensions";
import { SearchViewType, OrderingOption } from "@rhinestone/portal-web-api";

export class MobileTabsController {
  /* Input bindings */
  public resultView: SearchViewType;
  public onUpdateCurrentResult: (
    outputParams: { resultLocation: IResultLocation }
  ) => void;
  public result: ISearchResponse;

  /* Internal state */
  public filterExpanded: boolean = false;
  public sortingExpanded: boolean = false;
  public descending: boolean = false;
  public currentOrder: OrderingOption;

  public static $inject: any[] = [];

  constructor() {}

  public $onChanges(changes: { resultView?: SimpleChange<SearchViewType> }) {
    if (changes.resultView) {
      this.resetView(changes.resultView.currentValue);
    }
  }

  private expand(event: any) {
    const clickedButton = event.currentTarget.attributes.name.value;

    // This is kinda ugly and hardcoded.. Rasmus will probably complain
    if (clickedButton === "sorting") {
      this.sortingExpanded = !this.sortingExpanded;
      this.filterExpanded = false;
    }

    if (clickedButton === "filter") {
      this.filterExpanded = !this.filterExpanded;
      this.sortingExpanded = false;
    }
  }

  public orderBySelected = (event: any): void => {
    this.closeBothMenus();
    const fieldName = event.currentTarget.attributes.name.value;
    this.currentOrder = _(this.resultView.orderingOptions).find(
      (x: OrderingOption) => x.fieldName === fieldName
    );
    const defaultDirectionDescending = this.currentOrder
      .defaultDirectionDescending;

    this.onUpdateCurrentResult({
      resultLocation: {
        pageNumber: 1,
        sortName: fieldName,
        sortDescending: defaultDirectionDescending
      }
    });
  };

  public reverseOrder = (): void => {
    this.closeBothMenus();
    const fieldName = this.currentOrder.fieldName;
    this.descending = !this.descending;

    this.onUpdateCurrentResult({
      resultLocation: {
        pageNumber: 1,
        sortName: fieldName,
        sortDescending: !this.descending
      }
    });
  };

  public resetView(resultView: SearchViewType): void {
    this.currentOrder =
      _(resultView.orderingOptions).find(
        (x: OrderingOption) =>
          x.fieldName === this.resultView.defaultOrderingOption
      ) || resultView.orderingOptions[0];
    this.descending = this.currentOrder.defaultDirectionDescending;
  }

  private closeBothMenus(): void {
    this.sortingExpanded = false;
    this.filterExpanded = false;
  }
}

angular
  .module("PortalApp")
  .controller("Rhinestone.MobileTabsController", MobileTabsController);
