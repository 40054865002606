import * as angular from "angular";

export class QueryStringService {
  public static $inject = ["$location", "$window"];

  constructor(
    private $location: angular.ILocationService,
    private $window: angular.IWindowService
  ) {}

  public getQueryStringAsJsonString(): string {
    return JSON.stringify(this.getQueryStringAsJson());
  }

  public getQueryStringAsJson(): any {
    return this.$location.search();
  }

  public applyQueryString(query: string): void {
    const queryParsed = JSON.parse(query);
    this.$location.search(queryParsed);
    // this.$window.location.reload(); //<-- Not working on iPad. Reloads the unaltered url.
    this.$window.location.href = this.$location.absUrl();
  }
}

angular.module("PortalApp").service("queryStringService", QueryStringService);
