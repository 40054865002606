import * as angular from "angular";

export interface ISidePane {
  instanceId: string;
  sidePaneName: string;
  toggleExpand(): void;
  hide(): void;
  show(): void;
}

export class SidePaneService {
  private sidePanes = new Array<ISidePane>();

  public static $inject: any[] = [];

  constructor() {}

  public registerSidepane(sidePane: ISidePane) {
    this.sidePanes.push(sidePane);
  }
  public unregisterSidePane(sidePane: ISidePane) {
    this.sidePanes = this.sidePanes.filter(
      sidePane => sidePane.instanceId !== sidePane.instanceId
    );
  }
  public toggleSidepanes() {
    this.sidePanes.forEach(sidePane => sidePane.toggleExpand());
  }

  public hideSidePanes() {
    this.sidePanes.forEach(sidePane => {
      sidePane.hide();
    });
  }

  public showSidePane(name: "sidePane") {
    this.sidePanes
      .filter(sidePane => sidePane.sidePaneName === name)
      .forEach(sidePane => sidePane.show());
  }
}

angular.module("PortalApp").service("sidePaneService", SidePaneService);
