import * as angular from "angular";
import * as _ from "underscore";
import { SimpleChange } from "../../../TypeDefinitions/angularextensions";
import { IResultLocation } from "../IResultLocation";
import { ISearchResponse } from "../searchResponse";

import { SearchViewType, OrderingOption } from "@rhinestone/portal-web-api";

export class ResultSorterController {
  /* Input bindings */
  public onUpdateCurrentResult: (
    outputParams: { resultLocation: IResultLocation }
  ) => void;
  public resultView: SearchViewType;
  public result: ISearchResponse;

  /* Internal state */
  public pageCount: number;
  public resultTemplate: string;
  public currentPage: number = 1;
  public descending: boolean = false;
  public hasResults: boolean = false;
  public queryHasBeenExecuted: boolean = false;
  public currentOrder: OrderingOption;

  public static $inject: any = [];

  public $onChanges(changes: {
    result?: SimpleChange<ISearchResponse>;
    loading?: SimpleChange<boolean>;
    resultView?: SimpleChange<SearchViewType>;
  }) {
    if (changes.loading) {
      this.loadingStateChanged(changes.loading.currentValue);
    }

    if (changes.resultView) {
      this.onNewResultViewSet(changes.resultView.currentValue);
    }

    if (changes.result) {
      this.updateSearchResult(changes.result.currentValue);
    }
  }

  public loadingStateChanged(isLoading: boolean) {
    if (isLoading) {
      this.hasResults = false;
    }
  }

  public updateSearchResult(searchResponse: ISearchResponse) {
    if (!searchResponse) {
      this.resetResult();
    } else {
      this.onNewResponseSet(searchResponse);
    }
  }

  public resetResult() {
    this.queryHasBeenExecuted = false;
    this.pageCount = null;
    this.currentPage = 1;
    this.descending = false;
    this.hasResults = false;
  }

  public onNewResultViewSet(resultView: SearchViewType) {
    if (resultView == null) {
      return;
    }

    this.resultTemplate = `/templates/${resultView.templateName}`;
    this.currentOrder =
      _(resultView.orderingOptions).find(
        (x: OrderingOption) =>
          x.fieldName === this.resultView.defaultOrderingOption
      ) || resultView.orderingOptions[0];
    this.descending = this.currentOrder.defaultDirectionDescending;
  }

  public onNewResponseSet(searchResponse: ISearchResponse) {
    this.queryHasBeenExecuted = true;
    this.pageCount = Math.ceil(
      searchResponse.TotalCount / this.resultView.defaultPageSize
    );
    this.hasResults = searchResponse.TotalCount > 0;
    this.currentPage = searchResponse.PageNumber;
    this.currentOrder =
      _(this.resultView.orderingOptions).find(
        (x: OrderingOption) => x.fieldName === searchResponse.SortName
      ) || this.resultView.orderingOptions[0];
    this.descending = searchResponse.Descending;
  }

  public orderBySelected = (): void => {
    // it shouldn't work on view change where current order is not set yet
    if (this.currentOrder == null) {
      return;
    }

    this.onUpdateCurrentResult({
      resultLocation: {
        pageNumber: 1,
        sortName: this.currentOrder.fieldName,
        sortDescending: this.currentOrder.defaultDirectionDescending
      }
    });
  };

  public reverseOrder = (): void => {
    const fieldName = this.currentOrder.fieldName;

    this.onUpdateCurrentResult({
      resultLocation: {
        pageNumber: 1,
        sortName: fieldName,
        sortDescending: !this.descending
      }
    });
  };
}

angular
  .module("PortalApp")
  .controller("Rhinestone.ResultSorterController", ResultSorterController);
