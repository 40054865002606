import * as angular from "angular";
import { AssetsService } from "./assets.service";
import { IUserService } from "../Services/user.service";
import { IAssetCollectionMember } from "../Models/assetCollectionMember";
import { IPermission, Permission } from "../Services/models/permission";
import { IAddMembersRequest } from "./addMembersRequest";
import * as _ from "underscore";
import { UserViewModel } from "@rhinestone/portal-web-api";
import "./addMemberToAssetCollectionDialog.html";
import "./AddToAssetCollectionNoSelectionDialog.html";

export class AddMembersToAssetCollectionDialogController {
  public users: UserViewModel[] = [];
  public selectedUsers: UserViewModel[] = [];
  private permissions: IPermission[];
  public selectedPermissions: IPermission[];
  private maxResults = 50;

  public static $inject = [
    "$uibModalInstance",
    "$q",
    "assetCollectionId",
    "assetsService",
    "userService",
    "currentMembers"
  ];

  constructor(
    private $uibModalInstance: angular.ui.bootstrap.IModalServiceInstance,
    private $q: angular.IQService,
    private assetCollectionId: string,
    private assetsService: AssetsService,
    private userService: IUserService,
    private currentMembers: IAssetCollectionMember[]
  ) {
    this.assetsService.getPermissions().then(data => {
      this.permissions = data;
      this.selectedPermissions = _.map(this.permissions, item => {
        return {
          Permission: item.Permission,
          DisplayNameTranslationKey: item.DisplayNameTranslationKey,
          Selected: false
        } as IPermission;
      });
    });

    this.selectedUsers = [];
    this.selectedPermissions = [];
  }

  public addMembers = () => {
    if (this.canAdd()) {
      const request = {
        users: this.getSelectedUsers(),
        permissions: this.getSelectedPermissions()
      } as IAddMembersRequest;

      this.assetsService
        .addMembersToCollection(this.assetCollectionId, request)
        .then(() => {
          this.$uibModalInstance.close(request.users);
        });
    }
  };

  public permissionChanged = (target: any) => {
    if (target.Selected) {
      const lower = _.filter(
        this.selectedPermissions,
        item => item.Permission < target.Permission
      );
      _.each(lower, element => {
        element.Selected = true;
      });
    } else {
      const upper = _.filter(
        this.selectedPermissions,
        item => item.Permission > target.Permission
      );
      _.each(upper, element => {
        element.Selected = false;
      });
    }
  };

  private getSelectedPermissions = (): number[] => {
    return _.chain(this.selectedPermissions)
      .filter(item => item.Selected)
      .map(item => item.Permission)
      .value();
  };

  private getSelectedUsers = (): string[] => {
    return this.selectedUsers.map(u => u.id);
  };

  public canAdd = (): boolean => {
    return (
      this.selectedUsers &&
      this.selectedUsers.length > 0 &&
      this.getSelectedPermissions().length > 0
    );
  };

  public refreshList = (term: string, maxUsers?: number) => {
    const defer = this.$q.defer();
    if (!term) {
      defer.resolve();
      return defer.promise;
    }

    this.userService.getUsers(term, maxUsers).then((data: UserViewModel[]) => {
      this.users = _.filter(data, (user: UserViewModel) => {
        return !this.isMember(user);
      });

      defer.resolve();
    });

    return defer.promise;
  };

  private isMember = (user: UserViewModel): boolean => {
    return (
      _.find(this.currentMembers, member => member.Id === user.id) !== undefined
    );
  };

  public cancel = () => {
    this.$uibModalInstance.dismiss();
  };
}

angular
  .module("PortalApp")
  .controller(
    "Rhinestone.AddMembersToAssetCollectionDialogController",
    AddMembersToAssetCollectionDialogController
  );
