import * as angular from "angular";
import { AssetsService } from "./assets.service";
import { IDialogService } from "../Services/dialog.service";
import { INodeInfo } from "../Services/models/nodeInfo";
import { ILogicalNode } from "../Services/models/logicalNode";
import { NodeType } from "../Services/models/nodeType";
import { IMoveNodeRequest } from "../Services/models/moveNodeRequest";
import * as _ from "underscore";
import { ILocalizedNotificationService } from "../Services/localized.notification.service";
import { IAssetCollectionBase } from "./AssetCollectionBase";

export class AssetsTreeViewController {
  // Component bindings
  public assetCollection: IAssetCollectionBase;

  public isDisabled: boolean;
  public list: INodeInfo[] = [];
  private model: ILogicalNode[];
  public isBusy: boolean;
  public treeNodeOptions: any;
  public isMovingNode: boolean;
  public activeNode: INodeInfo;
  public isMobile: boolean;

  // array of unsubscribe (event) functions
  private events = new Array<any>();

  public static $inject = [
    "$scope",
    "$rootScope",
    "$timeout",
    "$mdMedia",
    "assetsService",
    "dialogService",
    "localizedNotificationService",
    "$q"
  ];

  constructor(
    private $scope: angular.IScope,
    private $rootScope: angular.IRootScopeService,

    private $timeout: angular.ITimeoutService,
    private $mdMedia: any,
    private assetsService: AssetsService,
    private dialogService: IDialogService,
    private localizedNotificationService: ILocalizedNotificationService,
    private $q: angular.IQService
  ) {}

  public $onInit() {
    this.isDisabled =
      !this.assetCollection.hasWriteAccess ||
      this.assetCollection.isArchived ||
      this.assetCollection.isTemplate;

    this.treeNodeOptions = {
      accept: () => true,
      dropped: (event: any) => {
        const targetLogicalNode = this.findLogicalNode(
          event.dest.nodesScope.$nodeScope.$modelValue.ItemId
        );
        const currentItemId = event.source.nodeScope.$modelValue.ItemId;
        const targetIndex = event.dest.index;

        this.moveNodeInternal(
          currentItemId,
          targetLogicalNode.FolderId,
          targetIndex
        );
      }
    };

    const headingAddedEvent = this.$rootScope.$on(
      "AssetCollection.HeadingAdded",
      (event: angular.IAngularEvent, args) => {
        if (
          args &&
          args.length === 2 &&
          args[0] === this.assetCollection.assetCollectionId
        ) {
          this.addHeading(args[1]);
        }
      }
    );

    this.events.push(headingAddedEvent);

    const fileAddedEvent = this.$rootScope.$on(
      "AssetCollection.FileAdded",
      (event: angular.IAngularEvent, args) => {
        if (args === this.assetCollection.assetCollectionId) {
          this.getTreeView();
        }
      }
    );

    this.events.push(fileAddedEvent);

    this.isMobile = this.$mdMedia("max-width: 767px"); // is changed to 767 since 768 is the min-width of lgMedia
    this.$scope.$watch(
      () => this.$mdMedia("max-width: 767px"),
      xs => {
        this.isMobile = xs;
      }
    );

    this.getTreeView().then(() => {
      $("nav#assets-menu").affix({ offset: { top: 300 } });
    });
  }

  public $onDestroy() {
    this.events.forEach(unsubscribe => unsubscribe());
    this.events = [];
  }

  public showButton = () => {
    return (
      this.assetCollection.hasWriteAccess &&
      !this.assetCollection.isArchived &&
      !this.assetCollection.isTemplate
    );
  };

  public addHeading = (newHeading: INodeInfo): any => {
    if (!this.activeNode || !this.isFolder(this.activeNode)) {
      // Add heading as the last child of the root node
      this.assetsService
        .createNode(
          this.assetCollection.assetCollectionId,
          this.assetCollection.rootFolderId,
          this.list.length,
          newHeading.Name
        )
        .then((assetItem: INodeInfo) => {
          this.activeNode = assetItem;
          this.getTreeView();
        });

      return null;
    }

    // The active node is a folder
    if (this.activeNode) {
      const targetLogicalNode = this.findLogicalNode(this.activeNode.ItemId);
      this.assetsService
        .createNode(
          this.assetCollection.assetCollectionId,
          targetLogicalNode.FolderId,
          targetLogicalNode.NumberOfChildren,
          newHeading.Name
        )
        .then((assetItem: INodeInfo) => {
          this.activeNode = assetItem;
          this.getTreeView();
        });
    }

    return null;
  };

  public isEmpty = (): boolean => {
    return this.list.length === 0;
  };

  public startMovingNode = (activeNode: INodeInfo) => {
    if (!this.canMoveGraph()) {
      return false;
    }

    this.activeNode.isSelected = true;
    this.isMovingNode = true;
    this.toggleSelectedState(activeNode.Nodes, true);

    return false;
  };

  public canMoveGraph = () => {
    return !this.isBusy && this.activeNode;
  };

  public moveGraphTo = (targetNode: INodeInfo) => {
    this.isMovingNode = false;
    this.activeNode.isSelected = false;
    this.toggleSelectedState(this.activeNode.Nodes, false);

    switch (this.activeNode.ItemType) {
      case NodeType.Document:
      case NodeType.Blob:
      case NodeType.Text:
        this.moveDocument(targetNode);
        break;
      case NodeType.Folder:
        this.moveFolder(targetNode);
        break;
    }

    return false;
  };

  public isFolder = (node: INodeInfo) => {
    return node.ItemType === NodeType.Folder;
  };

  public isQuote = (node: INodeInfo) => {
    return node.ItemType === NodeType.Text;
  };

  public canMoveToTop = (): boolean => {
    if (!this.activeNode || this.isBusy) {
      return false;
    }

    if (this.activeNode.Nodes.length > 0) {
      return false;
    }

    const firstLogicalNode = this.getFirstLogicalNode();
    if (firstLogicalNode) {
      if (firstLogicalNode.ItemId === this.activeNode.ItemId) {
        return false;
      }

      if (firstLogicalNode.ItemType !== NodeType.Folder) {
        return true;
      }

      return firstLogicalNode.ItemType === NodeType.Folder;
    }

    return false;
  };

  public moveGraphToTop = (): any => {
    if (!this.canMoveToTop()) {
      return null;
    }

    this.isBusy = true;
    this.isMovingNode = false;
    this.activeNode.isSelected = false;
    this.toggleSelectedState(this.activeNode.Nodes, false);

    // If the first elements in the tree is documents, then these will be placed within the folder that is moved to the top.
    const currentLogicalNode = this.getFirstLogicalNode();
    const currentNode = this.findNode(currentLogicalNode.ItemId, this.list);

    if (this.isFolder(this.activeNode)) {
      // If a folder is being moved to the top, move all items inside that folder.
      this.moveChildren(
        currentNode,
        currentLogicalNode,
        this.activeNode.Nodes.length
      ).then(() => {
        this.moveNode(
          this.activeNode.ItemId,
          this.assetCollection.rootFolderId,
          0
        ).then(_ => (this.isBusy = false));
      });
    } else {
      this.moveNode(
        this.activeNode.ItemId,
        this.assetCollection.rootFolderId,
        0
      ).then(_ => (this.isBusy = false));
    }

    return null;
  };

  private moveChildren(
    currentNode: any,
    currentLogicalNode: any,
    relativeIndex: any,
    deferred?: any
  ): angular.IPromise<{}> {
    if (!deferred) {
      deferred = this.$q.defer();
    }

    if (!this.isFolder(currentNode)) {
      this.moveNode(
        currentNode.ItemId,
        this.activeNode.FolderId,
        relativeIndex++,
        true
      ).then(() => {
        currentLogicalNode = this.findNextLogicalNode(currentNode.ItemId);
        currentNode = this.findNode(currentLogicalNode.ItemId, this.list);
        this.moveChildren(
          currentNode,
          currentLogicalNode,
          relativeIndex,
          deferred
        );
      });
    } else {
      deferred.resolve();
    }

    return deferred.promise;
  }

  public canMoveToBottom = () => {
    if (!this.activeNode || this.isBusy) {
      return false;
    }

    if (this.activeNode.ItemType === NodeType.Folder) {
      return true;
    }

    return false;
  };

  public moveGraphToBottom = (): any => {
    if (!this.canMoveToBottom()) {
      return null;
    }

    this.isMovingNode = false;
    this.activeNode.isSelected = false;
    this.toggleSelectedState(this.activeNode.Nodes, false);

    this.moveNode(
      this.activeNode.ItemId,
      this.assetCollection.rootFolderId,
      this.list.length
    );
    return null;
  };

  public isValidMoveTarget = (node: INodeInfo) => {
    // The selected node is already at this position. (Beneath itself)
    if (node.isSelected) {
      return false;
    }

    // The selected node is already at this position. (Above itself)
    const previousLogicalNode = this.findPreviousLogicalNode(
      this.activeNode.ItemId
    );
    if (previousLogicalNode) {
      if (previousLogicalNode.ItemId === node.ItemId) {
        return false;
      }
    }

    // The selected node can be moved to all other positions if it's not a folder,
    // since it won't affect any other nodes in the table of content.
    if (this.activeNode.ItemType !== NodeType.Folder) {
      return true;
    }

    // If the selected node is a folder it can't be inserted before a node that is not a folder,
    // since this would violate the rule that any document is a descendant of the last preceding header(folder).
    if (this.activeNode.ItemType === NodeType.Folder) {
      const nextlogicalNode = this.findNextLogicalNode(node.ItemId);
      if (nextlogicalNode) {
        if (nextlogicalNode.ItemType === NodeType.Folder) {
          return true; // TODO: Change this to false
        }
      }
    }

    return false; // TODO: Change this to true
  };

  private moveDocument = (targetNode: INodeInfo): any => {
    const targetLogicalNode = this.findLogicalNode(targetNode.ItemId);
    if (targetLogicalNode.ItemType === NodeType.Folder) {
      this.moveNode(this.activeNode.ItemId, targetLogicalNode.FolderId, 0);
      return null;
    }

    this.moveNode(
      this.activeNode.ItemId,
      targetLogicalNode.ParentFolderId,
      targetLogicalNode.RelativeIndex + 1
    );
    return null;
  };

  private moveFolder = (targetNode: INodeInfo): any => {
    const targetLogicalNode = this.findLogicalNode(targetNode.ItemId);
    if (targetLogicalNode.ItemType === NodeType.Folder) {
      this.moveNode(this.activeNode.ItemId, targetLogicalNode.FolderId, 0);
      return null;
    }

    if (this.activeNode.ItemType === NodeType.Folder) {
      const previousLogicalNode = this.findPreviousLogicalNode(
        this.activeNode.ItemId
      );
      if (previousLogicalNode) {
        if (previousLogicalNode.ItemId === targetLogicalNode.ItemId) {
          this.getTreeView();
          return null;
        }
      }
    }

    this.moveNode(
      this.activeNode.ItemId,
      targetLogicalNode.ParentFolderId,
      targetLogicalNode.RelativeIndex + 1
    );
    return null;
  };

  public toggleNode = (currentNode: INodeInfo) => {
    if (this.isDisabled || this.isMobile) {
      return false;
    }

    currentNode.isActive = !currentNode.isActive;
    this.deactivateAllOtherNodes(currentNode.ItemId, this.list);

    if (currentNode.isActive) {
      this.activeNode = currentNode;
    } else {
      this.activeNode = null;
      this.toggleSelectedState(this.list, false);
      this.isMovingNode = false;
    }

    return false;
  };

  private toggleSelectedState = (nodes: INodeInfo[], selected: boolean) => {
    if (!nodes) {
      return;
    }

    nodes.forEach(node => {
      node.isSelected = selected;
      if (node.Nodes && node.Nodes.length > 0) {
        this.toggleSelectedState(node.Nodes, selected);
      }
    });
  };

  public canMoveUp = (currentNode: INodeInfo) => {
    if (!currentNode) {
      return false;
    }

    if (this.isBusy) {
      return false;
    }

    const currentlogicalNode = this.findLogicalNode(currentNode.ItemId);
    return (
      currentlogicalNode &&
      !(
        currentlogicalNode.ParentFolderId ===
          this.assetCollection.rootFolderId &&
        currentlogicalNode.RelativeIndex === 0
      )
    );
  };

  public moveUp = (node: INodeInfo) => {
    if (!this.canMoveUp(node)) {
      return false;
    }

    switch (node.ItemType) {
      case NodeType.Document:
      case NodeType.Blob:
      case NodeType.Text:
        this.moveDocumentUp(node);
        break;
      case NodeType.Folder:
        this.moveFolderUp(node);
        break;
    }

    return false;
  };

  private moveDocumentUp = (currentNode: INodeInfo): any => {
    let previousLogicalNode = this.findPreviousLogicalNode(currentNode.ItemId);
    if (previousLogicalNode == null) {
      return null;
    }

    const currentlogicalNode = this.findLogicalNode(currentNode.ItemId);

    if (previousLogicalNode.FolderId !== currentlogicalNode.ParentFolderId) {
      if (previousLogicalNode.ItemType === NodeType.Folder) {
        this.moveNode(
          currentNode.ItemId,
          previousLogicalNode.FolderId,
          previousLogicalNode.NumberOfChildren
        ); // The last child of the previous folder
        return null;
      }

      this.moveNode(
        currentNode.ItemId,
        previousLogicalNode.ParentFolderId,
        previousLogicalNode.RelativeIndex
      ); // Before the previous document
      return null;
    }

    const parentRelativeIndex = previousLogicalNode.RelativeIndex;

    previousLogicalNode = this.findPreviousLogicalNode(
      previousLogicalNode.ItemId
    ); // The next item is our parent folder so we skip it
    if (previousLogicalNode == null) {
      this.moveNode(
        currentNode.ItemId,
        this.assetCollection.rootFolderId,
        parentRelativeIndex
      ); // Move to the root folder above our parent
      return null;
    }

    if (previousLogicalNode.ItemType === NodeType.Folder) {
      this.moveNode(currentNode.ItemId, previousLogicalNode.FolderId, 0); // Move to our parents folder above our parent
      return null;
    }

    this.moveNode(
      currentNode.ItemId,
      previousLogicalNode.ParentFolderId,
      previousLogicalNode.RelativeIndex + 1
    );
    return null;
  };

  private moveFolderUp = (currentNode: INodeInfo): any => {
    const previousLogicalNode = this.findPreviousLogicalNode(
      currentNode.ItemId
    );
    if (previousLogicalNode == null) {
      return null;
    }

    const currentlogicalNode = this.findLogicalNode(currentNode.ItemId);

    if (previousLogicalNode.ItemType === NodeType.Folder) {
      this.moveNodeInternal(
        currentNode.ItemId,
        previousLogicalNode.ParentFolderId,
        previousLogicalNode.RelativeIndex
      ).then(() => {
        if (currentlogicalNode.NumberOfChildren > 0) {
          this.moveChildrenInternal(
            currentlogicalNode.FolderId,
            previousLogicalNode.FolderId,
            0
          ).then(() => {
            this.getTreeView();
          });
        } else {
          this.getTreeView();
        }
      });

      return null;
    }

    this.moveNode(previousLogicalNode.ItemId, currentlogicalNode.FolderId, 0);
    return null;
  };

  public canMoveDown = (currentNode: INodeInfo) => {
    if (!currentNode) {
      return null;
    }

    const nextLogicalNode = this.findNextLogicalNode(currentNode.ItemId);
    return !this.isBusy && nextLogicalNode;
  };

  public moveDown = (node: INodeInfo) => {
    if (!this.canMoveDown(node)) {
      return false;
    }

    switch (node.ItemType) {
      case NodeType.Document:
      case NodeType.Blob:
      case NodeType.Text:
        this.moveDocumentDown(node);
        break;
      case NodeType.Folder:
        this.moveFolderDown(node);
        break;
    }

    return false;
  };

  private moveDocumentDown = (currentNode: INodeInfo): any => {
    const nextLogicalNode = this.findNextLogicalNode(currentNode.ItemId);
    if (nextLogicalNode == null) {
      return null;
    }

    if (nextLogicalNode.ItemType === NodeType.Folder) {
      this.moveNode(currentNode.ItemId, nextLogicalNode.FolderId, 0); // The first child of the next folder
      return null;
    }

    const parentLogicalNode = this.findLogicalNodeByFolderId(
      nextLogicalNode.ParentFolderId
    );
    if (parentLogicalNode) {
      this.moveNode(
        currentNode.ItemId,
        nextLogicalNode.ParentFolderId,
        nextLogicalNode.RelativeIndex + 1
      ); // After the next document
      return null;
    }

    this.moveNode(
      currentNode.ItemId,
      nextLogicalNode.ParentFolderId,
      nextLogicalNode.RelativeIndex + 1
    );
    return null;
  };

  private moveFolderDown = (currentNode: INodeInfo): any => {
    const nextLogicalNode = this.findNextLogicalNode(currentNode.ItemId);
    if (nextLogicalNode == null) {
      return null;
    }

    const currentlogicalNode = this.findLogicalNode(currentNode.ItemId);

    if (nextLogicalNode.ItemType === NodeType.Folder) {
      if (
        nextLogicalNode.ParentFolderId === currentlogicalNode.ParentFolderId
      ) {
        // Siblings
        this.moveNodeInternal(
          currentNode.ItemId,
          nextLogicalNode.ParentFolderId,
          nextLogicalNode.RelativeIndex + 1
        ).then(() => {
          if (nextLogicalNode.NumberOfChildren > 0) {
            this.moveChildrenInternal(
              nextLogicalNode.FolderId,
              currentlogicalNode.FolderId,
              currentlogicalNode.NumberOfChildren
            ).then(() => {
              this.getTreeView();
            });
          } else {
            this.getTreeView();
          }
        });

        return null;
      }

      if (nextLogicalNode.ParentFolderId === currentlogicalNode.FolderId) {
        // The next folder is a child
        this.moveChildrenInternal(
          nextLogicalNode.FolderId,
          currentlogicalNode.FolderId,
          0
        ).then(() => {
          this.moveNodeInternal(
            nextLogicalNode.ItemId,
            currentlogicalNode.ParentFolderId,
            currentlogicalNode.RelativeIndex
          ).then(() => {
            this.moveNode(
              currentlogicalNode.ItemId,
              nextLogicalNode.FolderId,
              0
            );
          });
        });

        return null;
      }

      // The next folder is a parent or greater
      this.moveChildrenInternal(
        nextLogicalNode.FolderId,
        currentlogicalNode.FolderId,
        0
      ).then(() => {
        this.moveNodeInternal(
          nextLogicalNode.ItemId,
          currentlogicalNode.ParentFolderId,
          currentlogicalNode.RelativeIndex + 1
        ).then(() => {
          this.moveNode(
            currentlogicalNode.ItemId,
            nextLogicalNode.ParentFolderId,
            nextLogicalNode.RelativeIndex
          );
        });
      });

      return null;
    }

    // Next logical node is a document

    const previousLogicalNode = this.findPreviousLogicalNode(
      currentNode.ItemId
    );
    if (previousLogicalNode) {
      if (previousLogicalNode.ItemType === NodeType.Folder) {
        this.moveNode(nextLogicalNode.ItemId, previousLogicalNode.FolderId, 0); // First child of the previous folder
        return null;
      }

      const parentLogicalFolder = this.findLogicalNodeByFolderId(
        previousLogicalNode.ParentFolderId
      );
      if (parentLogicalFolder) {
        this.moveNode(
          nextLogicalNode.ItemId,
          parentLogicalFolder.FolderId,
          previousLogicalNode.RelativeIndex + 1
        );
        return null;
      }

      this.moveNode(
        nextLogicalNode.ItemId,
        previousLogicalNode.ParentFolderId,
        previousLogicalNode.RelativeIndex + 1
      );
      return null;
    }

    this.moveNode(nextLogicalNode.ItemId, currentlogicalNode.ParentFolderId, 0); // First child of the parent folder
    return null;
  };

  public moveLeft = (currentNode: INodeInfo) => {
    if (!this.canMoveLeft(currentNode)) {
      return false;
    }

    switch (currentNode.ItemType) {
      case NodeType.Folder:
        this.moveFolderLeft(currentNode);
        break;
    }

    return false;
  };

  private moveFolderLeft = (currentNode: INodeInfo): any => {
    const currentlogicalNode = this.findLogicalNode(currentNode.ItemId);
    const parentLogicalNode = this.findLogicalNodeByFolderId(
      currentlogicalNode.ParentFolderId
    );
    if (!parentLogicalNode) {
      return null;
    }

    if (
      currentlogicalNode.RelativeIndex + 1 <
      parentLogicalNode.NumberOfChildren
    ) {
      this.moveSiblingsBelow(
        currentlogicalNode.ParentFolderId,
        currentlogicalNode.RelativeIndex + 1,
        currentlogicalNode.FolderId,
        currentlogicalNode.NumberOfChildren
      ).then(() => {
        this.moveNode(
          currentlogicalNode.ItemId,
          parentLogicalNode.ParentFolderId,
          parentLogicalNode.RelativeIndex + 1
        );
      });

      return null;
    }

    this.moveNode(
      currentlogicalNode.ItemId,
      parentLogicalNode.ParentFolderId,
      parentLogicalNode.RelativeIndex + 1
    );
    return null;
  };

  public canMoveLeft = (currentNode: INodeInfo) => {
    return (
      currentNode &&
      !this.isBusy &&
      currentNode.ItemType === 0 &&
      this.hasLogicalParent(currentNode)
    );
  };

  public moveRight = (currentNode: INodeInfo) => {
    if (!this.canMoveRight(currentNode)) {
      return false;
    }

    switch (currentNode.ItemType) {
      case NodeType.Folder:
        this.moveFolderRight(currentNode);
        break;
    }

    return false;
  };

  private moveFolderRight = (currentNode: INodeInfo): any => {
    const currentlogicalNode = this.findLogicalNode(currentNode.ItemId);
    const previousSibling = this.findLogicalNode(
      currentlogicalNode.PreviousSiblingId
    );
    if (!previousSibling || previousSibling.ItemType !== NodeType.Folder) {
      return null;
    }

    const childLogicalFolder = this.findFirstChildFolder(currentlogicalNode);
    if (!childLogicalFolder) {
      this.moveNode(
        currentNode.ItemId,
        previousSibling.FolderId,
        previousSibling.NumberOfChildren
      ); // Become child of our previous sibling
      return null;
    }

    this.moveNodeInternal(
      childLogicalFolder.ItemId,
      previousSibling.FolderId,
      previousSibling.NumberOfChildren
    ).then(() => {
      this.moveNode(
        currentlogicalNode.ItemId,
        previousSibling.FolderId,
        previousSibling.NumberOfChildren
      );
    });

    return null;
  };

  public canMoveRight = (currentNode: INodeInfo) => {
    return (
      currentNode &&
      !this.isBusy &&
      currentNode.ItemType === 0 &&
      this.hasPreviousSiblingFolder(currentNode)
    );
  };

  public canRemoveNode = (currentNode: INodeInfo) => {
    return currentNode && !this.isBusy;
  };

  public removeNode = (node: INodeInfo) => {
    if (!node) {
      return;
    }

    if (this.isBusy) {
      return;
    }

    const modalInstance = this.dialogService.openConfirmDeleteNodeDialog(node);

    modalInstance.result.then(() => {
      this.isBusy = true;
      let nextLogicalNode = this.findPreviousLogicalNode(
        this.activeNode.ItemId
      );
      if (!nextLogicalNode) {
        nextLogicalNode = this.findNextLogicalNode(this.activeNode.ItemId);
      }

      this.assetsService
        .removeNode(this.assetCollection.assetCollectionId, node.ItemId)
        .then(() => {
          this.localizedNotificationService.success(
            "assets.node_deleted_success",
            { name: node.Name }
          );
          if (nextLogicalNode) {
            this.activeNode = this.findNode(nextLogicalNode.ItemId, this.list);
          }

          this.getTreeView();
        });
    });
  };

  public editNode = (node: INodeInfo) => {
    if (!this.canEditNode(node)) {
      return;
    }

    const modalInstance = this.dialogService.openSaveAssetCollectionNodeDialog(
      this.assetCollection.assetCollectionId,
      this.assetCollection.rootFolderId,
      angular.copy(node),
      true
    );
    modalInstance.result.then(() => {
      this.getTreeView().then(() => {});
    });
  };

  public canEditNode = (node: any) => {
    return node && !this.isBusy && node.ItemType === NodeType.Folder;
  };

  public getNodeTypeIcon = (type: any) => {
    switch (type) {
      case NodeType.Folder:
        return "fa-header";
      case NodeType.Blob:
        return "fa-file-image-o";
      case NodeType.Document:
        return "fa-file-text-o";
      case NodeType.Text:
        return "fa-align-justify";
      default:
        return "fa-bug";
    }
  };

  private setActiveNode = (itemId: string) => {
    const node = this.findNode(itemId, this.list);
    if (node) {
      this.activeNode = node;
    }
  };

  private getTreeView = (): angular.IPromise<void> => {
    this.isBusy = true;
    return this.assetsService
      .getTreeView(
        this.assetCollection.assetCollectionId,
        this.assetCollection.isTemplate
      )
      .then((data: INodeInfo[]) => {
        this.model = [];
        this.getLogicalNodes(data, this.assetCollection.rootFolderId);
        this.list = this.getTreeNodes(data);

        if (this.activeNode) {
          const node = this.findNode(this.activeNode.ItemId, this.list);
          if (node != null) {
            node.isActive = true;
            this.activeNode = node;
            this.$timeout(() => {
              const element = $("#" + this.activeNode.ItemId);
              if (element) {
                const windowTop = $(window).scrollTop();
                const windowHeight = $(window).innerHeight();
                const windowBottom = windowTop + windowHeight;
                const elementTop = element.offset().top;
                const elementBottom =
                  elementTop +
                  element.children("div[ui-tree-handle]").outerHeight();

                if (elementTop - windowTop < 120) {
                  $("html, body").scrollTop(elementTop - 120);
                } else if (elementBottom - windowBottom > 20) {
                  $("html, body").scrollTop(elementBottom - windowHeight + 20);
                }
              }
            }, 0);
          }
        }
        this.isBusy = false;
      });
  };

  private findNode = (itemId: string, nodes: INodeInfo[]): INodeInfo => {
    if (!nodes || nodes.length === 0) {
      return null;
    }

    let found: any;

    nodes.forEach(item => {
      if (item.ItemId === itemId) {
        found = item;
      }

      const child = this.findNode(itemId, item.Nodes);
      if (child != null) {
        found = child;
      }
    });

    return found;
  };

  private deactivateAllOtherNodes = (itemId: string, nodes: INodeInfo[]) => {
    if (!nodes || nodes.length === 0) {
      return;
    }

    nodes.forEach(item => {
      if (item.ItemId !== itemId) {
        item.isActive = false;
      }

      this.deactivateAllOtherNodes(itemId, item.Nodes);
    });
  };

  private hasPreviousSiblingFolder = (currentNode: INodeInfo) => {
    const currentlogicalNode = this.findLogicalNode(currentNode.ItemId);
    if (!currentlogicalNode) {
      return false;
    }

    const previousSibling = this.findLogicalNode(
      currentlogicalNode.PreviousSiblingId
    );
    return previousSibling && previousSibling.ItemType === NodeType.Folder;
  };

  private hasLogicalParent = (currentNode: INodeInfo) => {
    const currentlogicalNode = this.findLogicalNode(currentNode.ItemId);
    if (!currentlogicalNode) {
      return false;
    }

    const parentLogicalNode = this.findLogicalNodeByFolderId(
      currentlogicalNode.ParentFolderId
    );
    return parentLogicalNode != null;
  };

  private getTreeNodes = (nodes: INodeInfo[]): INodeInfo[] => {
    const list: any[] = [];
    if (!nodes || nodes.length === 0) {
      return list;
    }

    nodes.forEach((node: INodeInfo) => {
      const mappedNode = angular.copy(node);
      mappedNode.Nodes = this.getTreeNodes(node.Nodes);
      mappedNode.IconCss = this.getNodeTypeIcon(node.ItemType);
      mappedNode.isSelected = false;
      if (node.Hyperlink) {
        const idName = this.assetCollection.isTemplate
          ? "assetCollectionTemplateId"
          : "assetCollectionId";
        mappedNode.Hyperlink = `${node.Hyperlink}&${idName}=${this.assetCollection.assetCollectionId}`;
        if (this.assetCollection.isArchived) {
          mappedNode.Hyperlink += "&isArchived=true";
        }
        mappedNode.Hyperlink += "&showExact=true";
      }

      list.push(mappedNode);
    });

    return list;
  };

  private moveNode = (
    currentItemId: string,
    targetFolderId: string,
    targetIndex: number,
    suppressUpdateView = false
  ): angular.IPromise<void> => {
    return this.moveNodeInternal(
      currentItemId,
      targetFolderId,
      targetIndex
    ).then(() => {
      if (!suppressUpdateView) {
        this.getTreeView();
      }
    });
  };

  private moveNodeInternal = (
    currentItemId: string,
    targetFolderId: string,
    targetIndex: number
  ): angular.IPromise<{}> => {
    const request = {
      FolderId: targetFolderId,
      Index: targetIndex
    } as IMoveNodeRequest;
    return this.assetsService.moveNode(
      this.assetCollection.assetCollectionId,
      currentItemId,
      request
    );
  };

  private moveChildrenInternal = (
    currentFolderId: string,
    targetFolderId: string,
    targetIndex: number
  ): angular.IPromise<{}> => {
    const request = {
      FolderId: targetFolderId,
      Index: targetIndex
    } as IMoveNodeRequest;
    return this.assetsService.moveChildren(
      this.assetCollection.assetCollectionId,
      currentFolderId,
      request
    );
  };

  private moveSiblingsBelow = (
    currentFolderId: string,
    fromIndex: number,
    targetFolderId: string,
    targetIndex: number
  ): angular.IPromise<{}> => {
    const request = {
      FolderId: targetFolderId,
      Index: targetIndex
    } as IMoveNodeRequest;
    return this.assetsService.moveSiblingsBelow(
      this.assetCollection.assetCollectionId,
      currentFolderId,
      fromIndex,
      request
    );
  };

  private moveSiblingsAbove = (
    currentFolderId: string,
    fromIndex: number,
    targetFolderId: string,
    targetIndex: number
  ): angular.IPromise<{}> => {
    const request = {
      FolderId: targetFolderId,
      Index: targetIndex
    } as IMoveNodeRequest;
    return this.assetsService.moveSiblingsAbove(
      this.assetCollection.assetCollectionId,
      currentFolderId,
      fromIndex,
      request
    );
  };

  private getLogicalNodes = (nodes: INodeInfo[], parentFolderId: string) => {
    nodes.forEach((node: INodeInfo, index: number) => {
      const previousSibling = index > 0 ? nodes[index - 1] : null;
      const logicalNode = this.createLogicalNode(
        angular.copy(node),
        parentFolderId,
        previousSibling,
        index
      );
      this.model.push(logicalNode);

      if (node.Nodes && node.Nodes.length > 0) {
        this.getLogicalNodes(node.Nodes, node.FolderId);
      }
    });
  };

  private createLogicalNode = (
    node: INodeInfo,
    parentFolderId: string,
    previousSibling: INodeInfo,
    relativeIndex: number
  ): ILogicalNode => {
    return {
      ItemId: node.ItemId,
      ItemType: node.ItemType,
      FolderId: node.FolderId,
      ParentFolderId: parentFolderId,
      RelativeIndex: relativeIndex,
      IconCss: this.getNodeTypeIcon(node.ItemType),
      NumberOfChildren: node.Nodes ? node.Nodes.length : 0,
      PreviousSiblingId: previousSibling ? previousSibling.ItemId : "",
      Name: node.Name
    } as ILogicalNode;
  };

  private findPreviousLogicalNode = (itemId: string): ILogicalNode => {
    const currentIndex = _.findIndex(
      this.model,
      item => item.ItemId === itemId
    );
    if (currentIndex === 0) {
      return null;
    }

    return this.model[currentIndex - 1];
  };

  private findNextLogicalNode = (itemId: string): ILogicalNode => {
    const currentIndex = _.findIndex(
      this.model,
      item => item.ItemId === itemId
    );
    if (currentIndex === this.model.length - 1) {
      return null;
    }

    return this.model[currentIndex + 1];
  };

  private findLogicalNode = (itemId: string): ILogicalNode => {
    return _.find(this.model, item => item.ItemId === itemId);
  };

  private findLogicalNodeByFolderId = (folderId: string): ILogicalNode => {
    return _.find(this.model, item => item.FolderId === folderId);
  };

  private findFirstChildFolderInFolder = (parentFolderId: string) => {
    return _.find(
      this.model,
      item =>
        item.ParentFolderId === parentFolderId &&
        item.ItemType === NodeType.Folder
    );
  };

  private findFirstChildFolder = (currentNode: ILogicalNode) => {
    return _.find(
      this.model,
      item =>
        item.ParentFolderId === currentNode.FolderId &&
        item.ItemType === NodeType.Folder
    );
  };

  private getFirstLogicalNode = () => {
    if (this.model && this.model.length > 0) {
      return this.model[0];
    }

    return null;
  };
}

angular
  .module("PortalApp")
  .controller("Rhinestone.AssetsTreeViewController", AssetsTreeViewController);
