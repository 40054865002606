import * as angular from "angular";
import * as bootstrap from "bootstrap";
import { Constants } from "../app.constants";
import { NewSearchSubscriptionDialogController } from "./newSearchSubscriptionDialog/newSearchSubscriptionDialog.controller";

export interface ISearchSubscriptionDialogService {
  openNewSearchSubscriptionDialog(query: {
    criteria: { [providerKey: string]: any[] };
  }): any;
}

export class SearchSubscriptionDialogService
  implements ISearchSubscriptionDialogService {
  public static $inject = ["$uibModal"];

  constructor(private $uibModal: angular.ui.bootstrap.IModalService) {}

  public openNewSearchSubscriptionDialog(query: {
    criteria: { [providerKey: string]: any[] };
  }) {
    const modal = this.$uibModal.open({
      templateUrl: `${
        Constants.TemplateBaseUrl
      }/SearchSubscriptions/newSearchSubscriptionDialog/newSearchSubscriptionDialog`,
      controller: NewSearchSubscriptionDialogController,
      controllerAs: "vm",
      bindToController: true,
      resolve: {
        searchQuery: query
      }
    });
    modal.result.then(() => {});
  }
}

angular
  .module("PortalApp")
  .service("searchSubscriptionDialogService", SearchSubscriptionDialogService);
