import * as angular from "angular";
import { AssetCollectionTemplateViewModel } from "@rhinestone/portal-web-api";
import { PortalDataHttpService } from "../Services/DataService/PortalDataHttpService";

export class AssetTemplatesService {
  public static $inject = ["portalDataService"];

  constructor(private portalDataService: PortalDataHttpService) {}

  public getUserTemplates(): angular.IPromise<
    AssetCollectionTemplateViewModel[]
  > {
    return this.portalDataService.get("AssetCollectionTemplates");
  }

  public getUserArchivedTemplates(): angular.IPromise<
    AssetCollectionTemplateViewModel[]
  > {
    return this.portalDataService.get("AssetCollectionTemplates/Archived");
  }
  public archiveTemplate(templateId: string): angular.IPromise<void> {
    return this.portalDataService.delete<void>(
      `AssetCollectionTemplates(${templateId})`
    );
  }
}

angular
  .module("PortalApp")
  .service("assetTemplatesService", AssetTemplatesService);
