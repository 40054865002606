import { Criteria } from "@rhinestone/portal-web-react";
import { ProductModel } from "@rhinestone/portal-web-api";

export function parseSearchString(
  searchString: string | undefined
): Criteria[] {
  if (!searchString) {
    return [];
  }
  try {
    return JSON.parse(searchString);
  } catch {
    console.error(
      "Could not parse defaultSearchString from configuration as JSON",
      searchString
    );
    return [];
  }
}

export function getSingleProductCriteria(
  singleProductPortalProduct: ProductModel
): Criteria[] {
  if (!singleProductPortalProduct) {
    return [];
  }

  return [
    {
      providerKey: "products",
      data: singleProductPortalProduct.code
    }
  ];
}
