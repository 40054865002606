import "./viewSwitch.html";
import { BaseComponent } from "../../Core/BaseComponent";
import * as angular from "angular";
import { Binding } from "../../Utils/AngularBindings";
import "./viewSwitch.controller";

export class ViewSwitchComponent extends BaseComponent {
  constructor() {
    super(
      "Rhinestone.ViewSwitchController",
      "/templates/Search/ViewSwitch/viewSwitch.html"
    );
    this.bindings = {
      views: Binding.oneWay,
      currentView: Binding.oneWay,
      onViewChanged: Binding.outputEvent
    };
  }
}

angular
  .module("PortalApp")
  .component("rhViewSwitch", new ViewSwitchComponent());
