import "./assets.html";
import { BaseComponent } from "../Core/BaseComponent";
import * as angular from "angular";
import "./assets.controller";
import "./assetsList.Component";
import "./assetTemplatesList.component";

export class AssetsComponent extends BaseComponent {
  constructor() {
    super("Rhinestone.AssetsController", "/templates/assets/assets");
  }
}

angular.module("PortalApp").component("rhAssets", new AssetsComponent());
