import "./searchFilterGroup.html";
import "./searchFilterGroup.controller";
import { BaseComponent } from "../Core/BaseComponent";
import * as angular from "angular";
import { Binding } from "../Utils/AngularBindings";

import "./StatusSearchFilter/statusSearchFilter.component";
import "./ProductSearchFilter/productSearchFilter.component";
import "./RefinementsSearchFilter/refinementsSearchFilter.component";
import "./NewLocalDocumentTypeTaxonomyFilter/newLocalDocumentTypeTaxonomyFilter.component";
import "./LocalDocumentTypeTaxonomyFilter/localDocumentTypeTaxonomyFilter.component";
import "./ProductTaxonomy/productTaxonomy.component";

export class SearchFilterGroupComponent extends BaseComponent {
  constructor() {
    super(
      "Rhinestone.SearchFilterGroupController",
      "/templates/search/searchFilterGroup.html"
    );

    this.bindings = {
      group: Binding.oneWay,
      criteria: Binding.oneWay,
      userProducts: Binding.oneWay,
      handleViewChange: Binding.oneWay,
      queryRefinements: Binding.oneWay,
      result: Binding.oneWay,
      productCode: Binding.oneWay
    };
  }
}

angular
  .module("PortalApp")
  .component("rhSearchFilterGroup", new SearchFilterGroupComponent());
