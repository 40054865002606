import "./carouselTile.html";
import { BaseComponent } from "../../Core/BaseComponent";
import * as angular from "angular";
import { Binding } from "../../Utils/AngularBindings";
import "./carouselTile.controller";
import "./carouselTile.less";

export class CarouselTileComponent extends BaseComponent {
  constructor() {
    super(
      "Rhinestone.CarouselTileController",
      "/templates/tiles/carouselTile/carouselTile.html"
    );
    this.bindings = {
      tile: Binding.oneWay
    };
  }
}
angular
  .module("PortalApp")
  .component("rhCarouselTile", new CarouselTileComponent());
