import * as angular from "angular";

export class SidePaneStorageService {
  public static $inject = ["localStorageService"];

  constructor(
    private localStorageService: angular.local.storage.ILocalStorageService
  ) {}

  public storeExpandedState(sidePaneId: string, expanded: boolean): void {
    this.localStorageService.set<boolean>(
      this.getExpandedStorageKey(sidePaneId),
      expanded
    );
  }

  public storedPinnedState(sidePaneId: string, pinned: boolean): void {
    this.localStorageService.set<boolean>(
      this.getPinnedStorageKey(sidePaneId),
      pinned
    );
  }

  public getExpandedState(sidePaneId: string): boolean | null {
    return this.localStorageService.get<boolean | null>(
      this.getExpandedStorageKey(sidePaneId)
    );
  }

  public getPinnedState(sidePaneId: string): boolean | null {
    return this.localStorageService.get<boolean | null>(
      this.getPinnedStorageKey(sidePaneId)
    );
  }

  private getExpandedStorageKey(sidePaneId: string) {
    return `pane-${sidePaneId}-expand`;
  }

  private getPinnedStorageKey(sidePaneId: string) {
    return `pane-${sidePaneId}-pin`;
  }
}

angular
  .module("PortalApp")
  .service("sidePaneStorageService", SidePaneStorageService);
