import * as angular from "angular";
import { convertMonthsToSentenceString } from "../Search/MonthsToSentenceString";
export class sentenceString {
  public static Factory(): any {
    const filterFac = () => {
      return (months: string) => {
        return convertMonthsToSentenceString(months);
      };
    };
    return filterFac;
  }
}

angular
  .module("PortalApp")
  .filter("monthsToSentenceString", sentenceString.Factory());
