import * as angular from "angular";
import { Constants } from "../app.constants";
import { LoginDialogController } from "./loginDialog.controller";
import { ICurrentUserService } from "../User/currentUser.service";
import { IPortalConfig } from "../Core/portal.provider";

export const dontShowLoginDialog = "dontShowLoginDialog";

export class LoginDialogService {
  public static $inject = ["$uibModal", "currentUserService", "portal"];

  constructor(
    private $uibModal: angular.ui.bootstrap.IModalService,
    private currentUserService: ICurrentUserService,
    private portal: IPortalConfig
  ) {}

  public initializeLoginPrompt() {
    this.shouldShowLoginPrompt().then(show => {
      if (show) {
        this.openLoginDialog();
      }
    });
  }

  private async shouldShowLoginPrompt() {
    if (
      this.portal.showLogInBox &&
      sessionStorage.getItem(dontShowLoginDialog) !== JSON.stringify(true)
    ) {
      return !(await this.currentUserService.isAuthenticatedAsync());
    } else {
      return false;
    }
  }

  private openLoginDialog() {
    const modal = this.$uibModal.open({
      templateUrl: `${Constants.TemplateBaseUrl}/controls/loginDialog`,
      controller: LoginDialogController,
      controllerAs: "vm",
      bindToController: true,
      resolve: {}
    });
    modal.result.then(() => {});
  }
}

angular.module("PortalApp").service("loginDialogService", LoginDialogService);
